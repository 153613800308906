import {
  Bank,
  CreateInvoiceParams,
  CreatePaymentParams,
  Invoice,
  LinkedBank,
  PatchInvoiceParams,
  Payment,
  PaymentRequest,
  PaymentSetting,
  UpdatePaymentSettingParams,
} from "@/types";
import { Transaction } from "@sentry/types";
import { ApiResponse, del, get, patch, post, put } from "./repository";

export default {
  listBanks(): ApiResponse<{ banks: Bank[] }> {
    return get("/banks");
  },
  buildLink(
    redirectTo: string,
    bankId: string,
    propertyId: number | string
  ): ApiResponse<{ link: string }> {
    return post("/banks/links", {
      redirect_to: redirectTo,
      bank_id: bankId,
      property_id: propertyId,
    });
  },
  finishRequisition(reference: string): ApiResponse<Record<string, never>> {
    return post("/banks/finish", {
      reference,
    });
  },
  listTransactions(
    propertyId: number | string
  ): ApiResponse<{ transactions: Transaction[] }> {
    return get(`/properties/${propertyId}/transactions`);
  },
  createInvoice(
    params: CreateInvoiceParams
  ): ApiResponse<{ invoice: Invoice }> {
    return post(`/invoices`, {
      invoice: params,
      transaction_id: params.transaction_id,
      amount: params.link_amount,
    });
  },
  patchInvoice(
    invoiceId: number,
    changes: PatchInvoiceParams
  ): ApiResponse<{ invoice: Invoice }> {
    return patch(`/invoices/${invoiceId}`, {
      changes,
    });
  },
  deleteInvoice(invoiceId: number | string): ApiResponse<{ invoice: Invoice }> {
    return del(`/invoices/${invoiceId}`);
  },
  createPayment(
    params: CreatePaymentParams
  ): ApiResponse<{ payment: Payment }> {
    return post(`/resident_payments`, { payment: params });
  },
  updatePayment(
    paymentId: number | string,
    params: CreatePaymentParams
  ): ApiResponse<{ payment: Payment }> {
    return put(`/resident_payments/${paymentId}`, { payment: params });
  },
  listPayments(
    propertyId: number | string
  ): ApiResponse<{ payments: Payment[] }> {
    return get(`/resident_payments/${propertyId}`);
  },
  listPaymentRequests(
    propertyId: number | string,
    userId: number | string
  ): ApiResponse<{ payment_requests: PaymentRequest[] }> {
    return get(`/payment_requests/${propertyId}/${userId}`);
  },
  listAllPaymentRequests(
    propertyId: number | string
  ): ApiResponse<{ payment_requests: PaymentRequest[] }> {
    return get(`/payment_requests/${propertyId}`);
  },
  listInvoices(
    propertyId: number | string
  ): ApiResponse<{ invoices: Invoice[] }> {
    return get(`/invoices/${propertyId}`);
  },
  listLinkedBanks(
    propertyId: number | string
  ): ApiResponse<{ linked_banks: LinkedBank[] }> {
    return get(`/properties/${propertyId}/linked_banks`);
  },
  getSettings(
    propertyId: number | string
  ): ApiResponse<{ payment_setting: PaymentSetting }> {
    return get(`/properties/${propertyId}/payment_settings`);
  },
  updateSettings(
    propertyId: number | string,
    changes: UpdatePaymentSettingParams
  ): ApiResponse<{ payment_setting: PaymentSetting }> {
    return post(`/properties/${propertyId}/payment_settings`, {
      changes,
    });
  },
};
