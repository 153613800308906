
import { defineComponent, reactive, ref } from "vue";
import { AppForm, FormField, FieldError } from "@/components/form/index";
import FileUploadIcon from "@/components/icons/FileUpload.vue";
import { useStore } from "vuex";
import { ValidationException } from "@/error_handling/ValidationException";
import { finActions, inFin } from "@/store/fin";
import { useRoute, useRouter } from "vue-router";
import { optimizeImage } from "@/util/image";
import { filesActions, inFiles } from "@/store/files";
import { DateTime } from "luxon";

export default defineComponent({
  components: { AppForm, FormField, FileUploadIcon, FieldError },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const inputEl = ref(null as null | HTMLInputElement);
    const formData = reactive({
      title: "",
      date: DateTime.now().toISODate(),
      amount: "",
      file: null as null | File,
      fileName: "",
    });
    const fieldErrors = ref({} as { [fieldName: string]: string[] });
    const loading = ref(false as boolean);

    async function uploadFile(file: File) {
      let blob: Blob = file;
      if (
        ["image/jpg", "image/png", "image/webp", "image/gif"].includes(
          file.type
        )
      ) {
        blob = await optimizeImage(file, 500);
      }
      return await store.dispatch(inFiles(filesActions.UPLOAD), {
        file: blob,
      });
    }

    return {
      inputEl,
      formData,
      fieldErrors,
      async handleSubmit() {
        if (formData.file == null) {
          fieldErrors.value = { file: ["Bestand verplicht"] };
          return;
        }

        loading.value = true;

        const parsedAmount = parseInt(
          (parseFloat(formData.amount) * 100).toFixed(2)
        );

        try {
          const doc = await store.dispatch(inFin(finActions.CREATE), {
            propertyId: route.params.id,
            params: {
              title: formData.title,
              amount: parsedAmount,
              date: formData.date,
            },
          });
          const uploadedFile = await uploadFile(formData.file);
          const updated = await store.dispatch(inFin(finActions.UPDATE), {
            docId: doc.id,
            updates: {
              file_id: uploadedFile.id,
            },
          });
          router.push({
            name: "properties.finances.list",
            params: {
              id: updated.property_id,
            },
          });
        } catch (e) {
          if (e instanceof ValidationException) {
            fieldErrors.value = e.getErrors();
          } else {
            throw e;
          }
        } finally {
          loading.value = true;
        }
      },
      showFilePicker() {
        if (inputEl.value == null) return;
        inputEl.value.click();
      },
      handleFileInputChange(e: InputEvent) {
        const target = e.target as HTMLInputElement;
        if (target == null || target.files == null) return;
        if (target.files.length === 0) return;
        formData.file = target.files[0];
        formData.fileName = target.files[0].name;

        if ("file" in fieldErrors.value) {
          delete fieldErrors.value["file"];
        }
      },
    };
  },
});
