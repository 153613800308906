
import { inOther, OtherActions } from "@/store/other";
import { defineComponent } from "vue";
import { useStore } from "vuex";
import GenericFinancePage from "../components/GenericFinancePage.vue";

export default defineComponent({
  components: { GenericFinancePage },
  setup() {
    const store = useStore();
    console.log("Overview");

    return {
      openMenu() {
        store.dispatch(inOther(OtherActions.SHOW_FINANCE_SIDEBAR));
      },
    };
  },
});
