import { createStore } from "vuex";
import auth from "./auth";
import properties from "./properties";
import files from "./files";
import issues from "./issues";
import users from "./users";
import chats from "./chats";
import fin from "./fin";
import accounting from "./accounting/accounting";
import other from "./other";
import { standardErrorActions } from "./plugins/standardErrorActions";
import documents from "./documents/documents";
import meetings from "./meetings/meetings";

// export interface RootState {
// }
export type RootState = Record<string, string>;

export const rootActions = {};

export const rootGetters = {};

export default createStore({
  state: (): RootState => ({}),
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    properties,
    files,
    issues,
    users,
    chats,
    fin,
    other,
    accounting,
    documents,
    meetings,
  },
  plugins: [standardErrorActions],
});
