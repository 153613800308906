<template>
  <div class="menu-dropper" ref="wrapperEl">
    <div class="target" @click="isVisible = !isVisible">
      <slot />
    </div>
    <div class="dropper" :class="{ visible: isVisible }">
      <div
        class="option"
        v-for="optionKey in Object.keys(options)"
        :key="optionKey"
        @click="select(optionKey)"
      >
        {{ options[optionKey] }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useOnClickOutside } from "@/util/click";
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isVisible = ref(false);

    return {
      wrapperEl: useOnClickOutside(() => {
        isVisible.value = false;
      }),
      isVisible,
      select(key: string) {
        emit("select", key);
        emit("select:" + key);
        isVisible.value = false;
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.menu-dropper {
  position: relative;
  z-index: 200;
}

.dropper {
  display: none;
  position: absolute;
  /* top: 100%; */
  top: calc(100% + 5px);
  background-color: white;
  border-radius: 5px;
  box-shadow: $shadow-general;
  border: 1px solid $border-general;
  /* min-width: 300px; */
  right: 0;
  flex-flow: column nowrap;
  padding: 10px 0;

  &.visible {
    display: flex;
  }
}

.option {
  padding: 10px 20px;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: rgba($bg-accent, 0.5);
  }
}

.target {
  cursor: pointer;
}
</style>
