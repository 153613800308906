<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.79895 16.101C9.09195 18.11 11.826 19.5 15 19.5C15.59 19.5 16.163 19.449 16.716 19.358L21 21.5V17.46C22.241 16.403 23 15.02 23 13.5C23 11.948 22.208 10.539 20.919 9.47302"
      stroke="currentColor"
      :stroke-width="strokeWidth"
      stroke-miterlimit="10"
    />
    <path
      d="M11 0.5C5.477 0.5 1 4.082 1 8.5C1 10.297 1.75 11.95 3 13.285V18.5L7.833 16.084C8.829 16.35 9.892 16.5 11 16.5C16.523 16.5 21 12.918 21 8.5C21 4.082 16.523 0.5 11 0.5Z"
      stroke="currentColor"
      :stroke-width="strokeWidth"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    strokeWidth: {
      type: Number,
      default: 1,
    },
    width: {
      type: String,
      default: "24px",
    },
    height: {
      type: String,
      default: "23px",
    },
  },
});
</script>
