<template>
  <button
    :type="type"
    :style="{ width }"
    :class="{ danger, thinner, thinner2, success, dark, disabled }"
    :disabled="disabled"
    @click="onClick"
  >
    <template v-if="loading">
      <Loading :color="dark || danger || success ? 'white' : undefined" />
    </template>
    <template v-else>
      <div class="icon" v-if="$slots.icon">
        <slot name="icon" />
      </div>
      <slot />
    </template>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Loading from "@/components/icons/Loader.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: { Loading },
  props: {
    to: {
      type: Object,
      defualt: null,
    },
    type: {
      type: String,
      default: "button",
    },
    width: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    thinner: {
      type: Boolean,
      default: false,
    },
    thinner2: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();

    return {
      onClick() {
        emit("click");
        if (props.to != null) {
          router.push(props.to);
        }
      },
    };
  },
});
</script>

<style lang="scss" scoped>
button {
  background-color: $bg-accent;
  color: $text-alt;
  font-size: 1.125rem;
  border-radius: 5px;
  font-weight: 500;
  font-family: $font-text;
  border: 1px solid #d7a62c;
  padding: 20px;
  min-height: 55px;
  cursor: pointer;
  transition: all 80ms ease-in-out;
  box-sizing: border-box;
  letter-spacing: 0.01em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  line-height: 100%;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background-color: $bg-accent-hover;
  }

  &:active {
    transition: all 0.2s ease-in-out;
    background-color: $bg-accent-hover;
    box-shadow: 0 0 4px 2px rgba($bg-accent, 0.7);
  }

  &.danger {
    background-color: $bg-error;
    color: white;

    &:hover {
      background-color: darken($bg-error, 10%);
      transform: scale(1.05);

      @include screen-from("desktop") {
        transform: scale(1.03);
      }
    }
  }

  &.success {
    background-color: $bg-success;
    color: white;

    &:hover {
      transform: scale(1.05);

      @include screen-from("desktop") {
        transform: scale(1.03);
      }
    }
  }

  &.dark {
    background-color: $bg-mobile-menu;
    color: white;

    &:hover {
      background-color: lighten($bg-mobile-menu, 5%);
      transform: scale(1.05);

      @include screen-from("desktop") {
        transform: scale(1.03);
      }
    }
  }

  &.disabled {
    opacity: 0.6;
    cursor: none;
    user-select: none;
    pointer-events: none;
  }

  &.thinner {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &.thinner2 {
    padding: 6px 15px;
    min-height: 45px;
  }
}

.icon {
  margin-right: 7px;
  padding-top: 2px;
}
.danger .icon {
  color: white;
}
</style>
