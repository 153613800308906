import { RouteRecordRaw } from "vue-router";
import ListNews from "./pages/ListNews.vue";

export const routes: RouteRecordRaw[] = [
  {
    path: "/news",
    name: "news.list",
    component: ListNews,
  },
];
