<template>
  <generic-page-popup title="Hoofdsleutel">
    <div class="total">
      Totaal: 10 000 duizendsten
      <a href="#" class="total-link">aanpassen</a>
    </div>

    <div class="lot-wrapper">
      <card>
        <div class="lot">
          <div class="lot-name">1.2C</div>
          <div class="lot-share">
            <textbox thinner placeholder="Duizendsten" />
          </div>
        </div>
      </card>
    </div>

    <div class="lot-wrapper">
      <card>
        <div class="lot">
          <div class="lot-name">1.2C</div>
          <div class="lot-share">
            <textbox thinner placeholder="Duizendsten" />
          </div>
        </div>
      </card>
    </div>

    <div class="lot-wrapper">
      <card>
        <div class="lot">
          <div class="lot-name">1.2C</div>
          <div class="lot-share">
            <textbox thinner placeholder="Duizendsten" />
          </div>
        </div>
      </card>
    </div>

    <div class="add">+ Kavel toevoegen</div>
  </generic-page-popup>
</template>

<script lang="ts">
import { showPopup } from "@/components/popups/popups";
import { defineComponent } from "vue";

const component = defineComponent({
  setup() {
    //
  },
});

export const showViewSharePopup = () => {
  return showPopup(component, {});
};

export default component;
</script>

<style lang="scss" scoped>
.total {
  font-size: 14px;
  color: $text-dark;
}

.total-link {
  color: $text-gray;
}

.add {
  width: 100%;
  box-sizing: border-box;
  padding: 15px 25px;
  text-align: center;
  display: block;
  border: 1px solid $border-general;
  border-radius: 5px;
  margin-top: 20px;
}

.lot {
  box-sizing: border-box;
  padding: 15px 25px;
}

.lot-wrapper {
  margin-top: 10px;
}

.lot-name {
  margin-right: 15px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.02em;
  margin-bottom: 5px;
}

.lot-share {
  // width: 100px;
}
</style>
