import accountingRepository from "@/repositories/accountingRepository";
import {
  Bank,
  CreateInvoiceParams,
  CreatePaymentParams,
  Invoice,
  PatchInvoiceParams,
  PaymentRequest,
  Transaction,
} from "@/types";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState } from "..";

interface AccountingState {
  banks: Bank[];
  transactions: { [propertyId: string]: Transaction[] };
  paymentRequests: PaymentRequest[];
}

export function inAccounting(action: string) {
  return "accounting/" + action;
}

export const accountingMutations = {
  SET_BANKS: "SET_BANKS",
  SET_TRANSACTIONS: "SET_TRANSACTIONS",
  ADD_PAYMENT_REQUESTS: "ADD_PAYMENT_REQUESTS",
};

export const accountingActions = {
  FETCH_BANKS: "FETCH_BANKS",
  BUILD_LINK: "BUILD_LINK",
  FINISH_BANK: "FINISH_BANK",
  FETCH_TRANSACTIONS: "FETCH_TRANSACTIONS",
  FETCH_PAYMENTS: "FETCH_PAYMENTS",
  CREATE_INVOICE: "CREATE_INVOICE",
  PATCH_INVOICE: "PATCH_INVOICE",
  DELETE_INVOICE: "DELETE_INVOICE",
  CREATE_PAYMENT: "CREATE_PAYMENT",
  UPDATE_PAYMENT: "UPDATE_PAYMENT",
  FETCH_PAYMENT_REQUESTS: "FETCH_PAYMENT_REQUESTS",
  FETCH_ALL_PAYMENT_REQUESTS: "FETCH_ALL_PAYMENT_REQUESTS",
  FETCH_INVOICES: "FETCH_INVOICES",
  FETCH_IBAN: "FETCH_IBAN",
};

export const accountingGetters = {
  BANKS: "BANKS",
  TRANSACTIONS: "TRANSACTIONS",
  ALL_TRANSACTIONS: "ALL_TRANSACTIONS",
  PAYMENT_REQUESTS: "PAYMENT_REQUESTS",
};

const state = (): AccountingState => ({
  banks: [],
  transactions: {},
  paymentRequests: [],
});

const getters: GetterTree<AccountingState, RootState> = {
  [accountingGetters.BANKS](state) {
    return state.banks;
  },
  [accountingGetters.TRANSACTIONS](state) {
    return (propertyId: number | string) => {
      return state.transactions[ensureString(propertyId)];
    };
  },
  [accountingGetters.ALL_TRANSACTIONS](state) {
    return state.transactions;
  },
  [accountingGetters.PAYMENT_REQUESTS](state) {
    return state.paymentRequests;
  },
};

const mutations: MutationTree<AccountingState> = {
  [accountingMutations.SET_BANKS](state, banks) {
    state.banks = banks;
  },
  [accountingMutations.SET_TRANSACTIONS](
    state,
    props: { propertyId: number | string; transactions: Transaction[] }
  ) {
    const propertyId: string =
      typeof props.propertyId === "number"
        ? props.propertyId + ""
        : props.propertyId;
    state.transactions[propertyId] = props.transactions;
  },
  [accountingMutations.ADD_PAYMENT_REQUESTS](
    state,
    paymentRequests: PaymentRequest[]
  ) {
    const paymentRequestsObj: { [id: string]: PaymentRequest } = {};
    state.paymentRequests.forEach((pr) => (paymentRequestsObj[pr.id] = pr));
    paymentRequests.forEach((pr) => (paymentRequestsObj[pr.id] = pr));
    state.paymentRequests = Object.values(paymentRequestsObj);
  },
};

const actions: ActionTree<AccountingState, RootState> = {
  async [accountingActions.UPDATE_PAYMENT](
    context,
    data: {
      putParams: CreatePaymentParams;
      paymentId: number | string;
      propertyId: number | string;
    }
  ) {
    const response = await accountingRepository.updatePayment(
      data.paymentId,
      data.putParams
    );
    context.dispatch(accountingActions.FETCH_TRANSACTIONS, data.propertyId);
    return response.data.data.payment;
  },
  async [accountingActions.CREATE_PAYMENT](
    context,
    data: {
      createParams: CreatePaymentParams;
      propertyId: number | string;
    }
  ) {
    const response = await accountingRepository.createPayment(
      data.createParams
    );
    const payment = response.data.data.payment;
    context.dispatch(accountingActions.FETCH_TRANSACTIONS, data.propertyId);
    return payment;
  },
  async [accountingActions.CREATE_INVOICE](
    context,
    data: {
      createParams: CreateInvoiceParams;
      propertyId: number | string;
    }
  ) {
    const response = await accountingRepository.createInvoice(
      data.createParams
    );
    context.dispatch(accountingActions.FETCH_TRANSACTIONS, data.propertyId);
    return response.data.data.invoice;
  },
  async [accountingActions.PATCH_INVOICE](
    context,
    params: {
      propertyId: number;
      invoiceId: number;
      changes: PatchInvoiceParams;
    }
  ) {
    const response = await accountingRepository.patchInvoice(
      params.invoiceId,
      params.changes
    );
    context.dispatch(accountingActions.FETCH_TRANSACTIONS, params.propertyId);
    return response.data.data.invoice;
  },
  async [accountingActions.DELETE_INVOICE](
    context,
    params: {
      propertyId: number;
      invoiceId: number;
    }
  ) {
    const response = await accountingRepository.deleteInvoice(params.invoiceId);
    context.dispatch(accountingActions.FETCH_TRANSACTIONS, params.propertyId);
    return response.data.data.invoice;
  },
  async [accountingActions.FETCH_BANKS](context) {
    const response = await accountingRepository.listBanks();
    const banks = response.data.data.banks;
    context.commit(accountingMutations.SET_BANKS, banks);
  },
  async [accountingActions.BUILD_LINK](
    context,
    { redirectTo, bankAspspId, propertyId }
  ) {
    const response = await accountingRepository.buildLink(
      redirectTo,
      bankAspspId,
      propertyId
    );
    return response.data.data.link;
  },
  async [accountingActions.FINISH_BANK](context, { reference }) {
    await accountingRepository.finishRequisition(reference);
  },
  async [accountingActions.FETCH_TRANSACTIONS](context, propertyId: string) {
    if (propertyId == null) return;
    const response = await accountingRepository.listTransactions(propertyId);
    const transactions = response.data.data.transactions;
    context.commit(accountingMutations.SET_TRANSACTIONS, {
      propertyId,
      transactions,
    });
    return;
  },
  async [accountingActions.FETCH_PAYMENTS](context, propertyId: string) {
    if (propertyId == null) return;
    const response = await accountingRepository.listPayments(propertyId);
    return response.data.data.payments;
  },
  async [accountingActions.FETCH_PAYMENT_REQUESTS](
    context,
    props: {
      propertyId: number | string;
      userId: number | string;
    }
  ): Promise<PaymentRequest[]> {
    const response = await accountingRepository.listPaymentRequests(
      props.propertyId,
      props.userId
    );
    const requests = response.data.data.payment_requests;
    context.commit(accountingMutations.ADD_PAYMENT_REQUESTS, requests);
    return requests;
  },
  async [accountingActions.FETCH_ALL_PAYMENT_REQUESTS](
    context,
    props: {
      propertyId: number | string;
    }
  ): Promise<PaymentRequest[]> {
    const response = await accountingRepository.listAllPaymentRequests(
      props.propertyId
    );
    const requests = response.data.data.payment_requests;
    context.commit(accountingMutations.ADD_PAYMENT_REQUESTS, requests);
    return requests;
  },
  async [accountingActions.FETCH_INVOICES](
    context,
    props: {
      propertyId: number | string;
    }
  ): Promise<Invoice[]> {
    const response = await accountingRepository.listInvoices(props.propertyId);
    return response.data.data.invoices;
  },
  async [accountingActions.FETCH_IBAN](
    context,
    props: {
      propertyId: number | string;
    }
  ): Promise<string> {
    const response = await accountingRepository.listLinkedBanks(
      props.propertyId
    );
    const linkedBanks = response.data.data.linked_banks;
    if (linkedBanks.length > 0) {
      return linkedBanks[0].iban;
    }
    return "not-found";
  },
};

function ensureString(value: string | number) {
  if (typeof value === "number") return value + "";
  return value;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
