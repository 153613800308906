
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    strokeWidth: {
      type: String,
      default: "2",
    },
  },
});
