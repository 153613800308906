<template>
  <router-link v-if="to != null" :to="to" class="link">
    <slot />
  </router-link>
  <div v-else class="link" @click="$emit('click')">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],
  props: {
    to: {},
  },
});
</script>

<style lang="scss" scoped>
.link {
  cursor: pointer;
  color: $text-alt;
  font-size: 18px;
  text-decoration: none;
  font-weight: 500;
  text-align: center;
  display: block;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    opacity: 0.8;
  }

  @include screen-from("tablet") {
    font-size: 1.25rem;
  }
}
</style>
