<template>
  <div class="switch" @click="handleClick">
    <h2 class="label" v-if="label != null">
      {{ label }}
    </h2>
    <div class="wrapper">
      <div class="option" :class="{ 'option--active': active }">Ja</div>
      <div class="option" :class="{ 'option--active': !active }">Nee</div>
      <div class="lever" :class="{ 'lever--active': !active }"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const active = ref(props.modelValue as boolean);
    onMounted(() => {
      emit("update:modelValue", props.modelValue === true);
    });

    watch(
      () => props.modelValue,
      () => {
        active.value = props.modelValue;
      }
    );

    return {
      active,
      handleClick() {
        active.value = !active.value;
        emit("update:modelValue", active.value);
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.switch {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  font-family: $font-title;
  font-size: 17px;
  line-height: 22px;
  font-weight: 500;
  color: $text-dark;
  width: 100%;
}

.wrapper {
  width: 120px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #e1d8b2;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 3px 5px;
  cursor: pointer;
  // box-sizing: border-box;
}

.option {
  font-family: $font-text;
  font-size: 15px;
  font-weight: 600;
  color: $text-gray;
  width: 60px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease-in-out;
  user-select: none;

  &--active {
    color: $text-dark;
  }
}

.lever {
  position: absolute;
  width: 60px;
  height: 35px;
  background-color: $bg-accent;
  z-index: -1;
  border-radius: 5px;
  left: 5px;
  transition: left 0.2s ease-in-out;
  pointer-events: none;

  &--active {
    left: calc(100% - 60px - 5px);
  }
}

.label {
  box-sizing: border-box;
  line-height: 120%;

  @include screen-from("tablet") {
    font-size: 1.5rem;
  }
}
</style>
