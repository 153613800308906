<template>
  <generic-page-popup title="Vergadering aanpassen">
    <div class="form">
      <generated-form
        submitText="Opslaan"
        :fieldSettings="formSettings"
        :submitHandler="submit"
        :fieldValues="values"
      />
    </div>
  </generic-page-popup>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import GeneratedForm, {
  FieldsSettings,
} from "@/components/form/GeneratedForm.vue";
import { EditMeetingParams, Meeting, Time } from "@/types";
import { useStore } from "vuex";
import { showPopup } from "@/components/popups/popups";
import { inMeetings, meetingsActions } from "@/store/meetings/meetings";
import { invalidate } from "@/store/util/invalidate";
import { showMeetingPopup } from "../ViewMeeting/ViewMeeting.vue";
import {
  dateOrStringToDate,
  dateOrStringToDateTime,
  mergeDateAndTime,
} from "../../helpers/datetime";

interface Params {
  meeting: Meeting;
}

const component = defineComponent({
  components: { GeneratedForm },
  props: {
    params: {
      type: Object as PropType<Params>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const formSettings: FieldsSettings = [
      {
        name: "scheduled_for",
        type: "date",
        label: "Datum",
        meta: {
          placeholder: "Datum",
        },
      },
      {
        name: "scheduled_for_time",
        type: "time",
        label: "Tijd",
        meta: {
          placeholder: "Tijd",
        },
      },
      {
        name: "location",
        type: "string",
        label: "Locatie",
        meta: {
          placeholder: "Waar vindt de vergadering plaats?",
        },
      },
      {
        name: "description",
        type: "textarea",
        label: "Beschrijving (optioneel)",
        meta: {
          placeholder: "Vrije extra beschrijving",
        },
      },
    ];

    const meeting = computed(() => props.params.meeting);
    const values = computed(() => {
      const dt = dateOrStringToDateTime(meeting.value.scheduled_for);
      return {
        scheduled_for: dateOrStringToDate(meeting.value.scheduled_for),
        scheduled_for_time: {
          hours: dt?.hour,
          minutes: dt?.minute,
          seconds: dt?.second,
        },
        location: meeting.value.location,
        description: meeting.value.description,
      };
    });

    return {
      values,
      formSettings,
      async submit(data: EditMeetingParams & { scheduled_for_time: Time }) {
        const editedMeeting = await store.dispatch(
          inMeetings(meetingsActions.UPDATE),
          {
            id: props.params.meeting.id,
            changes: {
              location: data.location,
              description: data.description,
              scheduled_for:
                mergeDateAndTime(
                  data.scheduled_for ??
                    dateOrStringToDate(meeting.value.scheduled_for),
                  data.scheduled_for_time
                ) ?? undefined,
            },
          }
        );
        invalidate("meetings");
        showMeetingPopup(editedMeeting);
        emit("finish");
      },
    };
  },
});

export const showEditMeetingPopup = (meeting: Meeting) => {
  return showPopup(component, {
    meeting,
  });
};

export default component;
</script>
