import appSocket from "@/channels/appSocket";
import { App } from "@capacitor/app";
import { Store } from "vuex";
import { authActions, authGetters, inAuth } from "../auth";
import { chatsActions, inChats } from "../chats";

export function startStoreUpdater(store: Store<any>) {
  App.addListener("appStateChange", ({ isActive }) => {
    if (!isActive) return;
    if (!store.getters[inAuth(authGetters.HAS_AUTH)]) return;
    store.dispatch(inAuth(authActions.FETCH_ACCESS_TOKEN)).then(() => {
      appSocket.ensureConnected();
    });
    // if (!store.getters[inAuth(authGetters.ACCESS_VALID)]) return;
    store.dispatch(inChats(chatsActions.RE_FETCH));
  });
}
