
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    width: {
      type: String,
      default: "24",
    },
    color: {
      type: String,
      default: "#111",
    },
  },
});
