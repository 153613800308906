<template>
  <header class="tabs-header">
    <div class="title-bar">
      <a class="hamburger-menu" @click="openMobileSidebar">
        <hamburger />
      </a>
      <h1 class="title">{{ title }}</h1>
      <div class="extra-item"></div>
    </div>
    <div class="tabs">
      <router-link
        v-for="tab in tabs"
        :key="tab.label"
        class="tab"
        :to="tab.to"
      >
        {{ tab.label }}
      </router-link>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import Hamburger from "@/components/icons/Hamburger.vue";
import { RouteLocationRaw, useRouter } from "vue-router";
import { useStore } from "vuex";
import { inOther, OtherActions } from "@/store/other";

export interface TabsHeaderTab {
  label: string;
  to: RouteLocationRaw;
}

export default defineComponent({
  components: { Hamburger },
  props: {
    title: {
      type: String,
      required: true,
    },
    tabs: {
      type: Array as PropType<TabsHeaderTab[]>,
      required: true,
    },
  },
  setup() {
    const selected = ref(null as string | null);
    const router = useRouter();
    const store = useStore();

    return {
      selected,
      to(name: string) {
        router.push({ name });
      },
      openMobileSidebar() {
        store.dispatch(inOther(OtherActions.SHOW_MOBILE_SIDEBAR));
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.tabs-header {
  background-color: white;
  border-bottom: 1px solid $border-general;
  margin-bottom: 30px;

  @include screen-from("tablet-wide") {
    display: none;
  }
}

.title-bar {
  width: 90%;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  box-sizing: border-box;
  padding: 0 10px;
}

.title {
  color: $text-dark;
  font-size: 1.3rem;
  font-weight: 600;
  font-family: $font-title;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 16 * 1.5px;
  white-space: nowrap;
  box-sizing: border-box;
  line-height: 110%;

  &--center {
    text-align: center;
    width: 100%;
  }

  &--no-padding {
    padding: 0;
  }
}

.hamburger-menu {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.web .hamburger-menu {
  @include screen-from("tablet-wide") {
    display: none;
  }
}

.extra-item {
  width: 40px;
}

.tabs {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

.tab {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  text-decoration: none;
  color: $text-dark;
  border-bottom: 2px solid transparent;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  letter-spacing: 0.01em;

  &:hover {
    background-color: $bg-accent;
    border-color: $bg-accent;
  }
}

.router-link-active.tab {
  font-weight: 500;
  /* background-color: $bg-accent; */
  border-color: $bg-accent;
}
</style>
