
import { defineComponent, ref } from "vue";
import AngleDown from "@/components/icons/AngleDown.vue";
import { useActiveProperty, useProperties } from "@/util/properties";
import PlusIcon from "@/components/icons/Add.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { inProperties, PropertiesActions } from "@/store/properties";
import { Property } from "@/repositories/propertyRepository";
import { useOnClickOutside } from "@/util/click";

export default defineComponent({
  components: { AngleDown, PlusIcon },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const activeProperty = useActiveProperty();
    const isOpen = ref(false);
    const properties = useProperties();

    function closeDropdown() {
      isOpen.value = false;
    }

    return {
      propertySelectorEl: useOnClickOutside(() => {
        isOpen.value = false;
      }),
      activeProperty,
      isOpen,
      properties,
      toggleDropdown() {
        isOpen.value = !isOpen.value;
      },
      async setProperty(property: Property) {
        closeDropdown();

        store.dispatch(inProperties(PropertiesActions.SELECT_PROPERTY), {
          propertyId: property.id,
        });

        if (route.params["propertyId"] != null) {
          if (route.name == null) {
            // TODO: handle when route name does not exist
            console.error("Route is missing a name:", route.path);
            return;
          }
          const newParams: any = {};
          Object.keys(route.params).forEach((key) => {
            if (key === "propertyId")
              newParams["propertyId"] = property.id.toString();
            else newParams[key] = route.params[key];
          });
          await router.push({
            name: route.name.toString(),
            params: newParams,
          });
        } else if (
          route.name != null &&
          route.name.toString().startsWith("properties") &&
          route.params?.id != null
        ) {
          const newParams: any = {};
          Object.keys(route.params).forEach((key) => {
            if (key === "id") newParams["id"] = property.id.toString();
            else newParams[key] = route.params[key];
          });
          router.push({
            name: route.name.toString(),
            params: newParams,
          });
        }
      },
      toCreate() {
        closeDropdown();
        router.push({ name: "properties.create" });
      },
    };
  },
});
