<template>
  <popup-wrapper @finish="$emit('finish')" class="info-popup">
    <div class="content">
      <h1 class="title">
        {{ $t("accounting.extra_info") }}
      </h1>
      <p class="item" v-for="item in items" :key="item">
        {{ item }}
      </p>
    </div>
  </popup-wrapper>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import PopupWrapper from "@/components/popups/PopupWrapper.vue";

export default defineComponent({
  components: { PopupWrapper },
  props: {
    params: {
      type: Object as PropType<{ info: string }>,
      required: true,
    },
  },
  setup(props) {
    //
    return {
      items: computed(() =>
        props.params.info
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll("'", "")
          .split(",")
      ),
    };
  },
});
</script>

<style lang="scss" scoped>
.content {
  padding: 20px;
  line-height: 130%;
}

.title {
  font-size: 1.125rem;
  color: $text-alt;
  font-weight: 600;
  font-family: $font-title;
  margin-bottom: 7px;
}
</style>
