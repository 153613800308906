<template>
  <div class="finance-item" @click="$emit('click')">
    <div class="date">{{ formattedDate }}</div>
    <div class="name">{{ doc.title }}</div>
    <div class="price">{{ formattedAmount }}</div>
    <a
      v-if="!noDownload"
      :href="doc.file.url"
      target="_blank"
      class="download"
      >{{ $t("common.download") }}</a
    >
    <div class="bg"></div>
  </div>
</template>

<script lang="ts">
import { DateTime } from "luxon";
import { computed, defineComponent } from "vue";
import { formatAsEuro } from "@/util/price";

export default defineComponent({
  emits: ["click"],
  props: {
    doc: {
      type: Object,
      required: true,
    },
    noDownload: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const formattedAmount = computed(() => {
      const rawAmount = props.doc.amount as number;
      return formatAsEuro(rawAmount);
    });

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dec",
    ];
    const formattedDate = computed(() => {
      const dt = DateTime.fromISO(props.doc.date);
      const monthName = monthNames[dt.month];

      return `${dt.day} ${monthName} ${dt.year}`;
    });

    return {
      formattedAmount,
      formattedDate,
    };
  },
});
</script>

<style lang="scss" scoped>
.finance-item {
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 5px;
  border: 1px solid $border-general;
  background-color: white;
  position: relative;
}

.date {
  font-size: 0.875rem;
  color: $text-dark;
  letter-spacing: 0.02rem;
  line-height: 1;
  font-family: $font-text;
}

.name {
  font-size: 1.3125rem;
  font-family: $font-title;
  line-height: 1.2;
  margin-bottom: 4px;
  margin-top: 8px;
  color: $text-alt;
  font-weight: 600;
}

.price {
  font-size: 1.3125rem;
  font-family: $font-text;
  line-height: 2;
  color: $text-dark;
  font-weight: 400;
}

.download {
  color: $text-accent;
  font-size: 1.125rem;
  line-height: 2.2;
  font-weight: 600;
  font-family: $font-title;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $text-alt;
  }
}

.bg {
  position: absolute;
  bottom: -5px;
  right: 20px;
  width: 89px;
  height: 87px;
  background-image: url("/img/document.svg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
