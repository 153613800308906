<template>
  <div class="wrapper">
    <empty-profile-icon class="icon" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import EmptyProfileIcon from "@/components/icons/EmptyProfile.vue";

export default defineComponent({
  components: { EmptyProfileIcon },
});
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: $text-alt;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.icon {
  width: 87.5%;
  height: 87.5%;
  color: $bg-accent;
  padding-top: 10px;
}
</style>
