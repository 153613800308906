import { Channel, Socket } from "phoenix";
import { AppChannel } from "./AppChannel";
import store from "@/store/index";
import { Chat, Message, RemoveEventListenerFunction } from "@/types";
import { chatsActions, chatsMutations, inChats } from "@/store/chats";

// TODO: when accepting invitation to new property, also join that property

export class ChatChannels extends AppChannel {
  private _channels: Record<number, Channel> = {};

  setup(socket: Socket): void {
    store.dispatch(inChats(chatsActions.RE_FETCH)).then((chats: Chat[]) => {
      chats.forEach((chat) => this.setupChannel(socket, chat));
    });

    this.globalEmitter.on("new-chat", (data?: { chat: Chat }) => {
      if (data == null) return;
      if (this._channels[data.chat.id] != null) return;
      store.commit(inChats(chatsMutations.ADD_CHAT), data.chat);
      this.setupChannel(socket, data.chat);
    });
  }

  public async sendMessage(
    chatId: number,
    text: string,
    type: Message["type"] = "text"
  ): Promise<void> {
    let channel = this._channels[chatId];
    if (channel == null) {
      await store.dispatch(inChats(chatsActions.RE_FETCH));
      channel = this._channels[chatId];
    }
    if (channel == null) {
      throw new Error(
        `Channel for chatId ${chatId} not found, even after refresh`
      );
    }
    channel
      .push("message", { text, type })
      .receive("ok", ({ message }: { message: Message }) => {
        store.commit(inChats(chatsMutations.ADD_MESSAGES), {
          chatId,
          messages: [message],
        });
      });
    // TODO: handle receive("error")
  }

  public onMessage(
    chatId: number,
    cb: (message: Message) => void
  ): RemoveEventListenerFunction {
    return this.onAnyMessage(({ message, chat }) => {
      if (chat.id === chatId) cb(message);
    });
  }

  public onAnyMessage(
    cb: (data: { message: Message; chat: Chat }) => void
  ): RemoveEventListenerFunction {
    return this.onEmitterEvent<{ message: Message; chat: Chat }>("message", cb);
  }

  public markSeen(chatId: number, lastSeenId: number): void {
    const channel = this._channels[chatId];
    if (channel == null) return;
    store.commit(inChats(chatsMutations.SET_LAST_SEEN), {
      chatId: chatId,
      lastSeenId: lastSeenId,
    });
    channel.push("mark_seen", { last_seen_id: lastSeenId });
  }

  public ensureChannelIsSetUp(chat: Chat) {
    if (this._channels[chat.id] != null) return;
    if (this._socket == null) {
      // TODO: Logging
      console.error("No socket has been found");
      return;
    }
    this.setupChannel(this._socket, chat);
  }

  private setupChannel(socket: Socket, chat: Chat) {
    const channel = socket.channel(`chat:${chat.id}`);
    this._channels[chat.id] = channel;
    channel
      .join()
      .receive("ok", (msg) => console.log("Catching up:", JSON.stringify(msg)));
    this.initMessages(chat);
    this.initListeners(chat);
    this.initLastSeen(chat);
  }

  private initLastSeen(chat: Chat) {
    const channel = this._channels[chat.id];
    if (channel == null) return;

    channel.on("seen", (data: { last_seen_id: number }) => {
      store.commit(inChats(chatsMutations.SET_LAST_SEEN), {
        chatId: chat.id,
        lastSeenId: data.last_seen_id,
      });
    });

    channel
      .push("last_seen", {})
      .receive("ok", (data: { last_seen_id: number }) => {
        store.commit(inChats(chatsMutations.SET_LAST_SEEN), {
          chatId: chat.id,
          lastSeenId: data.last_seen_id,
        });
      });
  }

  private initListeners(chat: Chat) {
    const channel = this._channels[chat.id];
    if (channel == null) return;

    channel.on("message", ({ message }: { message: Message }) => {
      this.emitter.emit("message", { message, chat });
      store.commit(inChats(chatsMutations.ADD_MESSAGE), {
        chatId: chat.id,
        message,
      });
    });
  }

  // Loads the most recent messages
  private initMessages(chat: Chat) {
    const channel = this._channels[chat.id];
    if (channel == null) return;

    channel
      .push("messages", {})
      .receive("ok", ({ messages }: { messages: Message[] }) => {
        store.commit(inChats(chatsMutations.ADD_MESSAGES), {
          chatId: chat.id,
          messages,
        });
      });
  }
}
