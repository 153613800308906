
import { Issue } from "@/repositories/issueRepository";
import { inIssues, issueActions } from "@/store/issues";
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import PopupWrapper from "./PopupWrapper.vue";
import IssueListing from "@/components/property/IssueListing.vue";

export default defineComponent({
  components: { PopupWrapper, IssueListing },
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const issues = ref(null as null | Issue[]);

    console.log(props.params);

    // TODO: order issues newest first
    const store = useStore();
    store.dispatch(inIssues(issueActions.LIST)).then((values) => {
      console.log(values);
      if (props.params.property_id != null) {
        values = values.filter(
          (v: Issue) => v.property_id === props.params.property_id
        );
      }
      if (props.params.property_ids != null) {
        const propertyIds: number[] = props.params.property_ids.map(
          (id: number | string) => {
            if (typeof id === "string") return parseInt(id);
            return id;
          }
        );
        values = values.filter((v: Issue) => {
          return propertyIds.includes(v.id);
        });
      }
      issues.value = values;
    });

    return {
      issues,
      selectIssue(issue: Issue) {
        emit("finish", issue);
      },
    };
  },
});
