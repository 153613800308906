<template>
  <desktop-menu responsive />
  <div class="container">
    <header class="header">
      <div class="back" @click="handleBack">
        <!-- <back /> -->
      </div>
      <div>
        <logo />
      </div>
      <div class="right"></div>
    </header>
    <h1 class="title">{{ title ?? $route.meta.title }}</h1>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Logo from "@/components/Logo.vue";
import { useRouter } from "vue-router";
import DesktopMenu from "@/components/layout/desktop_menu/DesktopMenu.vue";

export default defineComponent({
  components: {
    Logo,
    DesktopMenu,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  setup() {
    const router = useRouter();
    return {
      handleBack() {
        router.back();
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  text-align: center;
  padding: 30px 0 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;

  @include desktop-mode() {
    display: none;
  }
}

.title {
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 135%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;

  @include desktop-mode() {
    margin-top: 40px;
  }
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  padding: 0 30px;
}

.back {
  justify-self: start;
  cursor: pointer;
  width: 20px;
}

.right {
  width: 20px;
}
</style>
