
import { computed, defineComponent } from "vue";
import IssueIcon from "@/components/icons/Issue.vue";
import SettingsIcon from "@/components/icons/Settings.vue";
import DocumentsIcon from "@/components/icons/Documents.vue";
import ResidentsIcon from "@/components/icons/Residents.vue";
import ChatIcon from "@/components/icons/Chat.vue";
import LotIcon from "@/components/icons/Lot.vue";
import KeyIcon from "@/components/icons/Key.vue";
import { useActiveProperty } from "@/util/properties";
import { useCurrentUser } from "@/util/auth";
import { useTotalNewMessageCount } from "@/util/chat";
import PaymentsIcon from "@/components/icons/Payment.vue";
import BookIcon from "@/components/icons/Book.vue";
import HandshakeIcon from "@/components/icons/Handshake.vue";
import { useCurrentUserPaymentRequests } from "@/store/accounting/hooks";
import { useIssues } from "@/store/issues/hooks";
import { useUnvisitedItemIds } from "@/store/documents/hooks";

export default defineComponent({
  components: {
    KeyIcon,
    HandshakeIcon,
    IssueIcon,
    SettingsIcon,
    DocumentsIcon,
    ResidentsIcon,
    ChatIcon,
    PaymentsIcon,
    BookIcon,
    LotIcon,
  },
  setup() {
    const activeProperty = useActiveProperty();
    const currentUser = useCurrentUser();
    const isAdmin = computed(() => {
      if (currentUser.value == null || activeProperty.value == null)
        return false;
      return currentUser.value.id === activeProperty.value.admin_id;
    });
    const { data: unvisitedItemIds } = useUnvisitedItemIds();
    const unvisitedItemText = computed(() => {
      if (unvisitedItemIds.value == null || unvisitedItemIds.value.length === 0)
        return null;
      if (unvisitedItemIds.value.length > 99) {
        return "9+";
      }
      return unvisitedItemIds.value.length.toString();
    });

    const newMessageCount = useTotalNewMessageCount();
    const newMessageText = computed(() => {
      if (newMessageCount.value == null || newMessageCount.value === 0)
        return null;
      if (newMessageCount.value > 99) return "9+";
      return newMessageCount.value.toString();
    });

    const { data: issues } = useIssues();
    const activeIssuesText = computed(() => {
      if (issues.value == null) return null;
      const activeIssuesCount = issues.value.length;
      if (activeIssuesCount === 0) return null;
      if (activeIssuesCount > 99) return "9+";
      return activeIssuesCount.toString();
    });

    const { paymentRequests } = useCurrentUserPaymentRequests();

    return {
      unvisitedItemText,
      newMessageText,
      activeIssuesText,
      activeProperty,
      isAdmin,
      hasPayments: computed(() => paymentRequests.value.length > 0),
    };
  },
});
