import { Router } from "vue-router";
import { guessNavigatorLanguage } from "@/helpers/language";
import { setLocale } from "@/plugins/install_i18n";

export const LOCALSTORAGE_KEY = "mysyndic.language";

let _currentLanguage: null | string = null;

export function registerLangMiddleware(router: Router): void {
  router.beforeEach(async (to, from, next) => {
    if (_currentLanguage != null) {
      next();
      return;
    }

    const storedLanguage = localStorage.getItem(LOCALSTORAGE_KEY);
    if (storedLanguage != null) {
      _currentLanguage = storedLanguage;
      setLocale(_currentLanguage);
      next();
      return;
    }

    const guessedLanguage = guessNavigatorLanguage(navigator);
    if (guessedLanguage === "nl") {
      _currentLanguage = "nl";
      setLocale(_currentLanguage);
      localStorage.setItem(LOCALSTORAGE_KEY, "nl");
      next();
      return;
    }

    if (to.query.language != null) {
      const language = router.currentRoute.value.query.language as string;
      setLocale(language);
      localStorage.setItem(LOCALSTORAGE_KEY, "nl");
      next();
      return;
    }

    // TODO: route to language picker
    if (to.name !== "other.languages.pick") {
      next({
        name: "other.languages.pick",
        query: {
          from: encodeURIComponent(to.fullPath),
        },
      });
      return;
    }

    next();
  });
}
