
import { defineComponent, ref } from "vue";
import BellIcon from "@/components/icons/Bell.vue";
import CheckIcon from "@/components/icons/Check.vue";
import TimesIcon from "@/components/icons/Times.vue";
import { Invite } from "@/types";
import { useStore } from "vuex";
import { inProperties, PropertiesActions } from "@/store/properties";
import appSocket from "@/channels/appSocket";
import { useRemoveListener } from "@/util/component";

export default defineComponent({
  components: { BellIcon, CheckIcon, TimesIcon },
  setup(props, { emit }) {
    const store = useStore();
    const showing = ref(false as boolean);
    const invites = ref([] as Invite[]);

    const hide = () => (showing.value = false);
    const show = () => (showing.value = true);

    const updateInvites = () => {
      if (showing.value === true) return;
      store
        .dispatch(inProperties(PropertiesActions.LIST_INVITES))
        .then((values) => {
          invites.value = values;
        });
    };
    updateInvites();

    useRemoveListener(() =>
      appSocket.getUserChannel().onInvite(() => {
        updateInvites();
      })
    );

    useRemoveListener(() =>
      appSocket.getUserChannel().onInviteRemoved(() => {
        console.log("Invite removed");
        updateInvites();
      })
    );

    const accept = (invite: Invite) => {
      store
        .dispatch(inProperties(PropertiesActions.ACCEPT_INVITE), {
          id: invite.id,
        })
        .then(() => {
          invites.value = invites.value.filter((i) => i.id !== invite.id);
          emit("properties-changed");
        });
    };

    const decline = (invite: Invite) => {
      store
        .dispatch(inProperties(PropertiesActions.DECLINE_INVITE), {
          id: invite.id,
        })
        .then(() => {
          invites.value = invites.value.filter((i) => i.id !== invite.id);
        });
    };

    return {
      accept,
      decline,
      hide,
      show,
      showing,
      invites,
    };
  },
});
