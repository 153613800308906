import { Property } from "@/repositories/propertyRepository";
import { inProperties, PropertiesGetters } from "@/store/properties";
import { computed, Ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export function useProperties(): Ref<Property[]> {
  const store = useStore();
  return computed(
    () => store.getters[inProperties(PropertiesGetters.PROPERTIES)]
  );
}

// If a propertyId is passed, will select the given propertyId
export function useActiveProperty(): Ref<Property | null> {
  const store = useStore();

  return computed(
    () => store.getters[inProperties(PropertiesGetters.ACTIVE_PROPERTY)]
  );
}

export function withActiveProperty(
  cb: (property: Property) => unknown,
  settings: { timeout?: number } = {}
) {
  const router = useRouter();
  const activeProperty = useActiveProperty();

  const timeout = settings.timeout ?? 2000;
  const timeoutIndex = setTimeout(() => {
    router.push({
      name: "properties",
      query: {
        back: router.currentRoute.value.fullPath,
      },
    });
  }, timeout);

  watchEffect(() => {
    if (activeProperty.value == null) return;
    cb(activeProperty.value);
    clearTimeout(timeoutIndex);
  });
}

export function sharesForResident(property: Property, residentId: number) {
  if (property == null) return 0;
  const userProperty = property.user_properties.find(
    (up) => up.user_id === residentId
  );
  if (userProperty == null) return 0;
  return userProperty.shares;
}

/**
 * Sum of all shares of all residents (used to check if it = the total shares)
 */
export function sumOfResidentShares(property: Property) {
  if (property == null) return 0;
  return property.user_properties.reduce((sum, up) => sum + up.shares, 0);
}
