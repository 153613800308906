import { RouteRecordRaw } from "vue-router";
import ListMeetings from "./pages/ListMeetings.vue";
import CreateMeeting from "./pages/CreateMeeting.vue";

export const routes: RouteRecordRaw[] = [
  {
    path: "/meetings",
    name: "meetings.list",
    component: ListMeetings,
  },
  {
    path: "/meetings/new",
    name: "meetings.new",
    component: CreateMeeting,
  },
];
