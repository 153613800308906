import { attemptRegister } from "@/plugins/install_push";
import { Router } from "vue-router";

const timeBetweenAttemptsMs = 1000 * 60;
let lastAttempt: number | null = null;

export function registerNotificationMiddleware(router: Router) {
  router.beforeEach((to, from, next) => {
    const now = Date.now();
    if (lastAttempt == null || now - timeBetweenAttemptsMs > lastAttempt) {
      lastAttempt = now;
      attemptRegister();
    }
    next();
  });
}
