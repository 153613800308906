
import { defineComponent, PropType } from "vue";
import { useStore } from "vuex";
import { inOther, OtherActions } from "@/store/other";
import Hamburger from "../../icons/Hamburger.vue";
import GenericPageOptions, {
  GenericPageOption,
} from "./GenericPageOptions.vue";
import BackIcon from "@/components/icons/AngleLeft.vue";
import { GenericPageActions as GenericPageActionsType } from "./GenericPageActions.vue";
import GenericPageActions from "./GenericPageActions.vue";

export default defineComponent({
  components: { Hamburger, GenericPageOptions, BackIcon, GenericPageActions },
  props: {
    title: {
      type: String,
      required: true,
    },
    // Only shown from tablet & up
    longTitle: {
      type: String,
      default: null,
    },
    hideOnDesktop: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as PropType<GenericPageOption[]>,
      default: null,
    },
    backButton: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Object as PropType<GenericPageActionsType>,
      default: null,
    },
    titleInfo: {
      type: Object as PropType<{ title: string; text: string }>,
      default: null,
    },
  },
  setup() {
    const store = useStore();

    return {
      openMobileSidebar() {
        store.dispatch(inOther(OtherActions.SHOW_MOBILE_SIDEBAR));
      },
    };
  },
});
