import { authActions, inAuth } from "@/store/auth";
import store from "@/store/index";

// Automatically refresh tokens every 9 minutes
const REFRESH_EVERY_MS = 1000 * 60 * 9;

// let _timer: null | number = null;

export function startAuthTimer(): void {
  // if (_timer != null) return;
  // _timer = setInterval(() => {
  //   console.log("Refreshing auth.");
  //   store.dispatch(inAuth(authActions.FETCH_ACCESS_TOKEN));
  // }, REFRESH_EVERY_MS);
}
