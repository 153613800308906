<template>
  <div class="form-error">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang="scss" scoped>
.form-error {
  font-size: 14px;
  color: $text-error;
  display: block;
  margin-top: 5px;
  line-height: 130%;
}
</style>
