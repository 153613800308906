import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/auth/Login.vue";
import Signup from "../views/auth/Signup.vue";
// import NewsFeed from "../views/news/NewsFeed.vue";
import Profile from "../views/user/Profile.vue";
import UserProperties from "../views/property/UserProperties.vue";
import UserProperty from "../views/property/UserProperty.vue";
import CreateProperty from "../views/property/CreateProperty.vue";
import EditProperty from "../views/property/EditProperty.vue";
import PropertyIssues from "../views/property/issues/PropertyIssues.vue";
import CreatePropertyIssue from "../views/property/issues/CreatePropertyIssue.vue";
import EditPropertyIssue from "../views/property/issues/EditPropertyIssue.vue";
import PropertyIssue from "../views/property/issues/PropertyIssue.vue";
import ResolvedIssues from "../views/property/issues/ResolvedIssues.vue";
import ListResidents from "../views/property/residents/ListResidents.vue";
import AddResident from "../views/property/residents/AddResident.vue";
import { registerAuthMiddleware } from "./middleware/authMiddleware";
import { registerLangMiddleware } from "./middleware/langMiddleware";
import PropertyDetails from "../views/property/PropertyDetails.vue";
import ListChats from "../views/chat/ListChats.vue";
import Chat from "../views/chat/Chat.vue";
import ListFinances from "../views/property/finance/ListFinances.vue";
import CreateFinance from "../views/property/finance/CreateFinance.vue";
import ShowFinance from "../views/property/finance/ShowFinance.vue";
import ForgotPassword from "../views/auth/ForgotPassword.vue";
import ResetPassword from "../views/auth/ResetPassword.vue";
import OAuthCallback from "../views/auth/OAuthCallback.vue";
import Init from "../views/Init.vue";
import FinishSignUp from "../views/auth/FinishSignUp.vue";
import EmailInvite from "../views/email/EmailInvite.vue";
import PickLanguage from "../views/other/PickLanguage.vue";
import SetupAccounting from "../views/accounting/setup/Setup.vue";
import SelectBank from "../views/accounting/setup/SelectBank.vue";
import SelectBankSingleUse from "../views/accounting/setup/SelectBankSingleUse.vue";
import FinishBankSelection from "../views/accounting/setup/FinishBankSelection.vue";
import FinishInAppBrowser from "../views/accounting/setup/FinishInAppBrowser.vue";

import ListTransactions from "../views/accounting/transactions/ListTransactions.vue";
import SelectShares from "../views/accounting/setup/SelectShares.vue";
import SetupSettings from "../views/accounting/setup/SetupSettings.vue";
import SelectQuarter from "../views/accounting/setup/SelectQuarter.vue";
import SelectPayment from "../views/accounting/setup/SelectPayment.vue";

import { routes as accountingRoutes } from "@/features/accounting/routes";
import { routes as documentsRoutes } from "@/features/documents/routes";
import { routes as authRoutes } from "@/features/auth/routes";
import { routes as lotsRoutes } from "@/features/lots/routes";
import { routes as meetingsRoutes } from "@/features/meetings/routes";
import { routes as newsRoutes } from "@/features/news/routes";
import { routes as financeRoutes } from "@/features/finance/routes";
import { registerNotificationMiddleware } from "./middleware/notificationMiddleware";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      layout: "Empty",
    },
  },
  {
    path: "/init",
    name: "init",
    component: Init,
    meta: {
      layout: "Empty",
    },
  },
  {
    path: "/properties",
    name: "properties",
    component: UserProperties,
  },
  {
    path: "/properties/:id",
    name: "properties.show",
    component: UserProperty,
  },
  {
    path: "/properties/:id/details",
    name: "properties.details",
    component: PropertyDetails,
  },
  {
    path: "/properties/new",
    name: "properties.create",
    component: CreateProperty,
  },
  {
    path: "/properties/:id/edit",
    name: "properties.edit",
    component: EditProperty,
  },
  {
    path: "/properties/:id/issues",
    name: "properties.issues.list",
    component: PropertyIssues,
  },
  {
    path: "/properties/:id/issues/resolved",
    name: "properties.issues.resolved.list",
    component: ResolvedIssues,
  },
  {
    path: "/properties/:id/issues/new",
    name: "properties.issues.create",
    component: CreatePropertyIssue,
    // meta: {
    //   layout: "Empty",
    // },
  },
  {
    path: "/properties/:id/issues/edit/:issueId",
    name: "properties.issues.edit",
    component: EditPropertyIssue,
  },
  {
    path: "/properties/:propertyId/issues/:issueId",
    name: "properties.issues.show",
    component: PropertyIssue,
    // meta: {
    //   layout: "Empty",
    // },
  },
  {
    path: "/properties/:id/residents",
    name: "properties.residents.list",
    component: ListResidents,
    // meta: {
    //   layout: "Empty",
    // },
  },
  {
    path: "/properties/:id/residents/new",
    name: "properties.residents.create",
    component: AddResident,
    // meta: {
    //   layout: "Empty",
    // },
  },
  {
    path: "/properties/:id/finances",
    name: "properties.finances.list",
    component: ListFinances,
  },
  {
    path: "/properties/:id/finances/new",
    name: "properties.finances.create",
    component: CreateFinance,
  },
  {
    path: "/properties/:propertyId/finances/:id",
    name: "properties.finances.show",
    component: ShowFinance,
  },
  {
    path: "/chats",
    name: "chats.list",
    component: ListChats,
  },
  {
    path: "/chats/:id",
    name: "chats.show",
    component: Chat,
    meta: {
      layout: "Empty",
    },
  },
  // {
  //   path: "/news",
  //   name: "news",
  //   component: NewsFeed,
  // },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
  },
  {
    path: "/auth/login",
    name: "login",
    component: Login,
    meta: {
      title: "Log in",
      layout: "Auth",
      requireAuth: false,
    },
  },
  {
    path: "/auth/callback/:refresh",
    name: "auth.callback",
    component: OAuthCallback,
    meta: {
      layout: "Empty",
      requireAuth: false,
    },
  },
  // TODO: finish this
  {
    path: "/auth/oauth/finish",
    name: "auth.oauth.finish",
    component: FinishSignUp,
    meta: {
      title: "Registratie voltooien",
      layout: "Auth",
      requireAuth: false,
    },
  },
  {
    path: "/auth/signup",
    name: "signup",
    component: Signup,
    meta: {
      title: "Nieuw account",
      layout: "Auth",
      requireAuth: false,
    },
  },
  {
    path: "/auth/reset",
    name: "forgot-password",
    component: ForgotPassword,
    meta: {
      title: "Wachtwoord herstellen",
      layout: "Auth",
      requireAuth: false,
    },
  },
  {
    path: "/auth/reset/:token",
    name: "reset-password",
    component: ResetPassword,
    meta: {
      title: "Nieuw wachtwoord",
      layout: "Auth",
      requireAuth: false,
    },
  },
  {
    path: "/email/invite/:token",
    name: "email.invite",
    component: EmailInvite,
    meta: {
      title: "Uitnodiging Beheren",
      layout: "Auth",
      requireAuth: false,
    },
  },
  {
    path: "/other/languages/choose",
    name: "other.languages.pick",
    component: PickLanguage,
    meta: {
      layout: "Empty",
      requireAuth: false,
    },
  },
  {
    path: "/accounting/setup",
    name: "accounting.setup",
    component: SetupAccounting,
  },
  {
    path: "/accounting/setup/settings",
    name: "accounting.setup.settings",
    component: SetupSettings,
  },
  {
    path: "/accounting/setup/quarter",
    name: "accounting.setup.quarter",
    component: SelectQuarter,
  },
  {
    path: "/accounting/select_shares",
    name: "accounting.select_shares",
    component: SelectShares,
  },
  {
    path: "/accounting/select_bank",
    name: "accounting.select_bank",
    component: SelectBank,
  },
  {
    path: "/accounting/select_bank/:bankId/:propertyId/:token",
    name: "accounting.select_bank.su",
    component: SelectBankSingleUse,
    meta: {
      layout: "Empty",
      requireAuth: false,
    },
  },
  {
    path: "/accounting/select_payment_duration",
    name: "accounting.select_payment_duration",
    component: SelectPayment,
  },
  {
    path: "/accounting/select_bank/finish_mobile/:token",
    name: "accounting.select_bank.finish_app_browser",
    component: FinishInAppBrowser,
    meta: {
      layout: "Empty",
      requireAuth: false,
    },
  },
  {
    path: "/accounting/select_bank/finish/:propertyId/:token?",
    name: "accounting.select_bank.finish",
    component: FinishBankSelection,
    meta: {
      layout: "Empty",
      requireAuth: false,
    },
  },
  // {
  //   path: "/accounting/select_bank/finish",
  //   name: "accounting.select_bank.finish",
  //   component: FinishBankSelection,
  // },
  {
    path: "/accounting/transactions/list",
    name: "accounting.transactions.list",
    component: ListTransactions,
  },
  ...accountingRoutes,
  ...documentsRoutes,
  ...authRoutes,
  ...lotsRoutes,
  ...meetingsRoutes,
  ...newsRoutes,
  ...financeRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

registerAuthMiddleware(router);
registerLangMiddleware(router);
registerNotificationMiddleware(router);

export default router;
