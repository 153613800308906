import { Chat, User } from "@/types";
import { ApiResponse, get, post } from "./repository";

export default {
  list(): ApiResponse<{ chats: Chat[] }> {
    return get("/chats");
  },
  createPropertyChat(
    propertId: number
  ): ApiResponse<{ chat: Chat; users: User[] }> {
    return post("/chats", { property_id: propertId });
  },
  createUserChat(
    otherUserId: number
  ): ApiResponse<{ chat: Chat; users: User[] }> {
    return post("/chats", { other_user_id: otherUserId });
  },
};
