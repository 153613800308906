
import { defineComponent } from "vue";
import { getPlatform, isMobilePlatform, isWebPlatform } from "@/util/platform";
import { useRouter } from "vue-router";
import Hamburger from "../../icons/Hamburger.vue";
import { useStore } from "vuex";
import { inOther, OtherActions } from "@/store/other";

export default defineComponent({
  components: { Hamburger },
  props: {
    hideDesktop: {
      type: Boolean,
      default: false,
    },
    overrideBackbutton: {
      type: Boolean,
      default: false,
    },
    hideBackButton: {
      type: Boolean,
      default: false,
    },
    centerTitle: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();

    return {
      web: isWebPlatform(),
      ios: getPlatform() === "ios",
      mobile: isMobilePlatform(),
      goBack() {
        if (props.overrideBackbutton) {
          console.log("Backbutton");
          emit("back");
        } else {
          router.go(-1);
        }
      },
      openMobileSidebar() {
        store.dispatch(inOther(OtherActions.SHOW_MOBILE_SIDEBAR));
      },
    };
  },
});
