<template>
  <div
    @click="emit('click')"
    class="filter-tag"
    v-if="count > 0"
    :class="{ active: active, success, error }"
  >
    <div class="count">
      {{ count }}
    </div>
    <div class="text" v-if="count === 1">
      {{ singular ?? plural }}
    </div>
    <div class="text" v-else>
      {{ plural }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],
  props: {
    count: {
      type: Number,
      default: 0,
    },
    singular: {
      type: String,
      default: null,
    },
    plural: {
      type: String,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.filter-tag {
  padding: 8px 14px;
  background-color: white;
  border: 1px solid $border-general;
  border-radius: 5px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.875rem;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &.active {
    border: 1px solid white;
    background-color: $bg-accent;
    color: $text-dark;
    &.error {
      background-color: $bg-error;
      color: white;
    }
    &.success {
      background-color: $bg-success;
      color: white;
    }
  }
}

.count {
  margin-right: 5px;
  font-weight: bold;
}
</style>
