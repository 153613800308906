import store from "@/store";
import { authActions, authGetters, inAuth } from "@/store/auth";
import mitt from "mitt";
import { Socket } from "phoenix";
import { ChatChannels } from "./ChatChannels";
import { PropertyChannels } from "./PropertyChannels";
import { UserChannel } from "./UserChannel";

const socketUrl =
  process.env.NODE_ENV === "production"
    ? "wss://core-api.mysyndic.com/socket"
    : "ws://localhost:4000/socket";

// const socketUrl = "wss://core-api.mysyndic.com/socket";

class AppSocket {
  private _socket: Socket | null = null;
  private _userChannel: UserChannel;
  private _propertyChannels: PropertyChannels;
  private _chatChannels: ChatChannels;

  constructor() {
    const globalEmitter = mitt();
    this._userChannel = new UserChannel(globalEmitter);
    this._propertyChannels = new PropertyChannels(globalEmitter);
    this._chatChannels = new ChatChannels(globalEmitter);
  }

  ensureConnected() {
    if (this._socket != null) return;
    if (!store.getters[inAuth(authGetters.HAS_AUTH)]) return;
    this.connect();
  }

  getUserChannel() {
    return this._userChannel;
  }

  getPropertyChannels() {
    return this._propertyChannels;
  }

  getChatChannels() {
    return this._chatChannels;
  }

  public logout() {
    this._socket = null;
    const globalEmitter = mitt();
    this._userChannel = new UserChannel(globalEmitter);
    this._propertyChannels = new PropertyChannels(globalEmitter);
    this._chatChannels = new ChatChannels(globalEmitter);
  }

  private async connect() {
    console.log("Connecting");
    if (!store.getters[inAuth(authGetters.HAS_AUTH)]) return;
    console.log("Has auth");
    // if (!store.getters[inAuth(authGetters.ACCESS_VALID)]) return;
    await store.dispatch(inAuth(authActions.FETCH_ACCESS_TOKEN));
    console.log("Fetched access");
    this._socket = new Socket(socketUrl, {
      params: () => {
        // console.log("Fetching access token");
        const accessToken = store.getters[inAuth(authGetters.ACCESS_TOKEN)];
        if (accessToken == null) {
          store.dispatch(inAuth(authActions.FETCH_ACCESS_TOKEN));
          console.log("Fetched access again?");
          // TODO: No access token? Probably need to do something here
        }
        // console.log("Access token:", accessToken);
        // const accessToken = await store.dispatch(
        //   inAuth(authActions.FETCH_ACCESS_TOKEN)
        // );
        console.log("Sending access:", accessToken);
        return { access_token: accessToken };
      },
    });
    console.log("Connecting...");
    this._socket.connect();
    this._socket.onError((socketError) => {
      console.log("Socket error:", JSON.stringify(socketError));
    });
    this._socket.onOpen(() => {
      console.log("Socket opened");
    });
    this._socket.onClose(() => {
      console.log("Socket opened");
    });
    this.setupUserChannel();
  }

  private setupUserChannel() {
    if (this._socket == null) return;
    this._userChannel.setSocket(this._socket);
    this._propertyChannels.setSocket(this._socket);
    this._chatChannels.setSocket(this._socket);
  }
}

export default new AppSocket();
