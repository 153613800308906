<template>
  <form class="write-message-bar" @submit.prevent="send">
    <div class="textbox-wrapper">
      <input
        class="textbox"
        type="text"
        v-model="content"
        :placeholder="$t('chat.input_placeholder')"
        @keydown.enter="send"
      />
      <div class="add-icon-wrapper" ref="clickOutsideRef">
        <add-icon
          class="add-icon"
          @click="showAddPopup"
          strokeWidth="2"
          width="18"
          height="18"
        />
        <add-popup
          @hide="hidePopup"
          @document="$emit('add-document')"
          @issue="$emit('add-issue')"
          @image="$emit('add-image')"
          v-if="addPopupVisible"
        />
      </div>
    </div>
    <div class="send-wrapper" @click="send">
      <send-icon />
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import SendIcon from "@/components/icons/Send.vue";
import AddIcon from "@/components/icons/Add.vue";
import AddPopup from "@/components/chat/AddPopup.vue";
import { useOnClickOutside } from "@/util/click";

export default defineComponent({
  components: { SendIcon, AddIcon, AddPopup },
  setup(props, { emit }) {
    const content = ref("");

    const addPopupVisible = ref(false);
    function showAddPopup() {
      addPopupVisible.value = !addPopupVisible.value;
    }
    function hidePopup() {
      addPopupVisible.value = false;
    }

    const clickOutsideRef = useOnClickOutside(() => hidePopup());

    return {
      clickOutsideRef,
      hidePopup,
      showAddPopup,
      addPopupVisible,
      content,
      send() {
        emit("send", content.value);
        content.value = "";
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.write-message-bar {
  display: grid;
  grid-template-columns: auto 50px;
  column-gap: 10px;
  align-items: center;
  padding: 40px 10px 30px;
  box-sizing: border-box;
}

.textbox {
  background-color: white;
  border: 1px solid $border-general;
  border-radius: 81px;
  width: 100%;
  font-size: 0.9375rem;
  font-family: $font-text;
  color: $text-alt;
  padding: 15px 50px 17px 24px;
  letter-spacing: 0.01rem;
  flex-grow: 0;
  box-sizing: border-box;
  letter-spacing: 0.01rem;
  font-weight: 500;
  outline: none;

  &:focus {
    // outline: 1px solid $bg-accent;
    border-color: $bg-accent;
  }
}

.send-wrapper {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $text-alt;
  color: $bg-accent;
  border-radius: 50%;
}

.textbox-wrapper {
  position: relative;
}

.add-icon-wrapper {
  position: absolute;
  right: 20px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.add-icon {
  opacity: 0.5;
}
</style>
