
import { defineComponent, ref } from "vue";
import EmptyProfileIcon from "@/components/icons/EmptyProfile.vue";

export default defineComponent({
  components: { EmptyProfileIcon },
  props: {
    label: {
      type: String,
      default: "",
    },
    uploadFunction: {
      type: Function,
      default: null,
    },
    modelValue: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const fileEl = ref(null as null | HTMLInputElement);
    const currentPicture = ref(null as null | string);

    return {
      fileEl,
      currentPicture,
      handleClick() {
        if (fileEl.value) {
          fileEl.value.click();
        }
      },
      async imageSelected() {
        const files = fileEl.value?.files;
        if (files == null || files.length === 0) return;
        if (props.uploadFunction != null) {
          const result = await props.uploadFunction(files[0]);
          currentPicture.value = result;
          emit("update:modelValue", result);
          console.log(currentPicture.value, props.modelValue);
        }
      },
    };
  },
});
