<template>
  <div
    class="app-container"
    :class="{ thinner, centerh }"
    :style="{ 'max-width': maxWidth, width }"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

/**
 * Only works on big sizes, mobile container should be handled by component
 */
export default defineComponent({
  props: {
    // Mostly used for forms
    thinner: {
      type: Boolean,
      defualt: false,
    },
    maxWidth: {
      type: String,
      default: undefined,
    },
    width: {
      type: String,
      default: undefined,
    },
    centerh: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.app-container {
  /* padding: 0 var(--mobile-padding-x); */
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  &.centerh {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  @include screen-from("tablet-wide") {
    max-width: 1000px;

    &.thinner {
      max-width: 800px;
    }
  }
}
</style>
