
import { PropertyLot } from "@/types";
import { defineComponent, PropType } from "vue";
import EmptyProfileIcon from "@/components/icons/EmptyProfile.vue";

export default defineComponent({
  emits: ["select"],
  components: { EmptyProfileIcon },
  props: {
    lot: {
      type: Object as PropType<PropertyLot>,
      required: true,
    },
    avatar: {
      type: String,
      default: null,
    },
    hideAvatar: {
      type: Boolean,
      default: false,
    },
  },
});
