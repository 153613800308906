import { Timeout } from "@/error_handling/Timeout";

export function sleep(timeMs: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeMs);
  });
}

export async function waitUntil(
  func: () => boolean | Promise<boolean>,
  timeBetweenTriesMs = 20,
  timeoutMs = 4000
) {
  let running = true;
  const timeoutId = setTimeout(() => {
    running = false;
    throw new Timeout("waitUntil timed out");
  }, timeoutMs);

  while (running) {
    const result = await Promise.resolve(func());
    if (result === true) {
      clearTimeout(timeoutId);
      return;
    }
    await sleep(timeBetweenTriesMs);
  }
}
