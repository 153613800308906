<template>
  <!-- <div class="property-invitation"> -->
  <div class="property-invitation" v-if="newInvitation != null">
    <div class="bg" @click="hide"></div>
    <div class="content">
      <h1 class="title">{{ $t("property.you_were_invited") }}</h1>
      <p class="text">
        {{ $t("property.you_were_invited...") }}:
        <span class="name">"{{ newInvitation.property.name }}"</span>
      </p>
      <div class="buttons">
        <button class="button accept" @click="accept">
          {{ $t("common.accept") }}
        </button>
        <button class="button decline" @click="decline">
          {{ $t("common.deny") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import appSocket from "@/channels/appSocket";
import { inProperties, PropertiesActions } from "@/store/properties";
import { Invite } from "@/types";
import { useRemoveListener } from "@/util/component";
import { defineComponent } from "vue";
import { ref } from "vue-demi";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore();
    const newInvitation = ref(null as null | Invite);

    // TODO: make impossible to interact for a few seconds so accidental click is impossible

    useRemoveListener(() =>
      appSocket.getUserChannel().onInvite((invite: Invite) => {
        newInvitation.value = invite;
      })
    );

    useRemoveListener(() =>
      appSocket.getUserChannel().onInviteRemoved((invite: Invite) => {
        if (newInvitation.value?.id === invite.id) {
          newInvitation.value = null;
        }
      })
    );

    const hide = () => {
      newInvitation.value = null;
    };

    const accept = () => {
      if (newInvitation.value == null) return;
      store
        .dispatch(inProperties(PropertiesActions.ACCEPT_INVITE), {
          id: newInvitation.value.id,
        })
        .then(() => {
          hide();
        });
    };

    const decline = () => {
      if (newInvitation.value == null) return;
      store
        .dispatch(inProperties(PropertiesActions.DECLINE_INVITE), {
          id: newInvitation.value.id,
        })
        .then(() => {
          hide();
        });
    };

    return {
      accept,
      decline,
      hide,
      newInvitation,
    };
  },
});
</script>

<style lang="scss" scoped>
.property-invitation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.bg {
  background-color: #1e1803;
  opacity: 0.9;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.content {
  z-index: 2;
  position: relative;
  margin: 0 auto;
  padding: 25px 30px 30px;
  box-sizing: border-box;
  border-radius: 10px;
  top: 13vh;
  width: 90%;
  max-width: 400px;
  background-color: white;
}

.title {
  color: $text-alt;
  font-family: $font-title;
  font-size: 1.5rem;
  line-height: 175%;
  font-weight: 600;
  margin-bottom: 10px;
}

.text {
  font-family: $font-text;
  font-size: 1rem;
  line-height: 140%;
  color: $text-dark;
}

.name {
  display: block;
  font-weight: 500;
}

.buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 30px;
}

.button {
  color: $text-light;
  border: none;
  box-shadow: none;
  padding: 10px 26px;
  border-radius: 6px;
  font-size: 1rem;
  letter-spacing: 0.02rem;

  &:first-child {
    margin-right: 10px;
  }

  &.accept {
    background-color: $bg-success;
  }

  &.decline {
    background-color: $bg-error;
  }
}
</style>
