<template>
  <div class="chat-search">
    <div class="header-icon" @click="showing = true">
      <search-icon />
    </div>
    <popup :show="showing" @hide="showing = false" :maxWidth="'800px'">
      <div class="popup-content">
        <h2 class="title">{{ $t("chat.find_convo") }}</h2>
        <input
          v-model="searchQuery"
          type="search"
          class="searchbar"
          placeholder="Zoeken.."
        />
        <div class="results">
          <div
            class="result"
            v-for="connection in sortedConnections"
            :key="connection.id"
            @click="toChat(connection)"
          >
            <template v-if="connection.type === 'user'">
              <div
                v-if="connection.user?.avatar_url != null"
                class="icon"
                :style="{
                  'background-image': `url('${connection.user?.avatar_url}')`,
                }"
              ></div>
              <div class="icon" v-else>
                <empty-profile />
              </div>
              <div class="name">
                {{ connection.user.first_name }}
                {{ connection.user.last_name }}
              </div>
            </template>
            <template v-if="connection.type === 'property'">
              <div
                class="icon"
                :style="{
                  'background-image': `url('/img/chat_property.svg')`,
                }"
              ></div>
              <div class="name">
                {{ connection.property.name }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </popup>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import SearchIcon from "@/components/icons/Search.vue";
import Popup from "@/components/util/Popup.vue";
import { useStore } from "vuex";
import { inProperties, PropertiesActions } from "@/store/properties";
import { Property } from "@/repositories/propertyRepository";
import EmptyProfile from "@/components/util/EmptyProfile.vue";
import { User } from "@/types";
import { chatsActions, inChats } from "@/store/chats";

interface Connection {
  type: "user" | "property";
  property: null | Property;
  user: null | User;
  id: string;
}

export default defineComponent({
  components: { SearchIcon, Popup, EmptyProfile },
  setup(props, { emit }) {
    const store = useStore();

    const showing = ref(false);
    const searchQuery = ref("");

    const connections = ref(null as null | Connection[]);

    store
      .dispatch(inProperties(PropertiesActions.LIST))
      .then((properties: Property[]) => {
        properties.forEach((property) => {
          if (connections.value == null) {
            connections.value = [];
          }
          connections.value.push({
            type: "property",
            user: null,
            property,
            id: "property:" + property.id,
          });
        });
      });

    store
      .dispatch(inProperties(PropertiesActions.LIST_CONNECTIONS))
      .then((users: User[]) => {
        users.forEach((user) => {
          if (connections.value == null) {
            connections.value = [];
          }
          connections.value.push({
            id: "user:" + user.id,
            type: "user",
            property: null,
            user: user,
          });
        });
      });

    const sortedConnections = computed(() => {
      const conns = connections.value;
      if (conns == null) return [];
      const query = (searchQuery.value || "").toLowerCase();
      return conns
        .filter((conn) => {
          if (query === "") return true;
          if (conn.type === "user") {
            if (conn.user == null) return false;
            return (
              conn.user.first_name.toLowerCase().includes(query) ||
              conn.user.last_name.toLowerCase().includes(query) ||
              conn.user.email.toLowerCase().includes(query)
            );
          } else {
            if (conn.property == null) return false;
            return (
              conn.property.name.toLowerCase().includes(query) ||
              conn.property.street.toLowerCase().includes(query)
            );
          }
        })
        .sort((a, b) => {
          if (a.type === "property" && b.type === "user") {
            return -1;
          }
          if (a.type === "user" && b.type === "property") {
            return 1;
          }
          if (a.type === "user") {
            if (a.user == null || b.user == null) return 0;
            return (b.user.id as number) - a.user.id;
          }
          if (a.property == null || b.property == null) return 0;
          return b.property.id - a.property.id;
        });
    });

    return {
      searchQuery,
      showing,
      connections,
      sortedConnections,
      async toChat(conn: Connection) {
        let chat;
        if (conn.type === "property" && conn.property != null) {
          chat = await store.dispatch(
            inChats(chatsActions.GET_OR_CREATE_PROPERTY_CHAT),
            {
              propertyId: conn.property.id,
            }
          );
        }
        if (conn.type === "user" && conn.user != null) {
          chat = await store.dispatch(
            inChats(chatsActions.GET_OR_CREATE_USER_CHAT),
            {
              otherUserId: conn.user.id,
            }
          );
        }
        emit("select", chat);
        showing.value = false;
        // if (chat == null) return;
        // store.commit(inChats(chatsMutations.ADD_CHAT), chat);
        // chat
        // router.push({
        //   name: "chats.show",
        //   params: { id: chat.id },
        // });
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.header-icon {
  width: 20px;
  height: 20px;
  color: $text-alt;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:hover,
  &:active {
    opacity: 1;
  }
}

.popup-content {
  padding: 25px 30px 30px;
  box-sizing: border-box;
}

.title {
  color: $text-alt;
  font-family: $font-title;
  font-size: 1.5rem;
  line-height: 175%;
  font-weight: 600;
}

.searchbar {
  width: 100%;
  border: 1px solid $border-general;
  background-color: $bg-general;
  padding: 10px 20px;
  outline: none;
  border-radius: 6px;
  font-size: 1rem;
  font-family: $font-text;
  margin-top: 10px;
  margin-bottom: 15px;

  &:focus {
    border-color: $bg-accent;
  }
}

.results {
  display: flex;
  flex-flow: column nowrap;
  margin-left: -20px;
  margin-right: -20px;
}

.result {
  width: 100%;
  box-sizing: border-box;
  padding: 15px 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid rgba($border-general, 0.4);
  }

  &:hover,
  &:active {
    background-color: rgba($bg-accent, 0.2);
  }
}

.icon {
  width: 24px;
  height: 24px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 15px;
  border-radius: 50%;
}

.name {
  color: $text-alt;
  font-weight: 500;
  letter-spacing: 0.03rem;
}
</style>
