
import { defineComponent } from "vue";
import TimesIcon from "@/components/icons/Times.vue";

export default defineComponent({
  components: { TimesIcon },
  emits: ["finish"],
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: String,
      default: "600px",
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
});
