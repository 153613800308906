import appSocket from "@/channels/appSocket";
import chatRepository from "@/repositories/chatRepository";
import { Chat, Message } from "@/types";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState } from ".";

interface ChatsState {
  chats: Chat[] | null;
  messages: Record<number, Message[]>;
  lastSeen: Record<number, number | null>;
}

// type ChatsActionContext = ActionContext<ChatsState, void>;

export function inChats(action: string): string {
  return "chats/" + action;
}

export const chatsActions = {
  LIST: "LIST",
  RE_FETCH: "RE_FETCH",
  GET_OR_CREATE_PROPERTY_CHAT: "GOCPC",
  GET_OR_CREATE_USER_CHAT: "GOCUC",
  CLEAR: "CLEAR",
};

export const chatsGetters = {
  MESSAGES: "MESSAGES",
  CHAT_BY_ID: "CHAT_BY_ID",
  NEW_MESSAGE_COUNT: "NEW_MESSAGE_COUNT",
  TOTAL_NEW_MESSAGE_COUNT: "TOTAL_NEW_MESSAGE_COUNT",
  ANY_NEW_MESSAGES: "ANY_NEW_MESSAGES",
};

export const chatsMutations = {
  SET_CHATS: "SET_CHATS",
  ADD_CHAT: "ADD_CHAT",
  ADD_MESSAGES: "ADD_MESSAGES",
  ADD_MESSAGE: "ADD_MESSAGE",
  SET_LAST_SEEN: "SET_LAST_SEEN",
  CLEAR: "CLEAR",
};

const getInitialState = (): ChatsState => ({
  chats: null,
  messages: {},
  lastSeen: {},
});

const state = getInitialState();

const getters: GetterTree<ChatsState, RootState> = {
  [chatsGetters.CHAT_BY_ID](state) {
    return (chatId: number) => {
      const chats = state.chats;
      if (chats == null) return null;
      return chats.find((c) => c.id === chatId);
    };
  },
  [chatsGetters.MESSAGES](state) {
    return (chatId: number) => {
      if (state.messages[chatId] == null) return [];
      return state.messages[chatId];
    };
  },
  [chatsGetters.TOTAL_NEW_MESSAGE_COUNT](state) {
    return (forUserId: number) => {
      const chats = state.chats;
      if (chats == null) return 0;
      return chats.reduce((newCount, chat) => {
        const messages = state.messages[chat.id];
        if (messages == null) return newCount;

        const lastSeenId = state.lastSeen[chat.id];
        if (lastSeenId == null) {
          return messages.length === 0 ? newCount : messages.length + newCount;
        }

        const t = messages.reduce<number>((acc, m) => {
          if (m.author_id === forUserId) return acc;
          if (m.id > lastSeenId) {
            return acc + 1;
          }
          return acc;
        }, 0);
        return t + newCount;
      }, 0);
    };
  },
  [chatsGetters.NEW_MESSAGE_COUNT](state) {
    return (chatId: number, forUserId: number) => {
      const messages = state.messages[chatId];
      if (messages == null) return 0;

      const lastSeenId = state.lastSeen[chatId];
      if (lastSeenId == null) {
        return messages.length === 0 ? 0 : messages.length;
      }

      const t = messages.reduce<number>((acc, m) => {
        if (m.author_id === forUserId) return acc;
        if (m.id > lastSeenId) {
          return acc + 1;
        }
        return acc;
      }, 0);
      return t;
    };
  },
  [chatsGetters.ANY_NEW_MESSAGES](state) {
    return (forUserId: number) => {
      const chats = state.chats;
      if (chats == null) return false;
      const chat = chats.find((chat) => {
        const messages = state.messages[chat.id];
        if (messages == null) return false;

        const lastSeenId = state.lastSeen[chat.id];
        if (lastSeenId == null) return messages.length !== 0;

        return messages.find(
          (m) => m.author_id != forUserId && m.id > lastSeenId
        );
      });
      return chat != null;
    };
  },
};

const mutations: MutationTree<ChatsState> = {
  [chatsMutations.SET_CHATS](state, chats: Chat[]) {
    state.chats = chats;
  },
  [chatsMutations.ADD_CHAT](state, chat: Chat) {
    if (state.chats == null) state.chats = [chat];
    const duplicate = state.chats.find((c) => c.id === chat.id);
    if (duplicate != null) return;
    state.chats.push(chat);
  },
  [chatsMutations.ADD_MESSAGES](
    state,
    params: { chatId: number; messages: Message[] }
  ) {
    const messages = state.messages[params.chatId];
    if (messages == null) {
      state.messages[params.chatId] = params.messages;
      return;
    }
    // Remove duplicates
    const hashTable: { [messageId: number]: Message } = {};
    messages.forEach((msg) => (hashTable[msg.id] = msg));
    params.messages.forEach((msg) => (hashTable[msg.id] = msg));
    state.messages[params.chatId] = Object.values(hashTable);
  },
  [chatsMutations.ADD_MESSAGE](
    state,
    params: { chatId: number; message: Message }
  ) {
    const messages = state.messages[params.chatId];
    if (messages == null) {
      state.messages[params.chatId] = [params.message];
      return;
    }

    const duplicate = messages.find((m) => m.id === params.message.id);
    if (duplicate != null) return;

    state.messages[params.chatId].push(params.message);
  },
  [chatsMutations.SET_LAST_SEEN](
    state,
    params: { chatId: number; lastSeenId: number | null }
  ) {
    state.lastSeen[params.chatId] = params.lastSeenId;
  },
  [chatsMutations.CLEAR](state) {
    Object.assign(state, getInitialState());
  },
};

// Maybe move to init function ran at login?
const actions: ActionTree<ChatsState, RootState> = {
  // Fetch chats even when a list of chats already exists
  // Also inits the messages for all new chats
  async [chatsActions.RE_FETCH](context): Promise<Chat[]> {
    const response = await chatRepository.list();
    const chats = response.data.data.chats;

    chats.forEach((nc) => {
      appSocket.getChatChannels().ensureChannelIsSetUp(nc);
    });

    context.commit(chatsMutations.SET_CHATS, chats);
    return chats;
  },
  async [chatsActions.LIST](context): Promise<Chat[]> {
    if (context.state.chats != null) return context.state.chats;

    const response = await chatRepository.list();
    const chats = response.data.data.chats;
    context.commit(chatsMutations.SET_CHATS, chats);
    return chats;
  },
  async [chatsActions.GET_OR_CREATE_PROPERTY_CHAT](
    context,
    params: { propertyId: number }
  ): Promise<Chat> {
    const response = await chatRepository.createPropertyChat(params.propertyId);
    const chat = response.data.data.chat;
    chat.users = response.data.data.users;
    context.commit(chatsMutations.ADD_CHAT, chat);
    return chat;
  },
  async [chatsActions.GET_OR_CREATE_USER_CHAT](
    context,
    params: { otherUserId: number }
  ): Promise<Chat> {
    const response = await chatRepository.createUserChat(params.otherUserId);
    const chat = response.data.data.chat;
    chat.users = response.data.data.users;
    context.commit(chatsMutations.ADD_CHAT, chat);
    return chat;
  },
  [chatsActions.CLEAR](context) {
    context.commit(chatsMutations.CLEAR);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
