
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],
  props: {
    to: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    // Specify the name of the download
    download: {
      type: String,
      default: null,
    },
  },
});
