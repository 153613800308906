
import { defineComponent, ref, watchEffect } from "vue";

export default defineComponent({
  props: {
    type: {
      type: String,
      default: "text",
    },
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "100%",
    },
    maxWidth: {
      type: String,
      default: "auto",
    },
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    thinner: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const textboxRef = ref<HTMLInputElement | null>(null);

    watchEffect(() => {
      emit("ref", textboxRef.value);
    });

    return {
      textboxRef,
      handleInput(event: InputEvent) {
        emit("update:modelValue", (event.target as any).value);
      },
      handleBlur() {
        emit("blur");
      },
    };
  },
});
