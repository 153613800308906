
import { FileItem } from "@/repositories/fileRepository";
import { DateTime } from "luxon";
import { computed, defineComponent, Prop } from "vue";

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    fileItem: {
      type: Object,
      required: true,
    } as Prop<FileItem>,
    clickable: {
      type: Boolean,
      default: true,
    },
    noDownloadText: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const formattedDate = computed(() => {
      if (props.fileItem?.inserted_at == null) return "";
      const dt = DateTime.fromISO(props.fileItem.inserted_at);
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dec",
      ];
      const monthName = monthNames[dt.month];

      return `${dt.day} ${monthName} ${dt.year}`;
    });

    return {
      formattedDate,
    };
  },
});
