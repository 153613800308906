
import { defineComponent, PropType } from "vue";
import PopupWrapper from "@/components/popups/PopupWrapper.vue";
import { showPopup } from "@/components/popups/popups";

const component = defineComponent({
  components: { PopupWrapper },
  props: {
    params: {
      type: Object as PropType<{
        title: string;
        text: string;
      }>,
    },
  },
});

export const showInfoPopup = (title: string, text: string) => {
  showPopup(component, {
    title,
    text,
  });
};

export default component;
