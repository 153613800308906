import { Toast } from "@/types";

type Listener = (toast: Toast) => void;

const _listeners: Listener[] = [];

export function showToast(toast: Toast): void {
  _listeners.forEach((l) => l(toast));
}

export function listenForToast(listener: Listener): void {
  _listeners.push(listener);
}
