<template>
  <popup-wrapper maxWidth="200px" :hide-close="uploading" @finish="onFinish">
    <div class="content">
      <flash-message error v-if="fileTooLarge">
        Het gekozen bestand is te groot. Maximum 20 megabytes.
      </flash-message>
      <div class="button-wrapper">
        <app-button v-if="!uploading" thinner2 @click="selectFile"
          >Kies bestand</app-button
        >
        <loader
          v-else
          width="50px"
          height="50px"
          thickness="4px"
          opacity="0.5"
        />
      </div>
    </div>
  </popup-wrapper>
  <input
    type="file"
    ref="inputEl"
    class="input"
    @change="onChange"
    :accept="params.accept"
  />
</template>

<script lang="ts">
import { showPopup } from "@/components/popups/popups";
import { defineComponent, PropType, ref, watchEffect } from "vue";
import Loader from "@/components/icons/Loader.vue";
import PopupWrapper from "@/components/popups/PopupWrapper.vue";
import { isShittyPlatform } from "@/util/platform";

export interface UploadFileProps {
  // The popup will stay open while onFinish is running
  // And the result from onFinish will be the result of the popup
  onFinish?: (file: File) => any;
  accept?: string;
}

const component = defineComponent({
  emits: ["finish"],
  components: { Loader, PopupWrapper },
  props: {
    active: {
      type: Boolean,
    },
    params: {
      type: Object as PropType<UploadFileProps>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const inputEl = ref(null as HTMLInputElement | null);
    const uploading = ref(false);
    const fileTooLarge = ref(false);

    watchEffect(() => {
      if (inputEl.value == null) return;
      if (!isShittyPlatform()) {
        inputEl.value.click();
      }
    });

    return {
      fileTooLarge,
      uploading,
      inputEl,
      selectFile() {
        inputEl.value?.click();
      },
      onFinish() {
        if (!uploading.value) {
          emit("finish");
        }
      },
      async onChange() {
        uploading.value = true;
        try {
          fileTooLarge.value = false;
          if (inputEl.value == null) return;
          const files = inputEl.value.files;
          if (files == null || files.length === 0) {
            emit("finish");
            return;
          }
          const file = files[0];
          if (file.size > 20000000) {
            fileTooLarge.value = true;
            return;
          }
          // const upload: FileItem = await store.dispatch(
          //   inFiles(filesActions.UPLOAD),
          //   {
          //     file,
          //   }
          // );
          if (props.params.onFinish != null) {
            const result = await Promise.resolve(props.params.onFinish(file));
            emit("finish", result);
          } else {
            emit("finish", file);
          }
        } finally {
          uploading.value = false;
        }
      },
    };
  },
});

export const showUploadFilePopup = (props: UploadFileProps) => {
  return showPopup(component, props);
};

export default component;
</script>

<style lang="scss" scoped>
.content {
  min-height: 150px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}

.input {
  display: none;
}
</style>
