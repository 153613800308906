<template>
  <page-loader v-if="doc == null" />
  <div v-else>
    <generic-header>{{ doc.title }}</generic-header>
  </div>
</template>

<script lang="ts">
import { FinDocument } from "@/types";
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const doc = ref(null as null | FinDocument);

    return {
      doc,
    };
  },
});
</script>
