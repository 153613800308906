
import { defineComponent, ref } from "vue";
import IssueListing from "@/components/property/IssueListing.vue";
import { Issue } from "@/repositories/issueRepository";
import { useStore } from "vuex";
import { inIssues, issueActions } from "@/store/issues";
import { useRouter } from "vue-router";

export default defineComponent({
  components: { IssueListing },
  props: {
    issueId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const issue = ref(null as null | Issue);

    const store = useStore();
    store
      .dispatch(inIssues(issueActions.BY_ID), {
        id: props.issueId,
        allowFromCache: true,
      })
      .then((value) => {
        issue.value = value;
      });

    return {
      issue,
      routeToIssue() {
        if (issue.value == null) return;
        router.push({
          name: "properties.issues.show",
          params: {
            propertyId: issue.value.property_id,
            issueId: issue.value.id,
          },
        });
      },
    };
  },
});
