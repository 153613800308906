
import { defineComponent, PropType } from "vue";
import { showPopup } from "./popups";
import PopupWrapper from "./PopupWrapper.vue";
import { ConfirmPopupParams } from "./types";

const component = defineComponent({
  components: { PopupWrapper },
  props: {
    params: {
      type: Object as PropType<ConfirmPopupParams>,
      required: true,
    },
  },
});

export const showConfirmPopup = async (
  params: ConfirmPopupParams,
  options?: { throwOnDecline: boolean }
): Promise<boolean | undefined> => {
  let result = (await showPopup(component, params)) as any;
  if (result !== true && options?.throwOnDecline) {
    throw new Error("Declined");
  }
  return result;
};

export default component;
