import fileRepository, { FileItem } from "@/repositories/fileRepository";
import { ActionContext } from "vuex";
import { RootState } from ".";

type FilesState = Record<string, never>;

type FilesActionContext = ActionContext<FilesState, RootState>;

export function inFiles(action: string): string {
  return "files/" + action;
}

// TODO: Cache files from GET_ONE and GET_MANY
// TODO: Bundle requests from GET_ONE & GET_MANY
export const filesActions = {
  UPLOAD: "UPLOAD",
  GET_ONE: "GET_ONE",
  GET_MANY: "GET_MANY",
};

const state = (): FilesState => ({});

const actions = {
  async [filesActions.UPLOAD](
    context: FilesActionContext,
    params: { file: File }
  ): Promise<FileItem> {
    const response = await fileRepository.upload({ file: params.file });
    return response.data.data.file;
  },
  async [filesActions.GET_ONE](
    context: FilesActionContext,
    params: { id: string }
  ): Promise<FileItem> {
    const response = await fileRepository.getMany({ ids: [params.id] });
    const files = response.data.data.files;
    if (files.length === 0) {
      throw new Error("Image not found");
    }
    return files[0];
  },
  async [filesActions.GET_MANY](
    context: FilesActionContext,
    params: { ids: string[] }
  ): Promise<FileItem[]> {
    const response = await fileRepository.getMany({ ids: params.ids });
    return response.data.data.files;
  },
};

export default {
  namespaced: true,
  state,
  mutations: {},
  actions,
};
