
import { defineComponent, ref } from "vue";
import UploadIcon from "@/components/icons/Upload.vue";
import LoaderIcon from "@/components/icons/Loader.vue";
import { FileItem } from "@/repositories/fileRepository";

export default defineComponent({
  components: { UploadIcon, LoaderIcon },
  props: {
    uploadFunction: {
      type: Function,
      default: null,
    },
    images: {
      type: Array,
      default: () => [] as FileItem[],
    },
    label: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const fileInputEl = ref(null as HTMLInputElement | null);
    const loading = ref(false);

    return {
      fileInputEl,
      loading,
      selectImage() {
        if (fileInputEl.value == null) return;
        fileInputEl.value.click();
      },
      async uploadImage() {
        if (props.uploadFunction == null) return;
        const files = fileInputEl.value?.files;
        if (files == null || files.length <= 0) return;
        loading.value = true;
        const result = await props.uploadFunction(files[0]);
        if (result != null) {
          const newImages = [...(props.images ?? []), result];
          emit("newImages", newImages);
        }
        loading.value = false;
      },
    };
  },
});
