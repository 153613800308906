<template>
  <popup-wrapper @finish="$emit('finish')" maxWidth="300px">
    <form class="prompt" @submit.prevent="submit">
      <label for="name" class="label">Map hernoemen</label>
      <textbox
        thinner
        class="input"
        type="text"
        name="name"
        id="name"
        placeholder="Map naam"
        v-model="name"
      />
      <div class="buttons">
        <app-button type="submit" thinner2>Opslaan</app-button>
      </div>
    </form>
  </popup-wrapper>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import PopupWrapper from "@/components/popups/PopupWrapper.vue";
import { showPopup } from "@/components/popups/popups";

const component = defineComponent({
  components: { PopupWrapper },
  props: {
    params: {
      type: Object as PropType<{ name: string }>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const name = ref(props.params.name);

    return {
      name,
      submit() {
        if (name.value === props.params.name) {
          emit("finish");
          return;
        }
        emit("finish", name.value);
      },
    };
  },
});

export const promptNewFolderName = (currentName: string) => {
  return showPopup(component, { name: currentName });
};

export default component;
</script>

<style lang="scss" scoped>
.prompt {
  padding: 20px;
  box-sizing: border-box;

  @include screen-from("tablet") {
    padding: 30px;
  }
}

.buttons {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

.label {
  font-size: 1.5rem;
  font-weight: 500;
  padding-bottom: 10px;
  display: block;
}
</style>
