<template>
  <generic-page-popup :title="$t('residents.add')" use-margin-top>
    <!-- <container thinner> -->
    <!-- <div class="add-resident"> -->
    <!-- <generic-header>{{ $t("residents.add") }}</generic-header> -->
    <form class="container" @submit.prevent="submit">
      <!-- <label for="email" class="label">
        {{ $t("residents.send_email_invite") }}
      </label> -->
      <flash-message error v-if="generalError">{{
        generalError
      }}</flash-message>
      <textbox
        label="Email adres"
        v-model="email"
        id="email"
        name="email"
        placeholder="Email van mede-eigenaar"
        type="email"
        required
        autofocus
      />
      <form-error v-if="error != null">{{ error }}</form-error>
      <!-- TODO: Re-add this when paid plans come back -->
      <!-- TODO: Make this much more clear with a specification of how much. -->
      <div v-if="showUserCreation">
        <div class="textbox-wrapper">
          <textbox
            label="Voornaam"
            v-model="firstName"
            name="voornaam"
            placeholder="Voornaam van mede-eigenaar"
          />
        </div>
        <div class="textbox-wrapper">
          <textbox
            label="Naam"
            v-model="lastName"
            name="naam"
            placeholder="Naam van mede-eigenaar"
          />
        </div>
      </div>
      <!-- <div class="warning">
          <h2 class="title">De prijs van uw abonnement zal veranderen.</h2>
          <p class="description">
            De prijs van uw abonnement is berekent per resident. Wanneer u een
            resident toevoegt zal de totaalprijs dus stijgen.
          </p>
        </div> -->
      <!-- <app-button :loading="loading" type="submit" class="button" width="100%"
          >Ik begrijp het, versturen</app-button
        > -->
      <app-button
        :loading="loading"
        type="submit"
        class="button"
        width="100%"
        >{{ $t("common.send") }}</app-button
      >
    </form>
    <!-- </div> -->
    <!-- </container> -->
  </generic-page-popup>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { defineComponent, ref, watch } from "vue";
import { inProperties, PropertiesActions } from "@/store/properties";
import { DuplicateException } from "@/error_handling/DuplicateException";
import FormError from "@/components/form/FormError.vue";
import { showPopup } from "@/components/popups/popups";
import { useActiveProperty } from "@/util/properties";

const component = defineComponent({
  components: { FormError },
  setup(props, { emit }) {
    const activeProperty = useActiveProperty();
    const store = useStore();
    const email = ref("");
    const loading = ref(false);
    const error = ref(null as null | string);
    const generalError = ref(null as null | string);

    const showUserCreation = ref(false);
    const firstName = ref("");
    const lastName = ref("");

    watch(email, () => {
      showUserCreation.value = false;
      firstName.value = "";
      lastName.value = "";
    });

    return {
      email,
      firstName,
      lastName,
      loading,
      generalError,
      error,
      showUserCreation,
      async submit() {
        try {
          loading.value = true;
          error.value = null;
          generalError.value = null;
          if (email.value.length === 0) return;
          const emailExists = await store.dispatch(
            inProperties(PropertiesActions.USER_WITH_EMAIL_EXISTS),
            email.value
          );
          if (!emailExists && !showUserCreation.value) {
            console.log("test");
            showUserCreation.value = true;
            return;
          }
          if (!emailExists && (firstName.value == "" || lastName.value == "")) {
            generalError.value = "Gelieve alle informatie in te vullen.";
            return;
          }
          await store.dispatch(
            inProperties(PropertiesActions.INVITE_OR_CREATE),
            {
              propertyId: activeProperty.value?.id,
              email: email.value,
              firstName: firstName.value,
              lastName: lastName.value,
            }
          );
          emit("finish", true);
        } catch (e) {
          if (e instanceof DuplicateException) {
            error.value =
              "Een gebruiker met dit email adres is al lid van deze residentie";
          } else {
            throw e;
          }
        } finally {
          loading.value = false;
        }
      },
    };
  },
});

export const showAddResidentPopup = (): Promise<boolean | null> => {
  return showPopup(component, {});
};

export default component;
</script>

<style lang="scss" scoped>
.container {
  /* padding: 0 20px; */
  padding-bottom: 20px;
  box-sizing: border-box;
  margin-top: 20px;

  /* @include screen-from("tablet") {
    padding: 0;
  } */
}

.label {
  font-size: 1.125rem;
  font-weight: 600;
  color: $text-alt;
  font-family: $font-title;
  margin-bottom: 20px;
  display: block;
}

.title {
  color: $text-alt;
  font-size: 1.125rem;
  font-weight: 600;
  font-family: $font-title;
  margin-top: 30px;
  line-height: 130%;
}

.description {
  margin-top: 10px;
  font-size: 0.875rem;
  letter-spacing: 0.02rem;
  line-height: 140%;
  color: $text-alt;
  font-family: $font-text;
  max-width: 600px;
}

.button {
  margin-top: 30px;
}

.textbox-wrapper {
  margin-top: 15px;
}
</style>
