<template>
  <div class="header-button" @click="$emit('click')">
    <div class="icon-wrapper">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],
  setup() {
    //
  },
});
</script>

<style lang="scss" scoped>
.header-button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.icon-wrapper {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
</style>
