<template>
  <div class="options">
    <div
      class="single"
      v-if="options?.length === 1"
      @click="options[0].onClick"
    >
      <div class="single-mobile">
        <icon :name="options[0].iconName" strokeWidth="2" size="18" />
      </div>
      <div class="single-desktop">
        <app-button
          thinner2
          :danger="options[0].danger"
          :success="options[0].success"
        >
          <template v-slot:icon>
            <icon :name="options[0].iconName" strokeWidth="2" size="14" />
          </template>
          {{ options[0].text }}
        </app-button>
      </div>
    </div>
    <div class="multi" v-if="options?.length > 1">
      To Be Implemented :)
      <!-- TODO: To be implemented -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, Ref } from "vue";
import Icon from "@/components/icons/Icon.vue";

export interface GenericPageOption {
  id: string;
  iconName: string;
  text: string;
  onClick: () => void;
  danger?: boolean;
  success?: boolean;
  showWhen?: Ref<boolean>;
}

export default defineComponent({
  components: { Icon },
  props: {
    options: {
      type: Array as PropType<GenericPageOption[]>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.single {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.single-mobile {
  @include screen-from("tablet-wide") {
    display: none;
  }
}

.single-desktop {
  display: none;
  @include screen-from("tablet-wide") {
    display: block;
  }
}
</style>
