<template>
  <div class="pick-language">
    <h1 class="title">Pick a language</h1>
    <div class="choices">
      <div class="choice">
        <!-- <app-link class="choice-link" @click="pick('nl')">Nederlands</app-link> -->
        <app-button class="choice-button" @click="pick('nl')"
          >Nederlands</app-button
        >
      </div>
      <div class="choice">
        <app-button class="choice-button" @click="pick('en')"
          >English</app-button
        >
        <!-- <app-link class="choice-link" @click="pick('en')">English</app-link> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { setLocale } from "@/plugins/install_i18n";
import { LOCALSTORAGE_KEY } from "@/router/middleware/langMiddleware";
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();

    return {
      async pick(language: string) {
        localStorage.setItem(LOCALSTORAGE_KEY, language);
        setLocale(language);

        if (route.query?.from != null && typeof route.query.from === "string") {
          try {
            await router.push(decodeURIComponent(route.query.from));
          } catch (e) {
            router.push({ name: "login" });
          }
        } else {
          router.push({
            name: "login",
          });
        }
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.pick-language {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  height: 90vh;
  width: 100%;

  @include screen-from("tablet") {
    height: 60vh;
  }
}

.title {
  font-size: 2em;
  margin-bottom: 30px;

  @include screen-from("tablet") {
    font-size: 3em;
    margin-bottom: 90px;
  }
}

.choices {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  @include screen-from("tablet") {
    flex-direction: row;
  }
}

.choice {
  padding: 10px;

  .choice-link {
    font-size: 1.5em;
  }

  .choice-button {
    min-width: 180px;
  }
}
</style>
