
import { Invoice, Transaction } from "@/types";
import { formatAsEuro } from "@/util/price";
import { defineComponent, PropType } from "vue";
import TimesIcon from "@/components/icons/Times.vue";
import { useOpenFile } from "@/capacitor/hooks/useOpenFile";
import { showConfirmPopup } from "@/components/popups/common_popups";
import { useStore } from "vuex";
import { accountingActions, inAccounting } from "@/store/accounting/accounting";
import { useLoader } from "@/util/loading";
import LoaderIcon from "@/components/icons/Loader.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: { TimesIcon, LoaderIcon },
  props: {
    invoice: {
      type: Object as PropType<Invoice>,
      required: true,
    },
    transaction: {
      type: Object as PropType<Transaction>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const openFile = useOpenFile(props.invoice.file.url);
    const deletingLoader = useLoader();

    const { t } = useI18n();

    return {
      openFile,
      formatAsEuro,
      deletingLoader,
      // async removeInvoice() {
      removeInvoice: deletingLoader.use(async () => {
        const shouldDelete = await showConfirmPopup({
          title: t("accounting.invoice_delete.title"),
          confirmText: t("accounting.invoice_delete.confirmText"),
          declineText: t("accounting.invoice_delete.declineText"),
          subTitle: t("accounting.invoice_delete.subTitle"),
        });
        if (shouldDelete === true) {
          store.dispatch(inAccounting(accountingActions.DELETE_INVOICE), {
            invoiceId: props.invoice.id,
            propertyId: props.invoice.property_id,
          });
        }
      }),
      // handleClick() {
      //   showPopup(EditInvoice, {
      //     transaction: props.transaction,
      //     invoice: props.invoice,
      //   });
      // },
    };
  },
});
