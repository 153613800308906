<template>
  <div class="setup">
    <container thinner>
      <div class="box">
        <generic-header center-title>Boekhouding</generic-header>
        <div class="content">
          <features
            :features="[
              {
                title: $t('accounting.live_transaction_title'),
                text: $t('accounting.live_transaction_desc'),
              },
              {
                title: $t('accounting.add_invoices_title'),
                text: $t('accounting.add_invoices_desc'),
              },
              {
                title: $t('accounting.resident_payment_title'),
                text: $t('accounting.resident_payment_desc'),
              },
            ]"
          />
          <div class="button-wrapper">
            <app-button disabled @click="toSelectBank" thinner>{{
              $t("common.comingsoon")
            }}</app-button>
          </div>
          <!-- <div class="disclaimer">
            {{ $t("accounting.notice") }}
          </div> -->
        </div>
      </div>
    </container>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Features from "@/components/util/Features.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: { Features },
  setup() {
    const router = useRouter();

    return {
      toSelectBank: () => router.push({ name: "accounting.select_bank" }),
    };
  },
});
</script>

<style lang="scss" scoped>
.content {
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;

  @include screen-from("tablet-wide") {
    margin-top: 30px;
  }
}

.button-wrapper {
  margin-top: 40px;
}

.disclaimer {
  font-size: 0.875rem;
  text-align: center;
  margin-top: 40px;
  padding-bottom: 30px;
  line-height: 130%;
}
</style>
