
import { defineComponent } from "vue";
import { timeago } from "@/util/timeago";
import { issueTags } from "@/util/issues";

export default defineComponent({
  emits: ["click"],
  props: {
    issue: {
      type: Object,
      required: true,
    },
    noText: {
      type: Boolean,
      default: false,
    },
    noImages: {
      type: Boolean,
      default: false,
    },
    noTags: {
      type: Boolean,
      default: false,
    },
    noAuthor: {
      type: Boolean,
      default: false,
    },
    altTime: {
      type: Boolean,
      deafult: false,
    },
  },
  setup() {
    return {
      issueTags,
      timeago,
    };
  },
});
