<template>
  <div class="item">
    <card>
      <div class="item-content">
        <div class="cell">
          <div class="cell-label">Datum</div>
          <div class="cell-value">{{ formattedDate }}</div>
        </div>
        <div class="cell">
          <div class="cell-label">Bedrag</div>
          <div class="cell-value">{{ amount }}</div>
        </div>
      </div>
    </card>
  </div>
</template>

<script lang="ts">
import { Payment } from "@/types";
import { formatAsEuro } from "@/util/price";
import { DateTime } from "luxon";
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    payment: {
      type: Object as PropType<Payment>,
      required: true,
    },
  },
  setup(props) {
    console.log("Payment:", props.payment);
    return {
      amount: computed(() => formatAsEuro(props.payment.amount)),
      formattedDate: computed(() => {
        const dt = DateTime.fromISO(props.payment.transaction.value_date);
        return dt.toFormat("dd LLL yyyy");
      }),
    };
  },
});
</script>

<style lang="scss" scoped>
.item-content {
  box-sizing: border-box;
  padding: 20px 25px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  max-width: 400px;
}

.amount {
  /* font-weight: bold; */
  letter-spacing: 0.01em;
  font-size: 1rem;
}

.date {
  font-size: 1rem;
}

.cell-label {
  font-size: 0.65rem;
  text-transform: uppercase;
  color: $text-gray;
  font-weight: bold;
  margin-bottom: 3px;
  letter-spacing: 0.03em;
}

.cell-value {
  font-size: 1rem;
  font-weight: 600;
}
</style>
