
import { computed, defineComponent, PropType, ref } from "vue";
import { showPopup } from "@/components/popups/popups";
import { useResidents } from "@/store/properties/hooks";
import { useStore } from "vuex";
import { inProperties, PropertiesActions } from "@/store/properties";
import { PatchPropertyLotParams, PropertyLot } from "@/types";
import { showAddResidentPopup } from "@/features/residents/popups/AddResident.vue";
import { useLotTypeOptions } from "../hooks/types";

const component = defineComponent({
  props: {
    params: {
      type: Object as PropType<{ lot: PropertyLot; customTypes: string[] }>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const { residents, loading, refetch: refetchResidents } = useResidents();
    const submitting = ref(false);

    const identifier = ref(props.params.lot.identifier);
    const responsible = ref(props.params.lot.main_responsible_id.toString());
    const type = ref(props.params.lot.type);

    const error = ref("");
    const typeOptions = useLotTypeOptions(props.params.customTypes);

    return {
      type,
      typeOptions,
      identifier,
      responsible,
      residents,
      loading,
      submitting,
      error,
      residentOptions: computed(() => {
        if (loading.value) return {};
        return residents.value.reduce<Record<string, string>>(
          (options, resident) => {
            options[resident.id] =
              resident.first_name + " " + resident.last_name;
            return options;
          },
          {}
        );
      }),
      async createUser() {
        await showAddResidentPopup();
        refetchResidents();
      },
      async submit() {
        submitting.value = true;

        if (
          type.value == null ||
          responsible.value == null ||
          identifier.value.length <= 0
        ) {
          error.value = "Gelieve alle velden in te vullen.";
          return;
        }

        try {
          await store.dispatch(inProperties(PropertiesActions.PATCH_LOT), {
            lotId: props.params.lot.id,
            changes: {
              identifier: identifier.value,
              main_responsible_id: Number.parseInt(responsible.value as string),
              type: type.value,
            },
          } as PatchPropertyLotParams);
          emit("finish");
        } catch (e) {
          error.value =
            "Er ging iets mis, onze excuses hiervoor. U kan ons altijd bereiken op jasper@mysyndic.com";
        } finally {
          submitting.value = false;
        }
      },
    };
  },
});

export const showEditLotPopup = (lot: PropertyLot, customTypes: string[]) => {
  return showPopup(component, { lot, customTypes });
};

export default component;
