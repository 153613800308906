
import { defineComponent, ref } from "vue";
import FinanceItem from "@/components/finance/FinanceItem.vue";
import { useStore } from "vuex";
import { FinDocument } from "@/types";
import { finActions, inFin } from "@/store/fin";

export default defineComponent({
  components: { FinanceItem },
  props: {
    documentId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const document = ref(null as null | FinDocument);

    store
      .dispatch(inFin(finActions.BY_ID), {
        docId: props.documentId,
        allowFromCache: true,
      })
      .then((value) => {
        console.log(value);
        document.value = value;
      });

    return {
      document,
    };
  },
});
