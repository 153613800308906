<template>
  <popup-wrapper max-width="450px" @finish="$emit('finish', false)">
    <div class="content">
      <h1 class="title">{{ params.title }}</h1>
      <p class="sub-title" v-if="params.subTitle">
        {{ params.subTitle }}
      </p>
      <div class="options">
        <app-button thinner danger @click="$emit('finish', true)">{{
          params.confirmText
        }}</app-button>
        <app-button thinner dark @click="$emit('finish', false)">{{
          params.declineText
        }}</app-button>
      </div>
    </div>
  </popup-wrapper>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { showPopup } from "./popups";
import PopupWrapper from "./PopupWrapper.vue";
import { ConfirmPopupParams } from "./types";

const component = defineComponent({
  components: { PopupWrapper },
  props: {
    params: {
      type: Object as PropType<ConfirmPopupParams>,
      required: true,
    },
  },
});

export const showConfirmPopup = async (
  params: ConfirmPopupParams,
  options?: { throwOnDecline: boolean }
): Promise<boolean | undefined> => {
  let result = (await showPopup(component, params)) as any;
  if (result !== true && options?.throwOnDecline) {
    throw new Error("Declined");
  }
  return result;
};

export default component;
</script>

<style lang="scss" scoped>
.content {
  padding: 20px;
  box-sizing: border-box;

  @include screen-from("tablet") {
    padding: 40px;
  }
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  color: $text-alt;
  margin-bottom: 20px;
  line-height: 120%;
}

.sub-title {
  font-size: 1.125rem;
  line-height: 130%;
}

.options {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  margin-top: 30px;
}

.sub-title {
  margin-bottom: 20px;
  margin-top: -5px;
}
</style>
