
import {
  accountingActions,
  accountingGetters,
  inAccounting,
} from "@/store/accounting/accounting";
import { computed, defineComponent, PropType } from "vue";
import { useStore } from "vuex";
import PopupWrapper from "./PopupWrapper.vue";

export default defineComponent({
  emits: ["finish"],
  components: { PopupWrapper },
  props: {
    params: {
      type: Object as PropType<{ propertyId: string | number }>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    store.dispatch(
      inAccounting(accountingActions.FETCH_TRANSACTIONS),
      props.params.propertyId
    );

    // const openInvoices =

    return {};
  },
});
