<template>
  <auth-layout title="Email login">
    <form class="wrapper" @submit.prevent="submit">
      <flash-message error v-if="error != null"
        >Er ging iets mis, onze excuses. U kan ons steeds bereiken op
        jasper@mysyndic.com</flash-message
      >
      <div class="textbox-wrapper">
        <textbox
          label="Uw email adres"
          placeholder="jesse.doe@example.com"
          v-model="email"
          type="email"
          autofocus
        />
      </div>
      <div class="button-wrapper">
        <app-link @click="goBack">Terug</app-link>
        <app-button :loading="loading" thinner type="submit"
          >Code versturen</app-button
        >
      </div>
    </form>
  </auth-layout>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import AuthLayout from "@/layouts/Auth.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { authActions, inAuth } from "@/store/auth";
import { logBreakingException } from "@/util/logger";

export default defineComponent({
  components: { AuthLayout },
  setup() {
    const router = useRouter();
    const store = useStore();

    const email = ref("");
    const loading = ref(false);
    const error = ref<null | string>(null);

    let isValid = computed(
      () => email.value.includes("@") && email.value.length > 3
    );

    return {
      email,
      isValid,
      loading,
      error,
      goBack() {
        router.push({ name: "login" });
      },
      async submit() {
        error.value = null;
        loading.value = true;
        if (email.value === "") {
          error.value = "Email cannot be blank.";
          return;
        }
        try {
          await store.dispatch(
            inAuth(authActions.SEND_EMAIL_LOGIN),
            email.value
          );
          router.push({
            name: "auth.emaillogin.verify",
            query: {
              email: encodeURIComponent(email.value),
            },
          });
        } catch (e) {
          error.value = (e as Error).message;
          logBreakingException(e as Error);
        } finally {
          loading.value = false;
        }
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}

.button-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
</style>
