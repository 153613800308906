
import { capitalize } from "@/helpers/text";
import { Meeting } from "@/types";
import { DateTime } from "luxon";
import { computed, defineComponent, PropType } from "vue";
import { dateOrStringToDateTime } from "../helpers/datetime";

export default defineComponent({
  props: {
    meeting: {
      type: Object as PropType<Meeting>,
      required: true,
    },
  },
  setup(props) {
    const meeting = computed(() => props.meeting);

    const formattedDate = computed(() => {
      if (meeting.value == null) return;
      const str = DateTime.fromJSDate(
        new Date(meeting.value.scheduled_for)
      ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
      return capitalize(str);
    });

    const formattedTime = computed(() => {
      console.log(meeting.value);
      if (meeting.value == null) return;
      let dt = dateOrStringToDateTime(meeting.value.scheduled_for);
      if (dt == null) return null;
      return capitalize(dt.toLocal().toLocaleString(DateTime.TIME_24_SIMPLE));
    });

    return {
      formattedDate,
      formattedTime,
    };
  },
});
