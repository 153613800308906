<template>
  <generic-page
    title="Vergaderingen"
    :options="options"
    :loading="loading"
    :title-info="{
      title: 'Algemene vergadering',
      text: 'De algemene vergadering van een mede-eigendom, is een vergadering met alle mede-eigenaren die verplicht minimaal één keer per jaar moet plaatsvinden.',
    }"
  >
    <div class="meeting-wrapper" v-for="meeting of meetings" :key="meeting.id">
      <meeting-listing :meeting="meeting" @click="showMeeting(meeting)" />
    </div>
    <div class="empty-state" v-if="meetings.length === 0">
      Er zijn nog geen algemene vergaderingen aangemaakt in deze residentie. U
      kan een nieuwe vergadering aanmaken door rechtsboven op "+" te klikken.
    </div>
  </generic-page>
</template>

<script lang="ts">
import { GenericPageOption } from "@/components/common/generic-page/GenericPageOptions.vue";
import { useMeetings } from "@/store/meetings/hooks";
import { Meeting } from "@/types";
import { isCurrentPropertyAdmin } from "@/util/auth";
import { useActiveProperty } from "@/util/properties";
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import MeetingListing from "../components/MeetingListing.vue";
import { showMeetingPopup } from "../popups/ViewMeeting/ViewMeeting.vue";

export default defineComponent({
  components: { MeetingListing },
  setup() {
    const router = useRouter();
    const isAdmin = isCurrentPropertyAdmin();
    const property = useActiveProperty();
    const propertyId = computed(() => property.value?.id ?? null);
    const { meetings, loading } = useMeetings(propertyId);

    const options = computed<GenericPageOption[]>(() => {
      if (!isAdmin.value) return [];
      return [
        {
          id: "add",
          iconName: "add",
          text: "Vergadering plannen",
          async onClick() {
            router.push({ name: "meetings.new" });
          },
        },
      ];
    });
    return {
      loading,
      options,
      meetings,
      showMeeting(meeting: Meeting) {
        showMeetingPopup(meeting);
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.meeting-wrapper {
  margin-bottom: 15px;
}

.empty-state {
  text-align: center;
  font-size: 14px;
  padding: 0 10px;
  margin-top: 40px;
  color: $text-gray;
  line-height: 1.3;
}
</style>
