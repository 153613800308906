<template>
  <div class="wrapper">
    <div class="moving-item-banner">
      <div class="items">
        <div class="icon">
          <item-preview :item="item" />
        </div>
        <div class="icon-text">1 item</div>
      </div>
      <div class="options">
        <a
          href=""
          class="option option--active"
          @click.prevent="$emit('cancel')"
          >Annuleer</a
        >
        <a href="" class="option" @click.prevent="$emit('drop')">Plaats hier</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Item } from "@/types";
import { defineComponent, PropType } from "vue";
import ItemPreview from "./ItemPreview.vue";

export default defineComponent({
  components: { ItemPreview },
  props: {
    item: {
      type: Object as PropType<Item>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.moving-item-banner {
  background-color: $bg-mobile-menu;
  border-top: 1px solid $bg-mobile-menu;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: white;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 15px 30px;
  max-width: 500px;
  width: 100%;
}

.options {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.option {
  color: rgba($text-accent, 0.7);
  text-decoration: none;
  padding-left: 20px;
  font-size: 1.15rem;
  // font-size: 1.25rem;
  font-weight: 500;

  &--active {
    color: $text-accent;
  }

  &:hover {
    color: $text-accent;
  }
}

.icon {
  width: 38px;
  height: 38px;
}

.items {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.icon-text {
  font-size: 12px;
  margin-top: 6px;
  opacity: 0.4;
}
</style>
