
import { useStore } from "vuex";
import { defineComponent, ref, watch } from "vue";
import { inProperties, PropertiesActions } from "@/store/properties";
import { DuplicateException } from "@/error_handling/DuplicateException";
import FormError from "@/components/form/FormError.vue";
import { showPopup } from "@/components/popups/popups";
import { useActiveProperty } from "@/util/properties";

const component = defineComponent({
  components: { FormError },
  setup(props, { emit }) {
    const activeProperty = useActiveProperty();
    const store = useStore();
    const email = ref("");
    const loading = ref(false);
    const error = ref(null as null | string);
    const generalError = ref(null as null | string);

    const showUserCreation = ref(false);
    const firstName = ref("");
    const lastName = ref("");

    watch(email, () => {
      showUserCreation.value = false;
      firstName.value = "";
      lastName.value = "";
    });

    return {
      email,
      firstName,
      lastName,
      loading,
      generalError,
      error,
      showUserCreation,
      async submit() {
        try {
          loading.value = true;
          error.value = null;
          generalError.value = null;
          if (email.value.length === 0) return;
          const emailExists = await store.dispatch(
            inProperties(PropertiesActions.USER_WITH_EMAIL_EXISTS),
            email.value
          );
          if (!emailExists && !showUserCreation.value) {
            console.log("test");
            showUserCreation.value = true;
            return;
          }
          if (!emailExists && (firstName.value == "" || lastName.value == "")) {
            generalError.value = "Gelieve alle informatie in te vullen.";
            return;
          }
          await store.dispatch(
            inProperties(PropertiesActions.INVITE_OR_CREATE),
            {
              propertyId: activeProperty.value?.id,
              email: email.value,
              firstName: firstName.value,
              lastName: lastName.value,
            }
          );
          emit("finish", true);
        } catch (e) {
          if (e instanceof DuplicateException) {
            error.value =
              "Een gebruiker met dit email adres is al lid van deze residentie";
          } else {
            throw e;
          }
        } finally {
          loading.value = false;
        }
      },
    };
  },
});

export const showAddResidentPopup = (): Promise<boolean | null> => {
  return showPopup(component, {});
};

export default component;
