<template>
  <div class="tabs">
    <router-link class="tab" to="/accounting/overview/invoices">
      Facturen
    </router-link>
    <router-link class="tab" to="/accounting/overview/transactions">
      Bank
    </router-link>
    <router-link class="tab" to="/accounting/overview/payments">
      Betalingen
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { RouteLocationRaw } from "vue-router";

export interface GenericPageTab {
  text: string;
  to: RouteLocationRaw;
}

export default defineComponent({
  props: {
    tabs: {
      type: Array as PropType<GenericPageTab[]>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @include screen-from("tablet-wide") {
    justify-content: flex-start;
  }
}

.tab {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  text-decoration: none;
  color: $text-dark;
  border-bottom: 2px solid transparent;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  letter-spacing: 0.01em;

  &:hover {
    /* background-color: $bg-accent; */
    border-color: $bg-accent;
  }

  @include screen-from("tablet-wide") {
    /* margin: 0 20px; */
    width: 130px;
    /* width: auto; */
    /* padding: 0 30px; */
  }
}
.router-link-active.tab {
  font-weight: 500;
  /* background-color: $bg-accent; */
  border-color: $bg-accent;
}
</style>
