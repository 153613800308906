<template>
  <div class="resident-payment" @click="edit">
    <!-- <div class="tag">RESIDENT BETALING</div> -->
    <h3 class="name">
      {{ name }}
    </h3>
    <!-- <div class="amount">
      {{ formattedAmount }}
    </div> -->
  </div>
</template>

<script lang="ts">
import EditResidentPayment from "@/components/popups/EditResidentPayment.vue";
import { showPopup } from "@/components/popups/popups";
import { fullName } from "@/helpers/user";
import { Payment, Transaction } from "@/types";
import { formatAsEuro } from "@/util/price";
import { computed, defineComponent, PropType } from "vue";

// TODO: Edit resident payment

export default defineComponent({
  props: {
    payment: {
      type: Object as PropType<Payment>,
      required: true,
    },
    transaction: {
      type: Object as PropType<Transaction>,
      required: true,
    },
  },
  setup(props) {
    const resident = computed(() => props.payment.user);
    const name = computed(() =>
      resident.value == null ? "" : fullName(resident.value)
    );

    return {
      name,
      formattedAmount: computed(() => formatAsEuro(props.payment.amount)),
      edit() {
        showPopup(EditResidentPayment, {
          transaction: props.transaction,
          payment: props.payment,
        });
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.resident-payment {
  border: 1px solid $border-general;
  padding: 20px;
  border-radius: 5px;
  background-color: $bg-general;
  position: relative;
  z-index: 1;
}

.name {
  font-size: 1rem;
  font-weight: 600;
  color: $text-alt;
}

.amount {
  margin-top: 7px;
}

.tag {
  position: absolute;
  top: -10px;
  /* background-color: $bg-accent;
  color: $text-alt; */
  background-color: $bg-mobile-menu;
  color: white;
  padding: 3px 8px;
  border-radius: 5px;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 600;
}
</style>
