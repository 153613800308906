<template>
  <div class="finish-sign-up">
    <app-form
      :submitText="$t('auth.register')"
      submitAlwaysFull
      :submitHandler="finishRegistration"
    >
      <form-field>
        <textbox
          type="text"
          placeholder="John"
          :label="$t('common.first_name')"
          v-model="state.firstName"
          @blur="v.firstName.$touch()"
        />
        <form-error v-if="v.firstName.$error">{{
          $t("error.field_invalid")
        }}</form-error>
        <field-error :fieldErrors="fieldErrors" name="firstName" />
      </form-field>
      <form-field>
        <textbox
          type="text"
          placeholder="Doe"
          :label="$t('common.last_name')"
          v-model="state.lastName"
          @blur="v.lastName.$touch()"
        />
        <form-error v-if="v.lastName.$error">{{
          $t("error.field_invalid")
        }}</form-error>
        <form-error v-if="fieldErrors['lastName']">
          {{ fieldErrors["lastName"][0] }}
        </form-error>
        <field-error :fieldErrors="fieldErrors" name="lastName" />
      </form-field>
      <form-field>
        <textbox
          type="email"
          placeholder="my@email.com"
          :label="$t('common.email_address')"
          v-model="state.email"
          @blur="v.email.$touch()"
          disabled
        />
        <form-error v-if="v.email.$error">{{
          $t("error.field_invalid")
        }}</form-error>
        <field-error :fieldErrors="fieldErrors" name="email" />
      </form-field>
      <!-- <form-field>
        <textbox
          type="password"
          :placeholder="$t('auth.password')"
          :label="$t('auth.password')"
          v-model="state.password"
          @blur="v.password.$touch()"
        />
        <form-error v-if="v.password.$error">{{
          $t("error.field_invalid")
        }}</form-error>
        <field-error :fieldErrors="fieldErrors" name="password" />
      </form-field> -->
      <template v-slot:bottom>
        <div class="terms-of-use">
          {{ $t("legal.by_registering") }}
          <app-link to="/">{{ $t("legal.terms_of_use") }}</app-link>
        </div>
      </template>
    </app-form>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import AppForm from "@/components/form/AppForm.vue";
import FormField from "@/components/form/FormField.vue";
import FormError from "@/components/form/FormError.vue";
import FieldError from "@/components/form/FieldError.vue";
import { email, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { authActions, inAuth } from "@/store/auth";
import { logBreakingException } from "@/util/logger";
import { ValidationException } from "@/error_handling/ValidationException";

export default defineComponent({
  components: { AppForm, FormField, FormError, FieldError },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const token = route.query.token;
    if (token == null) {
      console.log("FinishSignUp: no token received, routing to login");
      router.push({
        name: "login",
      });
    }

    const state = reactive({
      firstName: "",
      lastName: "",
      email: route.query.email || "",
      // password: "",
    });
    const fieldErrors = ref({} as { [fieldName: string]: string[] });

    const rules = {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      // password: { required, minLength: minLength(6) },
    };

    const v = useVuelidate(rules, state);

    return {
      v,
      state,
      fieldErrors,
      async finishRegistration() {
        try {
          await store.dispatch(inAuth(authActions.FINISH_REGISTRATION), {
            token,
            user: {
              first_name: state.firstName,
              last_name: state.lastName,
              email: state.email,
              // password: state.password,
            },
          });
          router.push({
            name: "properties",
          });
        } catch (e) {
          if (e instanceof ValidationException) {
            fieldErrors.value = e.getErrors();
          } else {
            logBreakingException(e as Error);
          }
        }
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.finish-sign-up {
  @include screen-from("tablet-small") {
    max-width: 315px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
