
import { computed, defineComponent, ref, watch } from "vue";
import { useRouter } from "vue-router";

function createStartArray<T>(length: number, value: T): T[] {
  return Array.from(Array(length)).map(() => value);
}

const isValidInt = (value: string) => {
  return /^-?\d+$/.test(value);
};

export default defineComponent({
  props: {
    length: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const showInvalids = ref(false);
    const router = useRouter();
    const boxElements = ref<(HTMLInputElement | null)[]>(
      createStartArray(props.length, null)
    );
    const boxValues = ref<string[]>(createStartArray(props.length, ""));
    let currentIndex = ref(0);

    watch(currentIndex, () => {
      boxElements.value[currentIndex.value]?.focus();
      boxElements.value[currentIndex.value]?.select();
    });

    const valueForIndex = (index: number) => {
      const el = boxElements.value[index];
      if (el == null) return null;
      return el.value;
    };

    const isEmptyIndex = (index: number) => {
      return (
        boxValues.value[index] == null || boxValues.value[index].length === 0
      );
    };

    const isValid = computed(() => {
      for (const value of boxValues.value) {
        if (value == null || !isValidInt(value)) return false;
      }
      return true;
    });

    return {
      showInvalids,
      boxElements,
      boxValues,
      isValid,
      goBack() {
        router.push({ name: "login" });
      },
      onInput(index: number) {
        let value = valueForIndex(index);
        boxValues.value[index] = value ?? "";
        if (value == null) return;

        if (value.length === props.length) {
          for (let i = 0; i < props.length; i++) {
            boxValues.value[i] = value[i];
          }
          emit("code", boxValues.value.join(""));
          return;
        }

        if (!isValidInt(value)) {
          boxValues.value[index] = "";
          return;
        }

        if (value.length === 1) {
          let nextIndex = index + 1 >= props.length ? 0 : index + 1;
          currentIndex.value = nextIndex;
        }
        if (value.length > 1) {
          boxValues.value[index] = value.slice(value.length - 1);
        }

        if (isValid.value && index >= props.length - 1) {
          emit("code", boxValues.value.join(""));
        }
      },
      del() {
        if (isEmptyIndex(currentIndex.value)) {
          boxValues.value[currentIndex.value - 1] = "";
          currentIndex.value--;
        } else {
          boxValues.value[currentIndex.value] = "";
        }
      },
    };
  },
});
