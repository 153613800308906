<template>
  <generic-page-popup title="Document delen" class="choose-document">
    <div class="breadcrumbs-wrapper">
      <breadcrumbs
        :items="items"
        :currentId="currentDirectoryId"
        @goTo="openDir"
      />
    </div>
    <div class="documents">
      <Item
        hide-actions
        v-for="item in currentItems"
        :key="item.id"
        :item="item"
        @open="onOpen"
      />
    </div>
  </generic-page-popup>
</template>

<script lang="ts">
import { showPopup } from "@/components/popups/popups";
import { defineComponent } from "vue";
import { useItemTree } from "../hooks/useItemTree";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import { Item } from "@/types";
import ItemComponent from "../components/Item.vue";

const component = defineComponent({
  components: { Breadcrumbs, Item: ItemComponent },
  setup(props, { emit }) {
    const { currentItems, loading, items, openDir, currentDirectoryId } =
      useItemTree();

    return {
      items,
      loading,
      currentItems,
      currentDirectoryId,
      openDir,
      onOpen(item: Item) {
        if (item.is_folder) {
          return openDir(item.id);
        }
        emit("finish", item);
      },
    };
  },
});

export const showChooseDocumentPopup = (): Promise<null | Item> => {
  return showPopup(component);
};

export default component;
</script>

<style lang="scss" scoped>
.breadcrumbs-wrapper {
  margin-bottom: 10px;
}
</style>
