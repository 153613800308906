<template>
  <generic-page
    :title="$t('lots.many')"
    :options="options"
    :loading="loading"
    :titleInfo="{
      title: 'Wat zijn kavels?',
      text: 'Een kavel is een deel van een mede-eigendom dat een eigenaar heeft. Denk hierbij bijvoorbeeld aan een appartment, garage, parking, ...',
    }"
  >
    <div class="list-lots" v-for="type of Object.keys(lotsByType)" :key="type">
      <h2 class="lot-type">
        {{ translateType(type) }}
      </h2>
      <div class="lots-wrapper">
        <div class="lot-wrapper" v-for="lot in lotsByType[type]" :key="lot.id">
          <lot-item
            @select="editLot(lot)"
            :lot="lot"
            :avatar="residentPictures[lot.main_responsible_id] ?? null"
          />
        </div>
      </div>
    </div>
  </generic-page>
</template>

<script lang="ts">
import { GenericPageOption } from "@/components/common/generic-page/GenericPageOptions.vue";
import { useLots, useResidents } from "@/store/properties/hooks";
import { PropertyLot } from "@/types";
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import LotItem from "../components/LotItem.vue";
import { useTranslateType } from "../hooks/types";
import { showAddLotPopup } from "../popups/AddLotPopup.vue";
import { showEditLotPopup } from "../popups/EditLotPopup.vue";

export default defineComponent({
  components: { LotItem },
  setup() {
    const { t } = useI18n();
    const { loading, lots, refetch } = useLots();
    const { residents } = useResidents();

    let types = computed(() =>
      (lots.value ?? []).map((lot) => lot.type).filter((v) => v != null)
    );
    const options: GenericPageOption[] = [
      {
        id: "add",
        iconName: "add",
        text: t("lots.add"),
        async onClick() {
          await showAddLotPopup(types.value);
          refetch();
        },
      },
    ];

    const residentPictures = computed(() => {
      if (residents.value == null) return {};
      return residents.value.reduce<Record<string, string | null>>(
        (map, user) => {
          if (user.avatar_url == null) {
            map[user.id] = null;
          } else {
            map[user.id] = user.avatar_url;
          }
          return map;
        },
        {}
      );
    });

    const sortedLots = computed(() => {
      return [...lots.value].sort((a: PropertyLot, b: PropertyLot) => {
        if (a.identifier.toLowerCase() > b.identifier.toLowerCase()) return 1;
        if (b.identifier.toLowerCase() > a.identifier.toLowerCase()) return -1;
        return 0;
      });
    });

    const lotsByType = computed(() => {
      if (lots.value == null) return {};
      let result: Record<string, PropertyLot[]> = {};
      for (const lot of sortedLots.value) {
        if (result[lot.type] == null) {
          result[lot.type] = [lot];
        } else {
          result[lot.type].push(lot);
        }
      }
      return result;
    });

    return {
      translateType: useTranslateType(),
      residentPictures,
      async editLot(lot: PropertyLot) {
        await showEditLotPopup(lot, types.value);
        refetch();
      },
      lotsByType,
      loading,
      options,
    };
  },
});
</script>

<style lang="scss" scoped>
.lots-wrapper {
  display: flex;
  flex-flow: row wrap;
}

.lot-wrapper {
  margin-bottom: 18px;
  margin-right: 18px;
}

.lot-type {
  display: block;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
}
.list-lots:first-child .lot-type {
  margin-top: 10px;
}
</style>
