
import { authActions, inAuth } from "@/store/auth";
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const refresh = route.params.refresh;

    if (refresh == null) {
      console.log("OAuthCallback: no refresh token found, routing to login.");
      router.push("/");
      return;
    }

    store
      .dispatch(inAuth(authActions.LOGIN_WITH_REFRESH), { refresh })
      .then(() => {
        router.push({
          name: "init",
        });
      });
  },
});
