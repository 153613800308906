
import { computed, defineComponent, onMounted, ref } from "vue";
import AuthLayout from "@/layouts/Auth.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { authActions, authMutations, inAuth } from "@/store/auth";
import { logBreakingException } from "@/util/logger";
import LoginCodeInput from "../../components/LoginCodeInput.vue";
import { VerifyLoginCodeResponse } from "@/types";

export default defineComponent({
  components: { AuthLayout, LoginCodeInput },
  setup() {
    const router = useRouter();
    const store = useStore();

    const loading = ref(false);
    const error = ref<null | string>(null);

    onMounted(() => {
      if (router.currentRoute.value.query.email == null) {
        console.log("Nope", router.currentRoute.value.query);
        router.push({ name: "auth.emaillogin.enteremail" });
      }
    });

    const email = computed(() => {
      const encodedEmail = router.currentRoute.value.query.email;
      return decodeURIComponent(encodedEmail as string);
    });

    return {
      loading,
      error,
      goBack() {
        router.go(-1);
      },
      async submit(code: string) {
        error.value = null;
        loading.value = true;
        try {
          let verification: VerifyLoginCodeResponse = await store.dispatch(
            inAuth(authActions.VERIFY_CODE),
            { code, email: email.value }
          );
          if (verification.user_exists) {
            await store.dispatch(inAuth(authActions.LOGIN_WITH_CODE), {
              code,
              email: email.value,
            });
            router.push({ name: "init" });
          } else {
            store.commit(inAuth(authMutations.SET_LOGIN_CODE), {
              code,
              email: email.value,
            });
            router.push({
              name: "auth.emaillogin.register",
              query: {
                email: encodeURIComponent(email.value),
              },
            });
          }
        } catch (e) {
          console.log(e);
          console.log((e as any).status);
          error.value = (e as Error).message;
          logBreakingException(e as Error);
        } finally {
          loading.value = false;
        }
      },
    };
  },
});
