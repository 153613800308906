<template>
  <div class="transaction" :class="{ 'transaction--open': showDetails }">
    <!-- <div class="bg"></div> -->
    <!-- <div
      class="tag tag--right"
      v-if="!isComplete && !hasInvoices && transaction.type === 'out'"
    >
      GEEN FACTUUR
    </div> -->
    <div class="tag tag--right" v-if="!isComplete">
      {{ $t("accounting.INCOMPLETE") }}
    </div>
    <!-- <div class="tag tag--green tag--right" v-if="isComplete">IN ORDE</div> -->

    <div class="tag tag--green" v-if="transaction.type === 'in'">+</div>
    <div class="tag" v-if="transaction.type === 'out'">-</div>

    <div class="basic-info" @click="showDetails = !showDetails">
      <h2
        class="price"
        :class="{
          'price--in': false && transaction.type === 'in',
          'price--out': false && transaction.type === 'out',
        }"
      >
        <span class="minus-sign" v-if="transaction.type == 'out'"> -</span>
        <span class="price-value">
          {{ formatAsEuro(transaction.amount, { noEuroSign: true }) }}
        </span>
        <span class="euro-sign">€</span>
      </h2>
      <div class="time">
        {{ formattedDate }}
      </div>
      <!-- <div class="time-wrapper">
        <div class="time time--long">{{ formattedDate }}</div>
        <div class="time time--short">{{ formattedDateShort }}</div>
      </div> -->
      <div class="drop-icon">
        <angle-down-icon width="25px" height="25px" />
      </div>
    </div>
    <div class="extra-info" :class="{ 'extra-info--show': showDetails }">
      <div class="info-item" v-if="transaction.from_name">
        <span class="info-item-label">
          {{ $t("accounting.name") }}
        </span>
        {{ transaction.from_name }}
      </div>
      <div class="info-item" v-if="transaction.to_name">
        <span class="info-item-label">
          {{ $t("accounting.name") }}
        </span>
        {{ transaction.to_name }}
      </div>
      <div class="info-item" v-if="transaction.to_address">
        <span class="info-item-label">IBAN</span>
        {{ transaction.to_address }}
      </div>
      <div class="info-item" v-if="transaction.from_address">
        <span class="info-item-label">IBAN</span>
        {{ transaction.from_address }}
      </div>
      <div class="info-item" v-if="transaction.description">
        <span class="info-item-label">
          {{ $t("common.description") }}
        </span>
        {{ transaction.description }}
      </div>
      <div class="info-item info-item--long" v-if="transaction.additional_info">
        <span class="info-item-label">
          {{ $t("accounting.extra_info") }}
        </span>
        <app-link @click="showAddInfo(transaction.additional_info)">{{
          $t("accounting.show_extra_info")
        }}</app-link>
        <!-- <additional-info :value="transaction.additional_info" /> -->
        <!-- {{ parseAdditionalInfo(transaction.additional_info) }} -->
      </div>
      <div
        class="invoices"
        :class="{ 'invoices--mg-b': !isComplete }"
        v-if="transaction.invoices && transaction.invoices.length > 0"
      >
        <invoice
          v-for="invoice in transaction.invoices"
          :key="invoice.id"
          :invoice="invoice"
          :transaction="transaction"
        />
      </div>
      <div class="payments" v-if="hasPayments">
        <resident-payment
          v-for="payment in transaction.resident_payments"
          :key="payment.id"
          :payment="payment"
          :transaction="transaction"
        />
      </div>
      <a
        v-if="!isComplete && isOutgoing"
        class="cta"
        @click.stop="addInvoice"
        >{{ $t("accounting.upload_invoice") }}</a
      >
      <a
        v-if="!isComplete && !isOutgoing"
        class="cta"
        @click.stop="addPayment"
        >{{ $t("accounting.add_info") }}</a
      >
    </div>
  </div>
  <!-- <add-invoice
    @hide="hideAddInvoice"
    v-if="showAddInvoice"
    :transaction="transaction"
  /> -->
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue";
import { formatAsEuro } from "@/util/price";
import { Transaction } from "@/types";
import { DateTime } from "luxon";
import AddInvoicePopup from "@/components/popups/AddInvoice.vue";
import { showPopup } from "@/components/popups/popups";
import Invoice from "./Invoice.vue";
import AngleDownIcon from "@/components/icons/AngleDown.vue";
import AddResidentPayment from "@/components/popups/AddResidentPayment.vue";
import ResidentPayment from "./ResidentPayment.vue";
import AdditionalInfoPopupVue from "./AdditionalInfoPopup.vue";
import { isTransactionFinished } from "@/store/accounting/util";

export default defineComponent({
  components: { Invoice, AngleDownIcon, ResidentPayment },
  props: {
    transaction: {
      type: Object as PropType<Transaction>,
      required: true,
    },
  },
  setup(props) {
    // const { t } = useI18n();
    const showDetails = ref(false);

    const addInvoice = async () => {
      await showPopup(
        AddInvoicePopup,
        {
          transaction: props.transaction,
        },
        true
      );
    };

    const addPayment = async () => {
      await showPopup(
        AddResidentPayment,
        {
          transaction: props.transaction,
        },
        true
      );
    };

    const hasInvoices = computed(() => {
      return (
        props.transaction.invoices != null &&
        props.transaction.invoices.length > 0
      );
      // const invoices = props.transaction.invoices;
      // return invoices != null && invoices.length > 0;
    });
    const hasPayments = computed(() => {
      const payments = props.transaction.resident_payments;
      return payments != null && payments.length > 0;
    });

    const isComplete = computed(() => {
      return isTransactionFinished(props.transaction);
      // const transaction = props.transaction;
      // if (transaction.type === "in") {
      //   return (transaction.resident_payments?.length ?? 0) > 0;
      // }
      // // const invoice = transaction.invoice;
      // // const payments = transaction.resident_payments;
      // // if (invoices == null) return false;
      // // if (payments == null) return false;
      // // const invoicesTotal = invoices.reduce<number>(
      // //   (total, i) => total + i.amount,
      // //   0
      // // );
      // // const paymentsTotal = payments.reduce<number>(
      // //   (total, p) => total + p.amount,
      // //   0
      // // );
      // // return transaction.amount <= invoicesTotal + paymentsTotal;
      // // TODO: Is complete?
      // console.log(transaction.invoices);
      // return transaction.invoices != null && transaction.invoices.length > 0;
      // return props?.transaction?.invoices != null;
    });

    return {
      formatAsEuro,
      hasInvoices,
      hasPayments,
      isComplete,
      showDetails,
      showAddInfo(info: string) {
        showPopup(AdditionalInfoPopupVue, {
          info,
        });
      },
      isOutgoing: computed(() => props.transaction.type === "out"),
      // parseAdditionalInfo(additionalInfo: string) {
      //   if (additionalInfo == null) return "";
      //   console.log(additionalInfo);
      //   return additionalInfo
      //     .split(",")
      //     .map((item) => item.replaceAll("'", ""))
      //     .join("\n");
      // },
      formattedDate: computed(() => {
        if (props.transaction == null) return "";
        const dt = DateTime.fromISO(props.transaction.value_date);
        return dt.toFormat("dd LLLL yyyy");
      }),
      formattedDateShort: computed(() => {
        if (props.transaction == null) return "";
        const dt = DateTime.fromISO(props.transaction.value_date);
        return `${dt.toFormat("dd LLL")} '${dt.toFormat("yy")}`;
      }),
      addInvoice,
      addPayment,
    };
  },
});
</script>

<style lang="scss" scoped>
.transaction {
  border: 1px solid $border-general;
  background-color: white;
  padding: 20px 0 10px 0;
  /* padding: 20px 20px 40px 20px; */
  border-radius: 5px;
  position: relative;
  /* min-height: 80px; */
  cursor: pointer;
  /* transform: padding-bottom 0.2s ease-out; */

  &--open {
    animation: transactionPaddingBottom 0.05s ease-out forwards;
  }
}
@keyframes transactionPaddingBottom {
  from {
    padding-bottom: 0px;
  }
  to {
    padding-bottom: 20px;
  }
}

.tag {
  position: absolute;
  top: calc(-0.4375rem - 5px);
  height: calc(0.875rem + 10px);
  left: 20px;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: white;
  background-color: $bg-error;
  border-radius: 5px;
  padding: 5px 10px;
  line-height: 100%;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;

  &--right {
    left: auto;
    right: 27px;
  }

  &--green {
    background-color: $bg-success;
  }
}

.basic-info {
  padding: 0 20px 10px;
}
.transaction--open .basic-info {
  border-bottom: 1px solid $border-general;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.extra-info {
  display: none;
  padding: 0 20px;

  &--show {
    display: block;
  }
}

.drop-icon {
  position: absolute;
  right: 30px;
  top: 35px;
}

.bg {
  position: absolute;
  bottom: 0;
  right: 20px;
  width: 90px;
  height: 107px;
  background-image: url("/img/document.svg");
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
}

.time-wrapper {
  /* padding-bottom: 10px;
  position: absolute;
  right: 20px;
  top: 20px;
  margin-top: 7px; */

  &--left {
    display: none;
    /* position: relative;
    top: 0;
    right: 0;
    margin-top: 0; */
    /* top: auto;
    right: auto;
    left: 20px;
    bottom: 0px; */
  }
}

.time {
  color: $text-dark;
  letter-spacing: 0.02rem;
  margin-top: 3px;

  &--short {
    @include screen-from("tablet") {
      display: none;
    }
  }

  &--long {
    display: none;

    @include screen-from("tablet") {
      display: block;
    }
  }
}

.price {
  font-size: 2rem;
  font-weight: 600;
  color: $text-alt;
  margin-top: 2px;
  /* margin-bottom: 15px; */

  &--in {
    color: $text-success;
  }
  &--out {
    color: $text-error;
  }
}

.euro-sign {
  font-weight: 400;
  font-size: 1.25rem;
  padding-left: 3px;
}

.minus-sign {
  font-weight: 700;
  font-size: 1.25rem;
  /* color: $text-error; */
}

.cta {
  color: $text-accent;
  text-decoration: none;
  font-size: 1.125rem;
  font-weight: 600;
  padding-top: 10px;
  display: block;
  /* margin-top: 20px; */
  /* display: block;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 20px; */
}

.info-item {
  font-size: 1rem;
  margin-top: 5px;
  margin-bottom: 10px;
  letter-spacing: 0.04rem;
  /* max-width: calc(100% - 150px); */

  &--long {
    line-height: 130%;
    font-size: 0.875rem;
  }
}

.info-item-label {
  display: block;
  font-weight: bold;
  color: $text-gray;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-size: 0.8rem;
  margin-bottom: 4px;
}

.invoices {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;

  &--mg-b {
    margin-bottom: 15px;
  }

  @include screen-from("tablet") {
    margin-top: 40px;
  }
}

.payments {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;

  &--mg-b {
    margin-bottom: 15px;
  }

  @include screen-from("tablet") {
    margin-top: 40px;
  }
}
</style>
