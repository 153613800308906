
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],
  props: {
    count: {
      type: Number,
      default: 0,
    },
    singular: {
      type: String,
      default: null,
    },
    plural: {
      type: String,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
  },
});
