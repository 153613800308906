<template>
  <slot />
  <property-invitation />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PropertyInvitation from "@/components/notifications/PropertyInvitation.vue";

export default defineComponent({
  components: { PropertyInvitation },
});
</script>
