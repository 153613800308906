<template>
  <generic-page-popup title="Zoeken in documenten">
    <div class="wrapper">
      <div class="search-wrapper">
        <textbox
          type="search"
          autofocus
          thinner
          placeholder="Zoek bestand of folder ..."
          v-model="searchQuery"
          @ref="(ref) => (searchElRef = ref)"
        />
      </div>
      <div class="items">
        <item
          v-for="item in filter.items"
          :key="item.id"
          :item="item"
          @open="onOpen(item)"
          hide-actions
        />
        <div class="more" v-if="filter.hasMore">...</div>
        <div class="empty" v-if="filter.items.length === 0">
          Er zijn geen folders of bestanden met deze naam gevonden
        </div>
      </div>
    </div>
  </generic-page-popup>
</template>

<script lang="ts">
import { showPopup } from "@/components/popups/popups";
import { Item } from "@/types";
import { computed, defineComponent, PropType, ref, watchEffect } from "vue";
import ItemComponent from "../components/Item.vue";

interface Params {
  items: Item[];
}

const component = defineComponent({
  components: { Item: ItemComponent },
  props: {
    params: {
      type: Object as PropType<Params>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const searchElRef = ref<HTMLInputElement | null>(null);
    const searchQuery = ref("");
    const filter = computed(() => {
      const result = props.params.items.filter((item) => {
        return item.name
          .toLowerCase()
          .includes(searchQuery.value.toLowerCase());
      });
      return { items: result.slice(0, 9), hasMore: result.length > 9 };
    });

    watchEffect(() => {
      console.log("SeachElRef", searchElRef.value);
      if (searchElRef.value) {
        searchElRef.value.focus();
      }
    });

    return {
      searchElRef,
      searchQuery,
      filter,
      onOpen(item: Item) {
        emit("finish", item);
      },
    };
  },
});

export const showSearchDocumentsPopup = (
  params: Params
): Promise<Item | null> => {
  return showPopup(component, params);
};

export default component;
</script>

<style lang="scss" scoped>
.wrapper {
  height: 80vh;
}

.search-wrapper {
  margin-bottom: 20px;
}

.more {
  text-align: center;
  letter-spacing: 0.06em;
  margin-top: 30px;
}

.empty {
  text-align: center;
  margin-top: 40px;
  line-height: 1.3;
}
</style>
