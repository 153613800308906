
import { defineComponent } from "vue";
import Loader from "@/components/icons/Loader.vue";

export default defineComponent({
  components: { Loader },
  props: {
    smallHeight: {
      type: Boolean,
      default: false,
    },
  },
});
