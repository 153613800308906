<template>
  <div class="icon">
    <component :is="icon" :size="size" :strokeWidth="strokeWidth" />
  </div>
</template>

<script lang="ts">
import { Component, computed, defineComponent } from "vue";
import AddIcon from "./Add.vue";
import FolderIcon from "./Folder.vue";
import BookIcon from "./Book.vue";
import SettingsIcon from "./Settings.vue";
import TrashIcon from "./Trash.vue";
import SearchIcon from "./Search.vue";

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "22",
    },
    strokeWidth: {
      type: String,
      default: "1",
    },
  },
  setup(props) {
    const icons: { [name: string]: Component } = {
      add: AddIcon,
      edit: SettingsIcon,
      book: BookIcon,
      folder: FolderIcon,
      trash: TrashIcon,
      search: SearchIcon,
    };

    return {
      icons,
      icon: computed(() => {
        return icons[props.name];
      }),
    };
  },
});
</script>
