
import { Transaction, TransactionInvoice } from "@/types";
import { formatAsEuro } from "@/util/price";
import { formatDate } from "@/util/time";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    transaction: {
      type: Object as PropType<Transaction>,
      required: true,
    },
    transactionInvoice: {
      type: Object as PropType<TransactionInvoice>,
    },
  },
  setup(props) {
    return {
      formattedDate: formatDate(props.transaction.value_date),
      amount: formatAsEuro(
        props.transactionInvoice?.amount ?? props.transaction.amount
      ),
    };
  },
});
