
import { defineComponent } from "vue";
import Logo from "@/components/Logo.vue";
import { useRouter } from "vue-router";
import DesktopMenu from "@/components/layout/desktop_menu/DesktopMenu.vue";

export default defineComponent({
  components: {
    Logo,
    DesktopMenu,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  setup() {
    const router = useRouter();
    return {
      handleBack() {
        router.back();
      },
    };
  },
});
