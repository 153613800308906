<template>
  <div class="navbutton previous" @click.prevent="prev" v-if="hasPrev">
    <left-icon width="13px" />
  </div>
  <div class="navbutton next" @click.prevent="next" v-if="hasNext">
    <right-icon width="13px" />
  </div>
  <div class="nav-orbs">
    <div
      class="nav-orb"
      :class="{ active: index - 1 === activeIndex }"
      v-for="index in slideCount"
      :key="index"
      @click="goToIndex(index - 1)"
    ></div>
  </div>
  <div class="exit" @click="$emit('exit')">
    <times-icon size="18" />
  </div>
  <div class="download" @click="toDownload">
    <download-icon size="18" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import { useSwiper } from "swiper/vue/swiper-vue";
import LeftIcon from "@/components/icons/AngleLeft.vue";
import RightIcon from "@/components/icons/AngleRight.vue";
import TimesIcon from "@/components/icons/Times.vue";
import DownloadIcon from "@/components/icons/Download.vue";
import { isMobilePlatform } from "@/util/platform";
import { Browser } from "@capacitor/browser";

export default defineComponent({
  emits: ["exit"],
  components: { LeftIcon, RightIcon, TimesIcon, DownloadIcon },
  props: {
    images: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const swiper = useSwiper();
    const slideCount = computed(() => swiper.value.slides.length);
    const activeIndex = computed(() => swiper.value.activeIndex);
    const hasNext = computed(
      () => slideCount.value > 1 && activeIndex.value < slideCount.value - 1
    );
    const hasPrev = computed(
      () => slideCount.value > 1 && activeIndex.value > 0
    );
    return {
      activeIndex,
      slideCount,
      hasNext,
      hasPrev,
      next() {
        console.log("Next");
        swiper.value.slideNext();
      },
      prev() {
        console.log("Prev");
        swiper.value.slidePrev();
      },
      goToIndex(index: number) {
        swiper.value.slideTo(index);
      },
      toDownload() {
        const activeImage = props.images[activeIndex.value];
        if (isMobilePlatform()) {
          Browser.open({
            url: activeImage,
          });
        } else {
          window.open(activeImage, "_blank");
        }
        emit("exit");
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.navbutton {
  position: absolute;
  width: 40px;
  height: 40px;
  color: $text-alt;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000000;
  background-color: rgba(white, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.previous {
    left: 10px;
  }

  &.next {
    right: 10px;
  }
}

.nav-orbs {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-flow: row nowrap;
}

.nav-orb {
  width: 13px;
  height: 13px;
  border: 2px solid $bg-accent;
  border-radius: 50%;
  z-index: 100000;
  margin-right: 10px;
  cursor: pointer;

  &.active {
    background-color: $bg-accent;
  }
}

.exit {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 41px;
  height: 41px;
  background-color: rgba(white, 0.5);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
}

.download {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 41px;
  height: 41px;
  background-color: rgba(white, 0.5);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
}
</style>
