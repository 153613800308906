
import { computed, defineComponent, ref } from "vue";
import SidebarProfileInfo from "./SidebarProfileInfo.vue";
import PropertySelector from "@/components/layout/util/PropertySelector.vue";
import SidebarMenu from "./SidebarMenu.vue";
import CloseIcon from "@/components/icons/AngleLeft.vue";
import { useStore } from "vuex";
import { inOther, OtherActions, OtherGetters } from "@/store/other";
import { useRouter } from "vue-router";
import { useActiveProperty } from "@/util/properties";
import { isMobilePlatform } from "@/util/platform";
import { useCurrentUser } from "@/util/auth";

export default defineComponent({
  components: { SidebarProfileInfo, PropertySelector, SidebarMenu, CloseIcon },
  setup() {
    const store = useStore();
    const router = useRouter();
    const currentUser = useCurrentUser();

    const hiding = ref(false);
    const visible = computed(
      () => store.getters[inOther(OtherGetters.IS_MOBILE_SIDEBAR_OPEN)]
    );

    const activeProperty = useActiveProperty();

    const close = () => {
      hiding.value = true;
      setTimeout(() => {
        store.dispatch(inOther(OtherActions.HIDE_MOBILE_SIDEBAR));
        hiding.value = false;
      }, 200);
    };

    return {
      currentUser,
      visible,
      close,
      hiding,
      activeProperty,
      toCreateResidence() {
        router.push({
          name: "properties.create",
        });
        close();
      },
      openProfile() {
        router.push({
          name: "profile",
        });
        close();
      },
      isMobile: isMobilePlatform(),
    };
  },
});
