<template>
  <div class="flash-message" :class="{ error }">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    error: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.flash-message {
  background-color: rgba($bg-success, 0.03);
  border: 1px solid $bg-success;
  color: $bg-success;
  font-family: $font-text;
  font-size: 16px;
  font-weight: normal;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 4px;
  line-height: 1.3;

  &.error {
    background-color: rgba($bg-error, 0.03);
    border-color: $bg-error;
    color: $text-error;
  }
}
</style>
