import { Browser } from "@capacitor/browser";
import { computed, Ref } from "vue";

export const useOpenFile = (url: string | Ref<string>) => {
  const safeUrl = computed(() => (typeof url === "string" ? url : url.value));

  return () => {
    const tokens = safeUrl.value.toLocaleLowerCase().split(".");
    const extension = tokens[tokens.length - 1];

    if (["png", "gif", "jpg", "jpeg"].includes(extension)) {
      Browser.open({ url: safeUrl.value });
    } else if (extension === "pdf") {
      Browser.open({ url: safeUrl.value });
    } else {
      Browser.open({ url: safeUrl.value });
    }
  };
};
