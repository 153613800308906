<template>
  <nav class="sidebar-menu">
    <h2 v-if="isAdmin" class="menu-title">resident</h2>
    <!-- <div class="menu-item menu-item--active">
      <router-link
        @click="$emit('close')"
        class="menu-item-link"
        :to="{
          name: 'properties.show',
          params: {
            id: activeProperty?.id,
          },
        }"
      >
        <home-icon class="menu-item-icon" />
        <span class="menu-item-text">{{ $t("common.overview") }}</span>
      </router-link>
    </div> -->
    <!-- <div class="menu-item">
      <router-link
        class="menu-item-link"
        :to="{
          name: 'properties.show',
          params: {
            id: activeProperty?.id || 0,
          },
        }"
        @click="$emit('close')"
      >
        <home-icon class="menu-item-icon" />
        <span class="menu-item-text">{{ $t("common.overview") }}</span>
      </router-link>
    </div> -->
    <div class="menu-item">
      <router-link
        class="menu-item-link"
        :to="{
          name: 'properties.issues.list',
          params: {
            id: activeProperty?.id || 0,
          },
        }"
        @click="$emit('close')"
      >
        <issue-icon class="menu-item-icon" />
        <span class="menu-item-text">
          <span class="menu-item-text-wrapper">
            {{ $t("property.issues") }}
          </span>
          <span v-if="activeIssuesText" class="new-count">
            {{ activeIssuesText }}
          </span>
        </span>
      </router-link>
    </div>
    <div class="menu-item">
      <router-link
        class="menu-item-link"
        :to="{
          name: 'chats.list',
        }"
        @click="$emit('close')"
      >
        <chat-icon class="menu-item-icon" />
        <span class="menu-item-text">
          <!-- <span v-if="anyNewMessages" class="new-marker" /> -->
          <span class="menu-item-text-wrapper">
            {{ $t("chat.one") }}
          </span>
          <span v-if="newMessageText" class="new-count">
            {{ newMessageText }}
          </span>
        </span>
      </router-link>
    </div>

    <div class="menu-item" v-if="hasPayments">
      <router-link
        class="menu-item-link"
        :to="{
          name: 'accounting.user.payments',
        }"
        @click="$emit('close')"
      >
        <payments-icon class="menu-item-icon" />
        <span class="menu-item-text">{{ $t("accounting.contribution") }}</span>
      </router-link>
    </div>

    <div class="menu-item">
      <router-link
        class="menu-item-link"
        :to="{
          name: 'documents.list',
        }"
        @click="$emit('close')"
      >
        <book-icon class="menu-item-icon" />
        <!-- <span class="menu-item-text">Documenten</span> -->
        <span class="menu-item-text">
          <span class="menu-item-text-wrapper">Documenten</span>
          <span v-if="unvisitedItemText" class="new-count">
            {{ unvisitedItemText }}
          </span>
        </span>
      </router-link>
    </div>
    <div class="menu-item">
      <router-link
        class="menu-item-link"
        :to="{
          name: 'properties.residents.list',
          params: { id: activeProperty?.id ?? 0 },
        }"
        @click="$emit('close')"
      >
        <residents-icon class="menu-item-icon" />
        <span class="menu-item-text">{{ $t("property.residents") }}</span>
      </router-link>
    </div>
    <div class="menu-item">
      <router-link
        class="menu-item-link"
        :to="{
          name: 'meetings.list',
        }"
        @click="$emit('close')"
      >
        <handshake-icon class="menu-item-icon" />
        <span class="menu-item-text">Vergaderingen</span>
      </router-link>
    </div>
    <!-- <div class="menu-item">
      <router-link
        class="menu-item-link"
        :to="{
          name: 'chats.list',
        }"
        @click="$emit('close')"
      >
        <chat-icon class="menu-item-icon" />
        <span class="menu-item-text">{{ $t("chat.one") }}</span>
      </router-link>
    </div> -->
    <!-- <div class="menu-item" v-if="showFinance">
      <router-link
        class="menu-item-link"
        :to="{
          name: 'accounting.transactions.list',
        }"
        @click="$emit('close')"
      >
        <documents-icon class="menu-item-icon" />
        <span class="menu-item-text">{{ $t("property.finance") }}</span>
      </router-link>
    </div> -->
    <h2 v-if="isAdmin" class="menu-title">Beheer</h2>
    <div class="menu-item" v-if="isAdmin">
      <router-link
        class="menu-item-link"
        :to="{
          name: 'lots.list',
        }"
        @click="$emit('close')"
      >
        <lot-icon class="menu-item-icon" />
        <span class="menu-item-text">{{ $t("lots.many") }}</span>
      </router-link>
    </div>
    <div class="menu-item" v-if="isAdmin">
      <router-link
        class="menu-item-link"
        :to="{
          name: 'accounting.setup',
        }"
        @click="$emit('close')"
      >
        <documents-icon class="menu-item-icon" />
        <span class="menu-item-text">{{ $t("property.finance") }}</span>
      </router-link>
    </div>
    <div class="menu-item" v-if="isAdmin">
      <router-link
        class="menu-item-link"
        :to="{
          name: 'properties.edit',
          params: {
            id: activeProperty?.id ?? 0,
          },
        }"
        @click="$emit('close')"
      >
        <settings-icon class="menu-item-icon" />
        <span class="menu-item-text">{{ $t("common.settings") }}</span>
      </router-link>
    </div>
    <!-- <div class="menu-item">=======</div> -->
    <!-- <div class="menu-item">
      <router-link
        class="menu-item-link"
        :to="{
          name: 'properties.residents.list',
          params: {
            id: activeProperty?.id || 0,
          },
        }"
        @click="$emit('close')"
      >
        <residents-icon class="menu-item-icon" />
        <span class="menu-item-text">{{ $t("property.residents") }}</span>
      </router-link>
    </div> -->
    <!-- <div class="menu-item">
      <router-link
        class="menu-item-link"
        :to="{
          name: 'properties.details',
          params: {
            id: activeProperty?.id || 0,
          },
        }"
        @click="$emit('close')"
      >
        <question-icon class="menu-item-icon lighter" />
        <span class="menu-item-text">{{ $t("property.info") }}</span>
      </router-link>
    </div> -->
    <!-- <div class="hr"></div> -->
    <!-- <div class="menu-item" v-if="isAdmin">
      <router-link
        class="menu-item-link"
        :to="{
          name: 'properties.edit',
          params: {
            id: activeProperty?.id || 0,
          },
        }"
        @click="$emit('close')"
      >
        <settings-icon class="menu-item-icon" />
        <span class="menu-item-text">{{ $t("common.settings") }}</span>
      </router-link>
    </div> -->
    <!-- <div class="menu-item">
      <router-link class="menu-item-link" to="/">
        <profile-icon class="menu-item-icon" />
        <span class="menu-item-text">Mijn profiel</span>
      </router-link>
    </div> -->
  </nav>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import IssueIcon from "@/components/icons/Issue.vue";
import SettingsIcon from "@/components/icons/Settings.vue";
import DocumentsIcon from "@/components/icons/Documents.vue";
import ResidentsIcon from "@/components/icons/Residents.vue";
import ChatIcon from "@/components/icons/Chat.vue";
import LotIcon from "@/components/icons/Lot.vue";
import { useActiveProperty } from "@/util/properties";
import { useCurrentUser } from "@/util/auth";
import { useTotalNewMessageCount } from "@/util/chat";
import PaymentsIcon from "@/components/icons/Payment.vue";
import BookIcon from "@/components/icons/Book.vue";
import HandshakeIcon from "@/components/icons/Handshake.vue";
import { useCurrentUserPaymentRequests } from "@/store/accounting/hooks";
import { useIssues } from "@/store/issues/hooks";
import { useUnvisitedItemIds } from "@/store/documents/hooks";

export default defineComponent({
  components: {
    HandshakeIcon,
    IssueIcon,
    SettingsIcon,
    DocumentsIcon,
    ResidentsIcon,
    ChatIcon,
    PaymentsIcon,
    BookIcon,
    LotIcon,
  },
  setup() {
    const activeProperty = useActiveProperty();
    const currentUser = useCurrentUser();
    const isAdmin = computed(() => {
      if (currentUser.value == null || activeProperty.value == null)
        return false;
      return currentUser.value.id === activeProperty.value.admin_id;
    });
    const { data: unvisitedItemIds } = useUnvisitedItemIds();
    const unvisitedItemText = computed(() => {
      if (unvisitedItemIds.value == null || unvisitedItemIds.value.length === 0)
        return null;
      if (unvisitedItemIds.value.length > 99) {
        return "9+";
      }
      return unvisitedItemIds.value.length.toString();
    });

    const newMessageCount = useTotalNewMessageCount();
    const newMessageText = computed(() => {
      if (newMessageCount.value == null || newMessageCount.value === 0)
        return null;
      if (newMessageCount.value > 99) return "9+";
      return newMessageCount.value.toString();
    });

    const { data: issues } = useIssues();
    const activeIssuesText = computed(() => {
      if (issues.value == null) return null;
      const activeIssuesCount = issues.value.length;
      if (activeIssuesCount === 0) return null;
      if (activeIssuesCount > 99) return "9+";
      return activeIssuesCount.toString();
    });

    const { paymentRequests } = useCurrentUserPaymentRequests();

    return {
      unvisitedItemText,
      newMessageText,
      activeIssuesText,
      activeProperty,
      isAdmin,
      hasPayments: computed(() => paymentRequests.value.length > 0),
    };
  },
});
</script>

<style lang="scss" scoped>
.sidebar-menu {
  padding: 15px 8px;
  box-sizing: border-box;
}

.menu-item {
  /* margin: 5px 0; */
  font-weight: 400;
  font-size: 1.25rem;
}

.menu-item-link {
  padding: 15px 20px;
  border-radius: 5px;
  color: $text-alt;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  text-decoration: none;

  &.router-link-active {
    background-color: $bg-accent;
    font-weight: 500;
  }
}

.menu-item-icon {
  width: 20px;
  height: 20px;
  padding-right: 15px;
  opacity: 0.8;

  &.lighter {
    opacity: 0.5;
  }
}

.menu-item-text {
  padding-top: 1px;
  letter-spacing: 0.03em;
  position: relative;
  display: flex;
  align-items: center;
}

.menu-item-text-wrapper {
  position: relative;
  z-index: 2;
}

.hr {
  width: calc(100% + 10px);
  height: 1px;
  background-color: $border-general;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.new-marker {
  width: 15px;
  height: 15px;
  background-color: $bg-accent;
  border-radius: 50%;
  position: absolute;
  top: -4px;
  right: -6px;
  z-index: 1;
}
.router-link-active .new-marker {
  background-color: $bg-mobile-menu;
  opacity: 0.6;
}

.new-count {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-accent;
  border-radius: 50%;
  font-size: 12px;
  margin-left: 10px;
}
.router-link-active .new-count {
  background-color: rgba($bg-mobile-menu, 0.6);
  color: white;
}

.menu-title {
  font-size: 0.75rem;
  font-weight: 700;
  color: $text-dark;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  margin-top: 30px;
  margin-bottom: 10px;
  padding-left: 10px;
  opacity: 0.5;

  &:first-child {
    margin-top: 10px;
  }
}
</style>
