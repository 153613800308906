<template>
  <div class="form-field">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.form-field {
  margin-bottom: 20px;
  // display: flex;
  // flex-flow: row nowrap;
  // align-items: center;
  // justify-content: space-between;
  width: 100%;
  // justify-content: stretch;
}
</style>
