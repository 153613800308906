
import PopupWrapper from "@/components/popups/PopupWrapper.vue";
import { fullName } from "@/helpers/user";
import { Property } from "@/repositories/propertyRepository";
import { usePaymentRequests, useUserPayments } from "@/store/accounting/hooks";
import { getDebtForUser } from "@/store/accounting/util";
import { avatarStyles } from "@/store/users/util";
import { User } from "@/types";
import { formatAsEuro } from "@/util/price";
import { sharesForResident } from "@/util/properties";
import { computed, defineComponent, PropType } from "vue";
import PaymentItem from "../../payments/PaymentItem.vue";

export default defineComponent({
  components: { PopupWrapper, PaymentItem },
  props: {
    params: {
      type: Object as PropType<{ user: User; property: Property }>,
      required: true,
    },
  },
  setup(props) {
    const user = computed(() => props.params.user);
    const userId = computed(() => user.value.id);
    const { loading: loadingPayments, payments } = useUserPayments(userId);
    const { loading: loadingRequests, paymentRequests } =
      usePaymentRequests(userId);

    return {
      loading: computed(() => loadingPayments.value || loadingRequests.value),
      payments,
      user,
      avatarStyles: computed(() => avatarStyles(user.value)),
      fullName: computed(() => fullName(user.value)),
      debt: computed(() =>
        getDebtForUser(userId.value, payments.value, paymentRequests.value)
      ),
      formatAsEuro,
      shares: computed(() =>
        sharesForResident(props.params.property, props.params.user.id)
      ),
    };
  },
});
