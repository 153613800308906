<template>
  <div class="wrapper" ref="clickOutsideRef">
    <div class="actions" :class="{ 'actions--open': isOpen }">
      <div
        class="action"
        v-for="action in actions.actions"
        :key="action.label"
        @click="onClick(action)"
      >
        <div class="action-label">
          {{ action.label }}
        </div>
        <div class="action-icon-wrapper" v-if="!loading.includes(action.label)">
          <icon :name="action.icon" size="12" stroke-width="3" />
        </div>
        <div class="action-icon-wrapper" v-else>
          <loader
            width="10px"
            height="10px"
            thickness="2px"
            opacity="0.5"
            color="white"
          />
        </div>
      </div>
    </div>
    <div class="main-button-wrapper">
      <button class="main-button" @click="toggle">
        <icon :name="actions.icon" size="16" stroke-width="3" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watchEffect } from "vue";
import {
  GenericPageAction,
  GenericPageActions,
} from "../GenericPageActions.vue";
import Icon from "@/components/icons/Icon.vue";
import { useOnClickOutside } from "@/util/click";
import Loader from "@/components/icons/Loader.vue";

export default defineComponent({
  components: { Icon, Loader },
  props: {
    actions: {
      type: Object as PropType<GenericPageActions>,
      required: true,
    },
  },
  setup() {
    const isOpen = ref(false);
    const cooldown = ref(false);
    const loading = ref([] as string[]);

    const clickOutsideRef = useOnClickOutside(() => {
      isOpen.value = false;
    });

    watchEffect(() => console.log(loading.value));

    return {
      clickOutsideRef,
      isOpen,
      loading,
      async onClick(action: GenericPageAction) {
        loading.value.push(action.label);
        try {
          await Promise.resolve(action.onClick());
        } finally {
          loading.value = loading.value.filter((l) => l !== action.label);
          if (loading.value.length === 0) {
            isOpen.value = false;
          }
        }
      },
      toggle() {
        if (cooldown.value === true) return;
        cooldown.value = true;
        isOpen.value = !isOpen.value;
        setTimeout(() => (cooldown.value = false), 100);
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.wrapper {
  position: fixed;
  bottom: 15px;
  right: 15px;

  @include screen-from("tablet-wide") {
    display: none;
  }
}

.main-button {
  border: none;
  background-color: $bg-mobile-menu;
  color: white;
  border-radius: 50%;
  width: max(7vh, 40px);
  height: max(7vh, 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 100%;
  outline: none;
  cursor: pointer;
}

.actions {
  display: none;
}

.actions--open {
  display: block;
  animation: ShowActions 0.2s ease-out forwards;
}

@keyframes ShowActions {
  from {
    display: block;
    opacity: 0;
    transform: translateY(10px) scaleY(0.9);
  }
  to {
    display: block;
    opacity: 1;
    transform: translateY(0px), scaleY(1);
  }
}

.action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  opacity: 0.8;
  cursor: pointer;
}

.main-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.action-icon-wrapper {
  background-color: $bg-mobile-menu;
  color: white;
  width: max(4.5vh, 25px);
  height: max(4.5vh, 25px);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 100%;
  border-radius: 50%;
  margin-right: 9px;
  /* margin-bottom: 15px; */
  margin-left: 10px;
}

.action-label {
  font-size: 1.1rem;
}
</style>
