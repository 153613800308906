
import { defineComponent, PropType, reactive, ref, watch } from "vue";
import PopupWrapper from "./PopupWrapper.vue";
import AppForm from "@/components/form/AppForm.vue";
import FormField from "@/components/form/FormField.vue";
import FieldError from "@/components/form/FieldError.vue";
import { CreateInvoiceParams, Transaction } from "@/types";
import { formatAsEuro, parseToRawPrice } from "@/util/price";
import { useStore } from "vuex";
import { filesActions, inFiles } from "@/store/files";
import { accountingActions, inAccounting } from "@/store/accounting/accounting";
import PickInvoice from "@/components/popups/PickInvoice.vue";
import { showPopup } from "./popups";
import { useActiveProperty } from "@/util/properties";

function outstandingAmountTransaction(transaction: Transaction): number {
  // if (transaction.invoices == null) return transaction.amount;
  // return transaction.invoices?.reduce(
  //   (total, invoice) => total - invoice.amount,
  //   transaction.amount
  // );
  return transaction.amount;
}

export default defineComponent({
  components: { PopupWrapper, AppForm, FormField, FieldError },
  props: {
    params: {
      type: Object as PropType<{ transaction: Transaction }>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const fileInputEl = ref(null as null | HTMLInputElement);

    const state = reactive({
      description: "",
      amount: outstandingAmountTransaction(props.params.transaction) as
        | number
        | null,
      amountDisplayed: formatAsEuro(
        outstandingAmountTransaction(props.params.transaction),
        {
          noEuroSign: true,
        }
      ),
      file: null as null | File,
    });

    const formatAmount = () => {
      state.amountDisplayed = formatAsEuro(state.amount ?? 0, {
        noEuroSign: true,
      });
    };

    watch(
      () => state.amountDisplayed,
      (amountDisplayed: string) => {
        state.amount = parseToRawPrice(amountDisplayed);
      }
    );

    const fieldErrors = ref({} as Record<string, string[]>);

    const findFieldErrors = (): Record<string, string[]> => {
      return [
        state.amount == null ? "amount" : null,
        state.description.trim().length === 0 ? "description" : null,
        state.file == null ? "file" : null,
      ]
        .filter((v) => v != null)
        .map((v) => {
          return v;
        })
        .reduce((result, str) => {
          if (str == null) return result;
          result[str] = ["Veld is ongeldig"];
          return result;
        }, {} as Record<string, string[]>);
    };

    const store = useStore();
    const activeProperty = useActiveProperty();

    return {
      fieldErrors,
      fileInputEl,
      state,
      formatAmount,
      setFile(e: InputEvent) {
        const el = e.target as HTMLInputElement;
        const files = el.files;
        if (files == null || files.length === 0) {
          state.file = null;
          return;
        }
        state.file = files[0];
      },
      async handleSubmit() {
        if (activeProperty.value == null) return;
        const errors = findFieldErrors();
        fieldErrors.value = errors;
        if (Object.keys(errors).length > 0) return;

        console.log(fieldErrors.value);
        const file = await store.dispatch(inFiles(filesActions.UPLOAD), {
          file: state.file,
        });

        const params: CreateInvoiceParams = {
          amount: state.amount as number,
          description: state.description,
          transaction_id: props.params.transaction.id,
          file_id: file.id,
          property_id: activeProperty.value?.id,
          link_amount: Math.max(
            state.amount as number,
            props.params.transaction.amount
          ),
        };
        const invoice = await store.dispatch(
          inAccounting(accountingActions.CREATE_INVOICE),
          {
            createParams: params,
            propertyId: props.params.transaction.property_id,
          }
        );
        emit("finish", invoice);
      },
      async linkExisting() {
        const result = await showPopup(
          PickInvoice,
          {
            propertyId: props.params.transaction.property_id,
          },
          true
        );
        if (result == null) return;
        //
        console.log("Result:", result);
      },
    };
  },
});
