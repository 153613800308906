
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    width: {
      type: String,
      default: "23px",
    },
    height: {
      type: String,
      default: "18px",
    },
  },
});
