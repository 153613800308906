<template>
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.091 1.90906L7.45459 12.8181V19.1818L10.7273 15.2727"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M2.90918 9.18191L21.091 1.90918L17.4546 20.091L2.90918 9.18191Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
  </svg>
</template>
