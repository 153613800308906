import { Router } from "vue-router";
import store from "@/store";
import { authActions, inAuth } from "@/store/auth";
import AppSocket from "@/channels/appSocket";

export const registerAuthMiddleware = (router: Router): void => {
  router.beforeEach(async (to, from, next) => {
    if (to.meta.requireAuth === false) return next();

    try {
      await store.dispatch(inAuth(authActions.FETCH_ACCESS_TOKEN));
    } catch (e) {
      return next({
        name: "login",
        query: to.query,
      });
    }

    AppSocket.ensureConnected();
    next();
  });
};
