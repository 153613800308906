import { CreateMeetingParams, EditMeetingParams, Meeting } from "@/types";
import { ApiResponse, get, patch, post } from "./repository";

export default {
  listMeetings(
    propertyId: string | number
  ): ApiResponse<{ meetings: Meeting[] }> {
    return get(`/properties/${propertyId}/meetings`);
  },
  createMeeting(
    meetingData: CreateMeetingParams
  ): ApiResponse<{ meeting: Meeting }> {
    return post(`/meetings`, {
      meeting: meetingData,
    });
  },
  getMeeting(id: string | number): ApiResponse<{ meeting: Meeting }> {
    return get(`/meetings/${id}`);
  },
  updateMeeting(
    id: string | number,
    changes: EditMeetingParams
  ): ApiResponse<{ meeting: Meeting }> {
    return patch(`/meetings/${id}`, { changes });
  },
  addFile(
    meetingId: string | number,
    file: File,
    type: string
  ): ApiResponse<{ meeting: Meeting }> {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("meeting_id", meetingId.toString());
    formData.append("type", type);
    return post("/meetings/file", formData);
  },
};
