
import { computed, defineComponent, onMounted, ref } from "vue";
import AuthLayout from "@/layouts/Auth.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { authActions, authGetters, inAuth } from "@/store/auth";
import { logBreakingException } from "@/util/logger";

export default defineComponent({
  components: { AuthLayout },
  setup() {
    const router = useRouter();
    const store = useStore();

    const firstName = ref("");
    const lastName = ref("");
    const loading = ref(false);
    const error = ref<null | string>(null);

    onMounted(() => {
      let email = router.currentRoute.value.query?.email;
      if (email == null) {
        router.push({ name: "auth.emaillogin.enteremail" });
      }
    });

    const email = computed(() => {
      const encodedEmail = router.currentRoute.value.query?.email;
      if (encodedEmail == null) return null;
      return decodeURIComponent(encodedEmail as string);
    });

    const code = computed(() => {
      if (email.value == null) return;
      const loginCodes = store.getters[inAuth(authGetters.LOGIN_CODES)];
      if (loginCodes == null) return null;
      return loginCodes[email.value] ?? null;
    });

    onMounted(() => {
      if (code.value == null) {
        router.push({ name: "auth.emaillogin.enteremail" });
      }
    });

    return {
      loading,
      error,
      firstName,
      lastName,
      goBack() {
        router.go(-1);
      },
      async submit() {
        error.value = null;
        loading.value = true;
        if (firstName.value.length < 1 || lastName.value.length < 1) {
          return;
        }
        try {
          await store.dispatch(inAuth(authActions.REGISTER_WITH_LOGIN_CODE), {
            code: code.value,
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value,
          });
          router.push({ name: "init" });
        } catch (e) {
          console.log(e);
          console.log((e as any).status);
          error.value = (e as Error).message;
          logBreakingException(e as Error);
        } finally {
          loading.value = false;
        }
      },
    };
  },
});
