<template>
  <property-option :no-margin-x="noMarginX" @click="toResidents()">
    <template v-slot:icon>
      <residents-icon width="32px" height="28px" :stroke-width="2" />
    </template>
    {{ $t("property.residents") }}
  </property-option>
  <property-option :no-margin-x="noMarginX" @click="toLots()">
    <template v-slot:icon>
      <lot-icon width="32px" height="28px" :stroke-width="2" />
    </template>
    {{ $t("lots.many") }}
  </property-option>
  <property-option :no-margin-x="noMarginX" @click="toFinances()">
    <template v-slot:icon>
      <documents-icon />
    </template>
    {{ $t("property.finance") }}
  </property-option>
  <property-option @click="toSettings()" :no-margin-x="noMarginX">
    <template v-slot:icon>
      <settings-icon width="30px" :stroke-width="2" />
    </template>
    {{ $t("common.settings") }}
  </property-option>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ResidentsIcon from "@/components/icons/Residents.vue";
import SettingsIcon from "@/components/icons/Settings.vue";
import DocumentsIcon from "@/components/icons/Documents.vue";
import PropertyOption from "@/components/property/PropertyOption.vue";
import LotIcon from "@/components/icons/Lot.vue";
import { useRouter } from "vue-router";
import { useActiveProperty } from "@/util/properties";

export default defineComponent({
  components: {
    ResidentsIcon,
    PropertyOption,
    SettingsIcon,
    DocumentsIcon,
    LotIcon,
  },
  props: {
    noMarginX: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter();
    const property = useActiveProperty();

    return {
      toResidents() {
        if (property.value == null) return;
        router.push({
          name: "properties.residents.list",
          params: { id: property.value.id },
        });
      },
      toLots() {
        if (property.value == null) return;
        router.push({
          name: "lots.list",
        });
      },
      toFinances() {
        if (property.value == null) return;
        router.push({
          name: "accounting.overview.transactions",
        });
      },
      toSettings() {
        if (property.value == null) return;
        router.push({
          name: "properties.edit",
          params: { id: property.value.id },
        });
      },
    };
  },
});
</script>
