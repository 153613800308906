
import { Item } from "@/types";
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<Item[]>,
      required: true,
    },
    currentId: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const itemMap = computed(() => {
      const result: Record<string, Item> = {};
      for (let item of props.items) {
        result[item.id.toString()] = item;
      }
      return result;
    });
    const calcBreadCrumbs = (item: Item, prev: Item[] = [item]): Item[] => {
      if (item == null) return prev;
      if (item.parent_id == null) return prev;
      const parent = itemMap.value[item.parent_id.toString()];
      if (parent == null) {
        throw new Error(`Parent with id ${item.parent_id} not found`);
      }
      return calcBreadCrumbs(parent, [parent, ...prev]);
    };
    const breadcrumbs = computed(() => {
      if (props.currentId == null) return [];
      const currentItem = itemMap.value[props.currentId.toString()];
      if (currentItem == null) return [];
      return calcBreadCrumbs(currentItem);
    });

    return {
      breadcrumbs,
      onClick(item: Item) {
        emit("goTo", item?.id);
      },
    };
  },
});
