
import { setLocale } from "@/plugins/install_i18n";
import { LOCALSTORAGE_KEY } from "@/router/middleware/langMiddleware";
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();

    return {
      async pick(language: string) {
        localStorage.setItem(LOCALSTORAGE_KEY, language);
        setLocale(language);

        if (route.query?.from != null && typeof route.query.from === "string") {
          try {
            await router.push(decodeURIComponent(route.query.from));
          } catch (e) {
            router.push({ name: "login" });
          }
        } else {
          router.push({
            name: "login",
          });
        }
      },
    };
  },
});
