<template>
  <div class="page-loader" :class="{ smallHeight }">
    <loader width="50px" height="50px" thickness="4px" opacity="0.1" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Loader from "@/components/icons/Loader.vue";

export default defineComponent({
  components: { Loader },
  props: {
    smallHeight: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.page-loader {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &.smallHeight {
    height: 150px;
  }
}
</style>
