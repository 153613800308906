import { authActions, inAuth } from "../auth";
import { chatsActions, inChats } from "../chats";
import store from "../index";
import { inProperties, PropertiesActions } from "../properties";

export async function clearStore() {
  await store.dispatch(inAuth(authActions.LOGOUT));
  await store.dispatch(inProperties(PropertiesActions.CLEAR_SELECTED));
  await store.dispatch(inChats(chatsActions.CLEAR));
}
