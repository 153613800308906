
import { defineComponent, PropType } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import LightBoxSlide from "./LightBoxSlide.vue";
import LightBoxNav from "./LightBoxNav.vue";

import "swiper/swiper.scss";
import { showPopup } from "../popups/popups";

const component = defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    LightBoxSlide,
    LightBoxNav,
  },
  props: {
    params: {
      type: Object as PropType<{ images: string[] }>,
      required: true,
    },
  },
});

export const showLightboxPopup = (images: string[]) => {
  return showPopup(component, { images });
};

export default component;
