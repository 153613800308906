import meetingRepository from "@/repositories/meetingRepository";
import { CreateMeetingParams, EditMeetingParams, Meeting } from "@/types";
import { ActionTree } from "vuex";

export type MeetingsState = unknown;

export function inMeetings(action: string): string {
  return "meetings/" + action;
}

export enum meetingsActions {
  LIST = "LIST",
  CREATE = "CREATE",
  BY_ID = "BY_ID",
  ADD_FILE = "ADD_FILE",
  UPDATE = "UPDATE",
}

const state = (): MeetingsState => ({
  //
});

const actions: ActionTree<MeetingsState, unknown> = {
  async [meetingsActions.ADD_FILE](
    context,
    params: { meetingId: string | number; file: File; type: string }
  ): Promise<Meeting> {
    const response = await meetingRepository.addFile(
      params.meetingId,
      params.file,
      params.type
    );
    return response.data.data.meeting;
  },
  async [meetingsActions.LIST](
    context,
    { propertyId }: { propertyId: string | number }
  ): Promise<Meeting[]> {
    const response = await meetingRepository.listMeetings(propertyId);
    return response.data.data.meetings;
  },
  async [meetingsActions.CREATE](
    context,
    { params }: { params: CreateMeetingParams }
  ): Promise<Meeting> {
    const response = await meetingRepository.createMeeting(params);
    return response.data.data.meeting;
  },
  async [meetingsActions.BY_ID](
    context,
    { id }: { id: string }
  ): Promise<Meeting> {
    const response = await meetingRepository.getMeeting(id);
    return response.data.data.meeting;
  },
  async [meetingsActions.UPDATE](
    context,
    { id, changes }: { id: string; changes: EditMeetingParams }
  ) {
    const response = await meetingRepository.updateMeeting(id, changes);
    return response.data.data.meeting;
  },
};

export default {
  namespaced: true,
  state,
  actions,
};
