<template>
  <auth-layout title="Registreren">
    <form class="wrapper" @submit.prevent="submit">
      <div class="textbox-wrapper">
        <textbox
          label="Voornaam"
          placeholder="Jesse"
          v-model="firstName"
          thinner
          autofocus
        />
      </div>
      <div class="textbox-wrapper">
        <textbox label="Naam" placeholder="Doe" v-model="lastName" thinner />
      </div>
      <div class="button-wrapper">
        <app-button :loading="loading" thinner type="submit"
          >Registreren</app-button
        >
      </div>
    </form>
  </auth-layout>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import AuthLayout from "@/layouts/Auth.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { authActions, authGetters, inAuth } from "@/store/auth";
import { logBreakingException } from "@/util/logger";

export default defineComponent({
  components: { AuthLayout },
  setup() {
    const router = useRouter();
    const store = useStore();

    const firstName = ref("");
    const lastName = ref("");
    const loading = ref(false);
    const error = ref<null | string>(null);

    onMounted(() => {
      let email = router.currentRoute.value.query?.email;
      if (email == null) {
        router.push({ name: "auth.emaillogin.enteremail" });
      }
    });

    const email = computed(() => {
      const encodedEmail = router.currentRoute.value.query?.email;
      if (encodedEmail == null) return null;
      return decodeURIComponent(encodedEmail as string);
    });

    const code = computed(() => {
      if (email.value == null) return;
      const loginCodes = store.getters[inAuth(authGetters.LOGIN_CODES)];
      if (loginCodes == null) return null;
      return loginCodes[email.value] ?? null;
    });

    onMounted(() => {
      if (code.value == null) {
        router.push({ name: "auth.emaillogin.enteremail" });
      }
    });

    return {
      loading,
      error,
      firstName,
      lastName,
      goBack() {
        router.go(-1);
      },
      async submit() {
        error.value = null;
        loading.value = true;
        if (firstName.value.length < 1 || lastName.value.length < 1) {
          return;
        }
        try {
          await store.dispatch(inAuth(authActions.REGISTER_WITH_LOGIN_CODE), {
            code: code.value,
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value,
          });
          router.push({ name: "init" });
        } catch (e) {
          console.log(e);
          console.log((e as any).status);
          error.value = (e as Error).message;
          logBreakingException(e as Error);
        } finally {
          loading.value = false;
        }
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}

.button-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.textbox-wrapper {
  margin-top: 15px;
}

.button-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  @include screen-from("tablet") {
    justify-content: flex-end;
  }
}
</style>
