
import { showPopup } from "@/components/popups/popups";
import { defineComponent } from "vue";

const component = defineComponent({
  setup() {
    //
  },
});

export const showViewSharePopup = () => {
  return showPopup(component, {});
};

export default component;
