import { PropertyLot, User } from "@/types";
import { useCurrentUser } from "@/util/auth";
import { useActiveProperty } from "@/util/properties";
import { computed, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import { inProperties, PropertiesActions } from "../properties";
import { useStoreAction } from "../util/hooks";

export const useResidents = () => {
  const store = useStore();
  const property = useActiveProperty();
  const residents = ref([] as User[]);
  const loading = ref(false);

  const update = async () => {
    if (property.value == null) return;
    loading.value = true;
    residents.value = await store.dispatch(
      inProperties(PropertiesActions.LIST_RESIDENTS),
      { id: property.value.id }
    );
    loading.value = false;
  };

  watchEffect(async () => {
    await update();
  });

  return {
    residents,
    loading,
    refetch: update,
  };
};

export const useCurrentUserShares = () => {
  const activeProperty = useActiveProperty();
  const user = useCurrentUser();

  return computed(() => {
    if (user.value == null) return 0;
    if (activeProperty.value == null) return 0;

    const userProperty = activeProperty.value.user_properties.find(
      (up) => up.user_id === user.value?.id
    );
    if (userProperty == null) return 0;
    return userProperty.shares;
  });
};

export const useLots = () => {
  const activeProperty = useActiveProperty();

  const { loading, value, refetch } = useStoreAction<PropertyLot[]>(
    [],
    (store) => {
      const id = activeProperty.value?.id;
      if (id == null) return [];
      return store.dispatch(inProperties(PropertiesActions.LIST_LOTS), {
        propertyId: id,
      });
    }
  );

  return {
    loading,
    lots: value,
    refetch,
  };
};

export const useLotsForUser = (userId: string | number) => {
  const activeProperty = useActiveProperty();

  const { loading, value, refetch } = useStoreAction<PropertyLot[]>(
    [],
    (store) => {
      const id = activeProperty.value?.id;
      if (id == null) return [];
      return store.dispatch(
        inProperties(PropertiesActions.LIST_LOTS_FOR_USER),
        {
          propertyId: id,
          userId,
        }
      );
    }
  );

  return {
    loading,
    lots: value,
    refetch,
  };
};
