
import { FinDocument } from "@/types";
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const doc = ref(null as null | FinDocument);

    return {
      doc,
    };
  },
});
