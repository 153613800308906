
import { showPopup } from "@/components/popups/popups";
import store from "@/store";
import { inIssues, issueActions } from "@/store/issues";
import { useIssue } from "@/store/issues/hooks";
import { isCurrentPropertyAdmin } from "@/util/auth";
import { issueTags } from "@/util/issues";
import { timeago } from "@/util/timeago";
import { computed, defineComponent, PropType, ref } from "vue";
import CheckIcon from "@/components/icons/Check.vue";
import { GenericPageOption } from "@/components/common/generic-page/GenericPageOptions.vue";
import { showEditIssuePopup } from "./EditIssue.vue";
import { showLightboxPopup } from "@/components/util/LightBox.vue";
import { useQueryClient } from "vue-query";

interface ViewIssueParams {
  issueId: number;
}

const component = defineComponent({
  components: { CheckIcon },
  props: {
    params: {
      type: Object as PropType<ViewIssueParams>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const issueId = computed(() => props.params.issueId);
    const queryClient = useQueryClient();
    const { data: issue, isLoading: loadingIssue } = useIssue(issueId);
    const isAdmin = isCurrentPropertyAdmin();
    const marking = ref(false as boolean);

    const options: GenericPageOption[] = [
      {
        id: "edit",
        iconName: "edit",
        text: "Aanpassen",
        async onClick() {
          if (!issue.value) return;
          await showEditIssuePopup(issue.value.id);
          queryClient.invalidateQueries(["properties", "issues"]);
        },
      },
    ];

    return {
      options,
      issue,
      loading: loadingIssue,
      isAdmin,
      issueTags,
      timeago,
      marking,
      close(hasChanges: boolean) {
        emit("finish", hasChanges);
      },
      async mark(status: "open" | "resolved" | "in_progress") {
        if (issue.value == null) return;
        if (!["open", "resolved", "in_progress"].includes(status)) {
          throw new Error(`Invalid status ${status}`);
        }
        marking.value = true;
        await store.dispatch(inIssues(issueActions.UPDATE), {
          id: issue.value.id,
          updates: { status: status },
        });
        queryClient.invalidateQueries(["properties", "issues"]);
        marking.value = false;
      },
      showLightbox() {
        const pictures = issue.value?.pictures;
        if (pictures == null) return;
        showLightboxPopup(pictures.map((p) => p.url));
      },
    };
  },
});

export default component;

export const showViewIssuePopup = (
  params: ViewIssueParams
): Promise<boolean | null> => {
  return showPopup(component, params);
};
