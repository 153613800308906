<template>
  <div class="transaction-invoice">
    <card>
      <div class="content">
        <div class="date">{{ formattedDate }}</div>
        <div class="amount">
          {{ amount }}
        </div>
      </div>
    </card>
  </div>
</template>

<script lang="ts">
import { Transaction, TransactionInvoice } from "@/types";
import { formatAsEuro } from "@/util/price";
import { formatDate } from "@/util/time";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    transaction: {
      type: Object as PropType<Transaction>,
      required: true,
    },
    transactionInvoice: {
      type: Object as PropType<TransactionInvoice>,
    },
  },
  setup(props) {
    return {
      formattedDate: formatDate(props.transaction.value_date),
      amount: formatAsEuro(
        props.transactionInvoice?.amount ?? props.transaction.amount
      ),
    };
  },
});
</script>

<style lang="scss" scoped>
.transaction-invoice {
  margin-bottom: 10px;
}

.content {
  box-sizing: border-box;
  padding: 15px 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  max-width: 300px;
  letter-spacing: 0.01em;
}
</style>
