
import { DateTime } from "luxon";
import { computed, defineComponent } from "vue";
import { formatAsEuro } from "@/util/price";

export default defineComponent({
  emits: ["click"],
  props: {
    doc: {
      type: Object,
      required: true,
    },
    noDownload: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const formattedAmount = computed(() => {
      const rawAmount = props.doc.amount as number;
      return formatAsEuro(rawAmount);
    });

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dec",
    ];
    const formattedDate = computed(() => {
      const dt = DateTime.fromISO(props.doc.date);
      const monthName = monthNames[dt.month];

      return `${dt.day} ${monthName} ${dt.year}`;
    });

    return {
      formattedAmount,
      formattedDate,
    };
  },
});
