<template>
  <div class="form-ruler">
    <h2 class="label" v-if="label != null">
      {{ label }}
    </h2>
    <div class="ruler"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      default: null,
    },
  },
});
</script>

<style lang="scss" scoped>
.form-ruler {
  width: 100%;
}

.label {
  font-family: $font-title;
  font-size: 1.125rem;
  font-weight: 600;
  color: $text-alt;
  // margin-bottom: 10px;
  margin-bottom: -15px;
  margin-top: 30px;

  &:first-child {
    margin-top: 0;
  }

  @include screen-from("tablet") {
    font-size: 1.5rem;
    margin-top: 40px;
  }
}

.ruler {
  width: 100%;
  height: 1px;
  background-color: #f3eed7;
  // margin: 30px 0;
  margin-top: 30px;
  // margin-bottom: 10px;
  display: block;
}
</style>
