<template>
  <!-- <desktop-menu v-if="!isMobile" responsive /> -->
  <div class="default" :ios="onIos">
    <slot />
  </div>
  <mobile-sidebar />
  <property-invitation />
</template>

<script lang="ts">
import { defineComponent, watchEffect } from "vue";
import { useRoute } from "vue-router";
import PropertyInvitation from "@/components/notifications/PropertyInvitation.vue";
import { getPlatform, isMobilePlatform } from "@/util/platform";
import { useStore } from "vuex";
import { inProperties, PropertiesActions } from "@/store/properties";
import MobileSidebar from "@/components/layout/mobile/mobile-sidebar/MobileSidebar.vue";

export default defineComponent({
  components: { PropertyInvitation, MobileSidebar },
  setup() {
    const route = useRoute();
    const store = useStore();

    const setActiveProperty = (propertyId: number | string) => {
      store.dispatch(inProperties(PropertiesActions.SELECT_PROPERTY), {
        propertyId,
      });
    };
    const updateActiveProperty = () => {
      if (route.params.propertyId != null) {
        setActiveProperty(route.params.propertyId as string);
      } else if (
        route.name != null &&
        route.name.toString().startsWith("properties") &&
        route.params.id != null
      ) {
        setActiveProperty(route.params.id as string);
      }
    };
    updateActiveProperty();
    watchEffect(() => updateActiveProperty());

    return {
      onIos: getPlatform() === "ios",
      route,
      isMobile: isMobilePlatform(),
    };
  },
});
</script>

<style lang="scss" scoped>
.default {
  padding-bottom: 120px;

  @include screen-from("tablet-wide") {
    margin-top: 40px;
    padding-left: 100px;
  }

  @include screen-from("desktop") {
    padding-left: 0;
  }

  &--ios {
    margin-top: 20px;
  }
}
</style>
