
import { timeago } from "@/util/timeago";
import { computed, defineComponent, reactive, ref } from "vue";
import { Issue } from "@/repositories/issueRepository";
import { useStore } from "vuex";
import { inIssues, issueActions } from "@/store/issues";
import { useRoute, useRouter } from "vue-router";
import { showLightboxPopup } from "@/components/util/LightBox.vue";
import { authGetters, inAuth } from "@/store/auth";
import { issueTags } from "@/util/issues";
import CheckIcon from "@/components/icons/Check.vue";
import SettingsIcon from "@/components/icons/Settings.vue";
import { showEditIssuePopup } from "@/features/issues/popups/EditIssue.vue";

export default defineComponent({
  components: { CheckIcon, SettingsIcon },
  setup() {
    const issue = ref(null as null | Issue);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      lightboxEnabled: false,
      loadingButtons: false,
    });

    const fetchIssue = async () => {
      issue.value = await store.dispatch(inIssues(issueActions.BY_ID), {
        id: route.params.issueId,
      });
    };
    fetchIssue();

    const isAdmin = computed(() => {
      if (issue.value == null) return false;
      const user = store.getters[inAuth(authGetters.USER)];
      if (user == null) return false;
      const adminId = issue.value.property.admin_id;
      return adminId === user.id;
    });

    return {
      isAdmin,
      issue,
      state,
      timeago,
      issueTags,
      showLightbox() {
        let pictures = issue.value?.pictures;
        if (pictures == null) return;
        showLightboxPopup(pictures.map((p) => p.url));
      },
      goToEdit() {
        if (issue.value == null) return;
        // router.push({
        //   name: "properties.issues.edit",
        //   params: {
        //     id: issue.value.property_id,
        //     issueId: issue.value.id,
        //   },
        // });
        showEditIssuePopup(issue.value.id);
      },
      async markInProgress() {
        if (issue.value == null) return;
        state.loadingButtons = true;
        await store.dispatch(inIssues(issueActions.UPDATE), {
          id: issue.value.id,
          updates: { status: "in_progress" },
        });
        await fetchIssue();
        state.loadingButtons = false;
      },
      async markResolved() {
        if (issue.value == null) return;
        state.loadingButtons = true;
        const newIssue = await store.dispatch(inIssues(issueActions.UPDATE), {
          id: issue.value.id,
          updates: { status: "resolved" },
        });
        router.push({
          name: "properties.issues.list",
          params: {
            id: newIssue.property_id,
          },
        });
        state.loadingButtons = false;
      },
      async markOpen() {
        if (issue.value == null) return;
        state.loadingButtons = true;
        await store.dispatch(inIssues(issueActions.UPDATE), {
          id: issue.value.id,
          updates: { status: "open" },
        });
        await fetchIssue();
        state.loadingButtons = false;
      },
    };
  },
});
