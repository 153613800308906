
import { computed, defineComponent } from "vue";
import MiniProfileIcon from "@/components/icons/MiniEmptyAvatar.vue";
import { useLogout, useCurrentUser } from "@/util/auth";
import MenuDropper from "./MenuDropper.vue";

export default defineComponent({
  components: { MiniProfileIcon, MenuDropper },
  setup() {
    const currentUser = useCurrentUser();

    const name = computed(() => {
      if (currentUser.value == null) return "";
      return currentUser.value.first_name;
    });

    const doLogout = useLogout();

    return {
      currentUser,
      name,
      logout() {
        doLogout();
      },
    };
  },
});
