
import { computed, defineComponent, ref } from "vue";
// import SidebarMenu from "./SidebarMenu.vue";
import FinanceSidebarMenu from "./FinanceSidebarMenu.vue";
import CloseIcon from "@/components/icons/AngleLeft.vue";
import { useStore } from "vuex";
import { inOther, OtherActions, OtherGetters } from "@/store/other";
import { useRouter } from "vue-router";
import { useActiveProperty } from "@/util/properties";
import { isMobilePlatform } from "@/util/platform";
import { useCurrentUser } from "@/util/auth";
import AngleLeft from "@/components/icons/AngleLeft.vue";

export default defineComponent({
  components: { FinanceSidebarMenu, CloseIcon, AngleLeft },
  setup() {
    const store = useStore();
    const router = useRouter();
    const currentUser = useCurrentUser();
    const activeProperty = useActiveProperty();

    const hiding = ref(false);
    const visible = computed(
      () => store.getters[inOther(OtherGetters.IS_FINANCE_SIDEBAR_OPEN)]
    );

    const close = () => {
      hiding.value = true;
      setTimeout(() => {
        store.dispatch(inOther(OtherActions.HIDE_FINANCE_SIDEBAR));
        hiding.value = false;
      }, 200);
    };

    return {
      currentUser,
      visible,
      close,
      hiding,
      activeProperty,
      toCreateResidence() {
        router.push({
          name: "properties.create",
        });
        close();
      },
      openProfile() {
        router.push({
          name: "profile",
        });
        close();
      },
      isMobile: isMobilePlatform(),
      backToTheApp() {
        store.dispatch(inOther(OtherActions.HIDE_FINANCE_SIDEBAR));
        router.push({
          name: "properties.issues.list",
          params: {
            id: activeProperty.value?.id,
          },
        });
      },
    };
  },
});
