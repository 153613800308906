<template>
  <div class="finish-bank-selection">
    <page-loader />
  </div>
</template>

<script lang="ts">
import { accountingActions, inAccounting } from "@/store/accounting/accounting";
import { authActions, inAuth } from "@/store/auth";
import { inProperties, PropertiesActions } from "@/store/properties";
import { useHasAuth } from "@/util/auth";
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const ref = route.query.ref;
    const hasAuth = useHasAuth();

    if (ref == null) {
      router.push({
        name: "accounting.select_bank",
        query: {
          errorToken: "no-ref",
        },
      });
    }

    const run = async () => {
      const token = route.params?.token;
      if (token) {
        await store.dispatch(inAuth(authActions.SESSION_FROM_SU_TOKEN), token);
      } else {
        await store.dispatch(inAuth(authActions.FETCH_ACCESS_TOKEN));
      }
      const propertyId = route.params?.propertId;
      if (propertyId) {
        await store.dispatch(inProperties(PropertiesActions.SELECT_PROPERTY), {
          propertyId,
        });
      }

      if (!hasAuth.value) {
        console.log("Auth is required for bank selection");
        router.push({
          name: "login",
        });
        return;
      }

      await store.dispatch(inAccounting(accountingActions.FINISH_BANK), {
        reference: ref,
      });

      router.push({
        name: "accounting.select_payment_duration",
      });
    };
    run().catch((e) => {
      console.log("Under");
      console.log("E:", e);
      router.push({
        name: "accounting.select_bank",
        query: {
          error: e.message,
        },
      });
    });
  },
});
</script>
