<template>
  <div class="transactions-wrapper">
    <container thinner>
      <generic-header>
        {{ $t("accounting.transactions") }}
        <template v-slot:right>
          <generic-header-button
            @click="toggleSearch"
            v-if="transactions.length > 5"
          >
            <search-icon v-if="!showSearch" />
            <close-icon v-else small />
          </generic-header-button>
        </template>
      </generic-header>
    </container>
    <page-loader v-if="isLoading" />
    <container thinner width="90%" v-if="!isLoading">
      <div class="search-items" v-if="showSearch && transactions.length > 5">
        <dropdown
          label="Type"
          placeholder="type"
          :options="{
            both: $t('accounting.types.both'),
            in: $t('accounting.types.in'),
            out: $t('accounting.types.out'),
          }"
          v-model="searchOptions.type"
          thinner
        />
        <textbox
          class="search-box"
          placeholder="Zoeken..."
          v-model="searchOptions.text"
          :label="$t('common.searchdots')"
          thinner
        />
      </div>
      <div class="groups" v-if="unfinishedCount > 0">
        <div
          @click.stop="hideFinished = !hideFinished"
          class="group"
          :class="{ active: hideFinished }"
        >
          <span class="group-count">{{ unfinishedCount }}</span>
          <span v-if="unfinishedCount === 1" class="group-text">{{
            $t("accounting.incomplete_transaction")
          }}</span>
          <span v-else class="group-text">{{
            $t("accounting.incomplete_transactions")
          }}</span>
        </div>
      </div>
      <div class="transactions">
        <transaction-item
          class="transaction-item"
          :transaction="transaction"
          v-for="transaction in filteredTransactions"
          :key="transaction.id"
        />
        <div class="empty-state" v-if="transactions.length === 0">
          {{ $t("accounting.transactions_empty") }}
        </div>
      </div>
    </container>
  </div>
</template>

<script lang="ts">
import { accountingActions, inAccounting } from "@/store/accounting/accounting";
import { Transaction } from "@/types";
import { useActiveProperty } from "@/util/properties";
import { computed, defineComponent, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import TransactionItem from "./Transaction.vue";
import { useTransactionsActiveProperty } from "@/util/accounting";
import SearchIcon from "@/components/icons/Search.vue";
import CloseIcon from "@/components/icons/Times.vue";
import { isTransactionFinished } from "@/store/accounting/util";
import { useRouter } from "vue-router";

function useToggleSearch() {
  const showSearch = ref(false);
  const toggleSearch = () => (showSearch.value = !showSearch.value);

  return {
    showSearch,
    toggleSearch,
  };
}

export default defineComponent({
  components: {
    TransactionItem,
    SearchIcon,
    CloseIcon,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const activeProperty = useActiveProperty();
    const transactions = useTransactionsActiveProperty();
    const searchOptions = ref({
      type: null as null | "both" | "in" | "out",
      text: "" as string,
    });
    const isLoading = ref(true);
    const hideFinished = ref(false);

    watchEffect(() => {
      if (activeProperty.value == null) return;
      isLoading.value = true;
      store
        .dispatch(
          inAccounting(accountingActions.FETCH_TRANSACTIONS),
          activeProperty.value.id
        )
        .catch((e) => {
          if (e?.response?.status === 404) {
            router.push({
              name: "accounting.setup",
            });
          }
        })
        .finally(() => {
          isLoading.value = false;
        });
    });

    const fileInput = ref(null as null | HTMLInputElement);
    const selectingTransaction = ref(null as null | Transaction);

    const anyContainText = (needle: string, haystacks: string[]) =>
      haystacks.some(
        (h) => h != null && h.toLowerCase().includes(needle.toLowerCase())
      );

    const filteredTransactions = computed(() => {
      return transactions.value.filter((t: Transaction) => {
        if (hideFinished.value && isTransactionFinished(t)) return false;
        if (searchOptions.value.type === "in" && t.type === "out") return false;
        if (searchOptions.value.type === "out" && t.type === "in") return false;
        if (searchOptions.value.text == null || searchOptions.value.text === "")
          return true;
        return anyContainText(searchOptions.value.text, [
          t.from_address,
          t.from_name,
          t.to_address,
          t.to_name,
          t.description,
        ]);
      });
    });
    watchEffect(() => console.log("Raw:", transactions.value.length));
    watchEffect(() =>
      console.log("Filtered:", filteredTransactions.value.length)
    );

    const unfinishedCount = computed(() => {
      if (transactions.value === null) return 0;
      return transactions.value.filter((t) => !isTransactionFinished(t)).length;
    });

    return {
      unfinishedCount,
      hideFinished,
      filteredTransactions,
      searchOptions,
      transactions,
      fileInput,
      isLoading,
      addInvoice(transaction: Transaction) {
        const el = fileInput.value;
        if (el == null) return;
        selectingTransaction.value = transaction;
        el.click();
      },
      ...useToggleSearch(),
    };
  },
});
</script>

<style lang="scss" scoped>
.transactions {
  margin-top: 20px;

  @include screen-from("tablet") {
    margin-top: 40px;
  }
}

.transaction-item {
  margin-bottom: 20px;
}

.file-input {
  display: none;
}

.search-box {
  margin-top: 10px;
}

.search-items {
  margin-bottom: 40px;
}

.groups {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 10px;
}

.group {
  padding: 8px 14px;
  background-color: white;
  border: 1px solid $border-general;
  border-radius: 5px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.875rem;
  cursor: pointer;
  user-select: none;

  &.active {
    background-color: $bg-error;
    color: white;
    border: 1px solid white;
  }
}

.group-count {
  margin-right: 5px;
  font-weight: bold;
}

.empty-state {
  text-align: center;
  line-height: 130%;
  font-size: 18px;
  padding-top: 100px;
  opacity: 0.7;
}
</style>
