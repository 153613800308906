<template>
  <!-- <tabs-header
    :title="$t('property.finance')"
    :tabs="[
      {
        label: $t('accounting.invoices'),
        to: '/accounting/overview/invoices',
      },
      {
        label: $t('accounting.bank'),
        to: '/accounting/overview/transactions',
      },
      {
        label: $t('accounting.payments'),
        to: '/accounting/overview/payments',
      },
    ]"
  /> -->
  <generic-page
    :tabs="[
      {
        text: $t('accounting.invoices'),
        to: '/accounting/overview/invoices',
      },
      {
        text: $t('accounting.bank'),
        to: '/accounting/overview/transactions',
      },
      {
        text: $t('accounting.payments'),
        to: '/accounting/overview/payments',
      },
    ]"
    :title="$t('property.finance')"
  >
    <router-view />
  </generic-page>
</template>

<script lang="ts">
import { useActiveProperty } from "@/util/properties";
import { defineComponent, watchEffect } from "vue";
import { useRouter } from "vue-router";
import TabsHeader from "./TabsHeader.vue";

export default defineComponent({
  components: {
    TabsHeader,
  },
  setup() {
    const activeProperty = useActiveProperty();
    const router = useRouter();

    watchEffect(() => {
      if (activeProperty.value == null) return;
      if (
        activeProperty.value.payment_term == null ||
        activeProperty.value.payment_term === 0
      ) {
        router.push({ name: "accounting.select_payment_duration" });
      }
    });
  },
});
</script>
