import {
  User,
  Invite,
  UserProperty,
  PropertyLot,
  CreatePropertyLotParams,
  PatchPropertyLotParams,
} from "@/types";
import { ApiResponse, get, patch, post, del } from "./repository";

export interface Property {
  id: number;
  street: string;
  number: string;
  zipcode: string;
  city: string;
  emergency_contact: string;
  floors: number;
  has_basement: boolean;
  has_financial_data: boolean;
  has_parking: boolean;
  name: string;
  admin_id: number;
  user_properties: UserProperty[];
  total_shares: number;
  payment_term: number;
}

export interface PropertiesResponse {
  properties: Property[];
}

export interface PropertyResponse {
  property: Property;
}

export default {
  listProperties(): ApiResponse<PropertiesResponse> {
    return get<PropertiesResponse>("/properties");
  },
  listOverlappingProperties(
    userIds: number[]
  ): ApiResponse<PropertiesResponse> {
    return get(`/properties/overlaps/${userIds.join(",")}`);
  },
  propertyById(id: number): ApiResponse<PropertyResponse> {
    return get<PropertyResponse>("/properties/" + id);
  },
  create(params: any): ApiResponse<PropertyResponse> {
    return post<PropertyResponse>("/properties", { property: params });
  },
  patch(id: number, updates: any): ApiResponse<PropertyResponse> {
    return patch<PropertyResponse>("/properties/" + id, { updates });
  },
  del(id: number): ApiResponse<PropertyResponse> {
    return del<PropertyResponse>("/properties/" + id);
  },
  listResidents(propertyId: number): ApiResponse<{ users: User[] }> {
    return get<{ users: User[] }>("/properties/" + propertyId + "/residents");
  },
  invite(propertyId: number, email: string): ApiResponse<{ invite: Invite }> {
    return post<{ invite: Invite }>("/invites", {
      invite: {
        email,
        property_id: propertyId,
      },
    });
  },
  listInvited(propertyId: number): ApiResponse<{ invites: Invite[] }> {
    return get<{ invites: Invite[] }>(`properties/${propertyId}/invites`);
  },
  listInvites(): ApiResponse<{ invites: Invite[] }> {
    return get(`/invites`);
  },
  deleteInvite(inviteId: number): ApiResponse<{ invite: Invite }> {
    return del<{ invite: Invite }>(`/invites/${inviteId}`);
  },
  acceptInvite(inviteId: number): ApiResponse<{ invite: Invite }> {
    return post(`/invites/${inviteId}`);
  },
  declineInvite(inviteId: number): ApiResponse<{ invite: Invite }> {
    return del(`/invites/${inviteId}`);
  },
  listConnections(): ApiResponse<{ users: User[] }> {
    return get("/properties/connections");
  },
  acceptInviteWithToken(token: string): ApiResponse<{ invite: Invite }> {
    return post(`/invites/token/${token}`);
  },
  declineInviteWithToken(token: string): ApiResponse<{ invite: Invite }> {
    return del(`/invites/token/${token}`);
  },
  updateShares(
    propertyId: number | string,
    residentId: number | string,
    newShares: number
  ): ApiResponse<{ property: Property }> {
    return post(`/properties/${propertyId}/users/${residentId}/shares`, {
      new_shares: newShares,
    });
  },
  userWithEmailExists(email: string): ApiResponse<{ exists: boolean }> {
    return post(`/users/email/exists`, { email });
  },
  inviteOrCreate(
    propertyId: number | string,
    email: string,
    firstName?: string,
    lastName?: string
  ): ApiResponse<{ success: true }> {
    return post(`/properties/${propertyId}/invite`, {
      email,
      first_name: firstName,
      last_name: lastName,
    });
  },

  listLots(propertyId: string | number): ApiResponse<{ lots: PropertyLot[] }> {
    return get(`/properties/${propertyId}/lots`);
  },
  listLotsForUser(
    propertyId: string | number,
    userId: string | number
  ): ApiResponse<{ lots: PropertyLot[] }> {
    return get(`/properties/${propertyId}/users/${userId}/lots`);
  },
  createLot(
    params: CreatePropertyLotParams
  ): ApiResponse<{ lot: PropertyLot }> {
    return post(`/lots`, { lot: params });
  },
  patchLot(
    lotId: string | number,
    changes: PatchPropertyLotParams
  ): ApiResponse<{ lot: PropertyLot }> {
    return patch(`/lots/${lotId}`, { changes });
  },
  deleteLot(lotId: string | number): ApiResponse<{ lot: PropertyLot }> {
    return del(`/lots/${lotId}`);
  },
  removeFromProperty(
    userId: string | number,
    propertyId: string | number
  ): ApiResponse<{ success: true }> {
    return del(`/properties/${propertyId}/residents/${userId}`);
  },
};
