
import { useActiveProperty } from "@/util/properties";
import { defineComponent, watchEffect } from "vue";
import { useRouter } from "vue-router";
import TabsHeader from "./TabsHeader.vue";

export default defineComponent({
  components: {
    TabsHeader,
  },
  setup() {
    const activeProperty = useActiveProperty();
    const router = useRouter();

    watchEffect(() => {
      if (activeProperty.value == null) return;
      if (
        activeProperty.value.payment_term == null ||
        activeProperty.value.payment_term === 0
      ) {
        router.push({ name: "accounting.select_payment_duration" });
      }
    });
  },
});
