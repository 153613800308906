export function replaceMany(value: string, replacements: [string, string][]) {
  return replacements.reduce(
    (res, [searchValue, replaceValue]) =>
      res.replace(searchValue, replaceValue),
    value
  );
}

export function includesAll(value: string, maybeIncludedValues: string[]) {
  return !maybeIncludedValues.some((v) => !value.includes(v));
}
