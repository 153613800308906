<template>
  <div class="lot-item" @click="$emit('select')">
    <div class="title">{{ lot.identifier }}</div>
    <div class="avatar-wrapper" v-if="!hideAvatar">
      <div
        v-if="avatar != null"
        class="image-avatar"
        :style="{
          'background-image': `url('${avatar}')`,
        }"
      />
      <div class="empty-avatar" v-else>
        <empty-profile-icon />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropertyLot } from "@/types";
import { defineComponent, PropType } from "vue";
import EmptyProfileIcon from "@/components/icons/EmptyProfile.vue";

export default defineComponent({
  emits: ["select"],
  components: { EmptyProfileIcon },
  props: {
    lot: {
      type: Object as PropType<PropertyLot>,
      required: true,
    },
    avatar: {
      type: String,
      default: null,
    },
    hideAvatar: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.lot-item {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border: 1px solid $border-general;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: white;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.title {
  font-weight: bold;
  letter-spacing: 0.05em;
  font-size: 1.25rem;
}

.avatar-wrapper {
  // position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  top: -15px;
  right: 2px;
  border: 1px solid $bg-accent;
  background-color: $bg-accent;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.image-avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.empty-avatar {
  width: 16px;
  height: 16px;
  margin-bottom: 4px;
}
</style>
