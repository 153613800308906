
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    width: {
      type: String,
      default: "32px",
    },
    height: {
      type: String,
      default: "30px",
    },
    strokeWidth: {
      type: String,
      default: "2",
    },
  },
});
