import documentsRepository from "@/repositories/documentsRepository";
import { CreateItemParams, Item, PatchItemParams } from "@/types";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState } from "..";

export interface DocumentsState {
  items: null | Item[];
  unseenItemIds: number[] | null;
}

export function inDocuments(action: string) {
  return "documents/" + action;
}

export const documentsActions = {
  FETCH_ITEMS: "FETCH_ITEMS",
  CREATE_ITEM: "CREATE_ITEM",
  UPLOAD_FILE: "UPLOAD_FILE", // Creates an item with a file
  PATCH_ITEM: "PATCH_ITEM",
  DELETE_ITEM: "DELETE_ITEM",
  FETCH_SEEN_ITEMS: "FETCH_SEEN_ITEMS",
  MARK_ITEMS_SEEN: "MARK_ITEMS_SEEN",
};

export const documentsMutations = {
  ADD_ITEMS: "ADD_ITEMS",
  REMOVE_ITEMS: "REMOVE_ITEMS",
  CHANGE_ITEM: "CHANGE_ITEM",
  ADD_SEEN_ITEMS: "ADD_SEEN_ITEMS",
  SET_UNSEEN_ITEMS: "SET_UNSEEN_ITEMS",
};

export const documentsGetters = {
  UNSEEN_ITEMS: "UNSEEN_ITEMS",
};

const state = (): DocumentsState => ({
  items: null,
  unseenItemIds: null,
});

const getters: GetterTree<DocumentsState, unknown> = {
  [documentsGetters.UNSEEN_ITEMS](state) {
    return state.unseenItemIds;
  },
};

const mutations: MutationTree<DocumentsState> = {
  [documentsMutations.ADD_ITEMS](state, items: Item[]) {
    if (state.items == null) {
      state.items = [...items];
      return;
    }
    const itemsMap: Record<string, Item> = {};
    state.items.forEach((item) => (itemsMap[item.id] = item));
    items.forEach((item) => (itemsMap[item.id] = item));
    state.items = Object.values(itemsMap);
  },
  [documentsMutations.CHANGE_ITEM](
    state,
    { id, changes }: { id: number; changes: Partial<Item> }
  ) {
    if (state.items == null) return;
    const index = state.items.findIndex((item) => item.id === id);
    if (index == null) return;
    Object.assign(state.items[index], changes);
  },
  [documentsMutations.REMOVE_ITEMS](state, items: Item[]) {
    if (state.items == null) return;
    const keysToRemove = items.reduce((map, item) => {
      map[item.id] = item.id;
      return map;
    }, {} as Record<number, number>);
    state.items = state.items.filter((item) => keysToRemove[item.id] == null);
  },
  [documentsMutations.ADD_SEEN_ITEMS](state, itemIds: number[]) {
    if (state.unseenItemIds == null) {
      return;
    }
    const itemIdsToRemove = new Set(itemIds);
    state.unseenItemIds = state.unseenItemIds.filter(
      (itemId) => !itemIdsToRemove.has(itemId)
    );
  },
  [documentsMutations.SET_UNSEEN_ITEMS](state, itemIds: number[]) {
    state.unseenItemIds = [...itemIds];
  },
};

const actions: ActionTree<DocumentsState, RootState> = {
  async [documentsActions.FETCH_ITEMS](
    context,
    params: { propertyId: number | string }
  ): Promise<Item[]> {
    const response = await documentsRepository.listItems(params.propertyId);
    const items = response.data.data.items;
    context.commit(documentsMutations.ADD_ITEMS, items);
    return items;
  },
  async [documentsActions.UPLOAD_FILE](
    context,
    { params, file }: { params: CreateItemParams; file: File }
  ) {
    const response = await documentsRepository.uploadItem(params, file);
    const item = response.data.data.item;
    console.log("TT:", response.data.data, item);
    context.commit(documentsMutations.ADD_ITEMS, [item]);
    return item;
  },
  async [documentsActions.CREATE_ITEM](
    context,
    {
      params,
    }: {
      params: CreateItemParams;
    }
  ): Promise<Item> {
    const response = await documentsRepository.createItem(params);
    const item = response.data.data.item;
    context.commit(documentsMutations.ADD_ITEMS, [item]);
    return item;
  },
  async [documentsActions.PATCH_ITEM](
    context,
    params: {
      changes: PatchItemParams;
      itemId: number | string;
    }
  ): Promise<Item> {
    const response = await documentsRepository.patchItem(
      params.itemId,
      params.changes
    );
    context.commit(documentsMutations.CHANGE_ITEM, {
      id: params.itemId,
      changes: params.changes,
    });
    return response.data.data.item;
  },
  async [documentsActions.DELETE_ITEM](
    context,
    params: {
      itemId: number | string;
    }
  ): Promise<void> {
    const response = await documentsRepository.deleteItem(params.itemId);
    const removedItem = response.data.data.item;
    context.commit(documentsMutations.REMOVE_ITEMS, [removedItem]);
  },
  async [documentsActions.FETCH_SEEN_ITEMS](
    context,
    params: {
      propertyId: number | string;
    }
  ): Promise<void> {
    const response = await documentsRepository.getUnvisitedItemIds(
      params.propertyId
    );
    const unvisitedItemIds = response.data.data.unvisited_ids;
    context.commit(documentsMutations.SET_UNSEEN_ITEMS, unvisitedItemIds);
  },
  async [documentsActions.MARK_ITEMS_SEEN](
    context,
    params: {
      itemIds: number[];
    }
  ): Promise<void> {
    await documentsRepository.markItemsVisited(params.itemIds);
    context.commit(documentsMutations.ADD_SEEN_ITEMS, params.itemIds);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
