<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 32 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.24269 25.9968L14.1947 2.06784C14.3712 1.74465 14.6315 1.47494 14.9482 1.28705C15.265 1.09915 15.6264 1 15.9947 1C16.363 1 16.7244 1.09915 17.0411 1.28705C17.3579 1.47494 17.6182 1.74465 17.7947 2.06784L30.7467 25.9968C30.9137 26.3075 30.9971 26.6563 30.9888 27.0089C30.9805 27.3615 30.8807 27.7059 30.6992 28.0084C30.5178 28.3108 30.2608 28.5609 29.9536 28.7342C29.6464 28.9075 29.2994 28.998 28.9467 28.9968H3.04469C2.6918 28.9983 2.34458 28.9081 2.03708 28.735C1.72958 28.5618 1.47237 28.3117 1.29066 28.0092C1.10895 27.7067 1.00899 27.3622 1.00058 27.0094C0.992169 26.6566 1.0756 26.3077 1.24269 25.9968V25.9968Z"
      stroke="currentColor"
      :stroke-width="strokeWidth"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M15.9946 8.99683V18.9968"
      stroke="currentColor"
      :stroke-width="strokeWidth"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M15.9946 24.9968C16.8231 24.9968 17.4946 24.3253 17.4946 23.4968C17.4946 22.6684 16.8231 21.9968 15.9946 21.9968C15.1662 21.9968 14.4946 22.6684 14.4946 23.4968C14.4946 24.3253 15.1662 24.9968 15.9946 24.9968Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    width: {
      type: String,
      default: "32px",
    },
    height: {
      type: String,
      default: "30px",
    },
    strokeWidth: {
      type: String,
      default: "2",
    },
  },
});
</script>
