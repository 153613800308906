<template>
  <!-- width="21"
    height="21" -->
  <svg
    aria-hidden="true"
    role="img"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
  >
    <path
      d="M19.5 19.5L14.656 14.656"
      stroke="currentColor"
      stroke-miterlimit="10"
      stroke-linecap="square"
      stroke-width="2"
    />
    <path
      d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
      stroke="currentColor"
      stroke-miterlimit="10"
      stroke-linecap="square"
      stroke-width="2"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    size: {
      type: String,
    },
  },
});
</script>
