
import { defineComponent, PropType, Ref } from "vue";

export interface OverviewItem {
  label: string;
  value: Ref<string | number> | string | number;
  double?: number;
  success?: boolean | Ref<boolean>;
  error?: boolean | Ref<boolean>;
}

export default defineComponent({
  props: {
    items: {
      type: Object as PropType<OverviewItem>,
      required: true,
    },
  },
  setup() {
    //
  },
});
