
import { defineComponent, ref, computed, Ref } from "vue";
import {
  useItems,
  useChildMap,
  isItemEmpty,
  useGetUnvisitedCount,
} from "@/store/documents/hooks";
import ItemComponent from "../components/Item.vue";
import { GenericPageActions } from "@/components/common/generic-page/GenericPageActions.vue";
import { useStore } from "vuex";
import { documentsActions, inDocuments } from "@/store/documents/documents";
import { useActiveProperty } from "@/util/properties";
import { promptFolderName } from "../popups/PromptFolderName.vue";
import { Item } from "@/types";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import { showUploadFilePopup } from "../popups/UploadFile.vue";
import { isMobilePlatform } from "@/util/platform";
import { Browser } from "@capacitor/browser";
import { isCurrentPropertyAdmin } from "@/util/auth";
import { itemsSort } from "../util/sortdocs";
import { useI18n } from "vue-i18n";
import MovingItemBanner from "../components/MovingItemBanner.vue";
import { showToast } from "@/components/notifications/toast";
import { GenericPageOption } from "@/components/common/generic-page/GenericPageOptions.vue";
import { useRouter } from "vue-router";
import { showSearchDocumentsPopup } from "../popups/SearchItems.vue";
import { showConfirmPopup } from "@/components/popups/common_popups";

export default defineComponent({
  components: { Item: ItemComponent, Breadcrumbs, MovingItemBanner },
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const store = useStore();
    const activeProperty = useActiveProperty();
    const { value: items, loading, refetch: refetchItems } = useItems();
    const childMap = useChildMap(items);
    const { getUnvisitedCount, unvisitedItems } = useGetUnvisitedCount(
      childMap,
      items
    );
    const isAdmin = isCurrentPropertyAdmin();
    const movingItem = ref<null | Item>(null);

    const currentDirectoryId = computed(() => {
      const directoryId = router.currentRoute.value.params
        .directoryId as string;
      return directoryId ? parseInt(directoryId) : null;
    });
    const setDirectoryId = (id: number) => {
      router.push({ name: "documents.list", params: { directoryId: id } });
    };

    const currentItems = computed(() => {
      return [...items.value]
        .filter((item) => {
          if (item.parent_id == null && currentDirectoryId.value == null)
            return true;
          return item.parent_id == currentDirectoryId.value;
        })
        .sort(itemsSort);
    });

    const isEmpty = computed(
      () =>
        (item: Item): boolean =>
          isItemEmpty(item, childMap.value)
    );

    const itemTags = computed(() => (item: Item) => {
      if (isEmpty.value(item)) {
        return [t("common.empty")];
      }
      return [];
    });
    const onOpen = (item: Item) => {
      if (item.is_folder) {
        setDirectoryId(item.id);
      } else {
        if (item.file == null) {
          throw new Error(`Item with id ${item.id}, file not loaded`);
        }
        store.dispatch(inDocuments(documentsActions.MARK_ITEMS_SEEN), {
          itemIds: [item.id],
        });
        if (isMobilePlatform()) {
          Browser.open({
            url: item.file.url,
          });
        } else {
          window.open(item.file.url, "_blank");
        }
      }
    };

    const options: GenericPageOption[] = [
      {
        id: "search",
        iconName: "search",
        text: "Zoeken",
        onClick: async () => {
          const item = await showSearchDocumentsPopup({
            items: items.value,
          });
          if (item == null) return;
          onOpen(item);
        },
      },
    ];

    const actions: Ref<GenericPageActions | undefined> = computed(() => {
      if (!isAdmin.value) return undefined;
      return {
        icon: "add",
        label: "Nieuw",
        actions: [
          {
            icon: "folder",
            label: "Map",
            onClick: async () => {
              if (activeProperty.value == null) return;
              const folderName = await promptFolderName();
              if (folderName == null) return;
              // await new Promise((resolve) => setTimeout(resolve, 2000));
              console.log("CurrentDir:", currentDirectoryId);
              await store.dispatch(inDocuments(documentsActions.CREATE_ITEM), {
                params: {
                  name: folderName,
                  parent_id: currentDirectoryId.value,
                  property_id: activeProperty.value.id,
                },
              });
            },
          },
          {
            icon: "book",
            label: "Bestand",
            onClick: async () => {
              await showUploadFilePopup({
                async onFinish(file: File) {
                  if (activeProperty.value == null) {
                    throw new Error(
                      "Something went wrong while selecting the file"
                    );
                  }
                  return await store.dispatch(
                    inDocuments(documentsActions.UPLOAD_FILE),
                    {
                      file,
                      params: {
                        name: file.name,
                        parent_id: currentDirectoryId.value ?? undefined,
                        property_id: activeProperty.value.id,
                      },
                    }
                  );
                },
              });
            },
          },
        ],
      };
    });

    return {
      getUnvisitedCount,
      movingItem,
      currentDirectoryId,
      setDirectoryId,
      options,
      actions,
      loading,
      items,
      currentItems,
      itemTags,
      unvisitedItems,
      async markAllRead() {
        const answer = await showConfirmPopup({
          title: "Alle bestanden als gelezen markeren",
          subTitle:
            "Dit zal alle 'nieuw' tags van de huidige bestanden verwijderen. Bent u zeker dat u dit wilt doen?",
          confirmText: "Ja",
          declineText: "Neen, stop",
        });
        if (answer === true) {
          store.dispatch(inDocuments(documentsActions.MARK_ITEMS_SEEN), {
            itemIds: items.value.map((item) => item.id),
          });
        }
      },
      async onDropMovingItem() {
        const item = movingItem.value;
        if (item == null) return;
        item.parent_id = currentDirectoryId.value;
        try {
          await store.dispatch(inDocuments(documentsActions.PATCH_ITEM), {
            itemId: item.id,
            changes: {
              parent_id: currentDirectoryId.value,
            },
          });
        } finally {
          // TODO: Only refetch this single item
          refetchItems();
        }
        movingItem.value = null;
        showToast({
          type: "success",
          text: "Bestand verplaatst",
        });
      },
      onOpen,
      onMove(item: Item) {
        movingItem.value = item;
      },
    };
  },
});
