<template>
  <popup-wrapper @finish="$emit('finish')">
    <div class="content">
      <h2 class="title">{{ $t("fin.recent_docs") }}</h2>
      <div class="documents">
        <div class="document-wrapper" v-for="doc in documents" :key="doc.id">
          <finance-item @click="selectDocument(doc)" :doc="doc" no-download />
        </div>
      </div>
    </div>
  </popup-wrapper>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import PopupWrapper from "./PopupWrapper.vue";
import FinanceItem from "@/components/finance/FinanceItem.vue";
import { Exception } from "@/error_handling/Exception";
import { finActions, inFin } from "@/store/fin";

export default defineComponent({
  components: { PopupWrapper, FinanceItem },
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const documents = ref([] as Document[]);

    const store = useStore();

    // eslint-disable-next-line vue/no-setup-props-destructure
    const propertyIds: null | number[] = props.params.property_ids;
    if (propertyIds == null) {
      throw new Exception(
        "SelectDocumentPopup requires the property_ids parameter."
      );
    }
    propertyIds.forEach((pid) => {
      store
        .dispatch(inFin(finActions.LIST), { propertyId: pid })
        .then((values) => {
          documents.value.push(...values);
        });
    });

    return {
      documents,
      selectDocument(doc: Document) {
        emit("finish", doc);
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.page-loader {
  height: 200px;
}

.content {
  padding: 30px;
  box-sizing: border-box;
}

.title {
  font-size: 1.45rem;
  font-weight: 600;
  color: $text-alt;
  letter-spacing: 0.02rem;
  margin-bottom: 20px;
}

.document-wrapper {
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}
</style>
