import { DateTime } from "luxon";

export function monthIndexToMonth(
  monthIndex: number,
  t: (value: string) => string
) {
  return t(`months.${monthIndex.toString()}`);
}

export function formatDate(date: string | Date) {
  const format = "dd LLL yyyy";
  if (typeof date === "string") {
    return DateTime.fromISO(date).toFormat(format);
  }
  return DateTime.fromJSDate(date).toFormat(format);
}
