<template>
  <popup-wrapper @finish="$emit('finish')" class="upload-popup">
    <div
      class="content"
      @dragover="onDragOver"
      @dragleave="onDragLeave"
      @drop="onDrop"
      :class="{ dragging }"
    >
      <h2 class="title">{{ $t("upload.file") }}</h2>
      <flash-message v-if="uploadFailed" error>
        <p>{{ $t("upload.failed") }}</p>
        <!-- <p>We kijken het na en laten u zo snel mogelijk iets weten.</p> -->
      </flash-message>
      <div v-if="platform === 'web'" @click="selectFile" class="drop-zone">
        <template v-if="dragging"> {{ $t("upload.release") }} </template>
        <template v-else> {{ $t("upload.click_or_drag") }} </template>
      </div>
      <div v-else @click="selectFile" class="drop-zone">
        {{ $t("upload.click_to_choose") }}
      </div>
      <input
        @change="onFilePicked"
        class="file-input"
        type="file"
        ref="fileEl"
      />
      <div class="loader-wrapper" v-if="loading">
        <loader width="50px" height="50px" thickness="4px" opacity="0.1" />
      </div>
    </div>
  </popup-wrapper>
</template>

<script lang="ts">
import { filesActions, inFiles } from "@/store/files";
import { getPlatform } from "@/util/platform";
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import PopupWrapper from "./PopupWrapper.vue";
import * as Sentry from "@sentry/vue";
import Loader from "@/components/icons/Loader.vue";

export default defineComponent({
  components: { PopupWrapper, Loader },
  setup(props, { emit }) {
    const fileEl = ref(null as null | HTMLInputElement);
    const dragging = ref(false as boolean);
    const loading = ref(false as boolean);
    const store = useStore();
    const uploadFailed = ref(false as boolean);

    const uploadFile = async (file: File) => {
      console.log("Upload file:", file);
      loading.value = true;
      try {
        const result = await store.dispatch(inFiles(filesActions.UPLOAD), {
          file,
        });
        emit("finish", result);
      } catch (e) {
        uploadFailed.value = true;
        Sentry.captureException(e);
      } finally {
        loading.value = false;
        dragging.value = false;
      }
    };

    return {
      fileEl,
      dragging,
      loading,
      uploadFailed,
      platform: getPlatform(),
      selectFile() {
        if (fileEl.value == null) return;
        fileEl.value.click();
      },
      onFilePicked() {
        if (fileEl.value == null) return;
        const files = fileEl.value.files;
        if (files == null || files.length <= 0) return;
        const file = files[0];
        uploadFile(file);
      },
      onDragOver(e: DragEvent) {
        console.log("test");
        e.preventDefault();
        dragging.value = true;
      },
      onDragLeave(e: DragEvent) {
        e.preventDefault();
        dragging.value = false;
      },
      onDrop(e: DragEvent) {
        e.preventDefault();

        const files = e.dataTransfer?.files;
        if (files == null || files.length <= 0) return;
        const file = files[0];
        uploadFile(file);
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.content {
  padding: 30px;
  box-sizing: border-box;
  position: relative;
}

.title {
  font-size: 1.45rem;
  font-weight: 600;
  color: $text-alt;
  letter-spacing: 0.02rem;
  margin-bottom: 20px;
}

.drop-zone {
  width: 100%;
  cursor: pointer;
  height: 300px;
  max-height: 80vh;
  background-color: $bg-general;
  border: 2px dashed $text-dark;
  font-family: $font-text;
  font-size: 1.125rem;
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  color: $text-alt;
}
.dragging .drop-zone {
  border-color: $bg-accent;
  font-size: 1.25rem;
}

.file-input {
  display: none;
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
</style>
