<template>
  <generic-page-popup title="Vergadering" :options="options">
    <div class="lines">
      <div class="line">
        <div class="label">Datum</div>
        <div class="value">{{ formattedDate }}</div>
      </div>
      <div class="line">
        <div class="label">Tijd</div>
        <div class="value">{{ formattedTime }}</div>
      </div>
      <div class="line">
        <div class="label">Locatie</div>
        <div class="value">{{ meeting.location }}</div>
      </div>
      <div class="line" v-if="meeting.description">
        <div class="label">Beschrijving</div>
        <div class="value">{{ meeting.description }}</div>
      </div>
      <div class="line line--no-bottom">
        <div class="label">Spreekpunten document</div>
        <talking-points-file
          :files="files"
          @new-file="(file) => addFile(file, 'talkingpoints')"
          :loading="uploadingFile"
        />
      </div>
    </div>
  </generic-page-popup>
</template>

<script lang="ts">
import { GenericPageOption } from "@/components/common/generic-page/GenericPageOptions.vue";
import { showToast } from "@/components/notifications/toast";
import { showPopup } from "@/components/popups/popups";
import { inMeetings, meetingsActions } from "@/store/meetings/meetings";
import { Meeting } from "@/types";
import { isCurrentPropertyAdmin } from "@/util/auth";
import { DateTime } from "luxon";
import { capitalize, computed, defineComponent, PropType, Ref, ref } from "vue";
import { useStore } from "vuex";
import { showEditMeetingPopup } from "../EditMeeting/EditMeeting.vue";
import TalkingPointsFile from "./TalkingPointsFile.vue";

const component = defineComponent({
  components: { TalkingPointsFile },
  props: {
    params: {
      type: Object as PropType<{ meeting: Meeting }>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const meeting = ref(props.params.meeting);
    const uploadingFile = ref(false);

    const files = computed(() => meeting.value?.files ?? []);

    const formattedDate = computed(() => {
      if (meeting.value == null) return;
      const str = DateTime.fromJSDate(
        new Date(meeting.value.scheduled_for)
      ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
      return capitalize(str);
    });

    const formattedTime = computed(() => {
      if (meeting.value == null) return;
      let dt;
      if (typeof meeting.value.scheduled_for === "string") {
        dt = DateTime.fromISO(meeting.value.scheduled_for, {
          zone: "utc",
        });
      } else {
        dt = DateTime.fromJSDate(meeting.value.scheduled_for);
      }
      const str = dt.toLocal().toLocaleString(DateTime.TIME_24_SIMPLE);
      return capitalize(str);
    });

    const isAdmin = isCurrentPropertyAdmin();
    const options: Ref<GenericPageOption[]> = computed(() => {
      console.log("ADmin:", isAdmin.value);
      if (!isAdmin.value) return [];
      return [
        {
          id: "edit",
          iconName: "edit",
          text: "Aanpassen",
          onClick() {
            showEditMeetingPopup(props.params.meeting);
            emit("finish");
          },
        } as GenericPageOption,
      ];
    });

    return {
      options,
      files,
      uploadingFile,
      formattedDate,
      formattedTime,
      meeting,
      async addFile(file: File, type: string) {
        uploadingFile.value = true;
        try {
          const updatedMeeting = await store.dispatch(
            inMeetings(meetingsActions.ADD_FILE),
            {
              meetingId: props.params.meeting.id,
              file,
              type,
            }
          );
          console.log("Setting new meeting");
          meeting.value = updatedMeeting;
          showToast({
            type: "success",
            text: "Bestand geüpload!",
          });
        } catch (e) {
          showToast({
            type: "error",
            text: "Er ging iets mis :/. Gelieve jasper@mysyndic.com te contacteren. Onze oprechte excuses voor dit ongemak.",
          });
        } finally {
          uploadingFile.value = false;
        }
      },
    };
  },
});

export const showMeetingPopup = (meeting: Meeting) => {
  return showPopup(component, { meeting });
};

export default component;
</script>

<style lang="scss" scoped>
.line {
  padding: 17px 0;
  border-top: 1px solid $border-lighter;

  &--no-bottom {
    border-bottom: none;
  }
}

.label {
  font-size: 12px;
  font-weight: 700;
  color: $text-gray;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  margin-bottom: 3px;
}

.value {
  font-size: 16px;
  color: $text-dark;
  line-height: 1.3;
}
</style>
