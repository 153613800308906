import { FileItem } from "./fileRepository";
import { Property } from "./propertyRepository";
import { ApiResponse, get, patch, post } from "./repository";

export interface Issue {
  id: number;
  title: string;
  message: string;
  location: string;
  type: string;
  status: "open" | "in_progress" | "resolved";
  pictures: FileItem[];
  property: Property;
  property_id: number;
  urgent: boolean;
  private: boolean;
}

export default {
  create(propertyId: number, issueParams: any): ApiResponse<{ issue: Issue }> {
    return post("/issues", {
      issue: issueParams,
      property_id: propertyId,
    });
  },
  byId(id: number): ApiResponse<{ issue: Issue }> {
    return get(`/issues/${id}`);
  },
  list(): ApiResponse<{ issues: Issue[] }> {
    return get("/issues");
  },
  listAll(): ApiResponse<{ issues: Issue[] }> {
    return get("/issues/all");
  },
  update(id: number, updates: any): ApiResponse<{ issue: Issue }> {
    return patch(`/issues/${id}`, { updates });
  },
};
