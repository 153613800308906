
import { defineComponent } from "vue";
import EmptyProfile from "@/components/util/EmptyProfile.vue";

export default defineComponent({
  emits: ["click"],
  components: { EmptyProfile },
  props: {
    title: {
      type: String,
      required: true,
    },
    lastMessage: {
      type: String,
      required: true,
    },
    iconUrl: {
      type: String,
      default: null,
    },
    unreadCount: {
      type: Number,
      default: 0,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
});
