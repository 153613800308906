import { Component } from "@vue/runtime-core";
import mitt from "mitt";

export interface PopupMeta {
  id: number;
  component: Component;
  params: Record<string, any>;
  finishedCallback?: (result: any) => void;
  transient?: boolean;
  replace?: boolean;
}

const _emitter = mitt();
let _currId = 0;

function nextId() {
  return ++_currId;
}

export function onNewPopup(
  cb: (popupmeta: PopupMeta) => void
): (popupMeta: PopupMeta) => void {
  function handler(popupMeta?: PopupMeta) {
    if (popupMeta == null) return;
    cb(popupMeta);
  }
  _emitter.on("popup", handler);
  return handler;
}

export function showPopup<T>(
  component: Component,
  params: Record<string, any> = {},
  waitForResponse = true,
  // If another popup gets started from this popup, this one will dissapear
  // so closing to popup above will not show this one
  transient = false,
  replace = false
): Promise<T | null> {
  return new Promise((resolve) => {
    function finished(result: T) {
      if (!waitForResponse) return;
      resolve(result);
    }
    _emitter.emit("popup", {
      id: nextId(),
      component,
      params,
      finishedCallback: finished,
      transient,
      replace,
    });
    if (!waitForResponse) {
      resolve(null);
    }
  });
}
