<template>
  <container maxWidth="350px" centerh class="select-quarter">
    <generic-header>Selecteer kwartaal</generic-header>
    <p class="description">
      Vanaf welk kwartaal wilt u beginnen met het gebruiken van de boekhouding?
    </p>
    <dropdown
      label="Kwartaal"
      placeholder="Selecteer kwartaal"
      :options="{
        one: 'one',
      }"
      v-model="something"
    />
  </container>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const quarter = ref(null as null | string);

    return {
      quarter,
    };
  },
});
</script>

<style lang="scss" scoped>
.description {
  margin-top: 10px;
  line-height: 130%;
}
</style>
