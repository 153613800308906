
import { defineComponent, ref } from "vue";
import SendIcon from "@/components/icons/Send.vue";
import AddIcon from "@/components/icons/Add.vue";
import AddPopup from "@/components/chat/AddPopup.vue";
import { useOnClickOutside } from "@/util/click";

export default defineComponent({
  components: { SendIcon, AddIcon, AddPopup },
  setup(props, { emit }) {
    const content = ref("");

    const addPopupVisible = ref(false);
    function showAddPopup() {
      addPopupVisible.value = !addPopupVisible.value;
    }
    function hidePopup() {
      addPopupVisible.value = false;
    }

    const clickOutsideRef = useOnClickOutside(() => hidePopup());

    return {
      clickOutsideRef,
      hidePopup,
      showAddPopup,
      addPopupVisible,
      content,
      send() {
        emit("send", content.value);
        content.value = "";
      },
    };
  },
});
