<template>
  <svg
    width="30"
    height="32"
    viewBox="0 0 30 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 10H21"
      stroke="currentColor"
      :stroke-width="strokeWidth"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M7 16H21"
      stroke="currentColor"
      :stroke-width="strokeWidth"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M7 22H13"
      stroke="currentColor"
      :stroke-width="strokeWidth"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M15 31H1V1H27V17"
      stroke="currentColor"
      :stroke-width="strokeWidth"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M24 20L19 24V31H24H29V24L24 20Z"
      stroke="currentColor"
      :stroke-width="strokeWidth"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M24 28V31"
      stroke="currentColor"
      :stroke-width="strokeWidth"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    strokeWidth: {
      type: String,
      default: "2",
    },
  },
});
</script>
