
import { defineComponent, PropType, ref, watchEffect } from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { DateTime } from "luxon";
import { Time } from "@/types";

const currentTime = (): Time => {
  const now = DateTime.now();
  return {
    hours: now.hour,
    minutes: now.minute,
    seconds: now.second,
  };
};

export default defineComponent({
  components: { Datepicker },
  props: {
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Object as PropType<Time>,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const time = ref<Time>(props.modelValue ?? currentTime());

    watchEffect(() => {
      emit("update:modelValue", time.value);
    });

    return {
      time,
    };
  },
});
