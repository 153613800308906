<template>
  <nav class="desktop-menu" :class="{ responsive }" aria-label="Hoofdmenu">
    <div class="menu menu--left" role="menubar">
      <div class="project-selector-wrapper" v-if="hasAuth">
        <project-selector />
      </div>
      <div class="logo-wrapper" v-else>
        <logo />
      </div>
      <template v-if="activeProperty != null">
        <!-- <router-link class="menu-item" to="/">Nieuws</router-link> -->
        <router-link
          class="menu-item"
          :to="{ name: 'properties.show', params: { id: activeProperty.id } }"
          >{{ $t("common.overview") }}</router-link
        >
        <router-link
          class="menu-item"
          :to="{
            name: 'properties.issues.list',
            params: { id: activeProperty.id },
          }"
          >{{ $t("property.issues") }}</router-link
        >
        <router-link class="menu-item" :to="{ name: 'chats.list' }">
          <span v-if="anyNewMessages" class="new-marker" />
          <span class="chat-text">{{ $t("chat.one") }}</span>
        </router-link>
        <router-link
          v-if="isAdmin"
          class="menu-item"
          :to="{
            name: 'properties.edit',
            params: { id: activeProperty.id },
          }"
          >{{ $t("common.settings") }}</router-link
        >
        <div class="menu-item menu-item--dropdown">
          {{ $t("property.finance") }}
          <div class="sub-menu">
            <router-link
              class="sub-menu-item"
              :to="{ name: 'accounting.overview.transactions' }"
            >
              {{ $t("accounting.transactions") }}
            </router-link>
            <router-link
              class="sub-menu-item"
              :to="{ name: 'accounting.overview.payments' }"
            >
              {{ $t("accounting.payments") }}
            </router-link>
            <router-link
              class="sub-menu-item"
              :to="{ name: 'accounting.overview.invoices' }"
            >
              {{ $t("accounting.invoices") }}
            </router-link>
          </div>
        </div>
        <!-- <router-link
          v-if="isAdmin"
          class="menu-item"
          :to="{
            name: 'accounting.overview.',
            params: { id: activeProperty.id },
          }"
          >{{ $t("property.finance") }}</router-link
        > -->
      </template>
    </div>
    <!-- <div class="menu menu--right" v-if="!hasAuth">
      <router-link class="menu-item" to="/auth/signup">
        {{ $t("auth.new_account") }}
      </router-link>
      <router-link class="menu-item" to="/auth/login">{{
        $t("auth.login_action")
      }}</router-link>
    </div> -->
    <div class="menu menu--right" v-if="hasAuth">
      <profile-item />
      <div class="invitation-dropdown-wrapper">
        <invitation-dropdown />
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import Logo from "@/components/Logo.vue";
import ProfileItem from "./ProfileItem.vue";
import ProjectSelector from "../util/PropertySelector.vue";
import { useCurrentUser, useHasAuth } from "@/util/auth";
import { useActiveProperty } from "@/util/properties";
import { useAnyNewMessages } from "@/util/chat";
import InvitationDropdown from "@/components/notifications/InvitationDropdown.vue";

export default defineComponent({
  components: { Logo, ProfileItem, ProjectSelector, InvitationDropdown },
  props: {
    /**
     * If true will hide the component below tablet-wide breakpoint
     */
    responsive: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const hasAuth = useHasAuth();
    const user = useCurrentUser();
    const activeProperty = useActiveProperty();

    const isAdmin = computed(() => {
      return (
        user.value != null &&
        activeProperty.value != null &&
        user.value.id === activeProperty.value.admin_id
      );
    });

    const anyNewMessages = useAnyNewMessages();

    return {
      anyNewMessages,
      hasAuth,
      activeProperty,
      isAdmin,
    };
  },
});
</script>

<style lang="scss" scoped>
.desktop-menu {
  display: none;

  /* @include desktop-mode() {
    display: flex;
  } */

  width: 100%;
  height: 70px;
  background-color: white;
  border-bottom: 2px solid $border-general;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 50px;

  &.responsive {
    @include screen-from("tablet-wide") {
      display: flex;
    }
  }
  &:not(&.responsive) {
    display: flex;
  }
}

.logo-wrapper {
  padding-top: 3px;
  margin-right: 20px;
}

.menu {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.menu-item {
  position: relative;
  display: flex;
  align-items: center;
  height: calc(70px - 2px);
  color: $text-gray;
  font-family: $font-text;
  font-size: 1rem;
  font-weight: 500;
  border-bottom: 2px solid transparent;
  text-decoration: none;

  &:not(:last-child) {
    margin-right: 30px;
  }

  &.router-link-active {
    color: $text-dark;
    border-bottom: 2px solid $border-brown;
  }

  &:hover {
    color: $text-dark;
  }
}

.new-marker {
  width: 11px;
  height: 11px;
  background-color: $bg-accent;
  border-radius: 50%;
  position: absolute;
  top: 22px;
  right: -4px;
  z-index: 1;
}

.chat-text {
  position: relative;
  z-index: 2;
}

.project-selector-wrapper {
  /* margin-left: 20px; */
  margin-right: 30px;
}

.invitation-dropdown-wrapper {
  margin-left: 20px;
  padding-bottom: 7px;
}

.sub-menu {
  display: none;
  position: absolute;
  top: calc(100% - 10px);
  background-color: $bg-general;
  padding: 10px;
  border: 1px solid $border-general;
  border-radius: 4px;
  z-index: 3;
}
.menu-item:hover .sub-menu {
  display: block;
}

.sub-menu-item {
  display: flex;
  align-items: center;
  color: $text-gray;
  font-family: $font-text;
  font-size: 1rem;
  font-weight: 500;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  padding: 7px 10px;

  &:hover {
    color: $text-dark;
  }
}
</style>
