
import { computed, defineComponent, ref } from "vue";
import SearchIcon from "@/components/icons/Search.vue";
import Popup from "@/components/util/Popup.vue";
import { useStore } from "vuex";
import { inProperties, PropertiesActions } from "@/store/properties";
import { Property } from "@/repositories/propertyRepository";
import EmptyProfile from "@/components/util/EmptyProfile.vue";
import { User } from "@/types";
import { chatsActions, inChats } from "@/store/chats";

interface Connection {
  type: "user" | "property";
  property: null | Property;
  user: null | User;
  id: string;
}

export default defineComponent({
  components: { SearchIcon, Popup, EmptyProfile },
  setup(props, { emit }) {
    const store = useStore();

    const showing = ref(false);
    const searchQuery = ref("");

    const connections = ref(null as null | Connection[]);

    store
      .dispatch(inProperties(PropertiesActions.LIST))
      .then((properties: Property[]) => {
        properties.forEach((property) => {
          if (connections.value == null) {
            connections.value = [];
          }
          connections.value.push({
            type: "property",
            user: null,
            property,
            id: "property:" + property.id,
          });
        });
      });

    store
      .dispatch(inProperties(PropertiesActions.LIST_CONNECTIONS))
      .then((users: User[]) => {
        users.forEach((user) => {
          if (connections.value == null) {
            connections.value = [];
          }
          connections.value.push({
            id: "user:" + user.id,
            type: "user",
            property: null,
            user: user,
          });
        });
      });

    const sortedConnections = computed(() => {
      const conns = connections.value;
      if (conns == null) return [];
      const query = (searchQuery.value || "").toLowerCase();
      return conns
        .filter((conn) => {
          if (query === "") return true;
          if (conn.type === "user") {
            if (conn.user == null) return false;
            return (
              conn.user.first_name.toLowerCase().includes(query) ||
              conn.user.last_name.toLowerCase().includes(query) ||
              conn.user.email.toLowerCase().includes(query)
            );
          } else {
            if (conn.property == null) return false;
            return (
              conn.property.name.toLowerCase().includes(query) ||
              conn.property.street.toLowerCase().includes(query)
            );
          }
        })
        .sort((a, b) => {
          if (a.type === "property" && b.type === "user") {
            return -1;
          }
          if (a.type === "user" && b.type === "property") {
            return 1;
          }
          if (a.type === "user") {
            if (a.user == null || b.user == null) return 0;
            return (b.user.id as number) - a.user.id;
          }
          if (a.property == null || b.property == null) return 0;
          return b.property.id - a.property.id;
        });
    });

    return {
      searchQuery,
      showing,
      connections,
      sortedConnections,
      async toChat(conn: Connection) {
        let chat;
        if (conn.type === "property" && conn.property != null) {
          chat = await store.dispatch(
            inChats(chatsActions.GET_OR_CREATE_PROPERTY_CHAT),
            {
              propertyId: conn.property.id,
            }
          );
        }
        if (conn.type === "user" && conn.user != null) {
          chat = await store.dispatch(
            inChats(chatsActions.GET_OR_CREATE_USER_CHAT),
            {
              otherUserId: conn.user.id,
            }
          );
        }
        emit("select", chat);
        showing.value = false;
        // if (chat == null) return;
        // store.commit(inChats(chatsMutations.ADD_CHAT), chat);
        // chat
        // router.push({
        //   name: "chats.show",
        //   params: { id: chat.id },
        // });
      },
    };
  },
});
