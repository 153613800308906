<template>
  <div class="login">
    <div class="version">v{{ version }}</div>
    <flash-message v-if="registered">{{
      $t("auth.register_success")
    }}</flash-message>
    <flash-message v-if="route.query?.message === 'bad-token'">
      {{ $t("auth.invalid_password_reset") }}
    </flash-message>
    <flash-message v-if="errorMessage != null">
      {{ errorMessage }}
    </flash-message>
    <div>
      <social-login-button
        v-if="platform === 'ios' && !isIOSAndUnder13"
        @click="appleLogin"
        social="Apple"
        class="social"
      />
      <social-login-button
        v-if="canLoginWithGoogle()"
        @click="googleLogin"
        social="Google"
        class="social"
      />
      <social-login-button
        @click="loginWithEmail"
        social="email"
        class="social"
      />
    </div>
    <div class="terms-of-use">
      {{ $t("legal.by_login") }}
      <app-link target="_blank" href="https://termsofuse.mysyndic.com/">{{
        $t("legal.terms_of_use")
      }}</app-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from "vue";
import SocialLoginButton from "@/components/SocialLoginButton.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { authActions, inAuth } from "@/store/auth";
import { logBreakingException } from "@/util/logger";
import FlashMessage from "@/components/common/FlashMessage.vue";
import { FacebookLogin } from "@capacitor-community/facebook-login";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { UnauthorizedException } from "@/error_handling/UnauthorizedException";
import { version } from "../../../package.json";
import {
  getPlatform,
  isMobilePlatform,
  useIsIOSAndUnderVersion13,
} from "@/util/platform";
import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from "@capacitor-community/apple-sign-in";

export default defineComponent({
  components: { SocialLoginButton, FlashMessage },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      email: "",
      password: "",
    });
    const registered = ref(false as boolean);
    const incorrect = ref(false as boolean);
    const errorMessage = ref(null as null | string);

    if (route.query?.email != null) {
      state.email = route.query.email as string;
    }
    if (route.query?.registered != null) {
      registered.value = true;
    }

    // TODO: remove if not necessary
    onMounted(() => {
      if (isMobilePlatform()) {
        GoogleAuth.init();
      }
    });

    const isIOSAndUnder13 = useIsIOSAndUnderVersion13();

    store
      .dispatch(inAuth(authActions.FETCH_ACCESS_TOKEN))
      .then(() => {
        router.push("/");
      })
      .catch(() => undefined);

    return {
      route,
      state,
      registered,
      incorrect,
      errorMessage,
      platform: getPlatform(),
      isIOSAndUnder13,
      version,
      canLoginWithGoogle() {
        return getPlatform() !== "ios";
      },
      loginWithEmail() {
        console.log("test");
        router.push({
          name: "auth.emaillogin.enteremail",
        });
      },
      async googleLogin() {
        if (getPlatform() === "web") {
          store.dispatch(inAuth(authActions.GOOGLE_REDIRECT));
          return;
        }

        try {
          const user = await GoogleAuth.signIn();
          const token = user.authentication.idToken;
          await store.dispatch(inAuth(authActions.GOOGLE_LOGIN), {
            idToken: token,
          });
          router.push({
            name: "init",
          });
        } catch (e) {
          console.log("Error:", JSON.stringify(e));
        }
      },
      async appleLogin() {
        let options: SignInWithAppleOptions = {
          clientId: "com.mysyndic.webapp",
          redirectURI: "https://app.mysyndic.com/auth/oauth/callback/apple",
          scopes: "email name",
          nonce: "nonce",
        };

        SignInWithApple.authorize(options)
          .then(async (response: SignInWithAppleResponse) => {
            console.log("Apple response");
            console.log(JSON.stringify(response));

            // if (response.response.email == null) {
            //   errorMessage.value =
            //     "Please link an email address to your apple account and verify it.";
            //   return;
            // }

            let result:
              | { state: "logged_in" }
              | {
                  state: "more_info";
                  token: string;
                  email: string;
                };
            try {
              result = await store.dispatch(inAuth(authActions.APPLE_LOGIN), {
                idToken: response.response.identityToken,
                firstName: response.response.givenName,
                lastName: response.response.familyName,
              });
            } catch (e) {
              console.error(e);
              console.log("Failed to log in with apple login");
              throw e;
            }

            if (result.state === "logged_in") {
              router.push({
                name: "properties",
              });
              return;
            }

            router.push({
              name: "auth.oauth.finish",
              query: {
                token: result.token,
                email: result.email,
              },
            });
          })
          .catch((error) => {
            console.error(error);
            console.log("Apple Error:", error);
            console.log(JSON.stringify(error));
            // Handle error
          });
      },
      async facebookLogin() {
        if (getPlatform() === "web") {
          store.dispatch(inAuth(authActions.FACEBOOK_REDIRECT));
          return;
        }

        const FACEBOOK_PERMISSIONS = ["email"];
        const result = await FacebookLogin.login({
          permissions: FACEBOOK_PERMISSIONS,
        });
        if (!result.accessToken) {
          // TODO: show error
          return;
        }
        // const profile = await FacebookLogin.getProfile({
        //   fields: ["email", "first_name", "last_name", "picture"],
        // });
        try {
          await store.dispatch(inAuth(authActions.FACEBOOK_LOGIN), {
            token: result.accessToken.token,
          });
          // await attemptRegisterPush();
          router.push({
            name: "init",
          });
        } catch (e) {
          console.log("---ERROR");
          console.log(JSON.stringify(e));
        }
      },
      async handleSubmit() {
        try {
          incorrect.value = false;
          registered.value = false;
          await store.dispatch(inAuth(authActions.LOGIN), {
            email: state.email.toLowerCase(),
            password: state.password,
          });
          router.push({
            name: "init",
          });
        } catch (e) {
          if (e instanceof UnauthorizedException) {
            incorrect.value = true;
            return;
          }
          logBreakingException(e as any);
        }
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.login {
  @include screen-from("tablet-small") {
    max-width: 315px;
    margin-left: auto;
    margin-right: auto;
  }
}

.social {
  margin-bottom: 10px;
}

.divider {
  margin-top: 20px;
  margin-bottom: 15px;
}

.forgot-password {
  text-align: right;
}

.sign-up {
  color: $text-dark;
  font-weight: 500;
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 40px;
  text-align: center;
}

.terms-of-use {
  color: $text-dark;
  font-weight: 500;
  font-size: 16px;
  margin-top: 20px;
  line-height: 1.3;
  text-align: center;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.version {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.3;
  font-size: 14px;
  letter-spacing: 0.02em;
}
</style>
