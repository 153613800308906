import { RemoveEventListenerFunction } from "@/types";
import mitt, { Emitter } from "mitt";
import { Socket } from "phoenix";

export abstract class AppChannel {
  protected _socket: Socket | null = null;
  protected emitter: Emitter;
  protected globalEmitter: Emitter;

  constructor(globalEmitter: Emitter) {
    this.emitter = mitt();
    this.globalEmitter = globalEmitter;
  }

  setSocket(socket: Socket): void {
    this._socket = socket;
    this.setup(socket);
  }

  protected onEmitterEvent<T>(
    eventName: string,
    cb: (data: T) => void
  ): RemoveEventListenerFunction {
    const handler = (data?: T) => {
      if (data == null) return;
      cb(data);
    };
    this.emitter.on<T>(eventName, handler);
    return () => {
      this.emitter.off<T>(eventName, handler);
    };
  }

  protected abstract setup(socket: Socket): void;
}
