<template>
  <div class="register">
    <!-- <social-login-button social="Facebook" class="social" />
    <social-login-button social="Google" class="social" />
    <divider class="divider">of</divider> -->
    <app-form
      :submitText="$t('auth.register')"
      submitAlwaysFull
      :submitHandler="handleSubmit"
    >
      <form-field>
        <textbox
          type="text"
          placeholder="John"
          :label="$t('common.first_name')"
          v-model="state.firstName"
          @blur="v.firstName.$touch()"
        />
        <form-error v-if="v.firstName.$error">{{
          $t("error.field_invalid")
        }}</form-error>
        <field-error :fieldErrors="fieldErrors" name="firstName" />
      </form-field>
      <form-field>
        <textbox
          type="text"
          placeholder="Doe"
          :label="$t('common.last_name')"
          v-model="state.lastName"
          @blur="v.lastName.$touch()"
        />
        <form-error v-if="v.lastName.$error">{{
          $t("error.field_invalid")
        }}</form-error>
        <form-error v-if="fieldErrors['lastName']">
          {{ fieldErrors["lastName"][0] }}
        </form-error>
        <field-error :fieldErrors="fieldErrors" name="lastName" />
      </form-field>
      <form-field>
        <textbox
          type="email"
          placeholder="my@email.com"
          :label="$t('common.email_address')"
          v-model="state.email"
          @blur="v.email.$touch()"
        />
        <form-error v-if="v.email.$error">{{
          $t("error.field_invalid")
        }}</form-error>
        <field-error :fieldErrors="fieldErrors" name="email" />
      </form-field>
      <form-field>
        <textbox
          type="password"
          :placeholder="$t('auth.password')"
          :label="$t('auth.password')"
          v-model="state.password"
          @blur="v.password.$touch()"
        />
        <form-error v-if="v.password.$error">{{
          $t("error.field_invalid")
        }}</form-error>
        <field-error :fieldErrors="fieldErrors" name="password" />
      </form-field>
      <template v-slot:bottom>
        <div class="terms-of-use">
          {{ $t("legal.by_registering") }}
          <app-link target="_blank" href="https://termsofuse.mysyndic.com/">{{
            $t("legal.terms_of_use")
          }}</app-link>
        </div>
      </template>
    </app-form>
    <div class="sign-up">
      {{ $t("auth.already_registered?") }}
      <app-link to="/auth/login">{{ $t("auth.login") }}</app-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import AppForm from "@/components/form/AppForm.vue";
import FormField from "@/components/form/FormField.vue";
import FormError from "@/components/form/FormError.vue";
import useVuelidate from "@vuelidate/core";
import FieldError from "@/components/form/FieldError.vue";
import { required, email, minLength } from "@vuelidate/validators";
import { useStore } from "vuex";
import { authActions, inAuth } from "@/store/auth";
import { useRouter } from "vue-router";
import { ValidationException } from "@/error_handling/ValidationException";
import { logBreakingException } from "@/util/logger";
import { sleep } from "@/util/sleep";

export default defineComponent({
  components: {
    AppForm,
    FormField,
    FormError,
    FieldError,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const state = reactive({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    });
    const fieldErrors = ref({} as { [fieldName: string]: string[] });

    const rules = {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      password: { required, minLength: minLength(6) },
    };

    const v = useVuelidate(rules, state);

    return {
      state,
      v,
      fieldErrors,
      async handleSubmit() {
        v.value.$touch();
        if (v.value.$invalid) return;
        try {
          await sleep(500);
          await store.dispatch(inAuth(authActions.REGISTER), {
            firstName: state.firstName,
            lastName: state.lastName,
            email: state.email.toLowerCase(),
            password: state.password,
          });
          router.push({
            name: "login",
            query: {
              email: state.email,
              registered: "true",
            },
          });
        } catch (e) {
          if (e instanceof ValidationException) {
            fieldErrors.value = e.getErrors();
          } else {
            logBreakingException(e);
          }
        }
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.register {
  @include screen-from("tablet-small") {
    max-width: 315px;
    margin-left: auto;
    margin-right: auto;
  }
}

.social {
  margin-bottom: 10px;
}

.divider {
  margin-top: 20px;
  margin-bottom: 15px;
}

.terms-of-use {
  color: $text-dark;
  font-weight: 500;
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.sign-up {
  color: $text-dark;
  font-weight: 500;
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 40px;
  text-align: center;
}
</style>
