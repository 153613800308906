import { Property } from "@/repositories/propertyRepository";
import { Invoice, Payment, PaymentRequest, Transaction } from "@/types";
import { DateTime } from "luxon";

export const isTransactionFinished = (transaction: Transaction) => {
  if (transaction.type === "out") {
    // TODO: How do we know this?
    // const totalInvoices = transaction.invoices
    // return transaction.invoices != null;
    return getTransactionOutstandingAmount(transaction) <= 0;
  }
  if (transaction.resident_payments == null) return false;
  const totalPaymentAmount = transaction.resident_payments.reduce(
    (total, payment) => {
      return (total += payment.amount);
    },
    0
  );
  return totalPaymentAmount >= transaction.amount;
};

export const getTransactionOutstandingAmount = (
  transaction: Transaction
): number => {
  if (transaction == null) return 0;
  if (transaction.type === "in") {
    if (transaction.resident_payments == null) return transaction.amount;
    const paymentsTotal = transaction.resident_payments.reduce(
      (total, payment) => total + payment.amount,
      0
    );
    // TODO: What do we do when payments total si greater than the transaction?
    // TODO: Maybe we should just dissalow this?

    // TODO: Voeg checkbox toe: Betalingen van deze rekening altijd aan deze resident linken?
    return transaction.amount - paymentsTotal;
  }
  if (transaction.type === "out") {
    const amounts = transaction.transactions_invoices?.map((ti) => {
      if (ti.amount !== null) return ti.amount;
      const invoice = transaction.invoices?.find((i) => i.id === ti.invoice_id);
      return invoice?.amount ?? 0;
    });
    const sum = amounts?.reduce((sum, amt) => sum + amt, 0);
    if (sum == null) {
      throw new Error(
        `transaction_invoices are required to calculate outstanding amount of transaction ${transaction.id}`
      );
    }
    return transaction.amount - sum;
  }
  throw new Error(`Transaction type ${transaction.type} not supported`);
};

export const isInvoiceFinished = (invoice: Invoice) => {
  return getInvoiceOutstandingAmount(invoice) <= 0;
};

export const getInvoiceOutstandingAmount = (invoice: Invoice) => {
  if (invoice.transactions_invoices == null || invoice.transactions == null)
    throw new Error(
      `transactions_invoices are required to calc invoice finished ${invoice.id}`
    );
  console.log(invoice.transactions_invoices);
  const amountForTransaction = (transaction: Transaction) => {
    const ti = invoice.transactions_invoices?.find(
      (ti) => ti.transaction_id === transaction.id
    );
    if (ti != null) return ti.amount;
    return transaction.amount;
  };
  return (
    invoice.amount -
    invoice.transactions
      .map((t) => amountForTransaction(t))
      .reduce((sum, nr) => sum + nr, 0)
  );
};

export const getDebtForUser = (
  userId: number,
  payments: Payment[],
  paymentRequests: PaymentRequest[]
) => {
  const totalPayments = payments
    .filter((p) => p.user_id === userId)
    .reduce((sum, p) => sum + p.amount, 0);
  const totalRequests = paymentRequests
    .filter((p) => p.user_id === userId)
    .reduce((sum, p) => sum + p.amount, 0);
  return Math.max(totalRequests - totalPayments, 0);
};

export const getDebtForProperty = (
  payments: Payment[],
  paymentRequests: PaymentRequest[]
) => {
  const totalPayments = payments.reduce((sum, p) => sum + p.amount, 0);
  const totalRequests = paymentRequests.reduce((sum, p) => sum + p.amount, 0);
  return Math.max(totalRequests - totalPayments, 0);
};

export const dateNextPayment = (property: Property) => {
  const paymentTerm = property.payment_term ?? 1;
  const now = DateTime.utc();

  // MonthIds start at 1
  for (const monthId of getNextTwelveMonthIds()) {
    if (monthId % paymentTerm !== 0) continue;
    if (monthId < now.month) {
      return new Date(`${now.year + 1}-${monthId}-01`);
    }
    return new Date(`${now.year}-${monthId}-01`);
  }
  throw new Error("Can't find a month matching the given payment term");
};

// Month Ids start at 1 for Januari, 2 for Februari, ...
const getNextTwelveMonthIds = () => {
  const currentMonth = DateTime.utc().month;
  return Array.from({ length: 12 }, (v, k) => {
    if (k + currentMonth > 12) {
      return k + currentMonth - 12;
    }
    return k + currentMonth;
  });
};
