<template>
  <div class="file-item" @click="$emit('click')">
    <div class="date">{{ formattedDate }}</div>
    <div class="name">{{ name }}</div>
    <a
      v-if="!noDownloadText"
      :href="fileItem.url"
      target="_blank"
      class="download"
      >{{ $t("common.download") }}</a
    >
    <div class="bg"></div>
  </div>
</template>

<script lang="ts">
import { FileItem } from "@/repositories/fileRepository";
import { DateTime } from "luxon";
import { computed, defineComponent, Prop } from "vue";

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    fileItem: {
      type: Object,
      required: true,
    } as Prop<FileItem>,
    clickable: {
      type: Boolean,
      default: true,
    },
    noDownloadText: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const formattedDate = computed(() => {
      if (props.fileItem?.inserted_at == null) return "";
      const dt = DateTime.fromISO(props.fileItem.inserted_at);
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dec",
      ];
      const monthName = monthNames[dt.month];

      return `${dt.day} ${monthName} ${dt.year}`;
    });

    return {
      formattedDate,
    };
  },
});
</script>

<style lang="scss" scoped>
.file-item {
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 5px;
  border: 1px solid $border-general;
  background-color: white;
  position: relative;
  min-height: 120px;
  max-width: 500px;

  &:hover {
    cursor: pointer;
    transform: scale(1.02);
    transition: transform 0.05s;
    border-color: $border-brown;
  }
}

.date {
  font-size: 0.875rem;
  color: $text-dark;
  letter-spacing: 0.02rem;
  line-height: 1;
  font-family: $font-text;
}

.name {
  font-size: 1.3125rem;
  font-family: $font-title;
  line-height: 1.2;
  margin-bottom: 4px;
  margin-top: 8px;
  color: $text-alt;
  font-weight: 600;
}

.price {
  font-size: 1.3125rem;
  font-family: $font-text;
  line-height: 2;
  color: $text-dark;
  font-weight: 400;
}

.download {
  color: $text-accent;
  font-size: 1.125rem;
  line-height: 2.2;
  font-weight: 600;
  font-family: $font-title;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $text-alt;
  }
}

.bg {
  position: absolute;
  bottom: -5px;
  right: 20px;
  width: 89px;
  height: 87px;
  background-image: url("/img/document.svg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
