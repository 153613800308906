<template>
  <div class="images-upload">
    <div class="label-wrapper">
      <app-label v-if="label">{{ label }}</app-label>
    </div>
    <div class="images">
      <div
        class="image"
        v-for="image in images"
        :key="image.id"
        :style="{ 'background-image': `url('${image.url}')` }"
      ></div>
      <div class="upload-button" @click="selectImage">
        <loader-icon v-if="loading" color="#DBA00C" />
        <upload-icon v-else />
      </div>
    </div>
    <input
      @change="uploadImage"
      type="file"
      class="file-input"
      ref="fileInputEl"
      accept="image/*"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import UploadIcon from "@/components/icons/Upload.vue";
import LoaderIcon from "@/components/icons/Loader.vue";
import { FileItem } from "@/repositories/fileRepository";

export default defineComponent({
  components: { UploadIcon, LoaderIcon },
  props: {
    uploadFunction: {
      type: Function,
      default: null,
    },
    images: {
      type: Array,
      default: () => [] as FileItem[],
    },
    label: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const fileInputEl = ref(null as HTMLInputElement | null);
    const loading = ref(false);

    return {
      fileInputEl,
      loading,
      selectImage() {
        if (fileInputEl.value == null) return;
        fileInputEl.value.click();
      },
      async uploadImage() {
        if (props.uploadFunction == null) return;
        const files = fileInputEl.value?.files;
        if (files == null || files.length <= 0) return;
        loading.value = true;
        const result = await props.uploadFunction(files[0]);
        if (result != null) {
          const newImages = [...(props.images ?? []), result];
          emit("newImages", newImages);
        }
        loading.value = false;
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.label-wrapper {
  margin-bottom: -5px;
}

.images {
  display: flex;
  flex-flow: row wrap;
}

.image {
  width: 80px;
  height: 80px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
  margin-right: 8px;
  margin-top: 8px;
  background-color: white;

  @include screen-from("tablet") {
    width: 120px;
    height: 120px;
  }
}

.upload-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid $border-general;
  color: $text-accent;
  margin-right: 8px;
  margin-top: 8px;

  @include screen-from("tablet") {
    width: 120px;
    height: 120px;
    cursor: pointer;

    &:hover {
      border-color: $bg-accent;
    }
  }
}

.file-input {
  display: none;
}
</style>
