<template>
  <generic-page
    :options="options"
    :title="$t('property.issues')"
    :loading="issues == null"
  >
    <div class="issues">
      <issue-listing
        v-for="issue in issuesForCurrentProperty"
        :key="issue.id"
        :issue="issue"
        @click="toIssue(issue)"
      />
    </div>

    <div class="bottom-middle">
      <bottom-middle-link
        v-if="activeProperty != null"
        @click="toSolvedIssues"
        >{{ $t("issues.see_solved") }}</bottom-middle-link
      >
    </div>
  </generic-page>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import IssueListing from "@/components/property/IssueListing.vue";
import { Issue } from "@/repositories/issueRepository";
import { useActiveProperty } from "@/util/properties";
import BottomMiddleLink from "@/components/BottomMiddleLink.vue";
import { GenericPageOption } from "@/components/common/generic-page/GenericPageOptions.vue";
import { showCreateIssuePopup } from "@/features/issues/popups/CreateIssue.vue";
import { showViewIssuePopup } from "@/features/issues/popups/ViewIssue.vue";
import { showResolvedIssuesPopup } from "@/features/issues/popups/ResolvedIssues.vue";
import { useIssues } from "@/store/issues/hooks";

export default defineComponent({
  components: { IssueListing, BottomMiddleLink },
  setup() {
    const activeProperty = useActiveProperty();
    const { data: issues, refetch: refetchIssues } = useIssues();

    const issuesForCurrentProperty = computed(() => {
      if (issues.value == null) return [];
      const p = activeProperty.value;
      if (p == null) return issues.value;
      return issues.value.filter((issue) => issue.property_id === p.id);
    });

    const options: GenericPageOption[] = [
      {
        id: "create",
        iconName: "add",
        text: "Nieuwe melding",
        async onClick() {
          const issue = await showCreateIssuePopup();
          if (issue == null) return;
          refetchIssues.value();
        },
      },
    ];

    return {
      activeProperty,
      issues,
      issuesForCurrentProperty,
      options,
      toSolvedIssues() {
        showResolvedIssuesPopup();
      },
      async toIssue(issue: Issue) {
        await showViewIssuePopup({ issueId: issue.id });
        refetchIssues.value();
      },
    };
  },
});
</script>

<style lang="scss" scoped>
/* .property-issues {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  @include screen-from("desktop") {
    max-width: 1000px;
  }
} */

.header {
  background-color: white;
  border-bottom: 1px solid $border-lighter;
  padding-bottom: 15px;

  @include screen-from("tablet-wide") {
    border-bottom: none;
    background-color: transparent;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
}

.buttons {
  margin-top: 20px;
  padding: 0 20px;
  box-sizing: border-box;
  justify-self: end;

  @include screen-from("tablet-wide") {
    padding: 0;
    margin-top: 0;
  }
}

.button {
  width: 100%;

  @include screen-from("tablet-wide") {
    width: auto;
    /* width: */
  }
}

.bottom-middle {
  margin-top: 60px;
  opacity: 0.5;

  @include screen-from("tablet") {
    margin-top: 100px;
  }
}
</style>
