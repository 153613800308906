
import { GenericPageOption } from "@/components/common/generic-page/GenericPageOptions.vue";
import { useLots, useResidents } from "@/store/properties/hooks";
import { PropertyLot } from "@/types";
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import LotItem from "../components/LotItem.vue";
import { useTranslateType } from "../hooks/types";
import { showAddLotPopup } from "../popups/AddLotPopup.vue";
import { showEditLotPopup } from "../popups/EditLotPopup.vue";

export default defineComponent({
  components: { LotItem },
  setup() {
    const { t } = useI18n();
    const { loading, lots, refetch } = useLots();
    const { residents } = useResidents();

    let types = computed(() =>
      (lots.value ?? []).map((lot) => lot.type).filter((v) => v != null)
    );
    const options: GenericPageOption[] = [
      {
        id: "add",
        iconName: "add",
        text: t("lots.add"),
        async onClick() {
          await showAddLotPopup(types.value);
          refetch();
        },
      },
    ];

    const residentPictures = computed(() => {
      if (residents.value == null) return {};
      return residents.value.reduce<Record<string, string | null>>(
        (map, user) => {
          if (user.avatar_url == null) {
            map[user.id] = null;
          } else {
            map[user.id] = user.avatar_url;
          }
          return map;
        },
        {}
      );
    });

    const sortedLots = computed(() => {
      return [...lots.value].sort((a: PropertyLot, b: PropertyLot) => {
        if (a.identifier.toLowerCase() > b.identifier.toLowerCase()) return 1;
        if (b.identifier.toLowerCase() > a.identifier.toLowerCase()) return -1;
        return 0;
      });
    });

    const lotsByType = computed(() => {
      if (lots.value == null) return {};
      let result: Record<string, PropertyLot[]> = {};
      for (const lot of sortedLots.value) {
        if (result[lot.type] == null) {
          result[lot.type] = [lot];
        } else {
          result[lot.type].push(lot);
        }
      }
      return result;
    });

    return {
      translateType: useTranslateType(),
      residentPictures,
      async editLot(lot: PropertyLot) {
        await showEditLotPopup(lot, types.value);
        refetch();
      },
      lotsByType,
      loading,
      options,
    };
  },
});
