<template>
  <generic-page
    :loading="residents == null"
    :title="$t('residents.many')"
    :options="options"
  >
    <resident-list :residents="residents" :property="property" />

    <div class="invites" v-if="invites.length > 0">
      <h2 class="invited-title">Uitgenodigd</h2>
      <div class="invite" v-for="invite in invites" :key="invite.id">
        {{ invite.email }}
        <div class="cancel-invite" @click="cancelInvite(invite)" v-if="isAdmin">
          <add-icon
            cross
            stroke-width="2"
            width="18"
            height="18"
            v-if="deleting.find((i) => i.id === invite.id) == null"
          />
          <loader-icon v-else width="18px" height="18px" />
        </div>
      </div>
    </div>
  </generic-page>
  <!-- </div>
  </container> -->
</template>

<script lang="ts">
import { inProperties, PropertiesActions } from "@/store/properties";
import { Invite, User } from "@/types";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { computed, defineComponent, ref } from "vue";
import { timeago } from "@/util/timeago";
import AddIcon from "@/components/icons/Add.vue";
import { showToast } from "@/components/notifications/toast";
import { authGetters, inAuth } from "@/store/auth";
import LoaderIcon from "@/components/icons/Loader.vue";
import { useRemoveListener } from "@/util/component";
import appSocket from "@/channels/appSocket";
import { useActiveProperty } from "@/util/properties";
import ResidentList from "@/components/property/ResidentList.vue";
import { GenericPageOption } from "@/components/common/generic-page/GenericPageOptions.vue";
import { useI18n } from "vue-i18n";
import { showAddResidentPopup } from "@/features/residents/popups/AddResident.vue";

export default defineComponent({
  components: { AddIcon, LoaderIcon, ResidentList },
  setup() {
    const store = useStore();
    const route = useRoute();
    const property = useActiveProperty();
    const residents = ref(null as null | User[]);
    const invites = ref([] as Invite[]);

    function updateProperties() {
      store
        .dispatch(inProperties(PropertiesActions.LIST_RESIDENTS), {
          id: route.params.id,
        })
        .then((value) => {
          residents.value = value;
        });
    }
    updateProperties();

    function updateInvites() {
      store
        .dispatch(inProperties(PropertiesActions.INVITED_RESIDENTS), {
          id: route.params.id,
        })
        .then((value) => {
          invites.value = value;
        });
    }
    updateInvites();

    useRemoveListener(() =>
      appSocket.getPropertyChannels().onInviteAccepted((data) => {
        if (data.property.id !== property.value?.id) return;
        updateInvites();
        updateProperties();
      })
    );

    useRemoveListener(() =>
      appSocket.getPropertyChannels().onInviteDeclined((data) => {
        if (data.property.id !== property.value?.id) return;
        updateInvites();
      })
    );

    if (route.query.invited != null) {
      showToast({
        type: "success",
        text: `Mede-eigenaar uitgenodigd`,
      });
    }

    const currentUser = computed(() => store.getters[inAuth(authGetters.USER)]);
    const isAdmin = computed(() => {
      if (currentUser.value == null) return false;
      if (property.value == null) return false;
      return property.value.admin_id === currentUser.value.id;
    });

    const deleting = ref([] as Invite[]);

    const { t } = useI18n();
    const options: GenericPageOption[] = [
      {
        id: "add",
        iconName: "add",
        text: t("residents.invite"),
        async onClick() {
          let result = await showAddResidentPopup();
          if (!result) return;

          updateProperties();
          showToast({
            type: "success",
            text: `Mede-eigenaar uitgenodigd`,
          });
        },
      },
    ];

    return {
      options,
      residents,
      timeago,
      invites,
      property,
      currentUser,
      isAdmin,
      deleting,
      async cancelInvite(invite: Invite) {
        deleting.value.push(invite);
        await store.dispatch(inProperties(PropertiesActions.DELETE_INVITE), {
          id: invite.id,
        });
        invites.value = await store.dispatch(
          inProperties(PropertiesActions.INVITED_RESIDENTS),
          {
            id: route.params.id,
          }
        );
        deleting.value = deleting.value.filter((i) => i.id !== invite.id);
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.invited-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: $text-alt;
  font-family: $font-title;
  margin-bottom: 10px;
}

.invite {
  padding: 15px 20px;
  background-color: white;
  border: 1px solid $bg-accent;
  box-sizing: border-box;
  margin-top: 10px;
  border-radius: 5px;
  position: relative;
}

.cancel-invite {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  cursor: pointer;
}

.add-button {
  display: none;
  @include screen-from("tablet") {
    display: block;
  }
}

.add-icon-wrapper {
  @include screen-from("tablet") {
    display: none;
  }
}
</style>
