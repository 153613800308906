<template>
  <generic-page :loading="property == null" :title="property?.name">
    <div class="user-property">
      <div class="options">
        <user-property-items />

        <template v-if="isAdmin">
          <h2 class="sub-title">{{ $t("property.resident_admin") }}</h2>
          <user-property-admin-items />
        </template>
      </div>
    </div>
  </generic-page>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { computed, defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { authGetters, inAuth } from "@/store/auth";
import { useActiveProperty } from "@/util/properties";
import UserPropertyItems from "./UserPropertyItems.vue";
import UserPropertyAdminItems from "./UserPropertyAdminItems.vue";
import { useIssues } from "@/store/issues/hooks";

export default defineComponent({
  components: {
    UserPropertyItems,
    UserPropertyAdminItems,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const property = useActiveProperty();

    if (route.params.id == null) {
      router.push({ name: "properties" });
      return;
    }

    const currentUser = store.getters[inAuth(authGetters.USER)];
    const { data: issues } = useIssues();
    const propertyIssues = computed(() =>
      issues.value?.filter((issue) => issue.property_id === property.value?.id)
    );

    return {
      issues: propertyIssues,
      isAdmin: computed(() => property.value?.admin_id === currentUser.id),
      property,
    };
  },
});
</script>

<style lang="scss" scoped>
.options {
  margin-top: 20px;
}

.sub-title {
  font-size: 1.125rem;
  font-family: $font-title;
  font-weight: 600;
  color: $text-alt;
  margin: 30px 20px 0;
}

.user-property--mobile {
  @include screen-from("tablet-wide") {
    display: none;
  }
}

.user-property--desktop {
  display: none;

  @include screen-from("tablet-wide") {
    display: grid;
    column-gap: 25px;
    grid-template-columns: 1fr 1fr;
    padding-top: 25px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
}

.desktop-options {
  margin-top: -25px;
}

.desktop-issues {
  margin-top: 40px;
  grid-column: 1 / span 2;
}

.issues-title {
  font-size: 2rem;
  font-weight: 500;
  color: $text-alt;
  padding-bottom: 20px;
}
</style>
