import { computed, Ref } from "vue";
import { useStore } from "vuex";

export function useAnyNewMessages(): Ref<boolean> {
  const store = useStore();
  const user = computed(() => store.getters["auth/USER"]);
  return computed(() =>
    store.getters["chats/ANY_NEW_MESSAGES"](user.value?.id)
  );
}

export function useTotalNewMessageCount(): Ref<number> {
  const store = useStore();
  const user = computed(() => store.getters["auth/USER"]);
  return computed(() =>
    store.getters["chats/TOTAL_NEW_MESSAGE_COUNT"](user.value?.id)
  );
}
