<template>
  <div class="toast-viewer">
    <div
      class="toast"
      :class="[toast.type]"
      v-for="toast in toasts"
      :key="toast.text"
      @click="remove(toast)"
    >
      {{ toast.text }}
    </div>
  </div>
</template>

<script lang="ts">
import { Toast } from "@/types";
import { defineComponent, ref } from "vue";
import { listenForToast } from "./toast";

export default defineComponent({
  setup() {
    const toasts = ref([] as Toast[]);

    function remove(toast: Toast) {
      toasts.value = toasts.value.filter((t) => t.text !== toast.text);
    }

    listenForToast((toast) => {
      toasts.value.push(toast);

      setTimeout(() => {
        remove(toast);
      }, 2000);
    });

    return {
      toasts,
      remove,
    };
  },
});
</script>

<style lang="scss" scoped>
.toast-viewer {
  position: fixed;
  bottom: 100px;
  left: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 400;
}

.toast {
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  // background-color: $bg-accent;
  border: 1px solid $border-general;
  box-shadow: $shadow-general;
  color: $text-alt;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  animation: popIn 0.2s ease-out;
  line-height: 120%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.7;

  &.success {
    background-color: $bg-success;
    color: $text-light;
  }

  &.error {
    background-color: $bg-error;
    color: $text-light;
  }
}

@keyframes popIn {
  from {
    transform: translateY(15px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
