<template>
  <div class="textbox">
    <app-label :for-id="id" v-if="label">{{ label }}</app-label>
    <input
      ref="textboxRef"
      :autofocus="autofocus"
      :type="type"
      :name="name"
      :id="id"
      :placeholder="placeholder"
      :style="{ width, 'max-width': maxWidth }"
      :value="modelValue"
      @input="handleInput"
      @blur="handleBlur"
      :disabled="disabled"
      :class="{ thinner, disabled }"
      :accept="accept"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect } from "vue";

export default defineComponent({
  props: {
    type: {
      type: String,
      default: "text",
    },
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "100%",
    },
    maxWidth: {
      type: String,
      default: "auto",
    },
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    thinner: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const textboxRef = ref<HTMLInputElement | null>(null);

    watchEffect(() => {
      emit("ref", textboxRef.value);
    });

    return {
      textboxRef,
      handleInput(event: InputEvent) {
        emit("update:modelValue", (event.target as any).value);
      },
      handleBlur() {
        emit("blur");
      },
    };
  },
});
</script>

<style lang="scss" scoped>
input {
  background-color: white;
  border: 1px solid $border-general;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 400;
  font-family: $font-text;
  letter-spacing: 0.02rem;
  color: $text-dark;
  padding: 20px;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 0 2px 2px rgba($bg-accent, 0);

  &:focus {
    border-color: $bg-accent;
    box-shadow: 0 0 2px 2px rgba($bg-accent, 0.4);
    outline: none;
  }

  &.thinner {
    padding: 12px 20px;
  }

  &.disabled {
    background-color: rgba($bg-mobile-menu, 0.05);
  }
}

.textbox {
  width: 100%;
}
</style>
