
import { inProperties, PropertiesActions } from "@/store/properties";
import { Invite, User } from "@/types";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { computed, defineComponent, ref } from "vue";
import { timeago } from "@/util/timeago";
import AddIcon from "@/components/icons/Add.vue";
import { showToast } from "@/components/notifications/toast";
import { authGetters, inAuth } from "@/store/auth";
import LoaderIcon from "@/components/icons/Loader.vue";
import { useRemoveListener } from "@/util/component";
import appSocket from "@/channels/appSocket";
import { useActiveProperty } from "@/util/properties";
import ResidentList from "@/components/property/ResidentList.vue";
import { GenericPageOption } from "@/components/common/generic-page/GenericPageOptions.vue";
import { useI18n } from "vue-i18n";
import { showAddResidentPopup } from "@/features/residents/popups/AddResident.vue";

export default defineComponent({
  components: { AddIcon, LoaderIcon, ResidentList },
  setup() {
    const store = useStore();
    const route = useRoute();
    const property = useActiveProperty();
    const residents = ref(null as null | User[]);
    const invites = ref([] as Invite[]);

    function updateProperties() {
      store
        .dispatch(inProperties(PropertiesActions.LIST_RESIDENTS), {
          id: route.params.id,
        })
        .then((value) => {
          residents.value = value;
        });
    }
    updateProperties();

    function updateInvites() {
      store
        .dispatch(inProperties(PropertiesActions.INVITED_RESIDENTS), {
          id: route.params.id,
        })
        .then((value) => {
          invites.value = value;
        });
    }
    updateInvites();

    useRemoveListener(() =>
      appSocket.getPropertyChannels().onInviteAccepted((data) => {
        if (data.property.id !== property.value?.id) return;
        updateInvites();
        updateProperties();
      })
    );

    useRemoveListener(() =>
      appSocket.getPropertyChannels().onInviteDeclined((data) => {
        if (data.property.id !== property.value?.id) return;
        updateInvites();
      })
    );

    if (route.query.invited != null) {
      showToast({
        type: "success",
        text: `Mede-eigenaar uitgenodigd`,
      });
    }

    const currentUser = computed(() => store.getters[inAuth(authGetters.USER)]);
    const isAdmin = computed(() => {
      if (currentUser.value == null) return false;
      if (property.value == null) return false;
      return property.value.admin_id === currentUser.value.id;
    });

    const deleting = ref([] as Invite[]);

    const { t } = useI18n();
    const options: GenericPageOption[] = [
      {
        id: "add",
        iconName: "add",
        text: t("residents.invite"),
        async onClick() {
          let result = await showAddResidentPopup();
          if (!result) return;

          updateProperties();
          showToast({
            type: "success",
            text: `Mede-eigenaar uitgenodigd`,
          });
        },
      },
    ];

    return {
      options,
      residents,
      timeago,
      invites,
      property,
      currentUser,
      isAdmin,
      deleting,
      async cancelInvite(invite: Invite) {
        deleting.value.push(invite);
        await store.dispatch(inProperties(PropertiesActions.DELETE_INVITE), {
          id: invite.id,
        });
        invites.value = await store.dispatch(
          inProperties(PropertiesActions.INVITED_RESIDENTS),
          {
            id: route.params.id,
          }
        );
        deleting.value = deleting.value.filter((i) => i.id !== invite.id);
      },
    };
  },
});
