
import { defineComponent, reactive, ref } from "vue";
import AppForm from "@/components/form/AppForm.vue";
import FormField from "@/components/form/FormField.vue";
import FormError from "@/components/form/FormError.vue";
import FieldError from "@/components/form/FieldError.vue";
import { email, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { authActions, inAuth } from "@/store/auth";
import { logBreakingException } from "@/util/logger";
import { ValidationException } from "@/error_handling/ValidationException";

export default defineComponent({
  components: { AppForm, FormField, FormError, FieldError },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const token = route.query.token;
    if (token == null) {
      console.log("FinishSignUp: no token received, routing to login");
      router.push({
        name: "login",
      });
    }

    const state = reactive({
      firstName: "",
      lastName: "",
      email: route.query.email || "",
      // password: "",
    });
    const fieldErrors = ref({} as { [fieldName: string]: string[] });

    const rules = {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      // password: { required, minLength: minLength(6) },
    };

    const v = useVuelidate(rules, state);

    return {
      v,
      state,
      fieldErrors,
      async finishRegistration() {
        try {
          await store.dispatch(inAuth(authActions.FINISH_REGISTRATION), {
            token,
            user: {
              first_name: state.firstName,
              last_name: state.lastName,
              email: state.email,
              // password: state.password,
            },
          });
          router.push({
            name: "properties",
          });
        } catch (e) {
          if (e instanceof ValidationException) {
            fieldErrors.value = e.getErrors();
          } else {
            logBreakingException(e as Error);
          }
        }
      },
    };
  },
});
