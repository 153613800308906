<template>
  <!-- 
    TODO: Instellingen 
      - Automatisch betalings notificaties

   -->
  <div>
    <container maxWidth="450px" centerh>
      <generic-header>Test</generic-header>
      <generated-form
        :fieldSettings="fieldSettings"
        :submitHandler="handleSubmit"
        submitText="Opslaan"
      />
    </container>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import GeneratedForm, {
  FieldsSettings,
} from "@/components/form/GeneratedForm.vue";

export default defineComponent({
  components: { GeneratedForm },
  setup() {
    const fieldSettings: FieldsSettings = [
      {
        name: "notifications",
        type: "boolean",
        label: "Ontvang notificaties bij nieuwe transacties",
        smaller: true,
      },
    ];

    return {
      fieldSettings,
    };
  },
});
</script>
