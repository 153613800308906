
import { inProperties, PropertiesActions } from "@/store/properties";
import { useActiveProperty } from "@/util/properties";
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const customValue = ref("12");
    const selectedId = ref(null as null | string);
    const loading = ref(false);
    const store = useStore();
    const router = useRouter();
    const activeProperty = useActiveProperty();

    return {
      selectedId,
      customValue,
      loading,
      async submit() {
        const activePropertyId = activeProperty.value?.id;
        if (activePropertyId == null) return;
        if (selectedId.value == null) return;
        loading.value = true;
        const termStr = selectedId.value;
        const term = parseInt(termStr);
        await store.dispatch(inProperties(PropertiesActions.PATCH), {
          id: activePropertyId,
          updates: {
            payment_term: term,
          },
        });
        loading.value = false;
        router.push({
          name: "accounting.overview.transactions",
        });
      },
    };
  },
});
