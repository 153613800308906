<template>
  <div class="invoice" @click.stop="openFile">
    <div class="tag">FACTUUR</div>
    <h3 class="description">
      {{ invoice.description }}
    </h3>
    <!-- <div class="download">
      <app-link>Open</app-link>
    </div> -->
    <div class="delete-button" @click.stop="removeInvoice">
      <template v-if="deletingLoader.isLoading.value">
        <loader-icon />
      </template>
      <template v-else>
        <times-icon />
      </template>
    </div>
    <!-- <div class="amount">
      {{ formatAsEuro(invoice.amount) }}
    </div> -->
  </div>
</template>

<script lang="ts">
import { Invoice, Transaction } from "@/types";
import { formatAsEuro } from "@/util/price";
import { defineComponent, PropType } from "vue";
import TimesIcon from "@/components/icons/Times.vue";
import { useOpenFile } from "@/capacitor/hooks/useOpenFile";
import { showConfirmPopup } from "@/components/popups/common_popups";
import { useStore } from "vuex";
import { accountingActions, inAccounting } from "@/store/accounting/accounting";
import { useLoader } from "@/util/loading";
import LoaderIcon from "@/components/icons/Loader.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: { TimesIcon, LoaderIcon },
  props: {
    invoice: {
      type: Object as PropType<Invoice>,
      required: true,
    },
    transaction: {
      type: Object as PropType<Transaction>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const openFile = useOpenFile(props.invoice.file.url);
    const deletingLoader = useLoader();

    const { t } = useI18n();

    return {
      openFile,
      formatAsEuro,
      deletingLoader,
      // async removeInvoice() {
      removeInvoice: deletingLoader.use(async () => {
        const shouldDelete = await showConfirmPopup({
          title: t("accounting.invoice_delete.title"),
          confirmText: t("accounting.invoice_delete.confirmText"),
          declineText: t("accounting.invoice_delete.declineText"),
          subTitle: t("accounting.invoice_delete.subTitle"),
        });
        if (shouldDelete === true) {
          store.dispatch(inAccounting(accountingActions.DELETE_INVOICE), {
            invoiceId: props.invoice.id,
            propertyId: props.invoice.property_id,
          });
        }
      }),
      // handleClick() {
      //   showPopup(EditInvoice, {
      //     transaction: props.transaction,
      //     invoice: props.invoice,
      //   });
      // },
    };
  },
});
</script>

<style lang="scss" scoped>
.invoice {
  border: 1px solid $border-general;
  padding: 20px;
  border-radius: 5px;
  background-color: $bg-general;
  position: relative;
  z-index: 1;
}

.description {
  font-size: 1rem;
  font-weight: 600;
  color: $text-alt;
}

.amount {
  margin-top: 7px;
}

.tag {
  position: absolute;
  top: -10px;
  background-color: $bg-accent;
  padding: 3px 8px;
  border-radius: 5px;
  color: $text-alt;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 600;
}

.delete-button {
  position: absolute;
  width: 12px;
  height: 12px;
  padding: 5px;
  right: 15px;
  top: 15px;
}

.download {
  padding-top: 5px;
}
</style>
