<template>
  <div class="talking-points-file">
    <a
      v-if="currentFile == null && !loading"
      class="link upload-new"
      @click="uploadNew"
    >
      + Document toevoegen
    </a>
    <div class="preview" v-if="currentFile != null && !loading">
      <div class="preview-image" @click="openFile">
        <pdf-file-icon v-if="currentFile.type.includes('pdf')" size="38" />
        <file-icon v-else size="38" />
        <a class="open-link">Open</a>
      </div>
      <div class="buttons">
        <a class="link edit" @click="uploadNew">Nieuw bestand opladen</a>
      </div>
    </div>
    <div class="loading" v-if="loading">
      <loader width="20px" height="20px" thickness="3px" opacity="0.1" />
    </div>
    <input
      type="file"
      ref="fileRef"
      class="file-input"
      @change="onFileChange"
    />
  </div>
</template>

<script lang="ts">
import { FileItem } from "@/repositories/fileRepository";
import { computed, defineComponent, PropType, ref } from "vue";
import { DateTime } from "luxon";
import FileIcon from "@/components/icons/File.vue";
import PdfFileIcon from "@/components/icons/PdfFile.vue";
import { Browser } from "@capacitor/browser";
import Loader from "@/components/icons/Loader.vue";

export default defineComponent({
  emits: ["new-file"],
  components: { Loader, FileIcon, PdfFileIcon },
  props: {
    files: {
      type: Array as PropType<FileItem[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const fileRef = ref(null as null | HTMLInputElement);

    const currentFile = computed(() => {
      if (props.files.length === 0) return null;
      console.log(props.files);
      const sortedFiles = [...props.files].sort((a, b) => {
        const dateA = DateTime.fromISO(a.inserted_at);
        const dateB = DateTime.fromISO(b.inserted_at);
        return dateB.toMillis() - dateA.toMillis();
      });
      return sortedFiles[0];
    });

    return {
      fileRef,
      currentFile,
      uploadNew() {
        fileRef.value?.click();
      },
      async onFileChange() {
        const files = fileRef.value?.files;
        if (files == null) return;
        if (files.length === 0) return;
        emit("new-file", files[0]);
      },
      openFile() {
        const file = currentFile.value;
        if (file == null) return;
        Browser.open({
          url: file.url,
        });
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.upload-new {
  margin-top: 15px;
}

.link {
  text-decoration: underline;
  color: $text-alt;
  display: block;
  cursor: pointer;
}

.edit {
  margin-left: 30px;
}

.file-input {
  display: none;
}

.preview {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.preview-image {
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.open-link {
  color: $text-dark;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  display: block;
  margin-top: 10px;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    text-decoration: underline;
  }
}

.loading {
  padding: 20px;
}
</style>
