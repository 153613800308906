
import { defineComponent, PropType, ref } from "vue";
import Hamburger from "@/components/icons/Hamburger.vue";
import { RouteLocationRaw, useRouter } from "vue-router";
import { useStore } from "vuex";
import { inOther, OtherActions } from "@/store/other";

export interface TabsHeaderTab {
  label: string;
  to: RouteLocationRaw;
}

export default defineComponent({
  components: { Hamburger },
  props: {
    title: {
      type: String,
      required: true,
    },
    tabs: {
      type: Array as PropType<TabsHeaderTab[]>,
      required: true,
    },
  },
  setup() {
    const selected = ref(null as string | null);
    const router = useRouter();
    const store = useStore();

    return {
      selected,
      to(name: string) {
        router.push({ name });
      },
      openMobileSidebar() {
        store.dispatch(inOther(OtherActions.SHOW_MOBILE_SIDEBAR));
      },
    };
  },
});
