import finRepository from "@/repositories/finRepository";
import { Bank } from "@/types";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState } from ".";

interface FinState {
  banks: Bank[];
}
// type FinState = Record<string, string>;

export function inFin(action: string): string {
  return "fin/" + action;
}

export const finMutations = {
  SET_BANKS: "SET_BANKS",

  // TODO: remove
  ADD_DOCUMENT: "ADD_DOCUMENT",
  ADD_DOCUMENTS: "ADD_DOCUMENTS",
};

export const finActions = {
  UPDATE_BANKS: "UPDATE_BANKS",

  // TODO: remove
  CREATE: "CREATE",
  LIST: "LIST",
  BY_ID: "BY_ID",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
};

export const finGetters = {
  BANKS: "BANKS",
};

const state = (): FinState => ({
  banks: [],
});

const getters: GetterTree<FinState, RootState> = {
  [finGetters.BANKS](state) {
    return state.banks;
  },
};

const mutations: MutationTree<FinState> = {
  [finMutations.SET_BANKS](state, banks: Bank[]) {
    state.banks = banks;
  },
  // [finMutations.ADD_DOCUMENT](state, doc: FinDocument) {
  //   const existingDoc = state.cachedDocuments.find((d) => d.id === doc.id);
  //   if (existingDoc != null) return;
  //   state.cachedDocuments.push(doc);
  // },
  // [finMutations.ADD_DOCUMENTS](state, docs: FinDocument[]) {
  //   const documentsById: Record<number, FinDocument> = {};
  //   state.cachedDocuments.forEach((doc) => {
  //     documentsById[doc.id] = doc;
  //   });
  //   docs.forEach((doc) => {
  //     documentsById[doc.id] = doc;
  //   });
  //   return Object.values(documentsById);
  // },
};

const actions: ActionTree<FinState, RootState> = {
  async [finActions.UPDATE_BANKS](context) {
    const response = await finRepository.listBanks();
    const banks = response.data.data.banks;
    context.commit(finMutations.SET_BANKS, banks);
    return banks;
  },

  // TODO: Remove
  // async [finActions.CREATE](
  //   context,
  //   params: { propertyId: number; params: any }
  // ): Promise<FinDocument> {
  //   const response = await finRepository.create(
  //     params.propertyId,
  //     params.params
  //   );
  //   return response.data.data.document;
  // },
  // async [finActions.LIST](
  //   context,
  //   params: { propertyId: number }
  // ): Promise<FinDocument[]> {
  //   const response = await finRepository.list(params.propertyId);
  //   const documents = response.data.data.documents;
  //   context.commit(finMutations.ADD_DOCUMENTS, documents);
  //   return documents;
  // },
  // async [finActions.BY_ID](
  //   context,
  //   {
  //     docId,
  //     allowFromCache = false,
  //   }: { docId: number; allowFromCache?: boolean }
  // ): Promise<FinDocument> {
  //   if (allowFromCache) {
  //     if (typeof docId === "string") {
  //       docId = parseInt(docId);
  //     }
  //     const cachedDoc = context.state.cachedDocuments.find(
  //       (d) => d.id === docId
  //     );
  //     if (cachedDoc != null) {
  //       return cachedDoc;
  //     }
  //   }
  //   const response = await finRepository.show(docId);
  //   const document = response.data.data.document;
  //   context.commit(finMutations.ADD_DOCUMENT, document);
  //   return document;
  // },
  // async [finActions.UPDATE](
  //   context,
  //   params: { docId: number; updates: any }
  // ): Promise<FinDocument> {
  //   const response = await finRepository.update(params.docId, params.updates);
  //   return response.data.data.document;
  // },
  // async [finActions.DELETE](
  //   context,
  //   params: { docId: number }
  // ): Promise<FinDocument> {
  //   const response = await finRepository.delete(params.docId);
  //   return response.data.data.document;
  // },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
