import { Invoice, Payment, PaymentRequest } from "@/types";
import { useCurrentUser } from "@/util/auth";
import { useActiveProperty } from "@/util/properties";
import { computed, Ref, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import { useStoreAction } from "../util/hooks";
import {
  accountingActions,
  accountingGetters,
  inAccounting,
} from "./accounting";

export const usePayments = () => {
  const store = useStore();
  const activeProperty = useActiveProperty();
  const payments = ref([] as Payment[]);
  const loading = ref(false);

  watchEffect(async () => {
    if (activeProperty.value == null) return;
    loading.value = true;
    payments.value = await store.dispatch(
      inAccounting(accountingActions.FETCH_PAYMENTS),
      activeProperty.value.id
    );
    loading.value = false;
  });

  return {
    payments,
    loading,
  };
};

export const useUserPayments = (userId: Ref<number | null>) => {
  const { loading, payments } = usePayments();
  const userPayments = computed(() =>
    userId.value == null
      ? []
      : payments.value?.filter((p) => p.user_id === userId.value)
  );
  return {
    loading,
    payments: userPayments,
  };
};

export const usePaymentRequests = (userId: Ref<number | null>) => {
  const store = useStore();
  const activeProperty = useActiveProperty();
  const loading = ref(false);
  const paymentRequests = computed(
    () => store.getters[inAccounting(accountingGetters.PAYMENT_REQUESTS)]
  );

  watchEffect(async () => {
    if (userId.value == null) return;
    if (activeProperty.value == null) return;
    loading.value = true;
    await store.dispatch(
      inAccounting(accountingActions.FETCH_PAYMENT_REQUESTS),
      {
        propertyId: activeProperty.value.id,
        userId: userId.value,
      }
    );
    loading.value = false;
  });

  return {
    loading,
    paymentRequests,
  };
};

export const useCurrentUserPaymentRequests = () => {
  const user = useCurrentUser();
  const userId = computed(() => user.value?.id ?? null);
  return usePaymentRequests(userId);
};

export const useAllPaymentRequests = () => {
  const activeProperty = useActiveProperty();

  const { loading, value } = useStoreAction([], (store) => {
    if (activeProperty.value == null) return [];
    return store.dispatch(
      inAccounting(accountingActions.FETCH_ALL_PAYMENT_REQUESTS),
      { propertyId: activeProperty.value.id }
    );
  });
  return { loading, paymentRequests: value };
};

export const useActivePropertyInvoices = () => {
  const activeProperty = useActiveProperty();

  const { loading, value } = useStoreAction<Invoice[]>([], (store) => {
    if (activeProperty.value == null) return [];
    return store.dispatch(inAccounting(accountingActions.FETCH_INVOICES), {
      propertyId: activeProperty.value.id,
    });
  });
  return { loading, invoices: value };
};

export const useBankAccountDetails = (
  propertyId: Ref<number | string | undefined>
) => {
  const store = useStore();
  const details = ref(null as { iban: string } | null);
  const loading = ref(false);

  watchEffect(() => {
    if (propertyId == null) return;
    store
      .dispatch(inAccounting(accountingActions.FETCH_IBAN), {
        propertyId: propertyId.value,
      })
      .then((value) => {
        details.value = { iban: value };
      });
  });

  return {
    details,
    loading,
  };
};
