
import { defineComponent, PropType } from "vue";
import CheckIcon from "@/components/icons/Check.vue";

export default defineComponent({
  components: { CheckIcon },
  props: {
    features: {
      type: Array as PropType<{ title: string; text: string }[]>,
      required: true,
    },
  },
});
