<template>
  <container thinner class="create-finance">
    <generic-header>{{ $t("fin.new_doc") }}</generic-header>
    <div class="container">
      <app-form
        :submit-text="t('common.create')"
        submit-full
        :submit-handler="handleSubmit"
        thinner
      >
        <form-field>
          <textbox
            v-model="formData.title"
            :label="t('common.title')"
            :placeholder="t('common.short_description')"
          />
          <field-error :fieldErrors="fieldErrors" name="title" />
        </form-field>
        <form-field>
          <textbox
            v-model="formData.date"
            type="date"
            :label="t('common.date')"
          />
          <field-error :fieldErrors="fieldErrors" name="title" />
        </form-field>
        <form-field>
          <textbox
            v-model="formData.amount"
            :label="$t('common.amount_cur')"
            placeholder="3000,00"
          />
          <field-error :fieldErrors="fieldErrors" name="amount" />
        </form-field>
        <form-field>
          <button
            type="button"
            class="upload-file"
            @click.prevent="showFilePicker"
          >
            <div class="upload-file__icon">
              <file-upload-icon />
            </div>
            {{ $t("add_file") }}
          </button>
          <input
            @change="handleFileInputChange"
            ref="inputEl"
            type="file"
            class="upload-file__input"
          />
          <div class="file-selected" v-if="formData.fileName">
            {{ formData.fileName }}
          </div>
          <field-error :fieldErrors="fieldErrors" name="file" />
        </form-field>
      </app-form>
    </div>
  </container>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import { AppForm, FormField, FieldError } from "@/components/form/index";
import FileUploadIcon from "@/components/icons/FileUpload.vue";
import { useStore } from "vuex";
import { ValidationException } from "@/error_handling/ValidationException";
import { finActions, inFin } from "@/store/fin";
import { useRoute, useRouter } from "vue-router";
import { optimizeImage } from "@/util/image";
import { filesActions, inFiles } from "@/store/files";
import { DateTime } from "luxon";

export default defineComponent({
  components: { AppForm, FormField, FileUploadIcon, FieldError },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const inputEl = ref(null as null | HTMLInputElement);
    const formData = reactive({
      title: "",
      date: DateTime.now().toISODate(),
      amount: "",
      file: null as null | File,
      fileName: "",
    });
    const fieldErrors = ref({} as { [fieldName: string]: string[] });
    const loading = ref(false as boolean);

    async function uploadFile(file: File) {
      let blob: Blob = file;
      if (
        ["image/jpg", "image/png", "image/webp", "image/gif"].includes(
          file.type
        )
      ) {
        blob = await optimizeImage(file, 500);
      }
      return await store.dispatch(inFiles(filesActions.UPLOAD), {
        file: blob,
      });
    }

    return {
      inputEl,
      formData,
      fieldErrors,
      async handleSubmit() {
        if (formData.file == null) {
          fieldErrors.value = { file: ["Bestand verplicht"] };
          return;
        }

        loading.value = true;

        const parsedAmount = parseInt(
          (parseFloat(formData.amount) * 100).toFixed(2)
        );

        try {
          const doc = await store.dispatch(inFin(finActions.CREATE), {
            propertyId: route.params.id,
            params: {
              title: formData.title,
              amount: parsedAmount,
              date: formData.date,
            },
          });
          const uploadedFile = await uploadFile(formData.file);
          const updated = await store.dispatch(inFin(finActions.UPDATE), {
            docId: doc.id,
            updates: {
              file_id: uploadedFile.id,
            },
          });
          router.push({
            name: "properties.finances.list",
            params: {
              id: updated.property_id,
            },
          });
        } catch (e) {
          if (e instanceof ValidationException) {
            fieldErrors.value = e.getErrors();
          } else {
            throw e;
          }
        } finally {
          loading.value = true;
        }
      },
      showFilePicker() {
        if (inputEl.value == null) return;
        inputEl.value.click();
      },
      handleFileInputChange(e: InputEvent) {
        const target = e.target as HTMLInputElement;
        if (target == null || target.files == null) return;
        if (target.files.length === 0) return;
        formData.file = target.files[0];
        formData.fileName = target.files[0].name;

        if ("file" in fieldErrors.value) {
          delete fieldErrors.value["file"];
        }
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.container {
  padding: 30px 30px 20px;

  @include screen-from("tablet") {
    padding: 30px 0 20px;
  }
}

.upload-file {
  width: 100%;
  font-size: 1.125rem;
  color: $text-alt;
  font-family: $font-title;
  padding: 12px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid $border-general;
  outline: none;
  font-weight: 500;
  letter-spacing: 0.02rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    border-color: $text-accent;
  }

  &:active {
    background-color: $bg-accent;
  }
}

.upload-file__icon {
  color: $text-accent;
  margin-right: 10px;
  padding-top: 4px;
}

.upload-file__input {
  display: none;
}

.file-selected {
  color: $text-dark;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  margin: 10px 0;
  user-select: none;
  opacity: 0.8;
}
</style>
