
import { GenericPageOption } from "@/components/common/generic-page/GenericPageOptions.vue";
import { useMeetings } from "@/store/meetings/hooks";
import { Meeting } from "@/types";
import { isCurrentPropertyAdmin } from "@/util/auth";
import { useActiveProperty } from "@/util/properties";
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import MeetingListing from "../components/MeetingListing.vue";
import { showMeetingPopup } from "../popups/ViewMeeting/ViewMeeting.vue";

export default defineComponent({
  components: { MeetingListing },
  setup() {
    const router = useRouter();
    const isAdmin = isCurrentPropertyAdmin();
    const property = useActiveProperty();
    const propertyId = computed(() => property.value?.id ?? null);
    const { meetings, loading } = useMeetings(propertyId);

    const options = computed<GenericPageOption[]>(() => {
      if (!isAdmin.value) return [];
      return [
        {
          id: "add",
          iconName: "add",
          text: "Vergadering plannen",
          async onClick() {
            router.push({ name: "meetings.new" });
          },
        },
      ];
    });
    return {
      loading,
      options,
      meetings,
      showMeeting(meeting: Meeting) {
        showMeetingPopup(meeting);
      },
    };
  },
});
