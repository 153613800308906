<template>
  <generic-page title="Nieuwe vergadering">
    <div class="form">
      <generated-form
        submitText="Aanmaken"
        :fieldSettings="formSettings"
        :submitHandler="submit"
      />
    </div>
  </generic-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import GeneratedForm, {
  FieldsSettings,
} from "@/components/form/GeneratedForm.vue";
import { useStore } from "vuex";
import { inMeetings, meetingsActions } from "@/store/meetings/meetings";
import { CreateMeetingParams, Time } from "@/types";
import { useActiveProperty } from "@/util/properties";
import { useRouter } from "vue-router";
import { ValidationException } from "@/error_handling/ValidationException";
import { mergeDateAndTime } from "../helpers/datetime";

export default defineComponent({
  components: { GeneratedForm },
  setup() {
    const store = useStore();
    const router = useRouter();
    const activeProperty = useActiveProperty();

    const formSettings: FieldsSettings = [
      {
        name: "scheduled_for",
        type: "date",
        label: "Datum",
        meta: {
          placeholder: "Datum",
        },
        subCheckbox: {
          name: "send_notification",
          label:
            "Stuur een notificatie met tijd, datum en locatie naar alle mede-eigenaars",
        },
      },
      {
        name: "scheduled_for_time",
        type: "time",
        label: "Tijd",
        meta: {
          placeholder: "Tijd",
        },
      },
      {
        name: "location",
        type: "string",
        label: "Locatie",
        meta: {
          placeholder: "Waar vindt de vergadering plaats?",
        },
      },
      {
        name: "description",
        type: "textarea",
        label: "Beschrijving (optioneel)",
        meta: {
          placeholder: "Vrije extra beschrijving",
        },
      },
    ];

    return {
      formSettings,
      async submit(data: CreateMeetingParams & { scheduled_for_time: Time }) {
        if (activeProperty.value == null) return;

        if (data.scheduled_for_time == null) {
          // TODO: i18n
          throw new ValidationException({
            scheduled_for_time: ["veld verplicht"],
          });
        }

        await store.dispatch(inMeetings(meetingsActions.CREATE), {
          params: {
            location: data.location,
            description: data.description,
            send_notification: data.send_notification,
            property_id: activeProperty.value.id,
            scheduled_for: mergeDateAndTime(
              data.scheduled_for,
              data.scheduled_for_time
            ),
          } as CreateMeetingParams,
        });
        router.push({ name: "meetings.list" });
      },
    };
  },
});
</script>
