<template>
  <svg
    width="130"
    height="30"
    viewBox="0 0 130 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 21.6692V20.1524L2.56427 19.5294L3.40104 4.79432L1.02571 4.2255L1.07969 2.70865H7.7738L13.3342 15.6289L19.1106 2.70865H25.6427V4.2255L23.2404 4.79432L24.1851 19.5565L26.7764 20.1524V21.6692H17.3021V20.1524L19.6774 19.5836L18.9486 8.20722L12.9023 21.8047H11.1478L5.2905 8.12597L4.66968 19.5836L7.072 20.1524V21.6692H0Z"
      fill="#131003"
    />
    <path
      d="M26.0607 9.56155V8.12597H34.4013V9.56155L32.6198 9.88659L36.1019 16.8478L38.6391 10.0762L36.5877 9.56155V8.12597H42.8499V9.56155L40.6096 10.1845L33.0787 29.7681H32.5928C32.053 29.7681 31.5401 29.6507 31.0543 29.416C30.5864 29.1812 30.2535 28.8923 30.0556 28.5492L33.9695 22.2922L28.0851 10.1304L26.0607 9.56155Z"
      fill="#131003"
    />
    <path
      d="M44.3421 20.8025V15.8185H45.8537L47.2303 19.6107C47.9861 19.8815 48.9308 20.017 50.0645 20.017C51.2881 20.017 52.3408 19.8003 53.2226 19.3669C54.1223 18.9335 54.5722 18.2925 54.5722 17.4437C54.5722 17.2451 54.5452 17.0555 54.4912 16.8749C54.4552 16.6943 54.3833 16.5228 54.2753 16.3603C54.1673 16.1978 54.0594 16.0533 53.9514 15.9269C53.8434 15.7824 53.6815 15.638 53.4655 15.4935C53.2496 15.331 53.0606 15.2046 52.8987 15.1143C52.7367 15.0059 52.4938 14.8705 52.1699 14.708C51.864 14.5455 51.3151 14.2746 50.5234 13.8954L49.5516 13.4349C47.7701 12.5682 46.4925 11.6653 45.7187 10.7263C44.9449 9.76922 44.558 8.59546 44.558 7.20502C44.558 5.72429 45.1699 4.54151 46.3935 3.65668C47.6172 2.7538 49.2007 2.30236 51.1442 2.30236C53.3216 2.30236 55.283 2.58225 57.0285 3.14204V8.12597H55.5169L54.1943 4.49637C53.4565 4.33385 52.7457 4.25259 52.0619 4.25259C51.0002 4.25259 50.0915 4.44219 49.3357 4.8214C48.5799 5.18256 48.202 5.71526 48.202 6.41951C48.202 7.05153 48.4989 7.58423 49.0928 8.01762C49.7046 8.43294 50.7843 8.98371 52.3318 9.6699L52.8717 9.91368C54.7072 10.7443 56.0568 11.6653 56.9205 12.6765C57.7843 13.6877 58.2162 14.9157 58.2162 16.3603C58.2162 17.7146 57.8743 18.8252 57.1905 19.6919C56.5247 20.5406 55.6429 21.1546 54.5452 21.5338C53.4475 21.895 52.1969 22.0755 50.7933 22.0755C48.5619 22.0755 46.4115 21.6512 44.3421 20.8025Z"
      fill="#131003"
    />
    <path
      d="M58.0688 9.56155V8.12597H66.4094V9.56155L64.6279 9.88659L68.1099 16.8478L70.6472 10.0762L68.5958 9.56155V8.12597H74.858V9.56155L72.6177 10.1845L65.0868 29.7681H64.6009C64.0611 29.7681 63.5482 29.6507 63.0624 29.416C62.5945 29.1812 62.2616 28.8923 62.0636 28.5492L65.9775 22.2922L60.0932 10.1304L58.0688 9.56155Z"
      fill="#131003"
    />
    <path
      d="M75.7024 21.6692V20.2337L77.9967 19.6919V10.5908H75.7024V9.15525L80.0211 8.12597H82.1535V9.4532C83.2512 8.29751 84.6099 7.71967 86.2294 7.71967C86.9312 7.71967 87.561 7.82801 88.1189 8.0447C88.6947 8.24334 89.1716 8.53226 89.5495 8.91147C89.9453 9.29069 90.2513 9.74213 90.4672 10.2658C90.6831 10.7895 90.7911 11.3583 90.7911 11.9723V19.6919L93.0854 20.2337V21.6692H84.8798V20.2337L86.6343 19.6919V12.7307C86.6343 12.1709 86.5983 11.7194 86.5263 11.3764C86.4723 11.0333 86.3734 10.7714 86.2294 10.5908C86.0854 10.3922 85.8875 10.2658 85.6356 10.2116C85.4016 10.1394 85.1047 10.1033 84.7448 10.1033C84.061 10.1033 83.1972 10.5006 82.1535 11.2951V19.6919L83.9081 20.2337V21.6692H75.7024Z"
      fill="#131003"
    />
    <path
      d="M92.5905 15.3852C92.5905 12.9474 93.3553 11.0603 94.8849 9.72407C96.4324 8.3878 98.3129 7.71967 100.526 7.71967C101.084 7.71967 101.606 7.77384 102.092 7.88219V2.46487H99.7975V1.02929L104.116 0H106.276V19.6377H108.381V20.8025C108.273 20.8928 108.12 21.0101 107.922 21.1546C107.742 21.2991 107.373 21.4887 106.815 21.7234C106.258 21.9582 105.727 22.0755 105.223 22.0755C104.467 22.0755 103.828 21.895 103.306 21.5338C102.803 21.1727 102.443 20.7844 102.227 20.3691C101.237 21.5067 100.022 22.0755 98.5828 22.0755C96.9453 22.0755 95.5327 21.5067 94.345 20.3691C93.1754 19.2134 92.5905 17.5521 92.5905 15.3852ZM96.8553 15.2768C96.8553 16.7575 97.1702 17.8771 97.8 18.6355C98.4479 19.394 99.0867 19.7732 99.7165 19.7732C100.616 19.7732 101.408 19.4391 102.092 18.771V9.6699C101.588 9.54349 101.147 9.48029 100.769 9.48029C99.5815 9.48029 98.6278 9.95882 97.908 10.9159C97.2062 11.8549 96.8553 13.3085 96.8553 15.2768Z"
      fill="#131003"
    />
    <path
      d="M109.293 21.6692V20.2337L111.587 19.6919V10.5908H109.293V9.15525L113.612 8.12597H115.744V19.6919L118.039 20.2337V21.6692H109.293ZM111.156 3.95464C111.156 3.25039 111.398 2.66351 111.884 2.19401C112.37 1.70645 112.964 1.46267 113.666 1.46267C114.386 1.46267 114.979 1.70645 115.447 2.19401C115.933 2.66351 116.176 3.25039 116.176 3.95464C116.176 4.67694 115.933 5.28188 115.447 5.76944C114.979 6.25699 114.386 6.50077 113.666 6.50077C112.964 6.50077 112.37 6.25699 111.884 5.76944C111.398 5.28188 111.156 4.67694 111.156 3.95464Z"
      fill="#131003"
    />
    <path
      d="M118.15 14.8434C118.15 12.7487 118.834 11.0423 120.202 9.72407C121.587 8.3878 123.216 7.71967 125.087 7.71967C126.653 7.71967 128.192 8.09888 129.703 8.8573C129.649 10.3561 128.992 11.4576 127.733 12.1619L125.411 9.48029H125.249C124.494 9.69698 123.828 10.2568 123.252 11.1597C122.676 12.0445 122.388 13.2905 122.388 14.8976C122.37 16.7034 122.775 18.0577 123.603 18.9606C124.377 19.701 125.384 20.0711 126.626 20.0711C127.148 20.0711 127.652 20.026 128.138 19.9357C128.641 19.8454 129.154 19.719 129.676 19.5565L130 21.1546C128.542 21.7686 127.049 22.0755 125.519 22.0755C123.486 22.0755 121.749 21.4255 120.31 20.1253C118.87 18.8252 118.15 17.0645 118.15 14.8434Z"
      fill="#131003"
    />
  </svg>
</template>
