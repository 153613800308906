
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["select"],
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    bottomText: {
      type: String,
      required: true,
    },
    bottomLink: {
      type: String,
      default: null,
    },
    noHover: {
      type: Boolean,
      default: false,
    },
  },
});
