import appSocket from "@/channels/appSocket";
import authRepository from "@/repositories/authRepository";
import { TokenSet, User, VerifyLoginCodeResponse } from "@/types";
import { startAuthTimer } from "@/util/auth_timer";
import { DateTime } from "luxon";
import { watch } from "vue";
import { ActionContext } from "vuex";
import { setUser } from "@sentry/vue";
import { Unauthenticated } from "@/error_handling/Unauthenticated";
import { waitUntil } from "@/helpers/wait";
import { BrowserStorage } from "@/helpers/browserStorage";
import { RootState } from ".";
import * as Sentry from "@sentry/browser";
import LogRocket from "logrocket";

export const ACCESS_TOKEN_LIFE_SEC = 7200 - 180; // 2 hour (minus 3min margin)
export const REFRESH_TOKEN_LIFE_SEC = 2592000 - 3600; // 30 days (minus 1h margin)

const isTokenValid = (lastRefresh: DateTime, lifeTimeSec: number) => {
  return Math.abs(lastRefresh.diffNow("seconds").seconds) < lifeTimeSec;
};

const LOCALSTORAGE_KEY = "AUTH_DATA_MYSYNDIC";
interface LocalStorageData {
  lastRefresh: string;
  refresh: string;
}

const tokenStorage = new BrowserStorage<LocalStorageData>(LOCALSTORAGE_KEY);

export interface AuthState {
  initiated: boolean;
  user: null | User;
  tokenSet: null | TokenSet;
  lastRefresh: null | DateTime;
  refreshing: boolean;
  lastRefreshAttempt: DateTime | null;
  loginCodes: { [email: string]: string };
}

type AuthActionContext = ActionContext<AuthState, RootState>;

export function inAuth(action: string): string {
  return "auth/" + action;
}

export const authActions = {
  ENSURE_INITIATED: "ENSURE_INITIATED",
  REGISTER: "REGISTER",
  LOGIN: "LOGIN",
  LOGIN_WITH_REFRESH: "LOGIN_WITH_REFRESH",
  FACEBOOK_LOGIN: "FACEBOOK_LOGIN",
  FACEBOOK_REDIRECT: "FACEBOOK_REDIRECT",
  GOOGLE_LOGIN: "GOOGLE_LOGIN",
  GOOGLE_REDIRECT: "GOOGLE_REDIRECT",
  APPLE_LOGIN: "APPLE_LOGIN",
  // OAUTH: "OAUTH",
  LOGOUT: "LOGOUT",
  CREATE_PASS_RESET: "CREATE_PASS_RESET",
  GET_PASS_RESET: "GET_PASS_RESET",
  DELETE_PASS_RESET: "DELETE_PASS_RESET",
  FINISH_REGISTRATION: "FINISH_REGISTRATION",
  FETCH_ACCESS_TOKEN: "FETCH_ACCESS_TOKEN",
  CREATE_SINGLE_USE_TOKEN: "CREATE_SINGLE_USE_TOKEN",
  SESSION_FROM_SU_TOKEN: "SESSION_FROM_SU_TOKEN",
  SEND_EMAIL_LOGIN: "SEND_EMAIL_LOGIN",
  VERIFY_CODE: "VERIFY_CODE",
  LOGIN_WITH_CODE: "LOGIN_WITH_CODE",
  REGISTER_WITH_LOGIN_CODE: "REGISTER_WITH_LOGIN_CODE",
};

export const authMutations = {
  USER: "USER",
  TOKENS: "TOKENS",
  REMOVE_ALL: "REMOVE_ALL",
  SET_REFRESHING: "SET_REFRESHING",
  SET_LOGIN_CODE: "SET_LOGIN_CODE",
  CLEAR_LOGIN_CODES: "CLEAR_LOGIN_CODES",
};

export const authGetters = {
  HAS_AUTH: "HAS_AUTH",
  ACCESS_TOKEN: "ACCESS_TOKEN",
  USER: "USER",
  LAST_REFRESH: "LAST_REFRESH",
  ACCESS_VALID: "ACCESS_VALID",
  LOGIN_CODES: "LOGIN_CODES",
};

// interface AuthLocalstorageData {
//   refresh: string;
// }

const state = (): AuthState => ({
  initiated: false,
  user: null,
  tokenSet: null,
  lastRefresh: null,
  refreshing: false,
  lastRefreshAttempt: null,
  loginCodes: {},
});

const getters = {
  [authGetters.HAS_AUTH](state: AuthState): boolean {
    return state.user != null && state.lastRefresh != null;
  },
  [authGetters.ACCESS_TOKEN](state: AuthState): string | null {
    return state.tokenSet?.access || null;
  },
  [authGetters.USER](state: AuthState): User | null {
    return state.user;
  },
  [authGetters.LAST_REFRESH](state: AuthState): DateTime | null {
    return state.lastRefresh;
  },
  [authGetters.ACCESS_VALID](state: AuthState): boolean {
    return (
      state.lastRefresh != null &&
      state.tokenSet != null &&
      isTokenValid(state.lastRefresh, ACCESS_TOKEN_LIFE_SEC)
    );
  },
  [authGetters.LOGIN_CODES](state: AuthState): { [email: string]: string } {
    return state.loginCodes;
  },
};

const mutations = {
  [authMutations.USER](state: AuthState, { user }: { user: User }): void {
    state.user = user;
    Sentry.setUser({ id: user.id.toString(), email: user.email });
    LogRocket.identify(user.id.toString(), {
      email: user.email,
    });
  },
  [authMutations.TOKENS](
    state: AuthState,
    { tokenSet }: { tokenSet: TokenSet }
  ): void {
    state.tokenSet = tokenSet;
    state.lastRefresh = DateTime.utc();
  },
  [authMutations.REMOVE_ALL](state: AuthState): void {
    state.tokenSet = null;
    state.user = null;
  },
  [authMutations.SET_REFRESHING](
    state: AuthState,
    newRefreshing: boolean
  ): void {
    state.refreshing = newRefreshing;
    if (newRefreshing) {
      state.lastRefreshAttempt = DateTime.utc();
    }
  },
  [authMutations.SET_LOGIN_CODE](
    state: AuthState,
    params: {
      email: string;
      code: string;
    }
  ): void {
    state.loginCodes[params.email] = params.code;
  },
  [authMutations.CLEAR_LOGIN_CODES](state: AuthState) {
    state.loginCodes = {};
  },
};

/**
 * Attempt to refresh the auth tokens
 * Ensures that two refreshes can not run at the same time
 * @returns (void)
 * @throws RefreshFailedException
 */
// const refreshEmitter = mitt();

// const refreshTokens = async (
//   context: AuthActionContext,
//   refreshToken: string
// ): Promise<void> => {
//   // Limit to once every minute
//   if (
//     context.state.lastRefreshAttempt != null &&
//     context.state.lastRefreshAttempt.diffNow("seconds").seconds < 60
//   ) {
//     return;
//   }

//   // If already refreshing
//   if (context.state.refreshing) {
//     // Wait for current refresh to end
//     await new Promise((resolve, reject) => {
//       let to: number | null = null;
//       function onRefresh() {
//         refreshEmitter.off("refresh", onRefresh);
//         if (to != null) {
//           clearTimeout(to);
//         }
//         resolve("");
//       }
//       to = setTimeout(() => {
//         refreshEmitter.off("refresh", onRefresh);
//         reject("Waiting for refresh timed out");
//       }, 2000);
//       refreshEmitter.on("refresh", onRefresh.bind(this));
//     });
//     return;
//   }

//   context.commit(authMutations.SET_REFRESHING, true);

//   try {
//     const result = await authRepository.refresh(refreshToken);
//     const data = result.data.data;
//     // const user = result.data.data.user;
//     // context.commit(authMutations.USER, { user });
//     // context.commit(authMutations.TOKENS, {
//     //   tokenSet: {
//     //     refresh: result.data.data.refresh_token,
//     //     access: result.data.data.access_token,
//     //   },
//     // });
//     // startAuthTimer();
//     applyTokens(context, data.user, {
//       access: data.access_token,
//       refresh: data.refresh_token,
//     });
//   } catch (e) {
//     throw new RefreshFailedException();
//   } finally {
//     refreshEmitter.emit("refresh");
//     context.commit(authMutations.SET_REFRESHING, false);
//   }
// };

const actions = {
  // [authActions.OAUTH](
  //   context: AuthActionContext,
  //   params: { provider: "facebook" | "google" }
  // ): void {
  //   authRepository.oauth(params.provider);
  // },
  async [authActions.CREATE_SINGLE_USE_TOKEN](): Promise<string> {
    const response = await authRepository.createSingleUseToken();
    return response.data.data.token;
  },
  async [authActions.SESSION_FROM_SU_TOKEN](
    context: AuthActionContext,
    token: string
  ): Promise<void> {
    const response = await authRepository.createSessionFromSingleUseToken(
      token
    );
    console.log("Repsonse:", response);
    applyLoginData(context, response.data.data);
  },
  async [authActions.FETCH_ACCESS_TOKEN](
    context: AuthActionContext
  ): Promise<string> {
    if (
      context.state.lastRefresh != null &&
      context.state.tokenSet != null &&
      isTokenValid(context.state.lastRefresh, ACCESS_TOKEN_LIFE_SEC)
    ) {
      return context.state.tokenSet.access;
    }

    if (context.state.refreshing) {
      await waitUntil(() => !context.state.refreshing);
      if (
        context.state.lastRefresh != null &&
        context.state.tokenSet?.access != null &&
        isTokenValid(context.state.lastRefresh, ACCESS_TOKEN_LIFE_SEC)
      ) {
        return context.state.tokenSet.access;
      }
      console.log("Failed waiting for refresh.");
      throw new Unauthenticated();
    }

    context.commit(authMutations.SET_REFRESHING, true);

    const storedData = tokenStorage.get();
    const refreshToken =
      context.state?.tokenSet?.refresh ?? storedData?.refresh;
    let lastRefresh = context.state?.lastRefresh;
    if (!lastRefresh && storedData != null) {
      lastRefresh = DateTime.fromISO(storedData.lastRefresh);
    }

    if (
      (lastRefresh != null &&
        !isTokenValid(lastRefresh, REFRESH_TOKEN_LIFE_SEC)) ||
      refreshToken == null
    ) {
      throw new Unauthenticated();
    }

    try {
      const result = await authRepository.refresh(refreshToken);
      const loginData = result.data.data;
      applyLoginData(context, loginData);
      return loginData.access_token;
    } catch (e) {
      console.log("Unauthenticed exception: ", e);
      throw new Unauthenticated();
    } finally {
      context.state.refreshing = false;
    }
  },
  [authActions.FACEBOOK_REDIRECT](): void {
    authRepository.facebookRedirect();
  },
  [authActions.GOOGLE_REDIRECT](): void {
    authRepository.googleRedirect();
  },
  async [authActions.FACEBOOK_LOGIN](
    context: AuthActionContext,
    params: { token: string }
  ): Promise<User> {
    const result = await authRepository.facebookLogin(params.token);

    const { user, refresh_token, access_token } = result.data.data;
    applyTokens(context, user, {
      refresh: refresh_token,
      access: access_token,
    });

    return user;
  },
  async [authActions.LOGIN_WITH_REFRESH](
    context: AuthActionContext,
    params: { refresh: string }
  ): Promise<void> {
    context.state.tokenSet = { refresh: params.refresh, access: "" };
    await context.dispatch(authActions.FETCH_ACCESS_TOKEN);
  },
  async [authActions.ENSURE_INITIATED](
    context: AuthActionContext
  ): Promise<true> {
    const initiated = context.state.initiated;
    if (initiated) return true;
    return new Promise((resolve) => {
      const stopWatching = watch(
        () => context.state.initiated,
        () => {
          stopWatching();
          resolve(true);
        }
      );
    });
  },
  async [authActions.REGISTER](
    context: AuthActionContext,
    {
      email,
      password,
      firstName,
      lastName,
    }: {
      email: string;
      password: string;
      firstName: string;
      lastName: string;
    }
  ): Promise<User> {
    const result = await authRepository.register({
      email,
      password,
      first_name: firstName,
      last_name: lastName,
    });
    return result.data.data.user;
  },
  async [authActions.LOGIN](
    context: AuthActionContext,
    params: { email: string; password: string }
  ): Promise<User> {
    const result = await authRepository.login(params);

    const { user, refresh_token, access_token } = result.data.data;
    applyTokens(context, user, {
      refresh: refresh_token,
      access: access_token,
    });

    return user;
  },
  async [authActions.LOGOUT](context: AuthActionContext): Promise<void> {
    context.commit(authMutations.REMOVE_ALL);
    tokenStorage.clear();
    Sentry.configureScope((scope) => scope.setUser(null));
  },
  async [authActions.GOOGLE_LOGIN](
    context: AuthActionContext,
    params: { idToken: string }
  ): Promise<User> {
    const result = await authRepository.googleLogin(params.idToken);

    const { user, refresh_token, access_token } = result.data.data;
    applyTokens(context, user, {
      refresh: refresh_token,
      access: access_token,
    });

    return user;
  },
  async [authActions.APPLE_LOGIN](
    context: AuthActionContext,
    params: {
      idToken: string;
      firstName: string;
      lastName: string;
    }
  ): Promise<
    | { state: "logged_in" }
    | { state: "more_info"; token: string; email: string }
  > {
    const result = await authRepository.appleLogin(
      params.idToken,
      params.firstName,
      params.lastName
    );
    const data = result.data.data;

    if ("state" in data) {
      return data;
    }

    applyTokens(context, data.user, {
      refresh: data.refresh_token,
      access: data.access_token,
    });

    return { state: "logged_in" };
  },
  async [authActions.CREATE_PASS_RESET](
    context: AuthActionContext,
    params: { email: string }
  ): Promise<boolean> {
    const response = await authRepository.createPasswordReset(params.email);
    return response.data.data.success;
  },
  async [authActions.GET_PASS_RESET](
    context: AuthActionContext,
    params: { token: string }
  ): Promise<boolean> {
    const response = await authRepository.getPasswordReset(params.token);
    return response.data.data.valid;
  },
  async [authActions.DELETE_PASS_RESET](
    context: AuthActionContext,
    params: { token: string; newPassword: string }
  ): Promise<boolean> {
    const response = await authRepository.deletePasswordReset(
      params.token,
      params.newPassword
    );
    return response.data.data.success;
  },
  async [authActions.FINISH_REGISTRATION](
    context: AuthActionContext,
    params: {
      token: string;
      user: {
        email: string;
        // password: string;
        first_name: string;
        last_name: string;
      };
    }
  ): Promise<User> {
    const result = await authRepository.registerWithToken(
      params.token,
      params.user
    );

    return applyLoginData(context, result.data.data);
    // const user = result.data.data.user;
    // context.commit(authMutations.USER, { user });
    // context.commit(authMutations.TOKENS, {
    //   tokenSet: {
    //     refresh: result.data.data.refresh_token,
    //     access: result.data.data.access_token,
    //   },
    // });
    // localStorage.setItem(
    //   LOCALSTORAGE_KEY,
    //   JSON.stringify({
    //     refresh: result.data.data.refresh_token,
    //   })
    // );
    //
    // return user;
  },
  async [authActions.SEND_EMAIL_LOGIN](
    context: AuthActionContext,
    email: string
  ): Promise<void> {
    await authRepository.sendLoginCode(email);
  },
  async [authActions.VERIFY_CODE](
    context: AuthActionContext,
    params: { code: string; email: string }
  ): Promise<VerifyLoginCodeResponse> {
    const result = await authRepository.verifyLoginCode(
      params.code,
      params.email
    );
    return result.data.data;
  },
  async [authActions.LOGIN_WITH_CODE](
    context: AuthActionContext,
    params: { code: string; email: string }
  ): Promise<User> {
    const result = await authRepository.loginWithLoginCode(
      params.code,
      params.email
    );
    const { user, refresh_token, access_token } = result.data.data;
    applyTokens(context, user, {
      refresh: refresh_token,
      access: access_token,
    });
    return result.data.data.user;
  },
  async [authActions.REGISTER_WITH_LOGIN_CODE](
    context: AuthActionContext,
    params: {
      code: string;
      firstName: string;
      lastName: string;
      email: string;
    }
  ): Promise<User> {
    const result = await authRepository.registerWithLoginCode(
      params.code,
      params.firstName,
      params.lastName,
      params.email
    );
    const { user, refresh_token, access_token } = result.data.data;
    applyTokens(context, user, {
      refresh: refresh_token,
      access: access_token,
    });
    context.commit(authMutations.CLEAR_LOGIN_CODES);
    return result.data.data.user;
  },
};

function applyLoginData(
  context: AuthActionContext,
  loginData: {
    access_token: string;
    refresh_token: string;
    user: User;
  }
) {
  return applyTokens(context, loginData.user, {
    access: loginData.access_token,
    refresh: loginData.refresh_token,
  });
}

function applyTokens(
  context: AuthActionContext,
  user: User,
  tokenSet: TokenSet
) {
  setUser({
    id: String(user.id),
    email: user.email,
  });

  context.commit(authMutations.USER, { user });
  context.commit(authMutations.TOKENS, {
    tokenSet: {
      refresh: tokenSet.refresh,
      access: tokenSet.access,
    },
  });

  tokenStorage.set({
    lastRefresh: DateTime.utc().toISO(),
    refresh: tokenSet.refresh,
  });

  // TODO: move to separate function
  console.log("New tokens generated, ensuring connected");
  appSocket.ensureConnected();

  startAuthTimer();

  return user;
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
