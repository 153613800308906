export default {
  "ordinal": {
    "count": [
      1,
      2,
      3,
      4,
      5
    ],
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1e"])},
    "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "e"])}
  },
  "months": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["januari"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["februari"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maart"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["april"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mei"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["juni"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["juli"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["augustus"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["september"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oktober"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["november"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["december"])}
  },
  "auth": {
    "roles": {
      "resident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mede-eigenaar"])},
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheerder"])}
    },
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "login_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
    "re_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herhaal wachtwoord"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw wachtwoord"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren"])},
    "no_account?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog geen account?"])},
    "forgot_password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord vergeten?"])},
    "bad_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email en/of wachtwoord verkeerd"])},
    "register_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registratie was succesvol."])},
    "already_registered?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft u al een account?"])},
    "invalid_password_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige wachtwoord herstel link"])},
    "reset_email_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als een account met dit email bestaat, zal u een email ontvangen met een reset link."])},
    "unknown_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit email adres is niet bekend in onze databank"])},
    "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord herstellen"])},
    "didnt_forget_password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord niet vergeten?"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verander wachtwoord"])},
    "new_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw account"])},
    "login_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in met"])}
  },
  "chat": {
    "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "my_chats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn Chats"])},
    "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepen"])},
    "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personen"])},
    "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze chat heeft nog geen berichten"])},
    "no_messages_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog geen berichten"])},
    "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schrijf een bericht.."])},
    "find_convo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat zoeken"])}
  },
  "property": {
    "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residentie"])},
    "select_a_residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een residentie"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residentie info"])},
    "you_were_invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent uitgenodigd!"])},
    "you_were_invited...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent uitgenodigd om deel te nemen aan de residentie"])},
    "my_residences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn residenties"])},
    "many": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residenties"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residentie selecteren"])},
    "create_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Residentie aanmaken"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe Residentie"])},
    "name_residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam residentie"])},
    "association": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereniging van Mede-Eigenaars"])},
    "floor_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal verdiepen (zonder kelder en inclusief gelijksvloers)"])},
    "allow_financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow insights into financial data"])},
    "residence_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residentie details"])},
    "resident_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal mede-eigenaars"])},
    "base_deed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basisakte"])},
    "base_deed_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen basisakte opgeladen (vraag hiervoor aan de beheerder)"])},
    "upload_new_base_deed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe Basisakte opladen"])},
    "rules_internal_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reglement van interne orde"])},
    "no_rio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen RIO opgeladen (vraag hiervoor aan de beheerder)"])},
    "upload_new_rio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe RIO Opladen"])},
    "issues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldingen"])},
    "recent_issues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recente meldingen"])},
    "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boekhouding"])},
    "residents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mede-eigenaars"])},
    "resident_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residentie Administratie"])},
    "is_vme?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is een VME?"])}
  },
  "lots": {
    "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kavel"])},
    "many": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kavels"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw Pand"])}
  },
  "residents": {
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitnodigen"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mede-eigenaar toevoegen"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mede-eigenaar aanpassen"])},
    "send_email_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstuur email uitnodiging"])},
    "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mede-eigenaar"])},
    "many": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mede-eigenaars"])}
  },
  "fin": {
    "doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
    "new_doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw document"])},
    "add_doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document toevoegen"])},
    "doc_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal documenten"])},
    "total_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale uitgave"])},
    "recent_docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recente documenten"])}
  },
  "issues": {
    "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melding"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe melding"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melding aanpassen"])},
    "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesloten meldingen"])},
    "title_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamp tweede verdieping stuk"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie"])},
    "select_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer locatie"])},
    "select_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer type"])},
    "types": {
      "damage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schade"])},
      "elec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektrisch"])},
      "humid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vocht"])},
      "dirt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuil"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])}
    },
    "description_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze morgen merkte ik.."])},
    "mark_not_in_progres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maarkeer als niet in behandeling"])},
    "mark_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markeer als niet opgelost"])},
    "mark_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melding sluiten (archiveer)"])},
    "mark_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markeer als in behandeling"])},
    "see_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk gesloten meldingen"])},
    "recent_issues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recente meldingen"])}
  },
  "accounting": {
    "contribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijdrage"])},
    "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
    "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingen"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturen"])},
    "live_transaction_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importeer transacties"])},
    "live_transaction_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij importeren automatisch de transacties vanuit uw gedeelde bankrekening"])},
    "add_invoices_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturen toevoegen"])},
    "add_invoices_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload uw aankoopfacturen en link ze aan uw bank transacties"])},
    "resident_payment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mede-eigenaar betalingen"])},
    "resident_payment_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mede-eigenaars krijgen automatisch te weten hoeveel ze moeten betalen"])},
    "add_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankrekening toevoegen"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent zelf eindverantwoordelijke voor de correctheid van uw boekhouding."])},
    "select_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank selecteren"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banktransacties"])},
    "types": {
      "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkomend"])},
      "out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitgaand"])},
      "both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beide"])}
    },
    "incomplete_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onvolledige transactie"])},
    "incomplete_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onvolledige transacties"])},
    "transactions_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog geen bank transacties, toekomstige verrichtingen zullen hier zichtbaar worden."])},
    "invoice_delete": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bent u zeker dat u dit factuur wilt verwijderen?"])},
      "confirmText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, verwijder"])},
      "declineText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neen"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze actie kan niet ongedaan gemaakt worden"])}
    },
    "upload_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload uw factuur"])},
    "INCOMPLETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ONVOLLEDIG"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "add_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie toevoegen"])},
    "when_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanneer krijgen de mede-eigenaars hun afrekening voor de gemeenschappelijke kosten?"])},
    "when_payment_options": {
      "each_quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elk kwartaal"])},
      "each_semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elk semester"])},
      "each": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elke"])},
      "each_singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maand"])},
      "each_plural_suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])}
    },
    "extra_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra info"])},
    "show_extra_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show extra info"])},
    "add_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factuur toevoegen"])},
    "invoice_describe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waar gaat dit factuur over?"])},
    "invoice_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factuur vrije beschrijving"])},
    "invoice_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal op factuur (euro incl. BTW)"])},
    "invoice_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factuur bestand (pdf of foto)"])},
    "invoice_use_existing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestaand factuur gebruiken"])}
  },
  "common": {
    "comingsoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komt binnenkort"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen"])},
    "extra_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra info"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende"])},
    "take_a_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem een foto"])},
    "choose_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een bestand"])},
    "searchdots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken..."])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepteer"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiger"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
    "picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto"])},
    "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto's"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "amount_cur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag"])},
    "add_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestand toevoegen"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijving"])},
    "short_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korte beschrijving"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])},
    "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email adres"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "phone_nr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmaken"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versturen"])},
    "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appartement"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straat"])},
    "street_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijnstraat"])},
    "street_nr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaats"])},
    "city_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn stad"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "inspect_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk details"])},
    "basement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelder"])},
    "parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking"])},
    "ground_floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelijksvloers"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheerder"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel"])},
    "my_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn profiel"])},
    "choose_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies foto"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])},
    "invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitnodigingen"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leeg"])}
  },
  "upload": {
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestand uploaden"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oeps, upload mislukt."])},
    "release": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laat maar los :)"])},
    "click_or_drag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik of sleep uw bestand"])},
    "click_to_choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik hier om een bestand te kiezen"])}
  },
  "error": {
    "field_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veld is ongeldig"])}
  },
  "legal": {
    "by_registering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door te registreren aanvaardt u onze"])},
    "by_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door in te loggen aanvaardt u onze"])},
    "terms_of_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of use"])}
  },
  "user": {
    "show_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon email aan andere residenten"])}
  }
}