import { RouteRecordRaw } from "vue-router";
import EnterEmailForm from "./pages/emaillogin/EnterEmailForm.vue";
import EnterCodeForm from "./pages/emaillogin/EnterCodeForm.vue";
import RegisterCodeForm from "./pages/emaillogin/RegisterCodeForm.vue";

export const routes: RouteRecordRaw[] = [
  {
    path: "/auth/emaillogin/enteremail",
    name: "auth.emaillogin.enteremail",
    component: EnterEmailForm,
    meta: {
      requireAuth: false,
      layout: "Empty",
    },
  },
  {
    path: "/auth/emaillogin/verify",
    name: "auth.emaillogin.verify",
    component: EnterCodeForm,
    meta: {
      requireAuth: false,
      layout: "Empty",
    },
  },
  {
    path: "/auth/emaillogin/register",
    name: "auth.emaillogin.register",
    component: RegisterCodeForm,
    meta: {
      requireAuth: false,
      layout: "Empty",
    },
  },
];
