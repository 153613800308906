<template>
  <!-- 
    Add month dividers (based on term)
   -->
  <div class="list-invoices">
    <page-loader v-if="loading" />
    <!-- <container thinner width="90%" v-else> -->
    <div class="top"></div>
    <filter-tags v-if="unpaidInvoices.length > 0">
      <filter-tag
        @click="filters.unpaid == true"
        :count="unpaidInvoices.length"
        plural="Onbetaalde facturen"
        singular="Onbetaald factuur"
      />
    </filter-tags>
    <div class="invoices">
      <invoice
        v-for="invoice in filteredInvoices"
        :key="invoice.id"
        :invoice="invoice"
      />
      <div class="empty-state" v-if="invoices.length === 0">
        <div>Nog geen facturen.</div>
        <br />
        <div>
          U kan facturen toevoegen door op en transactie in het "Bank" tabblad
          te klikken.
        </div>
      </div>
    </div>
    <!-- </container> -->
  </div>
</template>

<script lang="ts">
import { useActivePropertyInvoices } from "@/store/accounting/hooks";
import { computed, defineComponent, reactive } from "vue";
import Invoice from "../../components/invoices/Invoice.vue";
import FilterTags from "@/components/filtertags/FilterTags.vue";
import FilterTag from "@/components/filtertags/FilterTag.vue";
import { isInvoiceFinished } from "@/store/accounting/util";
import { notF } from "@/util/functional";

export default defineComponent({
  components: { Invoice, FilterTags, FilterTag },
  setup() {
    const { loading, invoices } = useActivePropertyInvoices();
    const filters = reactive({
      unpaid: false,
    });

    const unpaidInvoices = computed(() =>
      invoices.value.filter(notF(isInvoiceFinished))
    );

    const filteredInvoices = computed(() => {
      let result = invoices.value;
      if (filters.unpaid) {
        result = result.filter(notF(isInvoiceFinished));
      }
      return result;
    });

    return {
      invoices,
      filters,
      filteredInvoices,
      unpaidInvoices,
      loading,
    };
  },
});
</script>

<style lang="scss" scoped>
.top {
  margin-top: 20px;
}

.empty-state {
  text-align: center;
  line-height: 130%;
  font-size: 18px;
  padding-top: 100px;
  opacity: 0.7;
}
</style>
