import { App } from "@vue/runtime-core";

import AppButton from "@/components/common/AppButton.vue";
import AppLink from "@/components/common/AppLink.vue";
import Textbox from "@/components/common/Textbox.vue";
import AppLabel from "@/components/common/AppLabel.vue";
import GenericHeader from "@/components/common/generic-header/GenericHeader.vue";
import HeaderButton from "@/components/common/generic-header/HeaderButton.vue";
import FlashMessage from "@/components/common/FlashMessage.vue";
import Switch from "@/components/common/Switch.vue";
import PageLoader from "@/components/common/PageLoader.vue";
import AppTextarea from "@/components/common/AppTextarea.vue";
import Dropdown from "@/components/common/Dropdown.vue";
import Container from "@/components/common/Container.vue";
import Card from "@/components/common/Card.vue";
import Flex from "@/components/common/Flex.vue";
import InfoTooltip from "@/components/common/InfoTooltip.vue";
import Overview from "@/components/common/overview/Overview.vue";
import Radio from "@/components/common/radio/Radio.vue";
import GenericPage from "@/components/common/generic-page/GenericPage.vue";
import GenericPagePopup from "@/components/common/generic-page/GenericPagePopup.vue";
import DropButton from "@/components/common/DropButton.vue";
import Info from "@/components/common/info-icon/Info.vue";
import DatePicker from "@/components/common/DatePicker.vue";
import TimePicker from "@/components/common/TimePicker.vue";
// import DateTimePicker from "@/components/common/DateTimePicker.vue";

export default (app: App): void => {
  app.component("app-button", AppButton);
  app.component("app-link", AppLink);
  app.component("textbox", Textbox);
  app.component("app-label", AppLabel);
  app.component("generic-header", GenericHeader);
  app.component("generic-header-button", HeaderButton);
  app.component("flash-message", FlashMessage);
  app.component("app-switch", Switch);
  app.component("page-loader", PageLoader);
  app.component("app-textarea", AppTextarea);
  app.component("dropdown", Dropdown);
  app.component("container", Container);
  app.component("card", Card);
  app.component("flex", Flex);
  app.component("info-tooltip", InfoTooltip);
  app.component("overview", Overview);
  app.component("radio", Radio);
  app.component("generic-page", GenericPage);
  app.component("generic-page-popup", GenericPagePopup);
  app.component("drop-button", DropButton);
  app.component("info", Info);
  app.component("date-picker", DatePicker);
  app.component("time-picker", TimePicker);
  // app.component("date-time-picker", DateTimePicker);
};
