<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 32 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 10L16 2L31 10"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M25 20C26.6569 20 28 18.6569 28 17C28 15.3431 26.6569 14 25 14C23.3431 14 22 15.3431 22 17C22 18.6569 23.3431 20 25 20Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M7 20C8.65685 20 10 18.6569 10 17C10 15.3431 8.65685 14 7 14C5.34315 14 4 15.3431 4 17C4 18.6569 5.34315 20 7 20Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M13 27V26.017C13 25.307 12.628 24.654 12.017 24.292C11.044 23.717 9.358 23 7 23C4.612 23 2.936 23.713 1.974 24.288C1.368 24.65 1 25.301 1 26.007V27H13Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M31 27V26.017C31 25.307 30.628 24.654 30.017 24.292C29.044 23.717 27.358 23 25 23C22.612 23 20.936 23.713 19.974 24.288C19.368 24.65 19 25.301 19 26.007V27H31Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    width: {
      type: String,
      default: "32",
    },
    height: {
      type: String,
      default: "28",
    },
  },
});
</script>
