<template>
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.96 18.9982V9.99817"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M7.95996 13.9982L11.96 9.99817L15.96 13.9982"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M15.9598 18.7751C17.5743 18.4073 19.0561 17.6011 20.242 16.4454C21.4278 15.2897 22.2719 13.8292 22.6812 12.2247C23.0905 10.6202 23.0493 8.93392 22.562 7.35136C22.0746 5.7688 21.1602 4.35136 19.9192 3.25504C18.6782 2.15872 17.1588 1.42602 15.5282 1.13759C13.8976 0.849155 12.2191 1.01616 10.6773 1.62024C9.13553 2.22431 7.79029 3.24202 6.78965 4.56138C5.78901 5.88073 5.17175 7.45057 5.00582 9.09813C3.79438 9.34392 2.71693 10.0297 1.98142 11.0232C1.24591 12.0167 0.904497 13.2475 1.02307 14.4779C1.14163 15.7083 1.71178 16.8512 2.62346 17.686C3.53515 18.5207 4.72374 18.9882 5.95982 18.9981H7.95982"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
  </svg>
</template>
