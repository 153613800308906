import { Issue } from "@/repositories/issueRepository";

// export function useIssuesForProperty(propertyId: number): Ref<Issue[]> {
//   const store = useStore();

//   const issues = ref([] as Issue[]);

//   // TODO: Cache issues and send websocket update on change
//   store.dispatch(inIssues(issueActions.LIST)).then((values) => {
//     issues.value = values.filter((i: Issue) => i.property_id === propertyId);
//   });

//   return issues;
// }

export function issueStatusText(issueStatus: string): string {
  switch (issueStatus.toLowerCase()) {
    case "open":
      return "open";
    case "in_progress":
      return "In behandeling";
    case "resolved":
      return "Opgelost";
    default:
      return "error";
  }
}

export function issueLocationText(issueLocation: string): string {
  return issueLocation;
}

export function issueTypetext(issueType: string): string {
  return issueType;
}

export function issueTags(issue: Issue): string[] {
  const tags: string[] = [];

  // tags.push(issueStatusText(issue.status));
  // if (issue.location) {
  //   tags.push(issueLocationText(issue.location));
  // }
  if (issue.type) {
    tags.push(issueTypetext(issue.type));
  }
  if (issue.status === "in_progress") {
    tags.push("In behandeling");
  }
  if (issue.status === "resolved") {
    tags.push("Gearchiveerd");
  }
  if (issue.urgent) {
    tags.push("Dringend");
  }
  if (issue.private) {
    tags.push("Privé");
  }

  return tags;
}
