
import { FileItem } from "@/repositories/fileRepository";
import { computed, defineComponent, PropType, ref } from "vue";
import { DateTime } from "luxon";
import FileIcon from "@/components/icons/File.vue";
import PdfFileIcon from "@/components/icons/PdfFile.vue";
import { Browser } from "@capacitor/browser";
import Loader from "@/components/icons/Loader.vue";

export default defineComponent({
  emits: ["new-file"],
  components: { Loader, FileIcon, PdfFileIcon },
  props: {
    files: {
      type: Array as PropType<FileItem[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const fileRef = ref(null as null | HTMLInputElement);

    const currentFile = computed(() => {
      if (props.files.length === 0) return null;
      console.log(props.files);
      const sortedFiles = [...props.files].sort((a, b) => {
        const dateA = DateTime.fromISO(a.inserted_at);
        const dateB = DateTime.fromISO(b.inserted_at);
        return dateB.toMillis() - dateA.toMillis();
      });
      return sortedFiles[0];
    });

    return {
      fileRef,
      currentFile,
      uploadNew() {
        fileRef.value?.click();
      },
      async onFileChange() {
        const files = fileRef.value?.files;
        if (files == null) return;
        if (files.length === 0) return;
        emit("new-file", files[0]);
      },
      openFile() {
        const file = currentFile.value;
        if (file == null) return;
        Browser.open({
          url: file.url,
        });
      },
    };
  },
});
