<template>
  <div
    class="chat-message"
    ref="messageEl"
    :class="{ reverse: author.id === currentUser?.id }"
  >
    <div
      v-if="author.avatar_url != null"
      class="sender-image"
      :style="{ 'background-image': `url('${author.avatar_url}')` }"
    ></div>
    <div class="sender-image" v-else>
      <div class="empty-wrapper">
        <empty-profile-icon class="empty" />
      </div>
    </div>
    <div class="content">
      <header class="content-header">
        <div class="name" v-if="author.id !== currentUser?.id">
          {{ author.first_name }} {{ author.last_name }}
        </div>
        <div class="name" v-else>Me</div>
        <div class="sent-at">{{ timeAgo(message) }}</div>
      </header>
      <div class="text" v-if="message.type === 'text' || message.type == null">
        {{ message.text }}
      </div>
      <div class="issue" v-if="message.type === 'issue'">
        <issue-chat-item :issue-id="message.text" />
      </div>
      <div class="document" v-if="message.type === 'document'">
        <document-chat-item :document-id="message.text" />
      </div>
      <div class="image" v-if="message.type === 'image'">
        <image-chat-item :image-id="message.text" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watchEffect } from "vue";
import EmptyProfileIcon from "@/components/icons/EmptyProfile.vue";
import { useStore } from "vuex";
import { authGetters, inAuth } from "@/store/auth";
import { Message } from "@/types";
import { DateTime } from "luxon";
import IssueChatItem from "./IssueChatItem.vue";
import DocumentChatItem from "./DocumentChatItem.vue";
import ImageChatItem from "./ImageChatItem.vue";

export default defineComponent({
  components: {
    EmptyProfileIcon,
    IssueChatItem,
    DocumentChatItem,
    ImageChatItem,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    message: {
      type: Object,
      required: true,
    },
    author: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const messageEl = ref(null as null | HTMLDivElement);
    const currentUser = computed(() => store.getters[inAuth(authGetters.USER)]);

    const timeAgo = (message: Message) => {
      const insertedAt = DateTime.fromISO(message.inserted_at, {
        zone: "utc",
      }).setZone(DateTime.local().zoneName);

      const now = DateTime.now();
      if (insertedAt.year !== now.year) {
        return insertedAt.toFormat("dd/LL/yyyy");
      }
      if (insertedAt.day !== now.day) {
        return insertedAt.toFormat("dd/LL");
      }

      return insertedAt.toFormat("hh:mm");
    };

    const isActive = computed(() => props.isActive);

    watchEffect(() => {
      if (isActive.value && messageEl.value != null) {
        messageEl.value.scrollIntoView();
      }
    });

    return {
      messageEl,
      currentUser,
      timeAgo,
    };
  },
});
</script>

<style lang="scss" scoped>
.chat-message {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin-top: 30px;
  box-sizing: border-box;
}
.reverse.chat-message {
  flex-direction: row-reverse;
}

.sender-image {
  width: 40px;
  height: 40px;
  // background-color: red;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-right: 15px;
  flex-shrink: 0;
  box-sizing: border-box;
}
.reverse .sender-image {
  margin-right: 0;
  margin-left: 15px;
}

.content {
  display: flex;
  flex-flow: column nowrap;
}

.content-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 5px;
}
.reverse .content-header {
  flex-flow: row-reverse;
}

.name {
  font-family: $font-title;
  font-size: 0.875rem;
  color: $text-alt;
  font-weight: 600;
  line-height: 1.2;
  margin-right: 10px;
}
.reverse .name {
  margin-right: 0;
  margin-left: 10px;
}

.sent-at {
  font-size: 0.8125rem;
  color: $text-gray;
  font-family: $font-text;
  letter-spacing: 0.03rem;
  line-height: 1.2;
}

.text {
  font-size: 0.875rem;
  color: $text-alt;
  line-height: 1.4;
}
.reverse .text {
  text-align: right;
}

.empty-wrapper {
  font-size: 10px;
  background-color: $text-alt;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.empty {
  width: 35px;
  height: 35px;
  color: $bg-accent;
  padding-top: 10px;
}
</style>
