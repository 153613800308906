
import { computed, defineComponent, PropType } from "vue";
import { useSwiper } from "swiper/vue/swiper-vue";
import LeftIcon from "@/components/icons/AngleLeft.vue";
import RightIcon from "@/components/icons/AngleRight.vue";
import TimesIcon from "@/components/icons/Times.vue";
import DownloadIcon from "@/components/icons/Download.vue";
import { isMobilePlatform } from "@/util/platform";
import { Browser } from "@capacitor/browser";

export default defineComponent({
  emits: ["exit"],
  components: { LeftIcon, RightIcon, TimesIcon, DownloadIcon },
  props: {
    images: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const swiper = useSwiper();
    const slideCount = computed(() => swiper.value.slides.length);
    const activeIndex = computed(() => swiper.value.activeIndex);
    const hasNext = computed(
      () => slideCount.value > 1 && activeIndex.value < slideCount.value - 1
    );
    const hasPrev = computed(
      () => slideCount.value > 1 && activeIndex.value > 0
    );
    return {
      activeIndex,
      slideCount,
      hasNext,
      hasPrev,
      next() {
        console.log("Next");
        swiper.value.slideNext();
      },
      prev() {
        console.log("Prev");
        swiper.value.slidePrev();
      },
      goToIndex(index: number) {
        swiper.value.slideTo(index);
      },
      toDownload() {
        const activeImage = props.images[activeIndex.value];
        if (isMobilePlatform()) {
          Browser.open({
            url: activeImage,
          });
        } else {
          window.open(activeImage, "_blank");
        }
        emit("exit");
      },
    };
  },
});
