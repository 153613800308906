<template>
  <generic-page :loading="user == null" :title="$t('common.my_profile')">
    <div class="wrapper">
      <generated-form
        :fieldSettings="fieldSettings"
        :fieldValues="user"
        :submitHandler="handleSubmit"
        :submitText="$t('common.save')"
      />
    </div>
    <div class="logout-wrapper">
      <app-button width="100%" danger @click="logout">{{
        $t("auth.logout")
      }}</app-button>
    </div>
    <div class="language-wrapper">
      <dropdown
        placeholder="Language"
        label="Language"
        :options="{ nl: 'Nederlands', en: 'English' }"
        :modelValue="language"
        @update:modelValue="setLanguage"
      />
    </div>
    <div class="version">v{{ version }}</div>
    <div class="delete-me">
      <app-link @click="deleteMe">Delete this account</app-link>
    </div>
  </generic-page>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watchEffect } from "vue";
import { User } from "@/types";
import { useStore } from "vuex";
import GeneratedForm, {
  FieldsSettings,
} from "@/components/form/GeneratedForm.vue";
import { required, email } from "@vuelidate/validators";
import { inUsers, usersActions, usersGetters } from "@/store/users";
import { useLogout } from "@/util/auth";
import { version } from "../../../package.json";
import { useI18n } from "vue-i18n";
import { authActions, inAuth } from "@/store/auth";
import userRepository from "@/repositories/userRepository";
import { showConfirmPopup } from "@/components/popups/Confirm.vue";
import { showInfoPopup } from "@/components/common/info-icon/InfoPopup.vue";

export default defineComponent({
  components: {
    GeneratedForm,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    async function uploadProfileImage(file: File) {
      const user: User = await store.dispatch(
        inUsers(usersActions.UPLOAD_PROFILE_PICTURE),
        { file }
      );
      store.dispatch(inAuth(authActions.FETCH_ACCESS_TOKEN));
      return user.avatar_url;
    }

    const fieldSettings: FieldsSettings = [
      {
        name: "avatar_url",
        type: "profile-image",
        label: t("common.choose_picture"),
        meta: {
          uploadFunction: uploadProfileImage,
        },
      },
      { name: "ruler", type: "hr", label: "" },
      {
        name: "first_name",
        type: "string",
        label: t("common.first_name"),
        meta: { placeholder: t("common.first_name") },
        rules: { required },
      },
      {
        name: "last_name",
        type: "string",
        label: t("common.last_name"),
        meta: { placeholder: t("common.last_name") },
        rules: { required },
      },
      {
        name: "email",
        type: "string",
        label: t("common.email_address"),
        meta: {
          placeholder: t("common.email_address"),
          type: "email",
        },
        rules: { required, email },
        subCheckbox: {
          name: "email_is_public",
          label: t("user.show_email"),
        },
      },
      {
        name: "tel",
        type: "string",
        label: t("common.phone_nr"),
        meta: { placeholder: t("common.phone_nr") },
        rules: { required },
      },
    ];

    const user = ref(null as null | User);
    store.dispatch(inUsers(usersActions.ME)).then((value) => {
      user.value = value;
    });

    watchEffect(() => console.log(user.value));

    const doLogout = useLogout();

    const language = computed(
      () => store.getters[inUsers(usersGetters.LANGUAGE)]
    );

    return {
      version,
      user,
      fieldSettings,
      language,
      async handleSubmit(changes: any) {
        console.log("Changes:", changes);
        const newUser = await store.dispatch(inUsers(usersActions.UPDATE), {
          updates: changes,
        });
        user.value = newUser;
      },
      async logout() {
        doLogout();
        // await logout();
        // appSocket.logout();
        // await store.dispatch(inAuth(authActions.LOGOUT));
        // router.push({
        //   name: "login",
        // });
      },
      setLanguage(language: string) {
        store.dispatch(inUsers(usersActions.SET_LANGUAGE), { language });
      },
      async deleteMe() {
        const confirmed = await showConfirmPopup({
          title: "Het hele account verwijderen?",
          subTitle: "Deze actie is onomkeerbaar en zal al uw data verwijderen",
          confirmText: "Delete me",
          declineText: "No, stop!",
        });
        if (!confirmed) return;
        userRepository.deleteMe();
        doLogout();
        showInfoPopup("Gerbuiker verwijderd", "De gebruiker is verwijderd");
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.ruler {
  margin-bottom: 30px;
}

.logout-wrapper {
  width: 100%;
  padding: 30px 0px 0;
  box-sizing: border-box;
  border-top: 1px solid $border-general;

  /*@include screen-from("tablet") {
    display: none;
  }*/
}

.version {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 50px;
  opacity: 0.4;
}

.language-wrapper {
  width: 100%;
  padding: 30px 0;
  box-sizing: border-box;
  border-top: 1px solid $border-general;
  margin-top: 30px;

  @include screen-from("tablet") {
    padding: 40px 0 40px;
  }
}

.delete-me {
  text-align: center;
  margin-top: 50px;
}
</style>
