import { RouteRecordRaw } from "vue-router";
import FinanceUserAgreement from "./pages/setup/FinanceUserAgreement.vue";
import LinkBank from "./pages/setup/LinkBank.vue";
import FinanceLayout from "./components/FinanceLayout.vue";
import FinanceOverview from "./pages/FinanceOverview.vue";
import Shares from "./pages/shares/Shares.vue";
import ViewShare from "./pages/shares/ViewShare.vue";
import Invoices from "./pages/invoices/Invoices.vue";

export const routes: RouteRecordRaw[] = [
  {
    path: "/finance/agreement",
    name: "finance.agreement",
    component: FinanceUserAgreement,
  },
  {
    path: "/finance/bank/link",
    name: "finance.bank.link",
    component: LinkBank,
  },
  {
    path: "/finance",
    component: FinanceLayout,
    children: [
      {
        path: "overview",
        name: "finance.overview",
        component: FinanceOverview,
      },
      {
        path: "shares",
        name: "finance.shares.list",
        component: Shares,
      },
      {
        path: "shares/:id",
        name: "finance.shares.view",
        component: ViewShare,
      },
      {
        path: "invoices",
        name: "finance.invoices.list",
        component: Invoices,
      },
    ],
  },
];
