<template>
  <app-label v-if="label">{{ label }}</app-label>
  <div class="dropdown" :class="{ thinner }" ref="clickOutsideRef">
    <div class="selected" @click="toggle" ref="selectedItemRef">
      <div class="selected-text">
        <div class="placeholder" v-if="modelValue == null">
          {{ placeholder }}
        </div>
        <template v-else>
          {{ options[modelValue] }}
        </template>
      </div>
      <div class="drop-icon">
        <angle-down-icon />
      </div>
    </div>
    <div
      class="items"
      :class="{ open, top: dropdownPosition === 'top' }"
      :style="{ 'max-height': `${dropperHeightLimit}px` }"
    >
      <template v-for="optionKey in Object.keys(options)" :key="optionKey">
        <div
          class="item"
          v-if="optionKey != modelValue"
          @click="select(optionKey)"
        >
          {{ options[optionKey] }}
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import AngleDownIcon from "@/components/icons/AngleDown.vue";
import { useOnClickOutside } from "@/util/click";
import { debounce } from "debounce";

export default defineComponent({
  components: { AngleDownIcon },
  props: {
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: String,
      default: null,
    },
    thinner: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const open = ref(false as boolean);

    function hide() {
      open.value = false;
    }

    const clickOutsideRef = useOnClickOutside(() => (open.value = false));

    const ITEM_HEIGHT_PX = 45;
    const MAX_HEIGHT_PX = 300;
    const selectedItemRef = ref(null as null | HTMLDivElement);
    const optionCount = computed(() => Object.keys(props.options).length);

    const dropdownPosition = ref("bottom" as "bottom" | "top");
    const dropperHeightLimit = ref(300);
    const calcDropdownPosition = debounce(() => {
      let ref = selectedItemRef.value;
      if (ref == null) return;

      const height = Math.min(
        optionCount.value * ITEM_HEIGHT_PX,
        MAX_HEIGHT_PX
      );
      const pageHeight =
        window.innerHeight ||
        document.body.clientHeight ||
        selectedItemRef.value?.clientHeight;

      if (pageHeight == null) return;

      const boundingBox = ref.getBoundingClientRect();
      const distanceToTopOfScreen = boundingBox.top;
      const distanceToBottomOfScreen = pageHeight - boundingBox.bottom;

      if (distanceToTopOfScreen < distanceToBottomOfScreen) {
        dropperHeightLimit.value = Math.min(300, distanceToBottomOfScreen);
        dropdownPosition.value = "bottom";
        return;
      }

      if (distanceToBottomOfScreen < height) {
        dropperHeightLimit.value = Math.min(300, distanceToTopOfScreen - 50);
        dropdownPosition.value = "top";
      } else {
        dropperHeightLimit.value = Math.min(300, distanceToBottomOfScreen - 50);
        dropdownPosition.value = "bottom";
      }
    }, 100);

    watch([selectedItemRef, optionCount], calcDropdownPosition);
    onMounted(() => {
      window.addEventListener("scroll", calcDropdownPosition, true);
      window.addEventListener("resize", calcDropdownPosition);
      calcDropdownPosition();
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", calcDropdownPosition);
      window.removeEventListener("resize", calcDropdownPosition);
    });

    return {
      dropdownPosition,
      dropperHeightLimit,
      selectedItemRef,
      clickOutsideRef,
      dropdownEl: useOnClickOutside(() => {
        open.value = false;
      }),
      open,
      show() {
        open.value = true;
      },
      hide,
      toggle() {
        open.value = !open.value;
      },
      select(optionKey: string) {
        emit("update:modelValue", optionKey);
        hide();
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.dropdown {
  width: 100%;
  position: relative;
}

.selected {
  background-color: white;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid $border-general;
  cursor: pointer;
  z-index: 5;
  user-select: none;
}
.dropdown.thinner .selected {
  padding: 15px;
}

.drop-icon {
  width: 10px;
  height: 13px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  padding-bottom: 3px;
}

.items {
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
  background-color: white;
  border: 1px solid $border-general;
  // padding-top: 5px;
  box-sizing: border-box;
  margin-top: -5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: $shadow-general;
  // display: none;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-5px);
  transition: all 0.1s ease-out;
  position: absolute;
  z-index: 6;

  &.open {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: all 0.1s ease-in;
  }

  &.top {
    bottom: 100%;
    display: flex;
    flex-flow: column-reverse nowrap;
  }
}

.item {
  width: 100%;
  box-sizing: border-box;
  padding: 14px 20px;
  border-bottom: 1px solid $border-general;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: $bg-accent;
    cursor: pointer;
  }
}
.items.top .item {
  &:last-child {
    border-bottom: 1px solid $border-general;
  }
  &:first-child {
    border-bottom: none;
  }
}
.dropdown.thinner .item {
  padding: 10px 16px;
}

.placeholder {
  color: $text-gray;
}
</style>
