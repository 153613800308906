<template>
  <div class="textbox">
    <app-label :for-id="id" v-if="label">{{ label }}</app-label>
    <textarea
      :name="name"
      :id="id"
      :placeholder="placeholder"
      :style="{ width, 'max-width': maxWidth }"
      :value="modelValue"
      @input="handleInput"
      @blur="handleBlur"
    ></textarea>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "100%",
    },
    maxWidth: {
      type: String,
      default: "auto",
    },
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    return {
      handleInput(event: InputEvent) {
        emit("update:modelValue", (event.target as any).value);
      },
      handleBlur() {
        emit("blur");
      },
    };
  },
});
</script>

<style lang="scss" scoped>
textarea {
  background-color: white;
  border: 1px solid $border-general;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 400;
  font-family: $font-text;
  letter-spacing: 0.02rem;
  color: $text-dark;
  padding: 20px;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 0 2px 2px rgba($bg-accent, 0);
  max-width: 100%;
  min-width: 100%;
  min-height: 150px;

  &:focus {
    border-color: $bg-accent;
    box-shadow: 0 0 2px 2px rgba($bg-accent, 0.4);
    outline: none;
  }
}

.textbox {
  width: 100%;
}
</style>
