
import EditResidentPayment from "@/components/popups/EditResidentPayment.vue";
import { showPopup } from "@/components/popups/popups";
import { fullName } from "@/helpers/user";
import { Payment, Transaction } from "@/types";
import { formatAsEuro } from "@/util/price";
import { computed, defineComponent, PropType } from "vue";

// TODO: Edit resident payment

export default defineComponent({
  props: {
    payment: {
      type: Object as PropType<Payment>,
      required: true,
    },
    transaction: {
      type: Object as PropType<Transaction>,
      required: true,
    },
  },
  setup(props) {
    const resident = computed(() => props.payment.user);
    const name = computed(() =>
      resident.value == null ? "" : fullName(resident.value)
    );

    return {
      name,
      formattedAmount: computed(() => formatAsEuro(props.payment.amount)),
      edit() {
        showPopup(EditResidentPayment, {
          transaction: props.transaction,
          payment: props.payment,
        });
      },
    };
  },
});
