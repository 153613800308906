
import { Issue } from "@/repositories/issueRepository";
import { inIssues, issueActions } from "@/store/issues";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed, defineComponent, reactive, ref } from "vue";
import {
  FormField,
  AppForm,
  ImagesUpload,
  FieldError,
} from "@/components/form";
import { FileItem } from "@/repositories/fileRepository";
import { useActiveProperty } from "@/util/properties";
import { filesActions, inFiles } from "@/store/files";
import { optimizeImage } from "@/util/image";
import { ValidationException } from "@/error_handling/ValidationException";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: { FormField, AppForm, ImagesUpload, FieldError },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const issue = ref(null as null | Issue);
    const formData = reactive({
      title: "",
      message: "",
      pictures: [] as FileItem[],
      type: null,
      location: null,
    });
    const fieldErrors = ref({} as { [fieldName: string]: string[] });
    const property = useActiveProperty();

    store
      .dispatch(inIssues(issueActions.BY_ID), { id: route.params.issueId })
      .then((value) => {
        issue.value = value;
        formData.title = value.title;
        formData.message = value.message;
        formData.type = value.type;
        formData.location = value.location;
        formData.pictures = value.pictures;
      });

    const locationOptions = computed(() => {
      if (property.value == null) return { "-2": t("common.loading") };
      let options: Record<string, string> = {};
      if (property.value.has_basement) {
        options["kelder"] = t("common.basement");
      }
      if (property.value.has_parking) {
        options["parking"] = t("common.parking");
      }
      options["gelijksvloers"] = t("common.ground_floor");
      for (let i = 1; i < property.value.floors; i++) {
        // TODO: Translate this
        options[`${i}e verdieping`] = `${i}e verdieping`;
      }
      return options;
    });

    return {
      issue,
      formData,
      fieldErrors,
      locationOptions,
      async submit() {
        if (issue.value == null) return;
        try {
          await store.dispatch(inIssues(issueActions.UPDATE), {
            id: issue.value.id,
            updates: formData,
          });
          router.push({
            name: "properties.issues.show",
            params: {
              propertyId: route.params.id,
              issueId: route.params.issueId,
            },
          });
        } catch (e) {
          if (e instanceof ValidationException) {
            fieldErrors.value = e.getErrors();
          } else {
            throw e;
          }
        }
      },
      async uploadImage(file: File) {
        const blob = await optimizeImage(file, 1000);
        const uploadedFile = await store.dispatch(
          inFiles(filesActions.UPLOAD),
          {
            file: blob,
          }
        );
        formData.pictures.push(uploadedFile);
      },
    };
  },
});
