
import { defineComponent, ref, watchEffect } from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { DateTime } from "luxon";

export default defineComponent({
  components: { Datepicker },
  props: {
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Date,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const date = ref<Date>(
      props.modelValue ? new Date(props.modelValue) : new Date()
    );
    if (props.modelValue == null) {
      emit("update:modelValue", date.value);
    }
    watchEffect(() => {
      if (
        new Date(date?.value as any).getTime() !==
        new Date(props.modelValue).getTime()
      ) {
        emit("update:modelValue", date.value);
      }
    });

    const formatDate = (date: Date) => {
      if (date == null) return null;
      if (typeof date === "string") {
        date = new Date(date);
      }
      const dt = DateTime.fromJSDate(date);
      return dt.toFormat("dd/LL/yyyy");
    };

    return {
      date,
      formatDate,
    };
  },
});
