
import { defineComponent } from "vue";
import Features from "@/components/util/Features.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: { Features },
  setup() {
    const router = useRouter();

    return {
      toSelectBank: () => router.push({ name: "accounting.select_bank" }),
    };
  },
});
