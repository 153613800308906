<template>
  <div class="drop-button" ref="clickOutsideRef">
    <div class="button" @click="toggle">
      <div class="label">
        <div class="label-icon" v-if="labelIcon != null">
          <icon :name="labelIcon" size="13" strokeWidth="3" />
        </div>
        <div class="label-text">{{ labelText }}</div>
      </div>
      <div class="drop-icon-wrapper">
        <div class="drop-icon">
          <angle-down-icon />
        </div>
      </div>
    </div>
    <div class="dropdown" :class="{ visible: isOpen }">
      <div
        class="item"
        v-for="item in items"
        :key="item.text"
        @click="onClick(item)"
      >
        <div class="item-icon">
          <icon :name="item.icon" size="13" strokeWidth="2" />
        </div>
        <div class="item-text">{{ item.label }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import Icon from "@/components/icons/Icon.vue";
import AngleDownIcon from "@/components/icons/AngleDown.vue";
import { useOnClickOutside } from "@/util/click";

export interface DropButtonItem {
  icon: string;
  label: string;
  onClick: () => void;
}

export default defineComponent({
  components: { Icon, AngleDownIcon },
  props: {
    labelText: {
      type: String,
      required: true,
    },
    labelIcon: {
      type: String,
      default: null,
    },
    items: {
      type: Array as PropType<DropButtonItem[]>,
      required: true,
    },
  },
  setup() {
    const isOpen = ref(false);
    const clickOutsideRef = useOnClickOutside(() => {
      isOpen.value = false;
    });

    return {
      clickOutsideRef,
      isOpen,
      toggle() {
        isOpen.value = !isOpen.value;
      },
      onClick(item: DropButtonItem) {
        item?.onClick();
        isOpen.value = false;
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.drop-button {
  margin-left: 15px;
  position: relative;
}

.button {
  cursor: pointer;
  background-color: $bg-accent;
  border: 1px solid #d7a62c;
  outline: none;
  border-radius: 5px;
  box-shadow: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  min-height: 43px;
}

.label {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 500;
  border-right: 1px solid #d7a62c;
  line-height: 100%;
  min-height: 43px;
  padding: {
    top: 7px;
    bottom: 7px;
    left: 20px;
    right: 13px;
  }
}

.label-icon {
  padding-right: 7px;
}

.drop-icon-wrapper {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  // height: 100%;
  padding: {
    left: 13px;
    right: 13px;
  }
}

.drop-icon {
  padding-top: 4px;
  opacity: 0.8;
  width: 11px;
}

.dropdown {
  display: none;
  background-color: white;
  position: absolute;
  top: 100%;
  right: 0;
  padding: 10px 0;
  min-width: 100%;
  box-sizing: border-box;
  box-shadow: $shadow-general;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  &.visible {
    display: block;
  }
}

.item {
  width: 100%;
  padding: 10px 23px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: $bg-accent;
  }
}

.item-icon {
  padding-right: 7px;
  opacity: 0.8;
}

.item-text {
  font-weight: 500;
}
</style>
