
import { defineComponent, PropType, ref, watchEffect } from "vue";
import {
  GenericPageAction,
  GenericPageActions,
} from "../GenericPageActions.vue";
import Icon from "@/components/icons/Icon.vue";
import { useOnClickOutside } from "@/util/click";
import Loader from "@/components/icons/Loader.vue";

export default defineComponent({
  components: { Icon, Loader },
  props: {
    actions: {
      type: Object as PropType<GenericPageActions>,
      required: true,
    },
  },
  setup() {
    const isOpen = ref(false);
    const cooldown = ref(false);
    const loading = ref([] as string[]);

    const clickOutsideRef = useOnClickOutside(() => {
      isOpen.value = false;
    });

    watchEffect(() => console.log(loading.value));

    return {
      clickOutsideRef,
      isOpen,
      loading,
      async onClick(action: GenericPageAction) {
        loading.value.push(action.label);
        try {
          await Promise.resolve(action.onClick());
        } finally {
          loading.value = loading.value.filter((l) => l !== action.label);
          if (loading.value.length === 0) {
            isOpen.value = false;
          }
        }
      },
      toggle() {
        if (cooldown.value === true) return;
        cooldown.value = true;
        isOpen.value = !isOpen.value;
        setTimeout(() => (cooldown.value = false), 100);
      },
    };
  },
});
