<template>
  <page-loader v-if="docs == null" />
  <container class="list-finance" v-else>
    <generic-header>
      {{ $t("property.finance") }}
      <template v-slot:right>
        <a
          @click.prevent="toNew()"
          class="add-button hide-desktop"
          v-if="isAdmin"
        >
          <add-icon />
        </a>
        <app-button class="hide-mobile" v-if="isAdmin" thinner @click="toNew()"
          ><add-icon
            class="button-add-icon"
            stroke-width="2"
            width="16"
            height="16"
          />
          {{ $t("fin.add_doc") }}</app-button
        >
      </template>
    </generic-header>
    <div class="container">
      <div class="extra-info">
        <h2 class="extra-info__title">
          {{ $t("fin.doc_count") }} ({{ currentYear }})
        </h2>
        <div class="extra-info__item">
          <!-- Aantal documenten: -->
          <span class="extra-info__value">{{ docsThisYear.length }}</span>
        </div>
        <h2 class="extra-info__title">
          {{ $t("fin.total_expenses") }} ({{ currentYear }})
        </h2>
        <div class="extra-info__item">
          <!-- Total uitgave (2021):  -->
          <span class="extra-info__value">
            {{ formatAsEuro(totalExpendedThisYear) }}
          </span>
        </div>
      </div>
      <finance-item
        @click="show(doc)"
        v-for="doc in docs"
        :key="doc.id"
        :doc="doc"
      />
    </div>
  </container>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import FinanceItem from "@/components/finance/FinanceItem.vue";
import AddIcon from "@/components/icons/Add.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { FinDocument } from "@/types";
import { finActions, inFin } from "@/store/fin";
import { DateTime } from "luxon";
import { formatAsEuro } from "@/util/price";
import { useIsAdminById } from "@/util/auth";

// TODO: Only show add icon when user is admin

export default defineComponent({
  components: { FinanceItem, AddIcon },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const propertyId = route.params.id;

    const docs = ref(null as null | FinDocument[]);

    store.dispatch(inFin(finActions.LIST), { propertyId }).then((values) => {
      docs.value = values;
    });

    const currentYear = DateTime.utc().year;

    const docsThisYear = computed(() => {
      if (docs.value == null) return [];
      return docs.value.filter((d) => {
        return DateTime.fromISO(d.inserted_at).year === currentYear;
      });
    });

    const totalExpendedThisYear = computed(() => {
      return docsThisYear.value.reduce((total: number, doc) => {
        if (doc.is_cost) {
          return total + doc.amount;
        } else {
          return total - doc.amount;
        }
      }, 0);
    });

    const { isAdmin } = useIsAdminById(propertyId as string);

    return {
      docs,
      currentYear,
      docsThisYear,
      totalExpendedThisYear,
      formatAsEuro,
      isAdmin,
      // show(doc: FinDocument) {
      // router.push({
      //   name: "properties.finances.show",
      //   params: {
      //     propertyId: doc.property_id,
      //     id: doc.id,
      //   },
      // });
      // },
      toNew() {
        router.push({
          name: "properties.finances.create",
          params: { id: propertyId },
        });
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.container {
  padding: 20px 20px 30px;

  @include screen-from("tablet") {
    padding: 20px 0 30px;
  }
}

.add-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  svg {
    width: 20px;
    height: 20px;
  }
}

.extra-info {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba($border-general, 0.5);
}

.extra-info__title {
  font-size: 0.875rem;
  letter-spacing: 0.04rem;
  font-weight: 400;
  color: $text-gray;
  margin-bottom: 10px;
}

.extra-info__item {
  font-size: 1rem;
  color: $text-dark;
  font-weight: 500;
  margin-bottom: 15px;
}

.extra-info__value {
  font-weight: 600;
  font-size: 1.125rem;
  margin-left: 5px;
}

.button-add-icon {
  padding-right: 6px;
}
</style>
