<template>
  <popup-wrapper @finish="$emit('finish')">
    <page-loader class="page-loader" v-if="issues == null" />
    <div class="content" v-else>
      <h2 class="title">{{ $t("issues.recent_issues") }}</h2>
      <div class="issues">
        <div class="empty-state" v-if="issues.length === 0">
          Deze residentie heeft nog geen problemen
        </div>
        <div class="issue-wrapper" v-for="issue in issues" :key="issue.id">
          <issue-listing @click="selectIssue(issue)" :issue="issue" no-text />
        </div>
      </div>
    </div>
  </popup-wrapper>
</template>

<script lang="ts">
import { Issue } from "@/repositories/issueRepository";
import { inIssues, issueActions } from "@/store/issues";
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import PopupWrapper from "./PopupWrapper.vue";
import IssueListing from "@/components/property/IssueListing.vue";

export default defineComponent({
  components: { PopupWrapper, IssueListing },
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const issues = ref(null as null | Issue[]);

    console.log(props.params);

    // TODO: order issues newest first
    const store = useStore();
    store.dispatch(inIssues(issueActions.LIST)).then((values) => {
      console.log(values);
      if (props.params.property_id != null) {
        values = values.filter(
          (v: Issue) => v.property_id === props.params.property_id
        );
      }
      if (props.params.property_ids != null) {
        const propertyIds: number[] = props.params.property_ids.map(
          (id: number | string) => {
            if (typeof id === "string") return parseInt(id);
            return id;
          }
        );
        values = values.filter((v: Issue) => {
          return propertyIds.includes(v.id);
        });
      }
      issues.value = values;
    });

    return {
      issues,
      selectIssue(issue: Issue) {
        emit("finish", issue);
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.page-loader {
  height: 200px;
}

.content {
  padding: 30px;
  box-sizing: border-box;
}

.title {
  font-size: 1.45rem;
  font-weight: 600;
  color: $text-alt;
  letter-spacing: 0.02rem;
  margin-bottom: 20px;
}

.issue-wrapper {
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.empty-state {
  color: $text-inactive;
  text-align: center;
  line-height: 130%;
}
</style>
