import { ApiResponse, post } from "./repository";

export interface FileItem {
  id: number;
  url: string;
  name: string;
  type: string;
  inserted_at: string;
}

export interface FileData {
  file: FileItem;
}

export default {
  upload(params: { file: File }): ApiResponse<FileData> {
    const formData = new FormData();
    formData.append("file", params.file);
    return post("/files", formData);
  },
  getMany(params: { ids: string[] }): ApiResponse<{ files: FileItem[] }> {
    return post("/files/get_many", { file_ids: params.ids });
  },
};
