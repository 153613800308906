
import { defineComponent, ref } from "vue";
import PopupWrapper from "@/components/popups/PopupWrapper.vue";
import { showPopup } from "@/components/popups/popups";

const component = defineComponent({
  components: { PopupWrapper },
  setup(props, { emit }) {
    const name = ref("");

    return {
      name,
      submit() {
        emit("finish", name.value);
      },
    };
  },
});

export const promptFolderName = () => {
  return showPopup(component, {});
};

export default component;
