// import pica from "pica";
import ImageBlobReducer from "image-blob-reduce";

// const p = pica({ idle: 20000 });

// maxSize (maxWidth or maxHeight whichever is biggest)
export async function optimizeImage(
  file: File,
  maxSize: number
): Promise<Blob> {
  const r = new ImageBlobReducer();
  return r.toBlob(file, { max: maxSize, unsharpAmount: 150 });
}
