<template>
  <div class="lightbox-slide">
    <div class="img" :style="{ 'background-image': `url('${image}')` }"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    image: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  height: 90vh;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
