
import { defineComponent } from "vue";

/**
 * Only works on big sizes, mobile container should be handled by component
 */
export default defineComponent({
  props: {
    // Mostly used for forms
    thinner: {
      type: Boolean,
      defualt: false,
    },
    maxWidth: {
      type: String,
      default: undefined,
    },
    width: {
      type: String,
      default: undefined,
    },
    centerh: {
      type: Boolean,
      default: false,
    },
  },
});
