
import { computed, defineComponent } from "vue";
import { useAllPaymentRequests, usePayments } from "@/store/accounting/hooks";
import { useResidents } from "@/store/properties/hooks";
import ResidentItem from "../../components/payments/ResidentItem.vue";
import { useActiveProperty } from "@/util/properties";
import {
  dateNextPayment,
  getDebtForProperty,
  getDebtForUser,
} from "@/store/accounting/util";
import { DateTime } from "luxon";
import { formatAsEuro } from "@/util/price";

export default defineComponent({
  components: { ResidentItem },
  setup() {
    const { payments, loading: paymentsLoading } = usePayments();
    const { residents, loading: residentsLoading } = useResidents();
    const activeProperty = useActiveProperty();
    const { paymentRequests, loading: paymentRequestsLoading } =
      useAllPaymentRequests();

    const debtForUser = computed(() => (userId: number) => {
      return getDebtForUser(userId, payments.value, paymentRequests.value);
    });

    const totalDebt = computed(() =>
      formatAsEuro(getDebtForProperty(payments.value, paymentRequests.value))
    );

    const formattedNextPaymentDate = computed(() => {
      if (activeProperty.value == null) return "";
      const date = dateNextPayment(activeProperty.value);
      return DateTime.fromJSDate(date).toFormat("dd LLLL yyyy");
      // activeProperty.value && dateNextPayment(activeProperty.value)
    });

    return {
      property: activeProperty,
      payments,
      residents,
      debtForUser,
      totalDebt,
      loading: computed(
        () =>
          paymentsLoading.value ||
          residentsLoading.value ||
          paymentRequestsLoading.value
      ),
      formattedNextPaymentDate,
    };
  },
});
