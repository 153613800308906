
import { Toast } from "@/types";
import { defineComponent, ref } from "vue";
import { listenForToast } from "./toast";

export default defineComponent({
  setup() {
    const toasts = ref([] as Toast[]);

    function remove(toast: Toast) {
      toasts.value = toasts.value.filter((t) => t.text !== toast.text);
    }

    listenForToast((toast) => {
      toasts.value.push(toast);

      setTimeout(() => {
        remove(toast);
      }, 2000);
    });

    return {
      toasts,
      remove,
    };
  },
});
