
import { defineComponent, PropType, ref, watchEffect } from "vue";

interface RadioOption {
  id: string;
  label: string;
  description: string;
}

export default defineComponent({
  emits: ["select"],
  props: {
    options: {
      type: Array as PropType<RadioOption[]>,
      required: true,
    },
    selected: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const selectedId = ref(null as null | string);

    watchEffect(() => {
      if (selectedId.value == null) {
        selectedId.value = props.selected;
      }
    });

    return {
      selectedId,
      select(option: RadioOption) {
        selectedId.value = option.id;
        emit("select", option.id);
      },
    };
  },
});
