
import { inProperties, PropertiesActions } from "@/store/properties";
import { User } from "@/types";
import {
  sumOfResidentShares,
  useActiveProperty,
  withActiveProperty,
} from "@/util/properties";
import { computed, defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ResidentList from "@/components/property/ResidentList.vue";

export default defineComponent({
  components: { ResidentList },
  setup() {
    const router = useRouter();
    const store = useStore();
    const residents = ref(null as null | User[]);
    const activeProperty = useActiveProperty();

    const fetchResidents = async (propertyId: number): Promise<User[]> => {
      return store.dispatch(inProperties(PropertiesActions.LIST_RESIDENTS), {
        id: propertyId,
      });
    };

    withActiveProperty(async (property) => {
      if (sumOfResidentShares(property) === property.total_shares) {
        router.push({
          name: "accounting.select_bank",
        });
        return;
      }
      residents.value = await fetchResidents(property.id);
    });

    return {
      property: activeProperty,
      residents,
      totalShares: computed(() =>
        activeProperty.value ? sumOfResidentShares(activeProperty.value) : 0
      ),
    };
  },
});
