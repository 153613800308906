<template>
  <popup-wrapper @finish="$emit('finish')" maxWidth="450px">
    <div class="content" v-if="loading">
      <page-loader />
    </div>
    <div class="content" v-else>
      <div class="avatar" :style="avatarStyles"></div>
      <div class="overview">
        <div>
          <div class="cell">
            <div class="cell-label">Naam</div>
            <div class="cell-value">
              {{ fullName }}
            </div>
          </div>
          <div class="cell">
            <div class="cell-label">Tienduizendsten</div>
            <div class="cell-value">{{ shares }}</div>
          </div>
        </div>
        <div>
          <div class="cell cell--right">
            <div class="cell-label">Schuld</div>
            <div
              class="cell-value cell-value--bigger"
              :class="{ 'cell-value--error': debt > 0 }"
            >
              {{ formatAsEuro(debt) }}
            </div>
          </div>
        </div>
      </div>
      <h3 class="items-title">Betalingen</h3>
      <div class="items items--empty" v-if="payments.length === 0">
        Deze persoon heeft nog geen betalingen
      </div>
      <div class="items" v-else>
        <payment-item
          v-for="payment in payments"
          :key="payment.id"
          :payment="payment"
        />
      </div>
    </div>
  </popup-wrapper>
</template>

<script lang="ts">
import PopupWrapper from "@/components/popups/PopupWrapper.vue";
import { fullName } from "@/helpers/user";
import { Property } from "@/repositories/propertyRepository";
import { usePaymentRequests, useUserPayments } from "@/store/accounting/hooks";
import { getDebtForUser } from "@/store/accounting/util";
import { avatarStyles } from "@/store/users/util";
import { User } from "@/types";
import { formatAsEuro } from "@/util/price";
import { sharesForResident } from "@/util/properties";
import { computed, defineComponent, PropType } from "vue";
import PaymentItem from "../../payments/PaymentItem.vue";

export default defineComponent({
  components: { PopupWrapper, PaymentItem },
  props: {
    params: {
      type: Object as PropType<{ user: User; property: Property }>,
      required: true,
    },
  },
  setup(props) {
    const user = computed(() => props.params.user);
    const userId = computed(() => user.value.id);
    const { loading: loadingPayments, payments } = useUserPayments(userId);
    const { loading: loadingRequests, paymentRequests } =
      usePaymentRequests(userId);

    return {
      loading: computed(() => loadingPayments.value || loadingRequests.value),
      payments,
      user,
      avatarStyles: computed(() => avatarStyles(user.value)),
      fullName: computed(() => fullName(user.value)),
      debt: computed(() =>
        getDebtForUser(userId.value, payments.value, paymentRequests.value)
      ),
      formatAsEuro,
      shares: computed(() =>
        sharesForResident(props.params.property, props.params.user.id)
      ),
    };
  },
});
</script>

<style lang="scss" scoped>
.content {
  height: 70vh;
  max-height: 70vh;
  box-sizing: border-box;
  padding: 30px 20px 20px;
}

/* .header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
} */

.avatar {
  top: -28px;
  /* top: -5px; */
  /* margin-top: -10px; */
  border: 2px solid white;
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: $bg-accent;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 10px;
}

.overview {
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
}

.name {
  font-weight: bold;
  font-size: 1.125rem;
}

.shares {
  font-size: 0.875rem;
  color: $text-gray;
  font-weight: normal;
  margin-top: 5px;
  letter-spacing: 0.01em;
}

.items-title {
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: -0.01em;
  margin-top: 20px;
}

.items {
  margin-top: 10px;

  &--empty {
    text-align: center;
    line-height: 130%;
    color: $text-gray;
  }
}

.cell {
  margin-bottom: 10px;

  &--right {
    text-align: right;
  }
}

.cell-label {
  font-size: 0.75rem;
  text-transform: uppercase;
  color: $text-gray;
  font-weight: bold;
  margin-bottom: 4px;
}

.cell-value {
  font-size: 1rem;
  /* font-weight: bold; */
  letter-spacing: 0.01em;

  &--bigger {
    font-size: 1.125rem;
  }

  &--error {
    color: $text-error;
    font-weight: bold;
  }
}
</style>
