<template>
  <form
    @submit.prevent="handleSubmit"
    novalidate
    :class="{ nopb: noPaddingBottom }"
    class="form"
  >
    <slot name="top" />
    <slot />
    <slot name="bottom" />
    <app-button
      class="submit"
      :class="{
        'submit-full': submitFull,
        'submit-always-full': submitAlwaysFull,
      }"
      type="submit"
      :loading="loading"
      :thinner="thinner"
      :danger="dangerButton"
      :dark="shouldUseDarkButton()"
    >
      {{ submitText }}
    </app-button>
  </form>
</template>

<script lang="ts">
import { getPlatform } from "@/util/platform";
import { defineComponent, ref } from "vue";

export default defineComponent({
  emits: ["submit"],
  props: {
    submitHandler: {
      type: Function,
      required: true,
    },
    submitText: {
      type: String,
      default: "Submit",
    },
    submitFull: {
      type: Boolean,
      default: false,
    },
    submitAlwaysFull: {
      type: Boolean,
      default: false,
    },
    thinner: {
      type: Boolean,
      default: false,
    },
    dangerButton: {
      type: Boolean,
      default: false,
    },
    noPaddingBottom: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const loading = ref(false);

    return {
      loading,
      async handleSubmit() {
        loading.value = true;
        await Promise.resolve(props.submitHandler());
        loading.value = false;
      },
      shouldUseDarkButton() {
        return getPlatform() === "web";
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.submit {
  margin-top: 30px;

  &.submit-full {
    width: 100%;

    @include screen-from("tablet") {
      width: auto;
    }
  }

  &.submit-always-full {
    width: 100%;

    @include screen-from("tablet") {
      width: 100%;
    }
  }
}

form {
  padding-bottom: 30px;

  &.nopb {
    padding-bottom: 0;
  }
}
</style>
