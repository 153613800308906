<template>
  <div class="property-selector" ref="propertySelectorEl">
    <div class="selected" @click="toggleDropdown">
      <div class="selected-name">
        <template v-if="activeProperty != null">
          {{ activeProperty.name }}
        </template>
        <template v-else>{{ $t("property.select") }}</template>
      </div>
      <div class="angle-down-wrapper">
        <angle-down />
      </div>
    </div>
    <div class="dropdown" :class="{ isOpen }">
      <template v-for="property in properties" :key="property.id">
        <div
          @click="setProperty(property)"
          class="item"
          :class="{ 'item--active': property.id === activeProperty?.id }"
        >
          {{ property.name }}
        </div>
      </template>
      <div class="item item--new" @click="toCreate">
        <plus-icon
          class="pluss-icon"
          stroke-width="3"
          width="15px"
          height="15px"
        />
        {{ $t("property.new") }}
      </div>
    </div>
    <!-- // TODO: New property in dropdown -->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import AngleDown from "@/components/icons/AngleDown.vue";
import { useActiveProperty, useProperties } from "@/util/properties";
import PlusIcon from "@/components/icons/Add.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { inProperties, PropertiesActions } from "@/store/properties";
import { Property } from "@/repositories/propertyRepository";
import { useOnClickOutside } from "@/util/click";

export default defineComponent({
  components: { AngleDown, PlusIcon },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const activeProperty = useActiveProperty();
    const isOpen = ref(false);
    const properties = useProperties();

    function closeDropdown() {
      isOpen.value = false;
    }

    return {
      propertySelectorEl: useOnClickOutside(() => {
        isOpen.value = false;
      }),
      activeProperty,
      isOpen,
      properties,
      toggleDropdown() {
        isOpen.value = !isOpen.value;
      },
      async setProperty(property: Property) {
        closeDropdown();

        store.dispatch(inProperties(PropertiesActions.SELECT_PROPERTY), {
          propertyId: property.id,
        });

        if (route.params["propertyId"] != null) {
          if (route.name == null) {
            // TODO: handle when route name does not exist
            console.error("Route is missing a name:", route.path);
            return;
          }
          const newParams: any = {};
          Object.keys(route.params).forEach((key) => {
            if (key === "propertyId")
              newParams["propertyId"] = property.id.toString();
            else newParams[key] = route.params[key];
          });
          await router.push({
            name: route.name.toString(),
            params: newParams,
          });
        } else if (
          route.name != null &&
          route.name.toString().startsWith("properties") &&
          route.params?.id != null
        ) {
          const newParams: any = {};
          Object.keys(route.params).forEach((key) => {
            if (key === "id") newParams["id"] = property.id.toString();
            else newParams[key] = route.params[key];
          });
          router.push({
            name: route.name.toString(),
            params: newParams,
          });
        }
      },
      toCreate() {
        closeDropdown();
        router.push({ name: "properties.create" });
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.property-selector {
  position: relative;
  z-index: 100;
}

.selected {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-family: $font-title;
  font-size: 1.25rem;
  font-weight: 600;
  color: $text-dark;
  cursor: pointer;
  padding: 5px 0;
}

.selected-name {
  margin-right: 8px;
}

.angle-down-wrapper {
  width: 10px;
  padding-top: 3px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.dropdown {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid $border-general;
  margin-top: 5px;
  margin-left: -5px;
  border-radius: 5px;
  min-width: 220px;
  padding: 5px 0 0;

  &.isOpen {
    display: block;
  }
}

.item {
  padding: 10px 15px;
  font-size: 1.125rem;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  color: $text-gray;
  display: block;

  &:hover {
    color: $text-dark;
  }

  &--new {
    border-top: 1px solid $border-lighter;
    /* font-weight: 400; */
    background-color: $bg-general;
    color: $text-gray;
    padding: 15px 15px;
    color: $text-accent;
    margin-top: 5px;

    &:hover {
      color: $text-dark;
    }
  }

  &--active {
    color: $text-dark;
  }
}

.plus-icon {
  margin-right: 5px;
}
</style>
