
import { filesActions, inFiles } from "@/store/files";
import { getPlatform } from "@/util/platform";
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import PopupWrapper from "./PopupWrapper.vue";
import * as Sentry from "@sentry/vue";
import Loader from "@/components/icons/Loader.vue";

export default defineComponent({
  components: { PopupWrapper, Loader },
  setup(props, { emit }) {
    const fileEl = ref(null as null | HTMLInputElement);
    const dragging = ref(false as boolean);
    const loading = ref(false as boolean);
    const store = useStore();
    const uploadFailed = ref(false as boolean);

    const uploadFile = async (file: File) => {
      console.log("Upload file:", file);
      loading.value = true;
      try {
        const result = await store.dispatch(inFiles(filesActions.UPLOAD), {
          file,
        });
        emit("finish", result);
      } catch (e) {
        uploadFailed.value = true;
        Sentry.captureException(e);
      } finally {
        loading.value = false;
        dragging.value = false;
      }
    };

    return {
      fileEl,
      dragging,
      loading,
      uploadFailed,
      platform: getPlatform(),
      selectFile() {
        if (fileEl.value == null) return;
        fileEl.value.click();
      },
      onFilePicked() {
        if (fileEl.value == null) return;
        const files = fileEl.value.files;
        if (files == null || files.length <= 0) return;
        const file = files[0];
        uploadFile(file);
      },
      onDragOver(e: DragEvent) {
        console.log("test");
        e.preventDefault();
        dragging.value = true;
      },
      onDragLeave(e: DragEvent) {
        e.preventDefault();
        dragging.value = false;
      },
      onDrop(e: DragEvent) {
        e.preventDefault();

        const files = e.dataTransfer?.files;
        if (files == null || files.length <= 0) return;
        const file = files[0];
        uploadFile(file);
      },
    };
  },
});
