<template>
  <generic-page wide :loading="chats == null" :title="$t('chat.my_chats')">
    <div>
      <div class="chats-wrapper">
        <div class="chats">
          <header class="chats-header">
            <div class="chats-header-title">
              {{ $t("chat.my_chats") }}
            </div>
            <div class="search-button">
              <chat-search @select="(chat) => toChat(chat)" />
            </div>
          </header>
          <div class="chats-content">
            <h2 class="sub-title">{{ $t("chat.groups") }}</h2>
            <template v-for="chat in chats" :key="chat.id">
              <chat-listing
                class="chat-listing"
                v-if="chat.property_id != null"
                :title="titleForChat(chat)"
                :last-message="
                  lastUsefulMessage(chat)?.text || 'nog geen berichten'
                "
                icon-url="/img/chat_property.svg"
                @click="toChat(chat)"
                :unreadCount="newMessageCount(chat)"
                :active="selectedChat?.id === chat.id"
              />
            </template>
            <h2 class="sub-title">{{ $t("chat.people") }}</h2>
            <template v-for="chat in chats" :key="chat.id">
              <chat-listing
                class="chat-listing"
                v-if="chat.property_id == null"
                :title="titleForChat(chat)"
                :last-message="
                  lastUsefulMessage(chat)?.text || $t('chat.no_messages_yet')
                "
                :icon-url="iconUrlForChat(chat)"
                @click="toChat(chat)"
                :unreadCount="newMessageCount(chat)"
                :active="selectedChat?.id === chat.id"
              />
            </template>
          </div>
        </div>
        <div class="chat-content">
          <header class="content-header">
            <template v-if="selectedChat != null">
              <div
                class="chat-icon"
                :style="{
                  'background-image': `url('${iconUrlForChat(selectedChat)}')`,
                }"
              >
                <empty-profile v-if="iconUrlForChat(selectedChat) == null" />
              </div>
              <div class="chat-name">
                {{ titleForChat(selectedChat) }}
              </div>
            </template>
          </header>
          <div class="chat-view-wrapper">
            <chat-view
              :title="titleForChat(selectedChat)"
              :chat="selectedChat"
              v-if="selectedChat != null"
              @hide="hideChat"
            />
          </div>
        </div>
      </div>
    </div>
  </generic-page>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watchEffect } from "vue";
import ChatListing from "@/components/chat/ChatListing.vue";
import { useStore } from "vuex";
import { chatsActions, chatsGetters, inChats } from "@/store/chats";
import { Chat, Message, User } from "@/types";
import { authGetters, inAuth } from "@/store/auth";
import ChatSearch from "@/components/chat/ChatSearch.vue";
import EmptyProfile from "@/components/util/EmptyProfile.vue";
import ChatView from "@/components/chat/ChatView.vue";
import { isMobilePlatform } from "@/util/platform";
import { useRoute } from "vue-router";

export default defineComponent({
  components: { ChatListing, ChatSearch, EmptyProfile, ChatView },
  setup() {
    const store = useStore();
    const chats = ref(null as null | Chat[]);
    const selectedChat = ref(null as null | Chat);
    const route = useRoute();

    watchEffect(async () => {
      const propertyId = route?.query?.propertyId;
      if (propertyId == null) return;
      const chat = await store.dispatch(
        inChats(chatsActions.GET_OR_CREATE_PROPERTY_CHAT),
        { propertyId }
      );
      selectedChat.value = chat;
    });

    store.dispatch(inChats(chatsActions.LIST)).then((values) => {
      chats.value = values;
    });

    const newMessageCount = computed(() => (chat: Chat) => {
      const user: User | null = store.getters[inAuth(authGetters.USER)];
      return store.getters[inChats(chatsGetters.NEW_MESSAGE_COUNT)](
        chat.id,
        user?.id
      );
    });

    const lastUsefulMessage = computed(() => (chat: Chat) => {
      const messages: Message[] = store.getters[inChats(chatsGetters.MESSAGES)](
        chat.id
      );
      if (messages.length === 0) return null;
      return messages.reduce<Message | null>(
        (latest: Message | null, curr: Message) => {
          if (curr.type !== "text") return latest;
          if (latest == null) return curr;
          return latest.inserted_at > curr.inserted_at ? latest : curr;
        },
        null
      );
    });

    // Make issue and document type messages available in excerpt
    // const messageExcerpt = computed(() => (message: Message) => {
    //   if (message.type === "text") return message.text;
    //   if (message.type === "issue") {
    //     // const issue =
    //   }
    // });

    const otherUsers = computed(() => (chat: Chat) => {
      const user: User | null = store.getters[inAuth(authGetters.USER)];
      if (user == null) return chat.users;
      return chat.users.filter((u) => u.id !== user?.id);
    });

    const titleForChat = computed(() => (chat: Chat) => {
      if (chat.property_id != null)
        return chat.property?.name || "Er ging iets mis";

      const user: User | null = store.getters[inAuth(authGetters.USER)];
      const otherUsers = chat.users.filter((u: User) => u.id !== user?.id);

      if (otherUsers.length === 0) return "Alleen ik";

      const firstUserName =
        otherUsers[0].first_name + " " + otherUsers[0].last_name;
      if (otherUsers.length === 1) {
        return firstUserName;
      }
      return firstUserName + ` en ${otherUsers.length} anderen`;
    });

    const iconUrlForChat = computed(() => (chat: Chat) => {
      if (chat.property_id != null) return "/img/chat_property.svg";

      const user: User | null = store.getters[inAuth(authGetters.USER)];
      if (chat.users.length === 2) {
        const otherUser = chat.users.find((u) => u.id !== user?.id);
        return otherUser?.avatar_url;
      }
      const messages: Message[] = store.getters[inChats(chatsGetters.MESSAGES)](
        chat.id
      );
      const newest = messages.reduce<Message | null>((newest, curr) => {
        if (newest == null) return curr;
        if (curr.inserted_at > newest.inserted_at) {
          return curr;
        }
        return newest;
      }, null);
      const authorId = newest?.author_id;
      if (authorId == null) return null;
      const lastUser = chat.users.find((u) => u.id === authorId);
      return lastUser?.avatar_url;
    });

    let orderedChats = computed(() => {
      if (chats.value == null) return null;
      return [...chats.value].sort((chatA: Chat, chatB: Chat) => {
        let lastMessageA = lastUsefulMessage.value(chatA);
        let lastMessageB = lastUsefulMessage.value(chatB);
        if (lastMessageA == null) return -1;
        if (lastMessageB == null) return 1;
        if (lastMessageA.inserted_at > lastMessageB.inserted_at) return -1;
        if (lastMessageA.inserted_at < lastMessageB.inserted_at) return 1;
        return 0;
      });
    });

    return {
      chats: orderedChats,
      lastUsefulMessage,
      otherUsers,
      titleForChat,
      iconUrlForChat,
      newMessageCount,
      selectedChat,
      toChat(chat: Chat) {
        console.log("Chat:", chat);
        selectedChat.value = chat;
        // router.push({
        //   name: "chats.show",
        //   params: {
        //     id: chat.id,
        //   },
        // });
      },
      hideChat() {
        console.log("hiding chat");
        selectedChat.value = null;

        if (isMobilePlatform()) {
          window.scrollTo(0, 0);
        }
      },
      test() {
        console.log("Test");
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.chats-wrapper {
  @include screen-from("tablet-wide") {
    display: grid;
    grid-template-columns: 300px 1fr;
    border: 1px solid $border-general;
    height: 580px;
    max-height: 580px;
    background-color: white;
    border-radius: 5px;
  }

  /* @media (min-height: 800px) {
    max-height: 650px;
    height: 650px;
  } */
}

.chats {
  /* padding: 20px 20px; */
  padding: 20px 0;
  box-sizing: border-box;

  @include screen-from("tablet-wide") {
    padding: 0;
    border-right: 1px solid $border-general;
    height: 100%;
    max-height: 100%;
  }
}

.chats-header {
  display: none;
  height: 60px;
  border-bottom: 1px solid $border-general;

  @include screen-from("tablet-wide") {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 20px;
  }
}

.chats-header-title {
  font-size: 1.125rem;
  font-weight: 600;
}

.chats-content {
  box-sizing: border-box;
  overflow-y: auto;
  padding: 0 20px;
  direction: rtl;
  display: grid;

  & > * {
    direction: ltr;
  }

  @include screen-from("tablet-wide") {
    max-height: calc(580px - 61px);
    padding: 0;
  }
}

.sub-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: $text-dark;
  letter-spacing: -0.01rem;

  margin-bottom: 30px;

  &:not(:first-child) {
    margin-top: 40px;
  }

  @include screen-from("tablet-wide") {
    display: none;
  }
}

.header-wrapper {
  @include screen-from("tablet-wide") {
    display: none;
  }
}

.content-header {
  display: none;

  @include screen-from("tablet-wide") {
    display: flex;
    height: 60px;
    border-bottom: 1px solid $border-general;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0 30px;
    box-sizing: border-box;
  }
}

.chat-content {
  max-height: calc(580px - 61px);

  /* @media (min-height: 800px) {
    max-height: calc(650px - 61px);
  } */
}

.chat-icon {
  width: 32px;
  height: 32px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-right: 10px;
}

.chat-name {
  color: $text-alt;
  font-family: $font-title;
  font-size: 1.125rem;
  font-weight: 600;
}

.chat-view-wrapper {
  /* height: 520px; */
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
}
</style>
