
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const quarter = ref(null as null | string);

    return {
      quarter,
    };
  },
});
