<template>
  <generic-finance-page title="Verdeelsleutels" :options="options">
    <card @click="toShare">
      <div class="share-item">
        <h2 class="title">Hoofdsleutel</h2>
      </div>
    </card>
  </generic-finance-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import GenericFinancePage from "@/features/finance/components/GenericFinancePage.vue";
import { GenericPageActions } from "@/components/common/generic-page/GenericPageActions.vue";
import { GenericPageOption } from "@/components/common/generic-page/GenericPageOptions.vue";
import { useRouter } from "vue-router";
import { showViewSharePopup } from "../../popups/ViewShare.vue";

export default defineComponent({
  components: { GenericFinancePage },
  setup() {
    const router = useRouter();

    const options: GenericPageOption[] = [
      {
        id: "add",
        iconName: "add",
        text: "Nieuwe sleutel",
        onClick() {
          router.push({});
        },
      },
    ];

    return {
      options,
      toShare() {
        // router.push({
        //   name: "finance.shares.view",
        //   params: {
        //     id: "xxx", // TODO: replace with actual value
        //   },
        // });
        showViewSharePopup();
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.share-item {
  padding: 20px;
  box-sizing: border-box;
}
</style>
