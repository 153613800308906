
import { defineComponent, PropType } from "vue";
import { RouteLocationRaw } from "vue-router";

export interface GenericPageTab {
  text: string;
  to: RouteLocationRaw;
}

export default defineComponent({
  props: {
    tabs: {
      type: Array as PropType<GenericPageTab[]>,
      required: true,
    },
  },
});
