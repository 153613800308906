import { RouteRecordRaw } from "vue-router";
import Overview from "./pages/overview/Overview.vue";
import ListTransactions from "./pages/overview/ListTransactions.vue";
import ListPayments from "./pages/overview/ListPayments.vue";
import ListInvoices from "./pages/overview/ListInvoices.vue";
import UserPayments from "./pages/user/UserPayments.vue";

export const routes: RouteRecordRaw[] = [
  {
    path: "/accounting/user/payments",
    name: "accounting.user.payments",
    component: UserPayments,
  },
  {
    path: "/accounting/overview",
    name: "accounting.overview",
    component: Overview,
    children: [
      {
        path: "transactions",
        name: "accounting.overview.transactions",
        component: ListTransactions,
      },
      {
        path: "payments",
        name: "accounting.overview.payments",
        component: ListPayments,
      },
      {
        path: "invoices",
        name: "accounting.overview.invoices",
        component: ListInvoices,
      },
    ],
  },
];
