
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    direction: {
      type: String as PropType<
        "row" | "column" | "row-reverse" | "column-reverse"
      >,
      default: "row",
    },
    align: {
      type: String as PropType<"flex-start" | "center" | "flex-end">,
      default: "flex-start",
    },
    justify: {
      type: String as PropType<"flex-start" | "center" | "flex-end">,
      default: "flex-start",
    },
  },
});
