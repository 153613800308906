import { onBeforeUnmount, onMounted, ref, Ref } from "vue";

export function useOnClickOutside(
  clickOutsideHandler: () => void
): Ref<null | HTMLElement> {
  const elRef = ref(null as null | HTMLElement);

  const listener = (event: Event) => {
    const el = elRef.value;
    const target = event.target;

    if (el == null) return;
    if (target == null) return;

    if (!el.contains(target as Node)) {
      clickOutsideHandler();
    }
  };

  onMounted(() => {
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
  });

  onBeforeUnmount(() => {
    document.removeEventListener("mousedown", listener);
    document.removeEventListener("touchstart", listener);
  });

  return elRef;
}
