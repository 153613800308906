<template>
  <card>
    <div class="meeting-listing">
      <div class="date">{{ formattedDate }}</div>
      <div class="time">om {{ formattedTime }}</div>
      <div class="location">{{ meeting.location }}</div>
    </div>
  </card>
</template>

<script lang="ts">
import { capitalize } from "@/helpers/text";
import { Meeting } from "@/types";
import { DateTime } from "luxon";
import { computed, defineComponent, PropType } from "vue";
import { dateOrStringToDateTime } from "../helpers/datetime";

export default defineComponent({
  props: {
    meeting: {
      type: Object as PropType<Meeting>,
      required: true,
    },
  },
  setup(props) {
    const meeting = computed(() => props.meeting);

    const formattedDate = computed(() => {
      if (meeting.value == null) return;
      const str = DateTime.fromJSDate(
        new Date(meeting.value.scheduled_for)
      ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
      return capitalize(str);
    });

    const formattedTime = computed(() => {
      console.log(meeting.value);
      if (meeting.value == null) return;
      let dt = dateOrStringToDateTime(meeting.value.scheduled_for);
      if (dt == null) return null;
      return capitalize(dt.toLocal().toLocaleString(DateTime.TIME_24_SIMPLE));
    });

    return {
      formattedDate,
      formattedTime,
    };
  },
});
</script>

<style lang="scss" scoped>
.meeting-listing {
  padding: 20px;
}

.date {
  font-weight: 600;
  font-size: 1.5rem;
  color: $text-dark;
}

.time {
  color: $text-gray;
}

.location {
  margin-top: 20px;
  text-align: right;
  padding-left: 10px;
  color: $text-gray;
  line-height: 1.3;
}
</style>
