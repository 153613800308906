import { Issue } from "@/repositories/issueRepository";
import { Property } from "@/repositories/propertyRepository";
import { useActiveProperty } from "@/util/properties";
import { computed, Ref } from "vue";
import { useQuery } from "vue-query";
import { useStore } from "vuex";
import { inIssues, issueActions } from "../issues";
import { useStoreAction } from "../util/hooks";

export const useIssues = () => {
  const store = useStore();
  return useQuery(
    ["properties", "issues"],
    () => store.dispatch(inIssues(issueActions.LIST)) as Promise<Issue[]>
  );
};

export const useOldIssues = () => {
  const { loading, value } = useStoreAction<Issue[]>([], async (store) => {
    return store.dispatch(inIssues(issueActions.LIST));
  });
  return {
    loading,
    issues: value,
  };
};

export const usePropertyIssues = (propertyId: Ref<number | null>) => {
  const { loading, issues } = useOldIssues();
  return {
    loading,
    issues: computed(() =>
      issues.value.filter((i) => i.property_id === propertyId.value)
    ),
  };
};

export const useActivePropertyIssues = () => {
  const property = useActiveProperty();
  const propertyId = computed(() => property.value?.id ?? null);
  return usePropertyIssues(propertyId);
};

export const useLocationOptions = (property: Ref<Property | null>) => {
  return computed(() => {
    if (property.value == null) return { "-2": "Laden.." };
    const options: Record<string, string> = {};
    if (property.value.has_basement) {
      options["kelder"] = "Kelder";
    }
    if (property.value.has_parking) {
      options["parking"] = "Parking";
    }
    options["gelijksvloers"] = "Gelijksvloers";
    for (let i = 1; i < property.value.floors; i++) {
      options[`${i}e verdieping`] = `${i}e verdieping`;
    }
    options["andere"] = "Andere";
    return options;
  });
};

export const useIssue = (issueId: Ref<number | string>) => {
  const store = useStore();
  const issueIdInt = computed(() =>
    typeof issueId.value === "string" ? parseInt(issueId.value) : issueId.value
  );
  return useQuery(
    ["properties", "issues", issueIdInt],
    () =>
      store.dispatch(inIssues(issueActions.BY_ID), {
        id: issueIdInt.value,
      }) as Promise<Issue>
  );
};

// export const useIssue = (issueId: Ref<number | string>) => {
//   return useStoreAction<Issue | null>(null, (store) => {
//     return store.dispatch(inIssues(issueActions.BY_ID), { id: issueId.value });
//   });
// };
