import { User } from "@/types";
import { ApiResponse, createFormData, del, get, patch } from "./repository";

export default {
  me(): ApiResponse<{ user: User }> {
    return get("/users/me");
  },
  patch(updates: any): ApiResponse<{ user: User }> {
    return patch("/users/me", { updates });
  },
  uploadProfileImage(file: File): ApiResponse<{ user: User }> {
    const formData = createFormData({ file });
    return patch("/users/me/avatar", formData);
  },
  deleteMe() {
    return del("/users/me");
  },
};
