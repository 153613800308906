<template>
  <page-loader v-if="chat == null" />
  <div class="chat" v-else>
    <generic-header>
      <div class="header-content">
        <div
          class="header-icon header-icon--property"
          v-if="chat.property_id != null"
        ></div>
        {{ titleForChat(chat) }}
      </div>
    </generic-header>
    <div class="messages">
      <div class="empty-notice" v-if="messages.length === 0">
        {{ $t("chat.empty_state") }}
      </div>
      <chat-message
        v-for="message in messages"
        :key="message"
        :message="message"
        :author="authorById(message.author_id)"
      />
      <!-- <chat-message /> -->
    </div>
    <write-message-bar
      @send="sendMessage"
      @add-document="addFinDocument"
      @add-issue="addIssue"
    />
  </div>
</template>

<script lang="ts">
import { authGetters, inAuth } from "@/store/auth";
import { chatsGetters, inChats } from "@/store/chats";
import { Chat, FinDocument, Message, User } from "@/types";
import { computed, defineComponent, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import WriteMessageBar from "@/components/chat/WriteMessageBar.vue";
import ChatMessage from "@/components/chat/ChatMessage.vue";
import appSocket from "@/channels/appSocket";
import { showPopup } from "@/components/popups/popups";
import SelectIssuePopup from "@/components/popups/SelectIssuePopup.vue";
import { Issue } from "@/repositories/issueRepository";
import { inProperties, PropertiesActions } from "@/store/properties";
import { Property } from "@/repositories/propertyRepository";
import SelectDocumentPopupVue from "@/components/popups/SelectDocumentPopup.vue";

export default defineComponent({
  components: { WriteMessageBar, ChatMessage },
  setup() {
    const store = useStore();
    const route = useRoute();

    const chatId = parseInt(route.params.id as string);
    const chat = computed(() =>
      store.getters[inChats(chatsGetters.CHAT_BY_ID)](chatId)
    );
    const messages = computed(() => {
      if (chat.value == null) return;
      const messages: Message[] =
        store.getters[inChats(chatsGetters.MESSAGES)](chatId);
      return messages.sort((a, b) => {
        return b.inserted_at.localeCompare(a.inserted_at);
      });
    });

    function markAllSeen() {
      if (messages.value == null) return;
      if (messages.value.length === 0) return;
      const oldest = messages.value[0];
      appSocket.getChatChannels().markSeen(chatId, oldest.id);
    }

    markAllSeen();
    watch(messages, () => {
      console.log("Marking all seen");
      markAllSeen();
    });

    const titleForChat = computed(() => (chat: Chat) => {
      if (chat.property_id != null)
        return chat.property?.name || "Er ging iets mis";

      const user: User | null = store.getters[inAuth(authGetters.USER)];
      const otherUsers = chat.users.filter((u: User) => u.id !== user?.id);

      if (otherUsers.length === 0) return "Alleen ik";

      const firstUserName =
        otherUsers[0].first_name + " " + otherUsers[0].last_name;
      if (otherUsers.length === 1) {
        return firstUserName;
      }
      return `Groepchat`;
    });

    const authorById = computed(() => (userId: number) => {
      if (chat.value == null) return null;
      return chat.value.users.find((u: User) => u.id === userId);
    });

    async function overlappingProperties(): Promise<Property[]> {
      const c: Chat | null = chat.value;
      if (c == null) return [];

      return await store.dispatch(
        inProperties(PropertiesActions.LIST_OVERLAPPING),
        {
          userIds: c.users.map((u) => u.id),
        }
      );
    }

    return {
      chat,
      titleForChat,
      messages,
      authorById,
      sendMessage(content: string) {
        appSocket.getChatChannels().sendMessage(chatId, content);
      },
      async addFinDocument() {
        const c: Chat | null = chat.value;
        if (c == null) return;

        const properties = await overlappingProperties();
        const params = {
          property_ids: properties.map((p) => p.id),
        };

        const doc: FinDocument | null = await showPopup(
          SelectDocumentPopupVue,
          params
        );
        if (doc == null) return;
        appSocket
          .getChatChannels()
          .sendMessage(chatId, doc.id.toString(), "document");
      },
      async addIssue() {
        const c: Chat | null = chat.value;
        if (c == null) return;

        let params: Record<string, any> = {};
        if (c.property_id != null) {
          params["property_id"] = c.property_id;
        } else {
          const overlappingProperties = await store.dispatch(
            inProperties(PropertiesActions.LIST_OVERLAPPING),
            {
              userIds: c.users.map((u) => u.id),
            }
          );
          params["property_ids"] = overlappingProperties.map(
            (p: Property) => p.id
          );
        }

        const issue: Issue | null = await showPopup(SelectIssuePopup, params);
        if (issue == null) return;
        appSocket
          .getChatChannels()
          .sendMessage(chatId, issue.id.toString(), "issue");
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.chat {
  display: grid;
  height: 100vh;
  max-height: 100vh;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr auto;
}

.header-content {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.header-icon {
  width: 24px;
  height: 24px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px;

  &--property {
    background-image: url("/img/chat_property.svg");
  }
}

.messages {
  padding: 0 30px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column-reverse nowrap;
  // justify-content: flex-end;
  // margin-top: auto;
  overflow-y: scroll;
  // height: auto;

  // & > :first-child {
  //   margin-top: auto;
  // }
}

.empty-notice {
  color: $text-gray;
  max-width: 315px;
  font-size: 0.875rem;
  line-height: 1.4;
  margin-bottom: 30px;
  text-align: center;
}
</style>
