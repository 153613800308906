import { User } from "@/types";

const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const fullName = (user: User) => {
  return capitalize(user.first_name) + " " + capitalize(user.last_name);
};

export const avatarUrl = (user: User) => {
  return user.avatar_url ?? "/img/empty_profile.svg";
};

export const isDefaultAvatar = (user: User) => {
  return user.avatar_url != null;
};

export const avatarStyles = (user: User) => {
  return {
    backgroundImage: `url('${avatarUrl(user)}')`,
    backgroundSize: isDefaultAvatar(user) ? "cover" : "60%",
  };
};
