
import { useActivePropertyInvoices } from "@/store/accounting/hooks";
import { computed, defineComponent, reactive } from "vue";
import Invoice from "../../components/invoices/Invoice.vue";
import FilterTags from "@/components/filtertags/FilterTags.vue";
import FilterTag from "@/components/filtertags/FilterTag.vue";
import { isInvoiceFinished } from "@/store/accounting/util";
import { notF } from "@/util/functional";

export default defineComponent({
  components: { Invoice, FilterTags, FilterTag },
  setup() {
    const { loading, invoices } = useActivePropertyInvoices();
    const filters = reactive({
      unpaid: false,
    });

    const unpaidInvoices = computed(() =>
      invoices.value.filter(notF(isInvoiceFinished))
    );

    const filteredInvoices = computed(() => {
      let result = invoices.value;
      if (filters.unpaid) {
        result = result.filter(notF(isInvoiceFinished));
      }
      return result;
    });

    return {
      invoices,
      filters,
      filteredInvoices,
      unpaidInvoices,
      loading,
    };
  },
});
