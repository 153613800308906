import propertyRepository, {
  Property,
} from "@/repositories/propertyRepository";
import {
  CreatePropertyLotParams,
  Invite,
  PatchPropertyLotParams,
  PropertyLot,
  User,
} from "@/types";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState } from ".";
import { chatsActions, inChats } from "./chats";

const LOCALSTORAGE_KEY = "PROPERTIES.ACTIVE_PROPERTY";

export type PropertiesState = {
  properties: Property[];
  activeProperty: Property | null;
};

export function inProperties(action: string): string {
  return "properties/" + action;
}

export const PropertiesActions = {
  LIST: "LIST",
  BY_ID: "BY_ID",
  CREATE: "CREATE",
  PATCH: "PATCH",
  DELETE: "DELETE",
  LIST_RESIDENTS: "LIST_RESIDENTS",
  INVITE_RESIDENT: "INVITE_RESIDENT",
  INVITED_RESIDENTS: "INVITED_RESIDENTS",
  DELETE_INVITE: "REMOVE_INVITE",
  LIST_INVITES: "LIST_INVITES",
  ACCEPT_INVITE: "ACCEPT_INVITE",
  ACCEPT_INVITE_TOKEN: "ACCEPT_INVITE_TOKEN",
  DECLINE_INVITE: "DECLINE_INVITE",
  DECLINE_INVITE_TOKEN: "DECLINE_INVITE_TOKEN",
  LIST_CONNECTIONS: "LIST_CONNECTIONS",
  LIST_OVERLAPPING: "LIST_OVERLAPPING",
  SELECT_PROPERTY: "SELECT_PROPERTY",
  CLEAR_SELECTED: "CLEAR_SELECTED",
  UPDATE_SHARES: "UPDATE_SHARES",
  USER_WITH_EMAIL_EXISTS: "USER_WITH_EMAIL_EXISTS",
  INVITE_OR_CREATE: "INVITE_OR_CREATE",
  REMOVE_FROM_PROPERTY: "REMOVE_FROM_PROPERTY",

  LIST_LOTS: "LIST_LOTS",
  LIST_LOTS_FOR_USER: "LIST_LOTS_FOR_USER",
  CREATE_LOT: "CREATE_LOT",
  PATCH_LOT: "PATCH_LOT",
  DELETE_LOT: "DELETE_LOT",
};

export const PropertiesMutations = {
  SET_PROPERTIES: "SET_PROPERTIES",
  SET_PROPERTY: "SET_PROPERTY",
  SET_ACTIVE_PROPERTY: "SET_ACTIVE_PROPERTY",
  CLEAR_SELECTED: "CLEAR_SELECTED",
};

export const PropertiesGetters = {
  PROPERTIES: "PROPERTIES",
  ACTIVE_PROPERTY: "ACTIVE_PROPERTY",
};

const state = (): PropertiesState => ({
  properties: [],
  activeProperty: null,
});

const getters: GetterTree<PropertiesState, RootState> = {
  [PropertiesGetters.PROPERTIES](state) {
    return state.properties;
  },
  [PropertiesGetters.ACTIVE_PROPERTY](state) {
    if (state.activeProperty == null && state.properties.length === 1) {
      return state.properties[0];
    }
    return state.activeProperty;
  },
};

const mutations: MutationTree<PropertiesState> = {
  [PropertiesMutations.SET_PROPERTIES](state, properties: Property[]) {
    state.properties = properties;
    if (state.activeProperty != null) {
      const activeProperty = properties.find(
        (p) => p.id === state.activeProperty?.id
      );
      if (activeProperty == null) return;
      state.activeProperty = activeProperty;
    }
  },
  [PropertiesMutations.SET_PROPERTY](state, property: Property) {
    const index = state.properties.findIndex((p) => p.id === property.id);
    if (index == null) {
      state.properties.push(property);
    } else {
      state.properties[index] = property;
    }
    if (state.activeProperty?.id === property.id) {
      state.activeProperty = property;
    }
  },
  [PropertiesMutations.SET_ACTIVE_PROPERTY](state, property: Property) {
    state.activeProperty = property;
  },
  [PropertiesMutations.CLEAR_SELECTED](state) {
    state.activeProperty = null;
  },
};

const actions: ActionTree<PropertiesState, RootState> = {
  [PropertiesActions.CLEAR_SELECTED](context): void {
    context.commit(PropertiesMutations.CLEAR_SELECTED);
  },
  async [PropertiesActions.UPDATE_SHARES](
    context,
    params: {
      propertyId: number | string;
      userId: number | string;
      newShares: number;
    }
  ): Promise<void> {
    await propertyRepository.updateShares(
      params.propertyId,
      params.userId,
      params.newShares
    );
    await context.dispatch(PropertiesActions.BY_ID, { id: params.propertyId });
  },
  async [PropertiesActions.SELECT_PROPERTY](
    context,
    params: { propertyId: number | string }
  ): Promise<void> {
    const propertyId =
      typeof params.propertyId === "number"
        ? params.propertyId
        : parseInt(params.propertyId);

    if (context.state.activeProperty?.id === propertyId) return;

    const cachedProperty = context.state.properties.find(
      (p) => p.id === propertyId
    );
    if (cachedProperty != null) {
      context.commit(PropertiesMutations.SET_ACTIVE_PROPERTY, cachedProperty);
      localStorage.setItem(LOCALSTORAGE_KEY, cachedProperty.id.toString());
      return;
    }

    const property = await context.dispatch(PropertiesActions.BY_ID, {
      id: propertyId,
    });
    if (property == null) {
      throw new Error(
        "Couldnt fetch property in SelectProperty with id " + propertyId
      );
    }
    context.commit(PropertiesMutations.SET_ACTIVE_PROPERTY, property);
    localStorage.setItem(LOCALSTORAGE_KEY, property.id.toString());
  },
  async [PropertiesActions.LIST_CONNECTIONS](): Promise<User[]> {
    const response = await propertyRepository.listConnections();
    return response.data.data.users;
  },
  async [PropertiesActions.ACCEPT_INVITE](
    context,
    params: { id: number }
  ): Promise<Invite> {
    const response = await propertyRepository.acceptInvite(params.id);
    context.dispatch(inChats(chatsActions.RE_FETCH), {}, { root: true });
    return response.data.data.invite;
  },
  async [PropertiesActions.DECLINE_INVITE](
    context,
    params: { id: number }
  ): Promise<Invite> {
    const response = await propertyRepository.declineInvite(params.id);
    return response.data.data.invite;
  },
  async [PropertiesActions.LIST_INVITES](): Promise<Invite[]> {
    const response = await propertyRepository.listInvites();
    return response.data.data.invites;
  },
  async [PropertiesActions.DELETE_INVITE](
    context,
    params: { id: number }
  ): Promise<Invite> {
    const response = await propertyRepository.deleteInvite(params.id);
    return response.data.data.invite;
  },
  async [PropertiesActions.INVITED_RESIDENTS](
    context,
    params: { id: number }
  ): Promise<Invite[]> {
    const response = await propertyRepository.listInvited(params.id);
    return response.data.data.invites;
  },
  async [PropertiesActions.INVITE_RESIDENT](
    context,
    params: { id: number; email: string }
  ): Promise<Invite> {
    const response = await propertyRepository.invite(params.id, params.email);
    return response.data.data.invite;
  },
  async [PropertiesActions.LIST_RESIDENTS](
    context,
    params: { id: number }
  ): Promise<User[]> {
    const response = await propertyRepository.listResidents(params.id);
    return response.data.data.users;
  },
  async [PropertiesActions.LIST](
    context,
    params: { allowFromCache?: boolean } = {}
  ): Promise<Property[]> {
    if (params.allowFromCache === true) {
      const cachedProperties = context.state.properties;
      if (cachedProperties.length > 0) {
        return cachedProperties;
      }
    }

    const response = await propertyRepository.listProperties();
    const properties = response.data.data.properties;

    const savedActiveId = localStorage.getItem(LOCALSTORAGE_KEY);
    if (context.state.activeProperty == null && savedActiveId != null) {
      const savedActiveIdInt = parseInt(savedActiveId);

      const property = properties.find((p) => p.id === savedActiveIdInt);
      if (property != null) {
        context.commit(PropertiesMutations.SET_ACTIVE_PROPERTY, property);
      }
    }

    context.commit(PropertiesMutations.SET_PROPERTIES, properties);
    return properties;
  },
  async [PropertiesActions.LIST_OVERLAPPING](
    context,
    params: { userIds: number[] }
  ) {
    const response = await propertyRepository.listOverlappingProperties(
      params.userIds
    );
    return response.data.data.properties;
  },
  async [PropertiesActions.BY_ID](
    context,
    params: { id: number }
  ): Promise<Property> {
    const response = await propertyRepository.propertyById(params.id);
    const property = response.data.data.property;
    context.commit(PropertiesMutations.SET_PROPERTY, property);
    return property;
  },
  async [PropertiesActions.CREATE](context, params: any): Promise<Property> {
    const response = await propertyRepository.create(params);
    return response.data.data.property;
  },
  async [PropertiesActions.PATCH](
    context,
    params: {
      id: number;
      updates: any;
    }
  ): Promise<Property> {
    const response = await propertyRepository.patch(params.id, params.updates);
    await context.dispatch(PropertiesActions.LIST);
    return response.data.data.property;
  },
  async [PropertiesActions.ACCEPT_INVITE_TOKEN](
    context,
    params: { token: string }
  ): Promise<Invite> {
    const response = await propertyRepository.acceptInviteWithToken(
      params.token
    );
    return response.data.data.invite;
  },
  async [PropertiesActions.DECLINE_INVITE_TOKEN](
    context,
    params: { token: string }
  ): Promise<Invite> {
    const response = await propertyRepository.declineInviteWithToken(
      params.token
    );
    return response.data.data.invite;
  },
  async [PropertiesActions.DELETE](
    context,
    params: { id: number }
  ): Promise<Property> {
    const response = await propertyRepository.del(params.id);
    context.commit(PropertiesMutations.SET_ACTIVE_PROPERTY, null);
    return response.data.data.property;
  },
  async [PropertiesActions.USER_WITH_EMAIL_EXISTS](
    context,
    email: string
  ): Promise<boolean> {
    const response = await propertyRepository.userWithEmailExists(email);
    return response.data.data.exists;
  },
  async [PropertiesActions.INVITE_OR_CREATE](
    context,
    params: {
      propertyId: number | string;
      email: string;
      firstName?: string;
      lastName?: string;
    }
  ): Promise<void> {
    await propertyRepository.inviteOrCreate(
      params.propertyId,
      params.email,
      params.firstName ?? undefined,
      params.lastName ?? undefined
    );
  },
  async [PropertiesActions.LIST_LOTS](
    context,
    params: {
      propertyId: string | number;
    }
  ): Promise<PropertyLot[]> {
    const response = await propertyRepository.listLots(params.propertyId);
    return response.data.data.lots;
  },
  async [PropertiesActions.LIST_LOTS_FOR_USER](
    context,
    params: {
      propertyId: string | number;
      userId: string | number;
    }
  ): Promise<PropertyLot[]> {
    const response = await propertyRepository.listLotsForUser(
      params.propertyId,
      params.userId
    );
    return response.data.data.lots;
  },
  async [PropertiesActions.CREATE_LOT](
    context,
    params: CreatePropertyLotParams
  ): Promise<PropertyLot> {
    const response = await propertyRepository.createLot(params);
    return response.data.data.lot;
  },
  async [PropertiesActions.PATCH_LOT](
    context,
    params: {
      lotId: string | number;
      changes: PatchPropertyLotParams;
    }
  ): Promise<PropertyLot> {
    const response = await propertyRepository.patchLot(
      params.lotId,
      params.changes
    );
    return response.data.data.lot;
  },
  async [PropertiesActions.DELETE_LOT](
    context,
    params: { lotId: string | number }
  ): Promise<void> {
    await propertyRepository.deleteLot(params.lotId);
  },
  async [PropertiesActions.REMOVE_FROM_PROPERTY](
    context,
    params: {
      userId: string | number;
      propertyId: string | number;
    }
  ): Promise<void> {
    await propertyRepository.removeFromProperty(
      params.userId,
      params.propertyId
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
