import { RouteRecordRaw } from "vue-router";
import ListLots from "./pages/ListLots.vue";

export const routes: RouteRecordRaw[] = [
  {
    path: "/lots",
    name: "lots.list",
    component: ListLots,
  },
];
