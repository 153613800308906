
import { FileItem } from "@/repositories/fileRepository";
import { filesActions, inFiles } from "@/store/files";
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
import Loader from "@/components/icons/Loader.vue";
import { showLightboxPopup } from "../util/LightBox.vue";

export default defineComponent({
  components: { Loader },
  props: {
    imageId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const imageId = computed(() => Number(props.imageId));
    const currentImage = ref(null as null | FileItem);

    const updateImage = async () => {
      let image = await store.dispatch(inFiles(filesActions.GET_ONE), {
        id: imageId.value,
      });
      console.log("Image:", image, imageId.value);
      currentImage.value = image;
    };

    watch(imageId, updateImage);
    updateImage();

    const currentUrl = computed(() => currentImage.value?.url);

    return {
      currentUrl,
      showLightbox() {
        if (currentImage.value == null) return;
        showLightboxPopup([currentImage.value.url]);
      },
    };
  },
});
