<template>
  <popup-wrapper max-width="400px" @finish="$emit('finish')">
    <div class="add-resident-payment">
      <div class="content">
        <h2 class="title">Resident betaling toevoegen</h2>
        <app-form
          :submitHandler="handleSubmit"
          thinner
          submitText="Betaling toevoegen"
          submitAlwaysFull
        >
          <form-field>
            <dropdown
              label="Mede-eigenaar"
              placeholder="Van wie komt deze betaling?"
              :options="residentOptions"
              v-model="state.selectedResident"
            />
            <field-error :fieldErrors="fieldErrors" name="resident" />
          </form-field>
          <!-- <form-field>
            <textbox
              thinner
              label="Factuur totaal (euro incl. BTW)"
              placeholder="Voor hoeveel euro was dit factuur?"
              v-model="state.amountDisplayed"
              @blur="formatAmount"
            />
            <field-error :fieldErrors="fieldErrors" name="amount" />
          </form-field> -->
        </app-form>
      </div>
    </div>
  </popup-wrapper>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  ref,
  watch,
  watchEffect,
} from "vue";
import PopupWrapper from "./PopupWrapper.vue";
import AppForm from "@/components/form/AppForm.vue";
import FormField from "@/components/form/FormField.vue";
import FieldError from "@/components/form/FieldError.vue";
import { CreatePaymentParams, Transaction, User } from "@/types";
import { formatAsEuro, parseToRawPrice } from "@/util/price";
import { useStore } from "vuex";
import { accountingActions, inAccounting } from "@/store/accounting/accounting";
import { inProperties, PropertiesActions } from "@/store/properties";
import { fullName } from "@/helpers/user";
import { getTransactionOutstandingAmount } from "@/store/accounting/util";

export default defineComponent({
  components: { PopupWrapper, AppForm, FormField, FieldError },
  props: {
    params: {
      type: Object as PropType<{ transaction: Transaction }>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const residents = ref([] as User[]);
    const state = reactive({
      selectedResident: null as null | string,
      amount: getTransactionOutstandingAmount(props.params.transaction) as
        | number
        | null,
      amountDisplayed: formatAsEuro(
        getTransactionOutstandingAmount(props.params.transaction),
        {
          noEuroSign: true,
        }
      ),
    });

    watchEffect(() => {
      store
        .dispatch(inProperties(PropertiesActions.LIST_RESIDENTS), {
          id: props.params.transaction.property_id,
        })
        .then((values) => {
          residents.value = values;
        });
    });

    const residentOptions = computed(() =>
      residents.value.reduce<{ [id: string]: string }>(
        (result, resident) =>
          Object.assign(result, { [resident.id]: fullName(resident) }),
        {}
      )
    );

    const formatAmount = () => {
      state.amountDisplayed = formatAsEuro(state.amount ?? 0, {
        noEuroSign: true,
      });
    };
    watch(
      () => state.amountDisplayed,
      (amountDisplayed: string) => {
        state.amount = parseToRawPrice(amountDisplayed);
      }
    );

    const fieldErrors = ref({} as Record<string, string[]>);

    return {
      state,
      residents,
      residentOptions,
      fieldErrors,
      formatAmount,
      async handleSubmit() {
        formatAmount();

        fieldErrors.value = {};
        if (state.selectedResident == null) {
          fieldErrors.value["resident"] = [
            "Gelieve een resident te selecteren.",
          ];
        }
        if (state.amount == null) {
          fieldErrors.value["amount"] = ["Ongeldige waarde"];
        }
        if (
          state.amount != null &&
          state.amount > props.params.transaction.amount
        ) {
          const max = (props.params.transaction.amount / 100).toFixed(2);
          fieldErrors.value["amount"] = [`Maximum waarde van €${max}`];
          state.amount = props.params.transaction.amount;
          formatAmount();
        }
        if (Object.keys(fieldErrors.value).length > 0) return;

        if (state.selectedResident == null) return;
        await store.dispatch(inAccounting(accountingActions.CREATE_PAYMENT), {
          propertyId: props.params.transaction.property_id,
          createParams: {
            amount: state.amount,
            user_id: parseInt(state.selectedResident),
            transaction_id: props.params.transaction.id,
          } as CreatePaymentParams,
        });
        emit("finish");
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.content {
  padding: 30px 30px 10px;
}
.content-loader {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  line-height: 120%;
  font-size: 1.5rem;
  font-weight: 600;
  color: $text-alt;
  margin-bottom: 20px;
}

.file-input {
  display: none;
}

.loader-wrapper {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
