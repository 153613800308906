import { UnauthorizedException } from "@/error_handling/UnauthorizedException";
import { DateTime } from "luxon";
import { Store } from "vuex";
import { authActions, inAuth } from "../auth";
import router from "@/router";
import store from "..";
import { RefreshFailedException } from "@/error_handling/RefreshFailedException";
import appSocket from "@/channels/appSocket";
import { clearStore } from "../util/clear";

let lastRefreshAttempt: DateTime | null = null;

export const standardErrorActions = (store: Store<any>) => {
  const dispatchFunc = store.dispatch;

  store.dispatch = async (type: any, params: any) => {
    const runDispatch = () => {
      return dispatchFunc.apply(store, [type, params]);
    };

    // Attempt running
    try {
      return await runDispatch();
    } catch (e) {
      if (e instanceof UnauthorizedException) {
        await attemptRefresh();

        // Retry
        try {
          return await runDispatch();
        } catch (e) {
          handleFailure();
        }
      }

      throw e;
    }
  };
};

const attemptRefresh = async () => {
  if (
    lastRefreshAttempt != null &&
    lastRefreshAttempt.diffNow("seconds").seconds < 60
  ) {
    handleFailure();
    return;
  }

  lastRefreshAttempt = DateTime.utc();
  try {
    await store.dispatch(inAuth(authActions.FETCH_ACCESS_TOKEN));
  } catch (e) {
    if (e instanceof RefreshFailedException) {
      handleFailure();
    }
    console.error(e);
  }
};

const handleFailure = async () => {
  const requireAuth = router.currentRoute.value.meta?.requireAuth;
  if (requireAuth === false) return;

  appSocket.logout();
  await clearStore();
  console.log("StandardErrorActions: Failed to recover auth, routing to login");
  router.push({
    name: "login",
  });
};
