<template>
  <div class="date-picker">
    <app-label :for-id="id" v-if="label">{{ label }}</app-label>
    <datepicker
      :placeholder="placeholder"
      auto-apply
      v-model="date"
      :min-date="new Date()"
      :enable-time-picker="false"
      :format="formatDate"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect } from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { DateTime } from "luxon";

export default defineComponent({
  components: { Datepicker },
  props: {
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Date,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const date = ref<Date>(
      props.modelValue ? new Date(props.modelValue) : new Date()
    );
    if (props.modelValue == null) {
      emit("update:modelValue", date.value);
    }
    watchEffect(() => {
      if (
        new Date(date?.value as any).getTime() !==
        new Date(props.modelValue).getTime()
      ) {
        emit("update:modelValue", date.value);
      }
    });

    const formatDate = (date: Date) => {
      if (date == null) return null;
      if (typeof date === "string") {
        date = new Date(date);
      }
      const dt = DateTime.fromJSDate(date);
      return dt.toFormat("dd/LL/yyyy");
    };

    return {
      date,
      formatDate,
    };
  },
});
</script>

<style lang="scss">
.date-picker {
  .dp__input {
    width: 100%;

    background-color: white;
    border: 1px solid $border-general;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 400;
    font-family: $font-text;
    letter-spacing: 0.02rem;
    color: $text-dark;
    padding: 20px;
    padding-left: 35px;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0 2px 2px rgba($bg-accent, 0);

    &:focus {
      border-color: $bg-accent;
      box-shadow: 0 0 2px 2px rgba($bg-accent, 0.4);
      outline: none;
    }
  }
}
</style>
