import { Ref, ref } from "vue";

export interface Loader {
  isLoading: Ref<boolean>;
  use: <T>(item: () => Promise<T>) => () => Promise<T | null>;
}

export const useLoader = (): Loader => {
  const isLoading = ref(false);

  return {
    isLoading,
    use<T>(item: () => Promise<T>): () => Promise<T | null> {
      return async () => {
        if (isLoading.value) return null;
        isLoading.value = true;
        const result = await item();
        isLoading.value = false;
        return result;
      };
    },
  };
};
