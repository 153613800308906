<template>
  <svg
    :width="size ?? width"
    :height="size ?? height"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="{ cross }"
  >
    <path
      d="M11 1V21"
      stroke="currentColor"
      :stroke-width="strokeWidth"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M21 11H1"
      stroke="currentColor"
      :stroke-width="strokeWidth"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    // color: {
    //   type: String,
    //   default: "#111111",
    // },
    width: {
      type: String,
      default: "22",
    },
    height: {
      type: String,
      default: "22",
    },
    size: {
      type: String,
      default: null,
    },
    cross: {
      type: Boolean,
      default: false,
    },
    strokeWidth: {
      type: String,
      default: "1",
    },
  },
});
</script>

<style lang="scss" scoped>
.cross {
  transform: rotate(45deg);
}
</style>
