import { onMounted, onUnmounted } from "vue";
import mitt from "mitt";

const emitter = mitt();

export const invalidate = (key: string) => {
  emitter.emit(key, null);
};

export const useInvalidatable = (
  keys: (string | number)[],
  onInvalidate: () => void
) => {
  const callback = () => onInvalidate();
  onMounted(() => {
    keys.forEach((key) => {
      emitter.on(key.toString(), callback);
    });
  });
  onUnmounted(() => {
    keys.forEach((key) => {
      emitter.off(key.toString(), callback);
    });
  });
};
