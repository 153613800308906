<template>
  <generic-page-popup title="Nieuwe melding">
    <div class="create-issue">
      <generated-form
        submitText="Aanmaken"
        :fieldSettings="settings"
        :submitHandler="submit"
      />
    </div>
  </generic-page-popup>
</template>

<script lang="ts">
import { showPopup } from "@/components/popups/popups";
import { computed, defineComponent, Ref } from "vue";
import GeneratedForm, {
  FieldsSettings,
} from "@/components/form/GeneratedForm.vue";
import { useLocationOptions } from "@/store/issues/hooks";
import { useActiveProperty } from "@/util/properties";
import { useStore } from "vuex";
import { inIssues, issueActions } from "@/store/issues";
import { optimizeImage } from "@/util/image";
import { filesActions, inFiles } from "@/store/files";
import { Issue } from "@/repositories/issueRepository";
import { isCurrentPropertyAdmin } from "@/util/auth";

const component = defineComponent({
  components: { GeneratedForm },
  setup(props, { emit }) {
    const store = useStore();
    const property = useActiveProperty();
    const locationOptions = useLocationOptions(property);
    const isAdmin = isCurrentPropertyAdmin();

    const settings: Ref<FieldsSettings> = computed(
      () =>
        [
          {
            type: "string",
            name: "title",
            label: "Titel",
            meta: { placeholder: "Lamp tweede verdieping stuk" },
            subCheckbox: {
              name: "urgent",
              label: isAdmin.value
                ? 'Dringend! (voegt de "dringend" tag toe)'
                : "Dringend! (verstuurd een email naar de beheerder)",
            },
          },
          {
            type: "dropdown",
            name: "location",
            label: "Locatie",
            meta: {
              placeholder: "Selecteer locatie",
              options: locationOptions.value,
            },
          },
          {
            type: "dropdown",
            name: "visibility",
            label: "Wie kan deze melding zien?",
            meta: {
              placeholder: "Selecteer locatie",
              options: {
                public: "Alle residenten",
                private: "Enkel de beheerder",
              },
              default: "public",
            },
          },
          {
            type: "textarea",
            name: "description",
            label: "Beschrijving",
            meta: {
              placeholder: "Deze morgen zag ik...",
            },
          },
          {
            type: "images",
            name: "images",
            label: "Foto's",
            meta: {
              async upload(file: File) {
                const blob = await optimizeImage(file, 1000);
                return await store.dispatch(inFiles(filesActions.UPLOAD), {
                  file: blob,
                });
              },
            },
          },
        ] as FieldsSettings
    );

    return {
      settings,
      async submit(formData: any) {
        if (property.value == null) return;
        const result = await store.dispatch(inIssues(issueActions.CREATE), {
          propertyId: property.value.id,
          issueParams: {
            title: formData.title,
            message: formData.description,
            pictures: formData.images?.map((i: any) => i.id) ?? [],
            type: formData.type,
            location: formData.location,
            urgent: formData.urgent,
            private: formData.visibility === "private",
          },
        });
        emit("finish", result);
      },
    };
  },
});

export const showCreateIssuePopup = (): Promise<null | Issue> => {
  return showPopup(component, {});
};

export default component;
</script>
