
import { errorTag } from "@/components/common/Card.vue";
import {
  getInvoiceOutstandingAmount,
  isInvoiceFinished,
} from "@/store/accounting/util";
import { Invoice } from "@/types";
import { formatAsEuro } from "@/util/price";
import { formatDate } from "@/util/time";
import { computed, defineComponent, PropType, watchEffect } from "vue";
import TransactionInvoiceMini from "./TransactionInvoiceMini.vue";

export default defineComponent({
  components: { TransactionInvoiceMini },
  props: {
    invoice: {
      type: Object as PropType<Invoice>,
      required: true,
    },
  },
  setup(props) {
    const isFinished = computed(() => isInvoiceFinished(props.invoice));

    const transactionInvoiceById = (transactionId: number) => {
      return props.invoice.transactions_invoices?.find(
        (ti) => ti.transaction_id === transactionId
      );
    };

    watchEffect(() => console.log(props.invoice));

    return {
      transactionInvoiceById,
      outstandingAmount: computed(() =>
        formatAsEuro(getInvoiceOutstandingAmount(props.invoice))
      ),
      formattedDate: computed(() => formatDate(props.invoice.inserted_at)),
      amount: computed(() => formatAsEuro(props.invoice.amount)),
      tags: isFinished.value ? [] : [errorTag("Onvolledig")],
    };
  },
});
