import { Time } from "@/types";
import { DateTime } from "luxon";

export const mergeDateAndTime = (date: Date, time: Time) => {
  if (date == null) return null;
  if (time == null) return date;

  const dateObj = DateTime.fromJSDate(new Date(date));
  const newDateObj = dateObj.set({
    hour: time.hours,
    minute: time.minutes,
  });

  return newDateObj.toJSDate();
};

export const dateOrStringToDateTime = (
  date: Date | string
): DateTime | null => {
  if (date == null) return null;
  if (typeof date === "string") {
    return DateTime.fromISO(date, { zone: "utc" }).toLocal();
  }
  return DateTime.fromJSDate(date);
};

export const dateOrStringToDate = (date: Date | string): Date | null => {
  if (date == null) return null;
  return dateOrStringToDateTime(date)?.toJSDate() ?? null;
};
