import { i18n } from "@/plugins/install_i18n";
import userRepository from "@/repositories/userRepository";
import { LOCALSTORAGE_KEY } from "@/router/middleware/langMiddleware";
import { User } from "@/types";
import { Settings } from "luxon";
import { ActionContext, GetterTree } from "vuex";
import { RootState } from ".";

interface UsersState {
  language: string;
}

type UsersActionContext = ActionContext<UsersState, RootState>;

export function inUsers(action: string): string {
  return "users/" + action;
}

export const usersActions = {
  ME: "ME",
  UPDATE: "UPDATE",
  UPLOAD_PROFILE_PICTURE: "UPLOAD_PROFILE",
  SET_LANGUAGE: "SET_LANGUAGE",
};

export const usersGetters = {
  LANGUAGE: "LANGUAGE",
};

const state = (): UsersState => ({
  language: "nl",
});

const getters: GetterTree<UsersState, RootState> = {
  [usersGetters.LANGUAGE](state) {
    return state.language;
  },
};

const actions = {
  [usersActions.SET_LANGUAGE](
    context: UsersActionContext,
    params: { language: string }
  ) {
    i18n.global.locale.value = params.language;
    context.state.language = params.language;
    localStorage.setItem(LOCALSTORAGE_KEY, params.language);
    Settings.defaultLocale = params.language;
  },
  async [usersActions.ME](): Promise<User> {
    const response = await userRepository.me();
    return response.data.data.user;
  },
  async [usersActions.UPDATE](
    context: UsersActionContext,
    params: { updates: any }
  ): Promise<User> {
    const response = await userRepository.patch(params.updates);
    return response.data.data.user;
  },
  async [usersActions.UPLOAD_PROFILE_PICTURE](
    context: UsersActionContext,
    params: { file: File }
  ): Promise<User> {
    const respones = await userRepository.uploadProfileImage(params.file);
    return respones.data.data.user;
  },
};

export default {
  namespaced: true,
  state,
  mutations: {},
  actions,
  getters,
};
