import { ValidationException } from "@/error_handling/ValidationException";
import axios, { AxiosResponse, Method } from "axios";
import store from "@/store";
import { authActions, inAuth } from "@/store/auth";
import { DuplicateException } from "@/error_handling/DuplicateException";
import { UnauthorizedException } from "@/error_handling/UnauthorizedException";

export type ApiResponse<T> = Promise<AxiosResponse<{ data: T }>>;

// const defaultBaseUrl = "https://core-api.mysyndic.com/v1";
const defaultBaseUrl =
  process.env.NODE_ENV === "production"
    ? "https://core-api.mysyndic.com/v1"
    : "http://localhost:4000/v1";

// const defaultBaseUrl = "https://core-api.mysyndic.com/v1";
const baseURL = process.env.VUE_APP_API_BASE_URL || defaultBaseUrl;

const instance = axios.create({
  // baseURL: process.env.VUE_APP_API_BASE_URL || "http://localhost:4000/v1",
  baseURL,
});

export async function call<T>(
  method: Method,
  path: string,
  data?: any,
  options?: RequestOptions
): ApiResponse<T> {
  try {
    let accessToken = undefined;
    if (options?.noAuth == null || options.noAuth === false) {
      accessToken = await store.dispatch(
        inAuth(authActions.FETCH_ACCESS_TOKEN)
      );
    }
    const response = await instance.request({
      method,
      url: path,
      data,
      headers:
        accessToken == null ? {} : { Authorization: "Bearer " + accessToken },
    });
    return response;
  } catch (err) {
    const e = err as any;
    if (e.response) {
      if (e.response.status === 422) {
        throw new ValidationException(e.response);
      }
      if (e.response.status === 409) {
        throw new DuplicateException();
      }
      if (e.response.status === 401) {
        // if (router.currentRoute.value.meta?.requireAuth === false) {
        //   throw new UnauthorizedException();
        // }
        throw new UnauthorizedException();
        // router.push({
        //   name: "login",
        // });
      }
    }
    // logBreakingException(e);
    throw e;
  }
}

export interface RequestOptions {
  noAuth: boolean;
}

export function get<T>(path: string, options?: RequestOptions): ApiResponse<T> {
  return call<T>("GET", path, undefined, options);
}

export function post<T>(
  path: string,
  data?: any,
  options?: RequestOptions
): ApiResponse<T> {
  return call<T>("POST", path, data, options);
}

export function patch<T>(
  path: string,
  data?: any,
  options?: RequestOptions
): ApiResponse<T> {
  return call<T>("PATCH", path, data, options);
}

export function put<T>(
  path: string,
  data?: any,
  options?: RequestOptions
): ApiResponse<T> {
  return call<T>("PUT", path, data, options);
}

export function del<T>(
  path: string,
  data?: any,
  options?: RequestOptions
): ApiResponse<T> {
  return call<T>("DELETE", path, data, options);
}

export function createFormData(params: Record<string, Blob | string>) {
  const formData = new FormData();
  Object.entries(params).forEach(
    ([key, value]) => value !== undefined && formData.append(key, value)
  );
  return formData;
}

export function redirectTo(path: string): void {
  window.location.href = baseURL + path;
}
