<template>
  <generic-page-popup title="Mede-eigenaar" use-margin-top>
    <div class="show-resident">
      <div class="textbox-wrapper">
        <textbox
          thinner
          label="Email adres"
          :modelValue="params.resident.email"
          disabled
          placeholder="Email van mede-eigenaar"
          type="email"
        />
      </div>
      <div class="textbox-wrapper">
        <textbox
          thinner
          label="Voornaam"
          :modelValue="params.resident.first_name"
          name="voornaam"
          placeholder="Voornaam van mede-eigenaar"
          disabled
        />
      </div>
      <div class="textbox-wrapper">
        <textbox
          thinner
          label="Naam"
          :modelValue="params.resident.last_name"
          name="naam"
          placeholder="Naam van mede-eigenaar"
          disabled
        />
      </div>
      <div class="textbox-wrapper">
        <textbox
          thinner
          label="Telefoonnummer"
          :modelValue="params.resident.tel"
          name="tel"
          placeholder="Geen telefoonnummer opgegeven"
          disabled
        />
      </div>
      <div class="lots-wrapper">
        <h2 class="lots-title">Kavels van mede-eigenaar</h2>
        <div class="lots">
          <div class="lot" v-for="lot in lots" :key="lot.id">
            <lot-item :lot="lot" hide-avatar @select="showLot(lot)" />
          </div>
          <div class="empty" v-if="lots.length === 0 && !loadingLots">
            Deze mede-eigenaar heeft geen kavels
          </div>
        </div>
      </div>
      <div class="buttons" v-if="!isCurrentUser">
        <app-button v-if="isAdmin" @click="deleteUser" thinner danger
          >Mede-eigenaar verwijderen</app-button
        >
        <app-link v-if="isAdmin" @click="makeAdmin"
          >Geef deze mede-eigenaar de beheerder rol</app-link
        >
      </div>
    </div>
  </generic-page-popup>
</template>

<script lang="ts">
import { showConfirmPopup } from "@/components/popups/Confirm.vue";
import { showPopup } from "@/components/popups/popups";
import { inProperties, PropertiesActions } from "@/store/properties";
import { useLots } from "@/store/properties/hooks";
import { PropertyLot, User } from "@/types";
import { isCurrentPropertyAdmin, useCurrentUser } from "@/util/auth";
import { useActiveProperty } from "@/util/properties";
import { computed, defineComponent, PropType, ref } from "vue";
import { useStore } from "vuex";
import LotItem from "@/features/lots/components/LotItem.vue";
import { showEditLotPopup } from "@/features/lots/popups/EditLotPopup.vue";

const component = defineComponent({
  components: { LotItem },
  props: {
    params: {
      type: Object as PropType<{
        resident: User;
      }>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const deleting = ref(false);
    const activeProperty = useActiveProperty();
    const isAdmin = isCurrentPropertyAdmin();
    const currentUser = useCurrentUser();
    const isCurrentUser = computed(
      () => props.params.resident.id === currentUser.value?.id
    );
    const { loading: loadingLots, lots } = useLots();
    const userLots = computed(() =>
      lots.value.filter(
        (lot) => lot.main_responsible_id === props.params.resident.id
      )
    );

    return {
      isCurrentUser,
      deleting,
      isAdmin,
      loadingLots,
      lots: userLots,
      showLot(lot: PropertyLot) {
        let types = computed(() =>
          (lots.value ?? []).map((lot) => lot.type).filter((v) => v != null)
        );
        showEditLotPopup(lot, types.value);
      },
      async makeAdmin() {
        if (activeProperty.value == null || props.params == null) return;
        deleting.value = true;
        try {
          const result = await showConfirmPopup({
            title: "Bent u zeker?",
            subTitle:
              "Er kan maar 1 beheerder zijn per residentie, u zal deze rol dus verliezen.",
            confirmText: "Rol overdragen",
            declineText: "Annuleren",
          });
          if (result !== true) return;
          await store.dispatch(inProperties(PropertiesActions.PATCH), {
            id: activeProperty.value.id,
            updates: { admin_id: props.params.resident.id },
          });
        } finally {
          deleting.value = false;
        }
      },
      async deleteUser() {
        if (activeProperty.value == null || props.params == null) return;
        deleting.value = true;
        try {
          const result = await showConfirmPopup({
            title: "Bent u zeker?",
            confirmText: "Verwijder gebruiker",
            declineText: "Behoud gebruiker",
            subTitle:
              "De gebruiker zal niet langer toegang hebben tot deze residentie",
          });
          if (result !== true) return;
          await store.dispatch(
            inProperties(PropertiesActions.REMOVE_FROM_PROPERTY),
            {
              userId: props.params?.resident.id,
              propertyId: activeProperty.value?.id,
            }
          );
          emit("finish", "deleted");
        } finally {
          deleting.value = false;
        }
      },
    };
  },
});

export const showViewResidentPopup = (
  resident: User
): Promise<undefined | "deleted"> => {
  return showPopup(component, { resident }) as any;
};

export default component;
</script>

<style lang="scss" scoped>
.textbox-wrapper {
  margin-bottom: 15px;
}

.buttons {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  text-align: center;

  margin-top: 25px;
  margin-bottom: 20px;

  @include screen-from("tablet") {
    display: flex;
    flex-flow: row-reverse nowrap;
    align-items: center;
    justify-content: space-between;
  }
}

.lots {
  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;
  margin-bottom: 50px;
}

.lot {
  margin-right: 6px;
  margin-bottom: 6px;
}

.empty {
  color: $text-gray;
}
</style>
