import { DateTime } from "luxon";

const shortTimeagoset = {
  J: "J",
  M: "M",
  D: "D",
  H: "U",
  m: "m",
  NOW: "Zo net",
};

const longTimeagoSet = {
  J: "Jaar",
  M: "Maand",
  D: "Dag",
  H: "Uur",
  m: "Minuten",
  NOW: "Zo net",
};

export function timeago(
  isoDate: string,
  suffix = "",
  long = false,
  prefix = long ? " " : ""
): string {
  const wordSet = long ? longTimeagoSet : shortTimeagoset;

  const res = DateTime.utc().diff(
    DateTime.fromISO(isoDate, { zone: "utc" }),
    "seconds"
  );
  const seconds = res.seconds;

  let interval = seconds / 31536000;
  if (interval >= 1) {
    return Math.floor(interval) + prefix + wordSet["J"] + suffix;
  }
  interval = seconds / 2592000;
  if (interval >= 1) {
    return Math.floor(interval) + prefix + wordSet["M"] + suffix;
  }
  interval = seconds / 86400;
  if (interval >= 1) {
    return Math.floor(interval) + prefix + wordSet["D"] + suffix;
  }
  interval = seconds / 3600;
  if (interval >= 1) {
    return Math.floor(interval) + prefix + wordSet["H"] + suffix;
  }
  // interval = seconds / 60;
  // if (interval >= 1) {
  //   return Math.floor(interval) + wordSet["m"] + suffix;
  // }
  return "Zo net";
  // return Math.floor(seconds) + "S";
}
