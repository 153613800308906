<template>
  <div class="time-picker">
    <app-label :for-id="id" v-if="label">{{ label }}</app-label>
    <datepicker
      :placeholder="placeholder"
      time-picker
      auto-apply
      v-model="time"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watchEffect } from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { DateTime } from "luxon";
import { Time } from "@/types";

const currentTime = (): Time => {
  const now = DateTime.now();
  return {
    hours: now.hour,
    minutes: now.minute,
    seconds: now.second,
  };
};

export default defineComponent({
  components: { Datepicker },
  props: {
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Object as PropType<Time>,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const time = ref<Time>(props.modelValue ?? currentTime());

    watchEffect(() => {
      emit("update:modelValue", time.value);
    });

    return {
      time,
    };
  },
});
</script>

<style lang="scss">
.time-picker {
  .dp__input {
    width: 100%;

    background-color: white;
    border: 1px solid $border-general;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 400;
    font-family: $font-text;
    letter-spacing: 0.02rem;
    color: $text-dark;
    padding: 20px;
    padding-left: 35px;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0 2px 2px rgba($bg-accent, 0);

    &:focus {
      border-color: $bg-accent;
      box-shadow: 0 0 2px 2px rgba($bg-accent, 0.4);
      outline: none;
    }
  }
}
</style>
