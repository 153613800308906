<template>
  <generic-finance-page title="Betaalde facturen">
    Invoices
    <!--  -->
  </generic-finance-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import GenericFinancePage from "@/features/finance/components/GenericFinancePage.vue";

export default defineComponent({
  components: { GenericFinancePage },
});
</script>
