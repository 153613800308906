<template>
  <div
    class="property-option"
    :class="{ error, success, 'no-margin-x': noMarginX }"
    @click="$emit('click')"
  >
    <div class="icon">
      <slot name="icon" />
    </div>
    <div class="title">
      <slot />
    </div>
    <div class="count" v-if="count > 0">
      {{ count }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    noMarginX: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
      default: 0,
    },
  },
});
</script>

<style lang="scss" scoped>
.property-option {
  position: relative;
  padding: 25px 25px;
  box-sizing: border-box;
  margin-top: 15px;
  /* margin-left: 20px;
  margin-right: 20px; */
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 5px;
  background-color: white;
  border: 1px solid $border-general;
  box-shadow: $shadow-general;
  cursor: pointer;
  user-select: none;

  &.error {
    border-color: $border-error;
  }

  &.success {
    border-color: $border-success;
  }

  &.no-margin-x {
    margin: 15px 0;

    &:first-child {
      margin-top: 25px;
    }
  }
}

.icon {
  color: $text-gray;
  margin-right: 20px;
  width: 32px;
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
}
.error .icon {
  color: $text-error;
}

.title {
  font-size: 1.3125rem;
  color: $text-alt;
  font-weight: 600;
}
.error .title {
  color: $text-error;
}

.count {
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  font-family: $font-text;
  font-weight: 600;
  font-size: 1.125rem;
  background-color: $bg-accent;
  color: $text-alt;
}
.property-option.error .count {
  background-color: $bg-error;
  color: $text-light;
}
.property-option.success .count {
  background-color: $bg-success;
  color: $text-light;
}
</style>
