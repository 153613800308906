
import { computed, defineComponent } from "vue";
import Logo from "@/components/Logo.vue";
import ProfileItem from "./ProfileItem.vue";
import ProjectSelector from "../util/PropertySelector.vue";
import { useCurrentUser, useHasAuth } from "@/util/auth";
import { useActiveProperty } from "@/util/properties";
import { useAnyNewMessages } from "@/util/chat";
import InvitationDropdown from "@/components/notifications/InvitationDropdown.vue";

export default defineComponent({
  components: { Logo, ProfileItem, ProjectSelector, InvitationDropdown },
  props: {
    /**
     * If true will hide the component below tablet-wide breakpoint
     */
    responsive: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const hasAuth = useHasAuth();
    const user = useCurrentUser();
    const activeProperty = useActiveProperty();

    const isAdmin = computed(() => {
      return (
        user.value != null &&
        activeProperty.value != null &&
        user.value.id === activeProperty.value.admin_id
      );
    });

    const anyNewMessages = useAnyNewMessages();

    return {
      anyNewMessages,
      hasAuth,
      activeProperty,
      isAdmin,
    };
  },
});
