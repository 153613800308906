
import { computed, defineComponent } from "vue";
import IssueListing from "@/components/property/IssueListing.vue";
import { Issue } from "@/repositories/issueRepository";
import { useActiveProperty } from "@/util/properties";
import BottomMiddleLink from "@/components/BottomMiddleLink.vue";
import { GenericPageOption } from "@/components/common/generic-page/GenericPageOptions.vue";
import { showCreateIssuePopup } from "@/features/issues/popups/CreateIssue.vue";
import { showViewIssuePopup } from "@/features/issues/popups/ViewIssue.vue";
import { showResolvedIssuesPopup } from "@/features/issues/popups/ResolvedIssues.vue";
import { useIssues } from "@/store/issues/hooks";

export default defineComponent({
  components: { IssueListing, BottomMiddleLink },
  setup() {
    const activeProperty = useActiveProperty();
    const { data: issues, refetch: refetchIssues } = useIssues();

    const issuesForCurrentProperty = computed(() => {
      if (issues.value == null) return [];
      const p = activeProperty.value;
      if (p == null) return issues.value;
      return issues.value.filter((issue) => issue.property_id === p.id);
    });

    const options: GenericPageOption[] = [
      {
        id: "create",
        iconName: "add",
        text: "Nieuwe melding",
        async onClick() {
          const issue = await showCreateIssuePopup();
          if (issue == null) return;
          refetchIssues.value();
        },
      },
    ];

    return {
      activeProperty,
      issues,
      issuesForCurrentProperty,
      options,
      toSolvedIssues() {
        showResolvedIssuesPopup();
      },
      async toIssue(issue: Issue) {
        await showViewIssuePopup({ issueId: issue.id });
        refetchIssues.value();
      },
    };
  },
});
