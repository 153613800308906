<template>
  <div class="overview">
    <card>
      <div class="items-wrapper">
        <div class="overview-item" v-for="item in items" :key="item.label">
          <div class="overview-label">{{ item.label }}</div>
          <div
            class="overview-value"
            :class="{ error: item.error, success: item.success }"
          >
            {{ item.value }}
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, Ref } from "vue";

export interface OverviewItem {
  label: string;
  value: Ref<string | number> | string | number;
  double?: number;
  success?: boolean | Ref<boolean>;
  error?: boolean | Ref<boolean>;
}

export default defineComponent({
  props: {
    items: {
      type: Object as PropType<OverviewItem>,
      required: true,
    },
  },
  setup() {
    //
  },
});
</script>

<style lang="scss" scoped>
.overview {
  margin-top: 10px;
  /* display: grid; */
  column-gap: 10px;
  row-gap: 10px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  box-sizing: border-box;
}

.items-wrapper {
  padding: 20px;
}

.overview-item {
  box-sizing: border-box;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

.overview-label {
  color: $text-gray;
  font-weight: 600;
  font-size: 0.72rem;
  margin-bottom: 3px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

.overview-value {
  font-size: 1.125rem;
  color: $text-dark;
  font-weight: 500;
  letter-spacing: 0.01em;

  &.success {
    color: $text-success;
  }
  &.error {
    color: $text-error;
  }
}
</style>
