import { Item } from "@/types";
import { useActiveProperty } from "@/util/properties";
import { computed, isRef, Ref, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import { documentsActions, documentsGetters, inDocuments } from "./documents";

export const useUnvisitedItemIds = () => {
  const store = useStore();
  const property = useActiveProperty();
  const propertyId = computed(() => property.value?.id);
  const seenItemIds = computed(
    () => store.getters[inDocuments(documentsGetters.UNSEEN_ITEMS)]
  );

  watchEffect(() => {
    if (propertyId.value == null) return;
    store.dispatch(inDocuments(documentsActions.FETCH_SEEN_ITEMS), {
      propertyId: propertyId.value,
    });
  });

  return {
    data: seenItemIds,
    loading: computed(() => seenItemIds.value == null),
  };
};

export const useItems = (): {
  loading: Ref<boolean>;
  value: Ref<Item[]>;
  refetch: () => Promise<void>;
} => {
  const activeProperty = useActiveProperty();
  const store = useStore();
  const loading = ref(false);

  const items = computed(
    () =>
      store.state.documents.items?.filter(
        (item: Item) => item.property_id === activeProperty.value?.id
      ) ?? []
  );

  const fetchItems = async () => {
    if (activeProperty.value == null) return;
    await store.dispatch(inDocuments(documentsActions.FETCH_ITEMS), {
      propertyId: activeProperty.value.id,
    });
  };

  watchEffect(async () => {
    if (activeProperty.value == null) return;
    loading.value = true;
    try {
      await fetchItems();
    } finally {
      loading.value = false;
    }
  });

  return {
    loading,
    value: items,
    refetch: fetchItems,
  };
};

export type ItemChildMap = Record<number, number[]>;
export const useChildMap = (items: Item[] | Ref<Item[]>) => {
  return computed(() => {
    const itemValues = isRef(items) ? items.value : items;
    return itemValues.reduce<ItemChildMap>((map, item) => {
      if (item.parent_id == null) return map;
      if (map[item.parent_id] == null) {
        map[item.parent_id] = [item.id];
      } else {
        map[item.parent_id].push(item.id);
      }
      return map;
    }, {});
  });
};

export const useGetUnvisitedCount = (
  childMap: Ref<ItemChildMap>,
  items: Ref<Item[]>
) => {
  const { data: unvisitedItems } = useUnvisitedItemIds();
  const itemsMap = computed(() => {
    if (items.value == null) return {};
    return items.value.reduce<Record<number, Item>>((itemsMap, item) => {
      itemsMap[item.id] = item;
      return itemsMap;
    }, {});
  });

  return {
    unvisitedItems,
    getUnvisitedCount: computed(() => {
      return (itemId: number) => {
        if (unvisitedItems.value == null) return 0;
        const unvisitedItemsSet = new Set(unvisitedItems.value);
        const allChildren = [itemId];
        const findChildren = (itemId: number) => {
          const children = childMap.value[itemId];
          if (children == null) return;
          allChildren.push(...children);
          children.forEach(findChildren);
        };
        findChildren(itemId);
        return allChildren.reduce((count, itemId) => {
          if (itemsMap.value[itemId]?.is_folder) return count;
          if (unvisitedItemsSet.has(itemId)) return count + 1;
          return count;
        }, 0);
      };
    }),
  };
};

export const isItemEmpty = (item: Item, childMap: ItemChildMap): boolean => {
  if (item.is_folder) {
    return childMap[item.id] == null;
  }
  return false;
};
