
import { defineComponent, PropType, ref } from "vue";
import Icon from "@/components/icons/Icon.vue";
import AngleDownIcon from "@/components/icons/AngleDown.vue";
import { useOnClickOutside } from "@/util/click";

export interface DropButtonItem {
  icon: string;
  label: string;
  onClick: () => void;
}

export default defineComponent({
  components: { Icon, AngleDownIcon },
  props: {
    labelText: {
      type: String,
      required: true,
    },
    labelIcon: {
      type: String,
      default: null,
    },
    items: {
      type: Array as PropType<DropButtonItem[]>,
      required: true,
    },
  },
  setup() {
    const isOpen = ref(false);
    const clickOutsideRef = useOnClickOutside(() => {
      isOpen.value = false;
    });

    return {
      clickOutsideRef,
      isOpen,
      toggle() {
        isOpen.value = !isOpen.value;
      },
      onClick(item: DropButtonItem) {
        item?.onClick();
        isOpen.value = false;
      },
    };
  },
});
