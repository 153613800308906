
import { computed, defineComponent, ref } from "vue";
import FinanceItem from "@/components/finance/FinanceItem.vue";
import AddIcon from "@/components/icons/Add.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { FinDocument } from "@/types";
import { finActions, inFin } from "@/store/fin";
import { DateTime } from "luxon";
import { formatAsEuro } from "@/util/price";
import { useIsAdminById } from "@/util/auth";

// TODO: Only show add icon when user is admin

export default defineComponent({
  components: { FinanceItem, AddIcon },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const propertyId = route.params.id;

    const docs = ref(null as null | FinDocument[]);

    store.dispatch(inFin(finActions.LIST), { propertyId }).then((values) => {
      docs.value = values;
    });

    const currentYear = DateTime.utc().year;

    const docsThisYear = computed(() => {
      if (docs.value == null) return [];
      return docs.value.filter((d) => {
        return DateTime.fromISO(d.inserted_at).year === currentYear;
      });
    });

    const totalExpendedThisYear = computed(() => {
      return docsThisYear.value.reduce((total: number, doc) => {
        if (doc.is_cost) {
          return total + doc.amount;
        } else {
          return total - doc.amount;
        }
      }, 0);
    });

    const { isAdmin } = useIsAdminById(propertyId as string);

    return {
      docs,
      currentYear,
      docsThisYear,
      totalExpendedThisYear,
      formatAsEuro,
      isAdmin,
      // show(doc: FinDocument) {
      // router.push({
      //   name: "properties.finances.show",
      //   params: {
      //     propertyId: doc.property_id,
      //     id: doc.id,
      //   },
      // });
      // },
      toNew() {
        router.push({
          name: "properties.finances.create",
          params: { id: propertyId },
        });
      },
    };
  },
});
