
import { computed, defineComponent, PropType, ref } from "vue";
import FolderIcon from "@/components/icons/Folder.vue";
import OptionsIcon from "@/components/icons/Options.vue";
import { Item } from "@/types";
import TrashIcon from "@/components/icons/Trash.vue";
import BookIcon from "@/components/icons/Book.vue";
import { useOnClickOutside } from "@/util/click";
import { showConfirmPopup } from "@/components/popups/Confirm.vue";
import { useStore } from "vuex";
import { documentsActions, inDocuments } from "@/store/documents/documents";
import { promptNewFolderName } from "../popups/PromptRename.vue";
import { isCurrentPropertyAdmin } from "@/util/auth";
import ItemPreview from "./ItemPreview.vue";

export default defineComponent({
  components: { FolderIcon, OptionsIcon, TrashIcon, BookIcon, ItemPreview },
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object as PropType<Item>,
      required: true,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    moving: {
      type: Boolean,
      default: false,
    },
    unvisitedCount: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const optionsVisible = ref(false);
    const clickOutsideRef = useOnClickOutside(() => {
      optionsVisible.value = false;
    });
    const isAdmin = isCurrentPropertyAdmin();

    const displayName = computed(() => {
      const itemName = props.item.name;
      if (!itemName.includes(".")) return itemName;
      return itemName
        .split(".")
        .filter((v, i) => i !== itemName.split(".").length - 1)
        .join(".");
    });

    return {
      displayName,
      isAdmin,
      clickOutsideRef,
      optionsVisible,
      isFolder: computed(() => props.item.is_folder),
      async onDelete() {
        const confirmation = await showConfirmPopup({
          title: "Bent u zeker?",
          confirmText: "Verwijder",
          declineText: "Neen, behouden",
          subTitle: "Bent u zeker dat u dit item wilt verwijderen?",
        });
        if (confirmation !== true) return;
        await store.dispatch(inDocuments(documentsActions.DELETE_ITEM), {
          itemId: props.item.id,
        });
      },
      async onRename() {
        const newName = await promptNewFolderName(props.item.name);
        if (newName == null || newName === "") return;
        await store.dispatch(inDocuments(documentsActions.PATCH_ITEM), {
          itemId: props.item.id,
          changes: { name: newName },
        });
      },
      onOpen() {
        emit("open", props.item);
      },
      onMove() {
        optionsVisible.value = false;
        emit("move");
      },
      isImage() {
        return props?.item?.file?.type?.startsWith("image") ?? false;
      },
    };
  },
});
