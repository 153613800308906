
import { showToast } from "@/components/notifications/toast";
import { inProperties, PropertiesActions } from "@/store/properties";
import { computed, defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const inviteToken = computed(() => route.params.token);

    const loading = ref(null as null | "accept" | "decline");

    const errorMessage = ref(null as null | string);

    return {
      loading,
      errorMessage,
      async accept() {
        loading.value = "accept";

        try {
          await store.dispatch(
            inProperties(PropertiesActions.ACCEPT_INVITE_TOKEN),
            { token: inviteToken.value }
          );
          showToast({
            type: "success",
            text: "Uitnodiging geaccepteerd",
          });
          setTimeout(() => {
            router.push({ name: "login" });
            loading.value = null;
          }, 2000);
        } catch (e) {
          if (e.response && e.response.status === 404) {
            errorMessage.value = "Link is ongeldig of al gebruikt.";
            return;
          }

          throw e;
        }
      },
      async decline() {
        loading.value = "decline";
        try {
          await store.dispatch(
            inProperties(PropertiesActions.DECLINE_INVITE_TOKEN),
            { token: inviteToken.value }
          );
          showToast({
            type: "success",
            text: "Uitnodiging geweigerd",
          });
          setTimeout(() => {
            router.push({ name: "login" });
            loading.value = null;
          }, 2000);
        } catch (e) {
          if (e.response && e.response.status === 404) {
            errorMessage.value = "Link is ongeldig of al gebruikt.";
            return;
          }

          throw e;
        }
      },
    };
  },
});
