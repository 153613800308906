
import { User } from "@/types";
import { defineComponent, PropType } from "vue";
import EmptyProfileIcon from "@/components/icons/EmptyProfile.vue";
import { sharesForResident } from "@/util/properties";
import { Property } from "@/repositories/propertyRepository";
import { CardTag, CardTagType } from "../common/Card.vue";
import { showViewResidentPopup } from "@/features/residents/popups/ViewResident.vue";
import { isCurrentPropertyAdmin } from "@/util/auth";

export default defineComponent({
  components: { EmptyProfileIcon },
  props: {
    residents: {
      type: Array as PropType<User[]>,
      required: true,
    },
    property: {
      type: Object as PropType<Property | null>,
    },
  },
  setup(props) {
    const isAdmin = isCurrentPropertyAdmin();

    return {
      isAdmin,
      tagsForUser(user: User): CardTag[] {
        if (props.property?.admin_id === user.id) {
          return [{ type: CardTagType.INFO, text: "Beheerder" }];
        }
        return [];
      },
      sharesForResident(residentId: number) {
        if (props.property == null) return 0;
        return sharesForResident(props.property, residentId) ?? 0;
      },
      async showUser(resident: User) {
        if (isAdmin.value) {
          showViewResidentPopup(resident);
        }
      },
    };
  },
});
