import { Bank, FinDocument } from "@/types";
import { ApiResponse, del, get, patch, post } from "./repository";

interface FinDocumentResponse {
  document: FinDocument;
}

export default {
  listBanks(): ApiResponse<{ banks: Bank[] }> {
    return get("/banks");
  },

  // TODO: remove
  list(propertyId: number): ApiResponse<{ documents: FinDocument[] }> {
    return get(`/properties/${propertyId}/documents`);
  },
  show(docId: number): ApiResponse<FinDocumentResponse> {
    return get(`/documents/${docId}`);
  },
  update(docId: number, updates: any): ApiResponse<FinDocumentResponse> {
    return patch(`/documents/${docId}`, {
      updates,
    });
  },
  create(propertyId: number, params: any): ApiResponse<FinDocumentResponse> {
    return post(`/properties/${propertyId}/documents`, {
      doc_params: params,
    });
  },
  delete(docId: number): ApiResponse<FinDocumentResponse> {
    return del(`/documents/${docId}`);
  },
};
