
import { defineComponent } from "vue";
import Loading from "@/components/icons/Loader.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: { Loading },
  props: {
    to: {
      type: Object,
      defualt: null,
    },
    type: {
      type: String,
      default: "button",
    },
    width: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    thinner: {
      type: Boolean,
      default: false,
    },
    thinner2: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();

    return {
      onClick() {
        emit("click");
        if (props.to != null) {
          router.push(props.to);
        }
      },
    };
  },
});
