import { Exception } from "./Exception";

type ErrorsType = { [field: string]: string[] };

export class ValidationException extends Exception {
  private errors: ErrorsType;

  constructor(errors: ErrorsType) {
    super("Validation exception");

    this.errors = errors;
  }

  public getErrorsForField(fieldName: string): string[] | null {
    return this.errors[fieldName] || null;
  }

  public getErrors(): ErrorsType {
    return this.errors;
  }
}
