
import { accountingActions, inAccounting } from "@/store/accounting/accounting";
import { authActions, inAuth } from "@/store/auth";
import { useHasAuth } from "@/util/auth";
import { Browser } from "@capacitor/browser";
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const ref = route.query.ref;
    const hasAuth = useHasAuth();

    if (ref == null) {
      console.log("No ref, redirecting to transactions");
      router.push({
        name: "accounting.transactions.list",
      });
    }

    const run = async () => {
      const token = route.params?.token;
      console.log("Token:", token);
      if (token) {
        await store.dispatch(inAuth(authActions.SESSION_FROM_SU_TOKEN), token);
      }

      if (!hasAuth.value) {
        console.log("Auth is required for bank selection");
        router.push({
          name: "login",
        });
        return;
      }

      await store.dispatch(inAccounting(accountingActions.FINISH_BANK), {
        reference: ref,
      });
      console.log("Finished bank link");

      Browser.close();
      window.location.href = "mysyndicapp://";
    };
    run().catch((err) => {
      console.log("Err:", err);
    });
  },
});
