import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { Ref, ref } from "vue";

export function getPlatform(): string {
  return Capacitor.getPlatform();
}

export function isMobilePlatform(): boolean {
  return getPlatform() === "ios" || getPlatform() === "android";
}

export function isShittyPlatform(): boolean {
  return getPlatform() === "ios";
}

export function isWebPlatform(): boolean {
  return getPlatform() === "web";
}

// TODO: Need to unbind listener
export function useIsActive(): Ref<boolean> {
  const _isActive = ref(false as boolean);
  if (!isMobilePlatform()) return _isActive;
  App.addListener("appStateChange", ({ isActive }) => {
    _isActive.value = isActive;
  });
  return _isActive;
}

// Check if platform is IOS and has IOS version 12 or less
// This is important because a lot of features require IOS 13
export function useIsIOSAndUnderVersion13() {
  const result = ref(false);

  if (getPlatform() === "ios") {
    Device.getInfo().then((data) => {
      const versionFloat = parseFloat(data.osVersion);
      if (versionFloat < 13) {
        result.value = true;
      }
    });
  }

  return result;
}
