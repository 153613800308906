
import { useRoute } from "vue-router";
import { defineComponent, Ref, ref, watchEffect } from "vue";
import { User } from "@/types";
import { inProperties, PropertiesActions } from "@/store/properties";
import { useStore } from "vuex";
import { useIsAdminById } from "@/util/auth";
import UploadPopup from "@/components/popups/UploadPopup.vue";
import { showPopup } from "@/components/popups/popups";
import { FileItem } from "@/repositories/fileRepository";
import FileItemView from "@/components/util/FileItemView.vue";
import { Property } from "@/repositories/propertyRepository";

function fetchProperty(id: number | string): Ref<null | Property> {
  const store = useStore();
  const property = ref(null as null | Property);

  store
    .dispatch(inProperties(PropertiesActions.BY_ID), { id })
    .then((value) => (property.value = value));

  return property;
}

export default defineComponent({
  components: { FileItemView },
  setup() {
    const route = useRoute();
    const store = useStore();
    const property = fetchProperty(route.params.id as string);
    const isAdmin = useIsAdminById(route.params.id as string);
    const residents = ref([] as User[]);

    watchEffect(() => console.log(property));

    store
      .dispatch(inProperties(PropertiesActions.LIST_RESIDENTS), {
        id: route.params.id,
      })
      .then((value) => {
        residents.value = value;
      });

    return {
      property,
      residents,
      isAdmin,
      async newRIO() {
        if (property.value == null) return;
        const file = (await showPopup(UploadPopup)) as FileItem;
        if (file == null) return;

        const newProperty = await store.dispatch(
          inProperties(PropertiesActions.PATCH),
          {
            id: property.value.id,
            updates: {
              rio_id: file.id,
            },
          }
        );
        property.value = newProperty;
      },
      async newBasisakte() {
        if (property.value == null) return;
        const file = (await showPopup(UploadPopup)) as FileItem;
        if (file == null) return;

        const newProperty = await store.dispatch(
          inProperties(PropertiesActions.PATCH),
          {
            id: property.value.id,
            updates: {
              basisakte_id: file.id,
            },
          }
        );
        property.value = newProperty;
      },
      routeToFile(file: FileItem) {
        window.open(file.url, "_blank");
      },
    };
  },
});
