<template>
  <div class="item" :class="{ moving }" @click="onOpen">
    <div class="content">
      <!-- <div class="icon" v-if="item.is_folder">
        <folder-icon />
      </div>
      <div
        class="image-preview"
        v-if="isImage()"
        :style="{ backgroundImage: `url('${item.file.url}')` }"
      ></div> -->
      <div class="icon">
        <item-preview :item="item" />
      </div>
      <div class="name">
        {{ displayName }}
        <div class="tags">
          <div class="tag" v-for="tag in tags" :key="tag">
            {{ tag }}
          </div>
          <div class="tag" v-if="unvisitedCount != null && unvisitedCount > 0">
            <template v-if="isFolder">{{ unvisitedCount }} nieuw</template>
            <template v-else>nieuw</template>
          </div>
        </div>
      </div>
      <div
        v-if="isAdmin && !hideActions"
        class="options-button"
        @click.stop="optionsVisible = !optionsVisible"
        ref="clickOutsideRef"
      >
        <options-icon :size="16" />
        <div class="options" :class="{ visible: optionsVisible }">
          <div class="option" @click.stop="onRename">
            <div class="option-icon">
              <folder-icon v-if="isFolder" size="16" />
              <book-icon v-else size="16" />
            </div>
            <div class="option-text">Rename</div>
          </div>
          <div class="option" @click.stop="onMove">
            <div class="option-icon">
              <trash-icon size="16" />
            </div>
            <div class="option-text">Verplaatsen</div>
          </div>
          <div class="option" @click.stop="onDelete">
            <div class="option-icon">
              <trash-icon size="16" />
            </div>
            <div class="option-text">Remove</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue";
import FolderIcon from "@/components/icons/Folder.vue";
import OptionsIcon from "@/components/icons/Options.vue";
import { Item } from "@/types";
import TrashIcon from "@/components/icons/Trash.vue";
import BookIcon from "@/components/icons/Book.vue";
import { useOnClickOutside } from "@/util/click";
import { showConfirmPopup } from "@/components/popups/Confirm.vue";
import { useStore } from "vuex";
import { documentsActions, inDocuments } from "@/store/documents/documents";
import { promptNewFolderName } from "../popups/PromptRename.vue";
import { isCurrentPropertyAdmin } from "@/util/auth";
import ItemPreview from "./ItemPreview.vue";

export default defineComponent({
  components: { FolderIcon, OptionsIcon, TrashIcon, BookIcon, ItemPreview },
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object as PropType<Item>,
      required: true,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    moving: {
      type: Boolean,
      default: false,
    },
    unvisitedCount: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const optionsVisible = ref(false);
    const clickOutsideRef = useOnClickOutside(() => {
      optionsVisible.value = false;
    });
    const isAdmin = isCurrentPropertyAdmin();

    const displayName = computed(() => {
      const itemName = props.item.name;
      if (!itemName.includes(".")) return itemName;
      return itemName
        .split(".")
        .filter((v, i) => i !== itemName.split(".").length - 1)
        .join(".");
    });

    return {
      displayName,
      isAdmin,
      clickOutsideRef,
      optionsVisible,
      isFolder: computed(() => props.item.is_folder),
      async onDelete() {
        const confirmation = await showConfirmPopup({
          title: "Bent u zeker?",
          confirmText: "Verwijder",
          declineText: "Neen, behouden",
          subTitle: "Bent u zeker dat u dit item wilt verwijderen?",
        });
        if (confirmation !== true) return;
        await store.dispatch(inDocuments(documentsActions.DELETE_ITEM), {
          itemId: props.item.id,
        });
      },
      async onRename() {
        const newName = await promptNewFolderName(props.item.name);
        if (newName == null || newName === "") return;
        await store.dispatch(inDocuments(documentsActions.PATCH_ITEM), {
          itemId: props.item.id,
          changes: { name: newName },
        });
      },
      onOpen() {
        emit("open", props.item);
      },
      onMove() {
        optionsVisible.value = false;
        emit("move");
      },
      isImage() {
        return props?.item?.file?.type?.startsWith("image") ?? false;
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.item {
  margin-bottom: 8px;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &.moving {
    opacity: 0.4;
    cursor: default;
    user-select: none;
  }
}

.content {
  box-sizing: border-box;
  padding-left: 20px;
  min-height: 60px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: white;
  border: 1px solid $border-general;
  border-radius: 5px;

  @include screen-from("tablet") {
    padding-left: 30px;
  }
}

.icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;

  @include screen-from("tablet") {
    margin-right: 15px;
  }
}

.name {
  flex: 1;
  display: flex;
}

.options-button {
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  max-width: 50px;
  height: 58px;
  padding-right: 20px;

  @include screen-from("tablet") {
    padding-right: 30px;
  }
}

.options {
  padding: 10px 0;
  position: absolute;
  width: 200px;
  background-color: white;
  border: 1px solid $border-general;
  margin-top: 130px;
  margin-right: -15px;
  border-radius: 5px;
  display: none;

  &.visible {
    display: block;
  }
}

.option {
  padding: 10px 25px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  user-select: none;

  &:hover {
    background-color: $bg-accent;
    cursor: pointer;
  }
}

.option-icon {
  width: 30px;
}

.tags {
  display: flex;
  margin-left: 10px;
}

.tag {
  /*opacity: 0.6;
  background-color: $text-gray;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.01em;
  border-radius: 10px;
  line-height: 1;
  padding: 3px 9px;
  margin-right: 5px;
  */
  margin-right: 5px;
  border-radius: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  background-color: rgba($bg-mobile-menu, 0.1);
  padding: 4px 6px;
}

/*
.unread {
  margin-left: 10px;
  border-radius: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  background-color: rgba($bg-mobile-menu, 0.1);
  padding: 4px 6px;
}*/
</style>
