<template>
  <div class="filter-tags">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.filter-tags {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 10px;
}
</style>
