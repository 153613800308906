<template>
  <container maxWidth="350px" centerh class="select-quarter">
    <generic-header>Betalingstermijn</generic-header>
  </container>
  <container maxWidth="350px" width="90%" centerh class="select-quarter">
    <p class="description">
      {{ $t("accounting.when_payments") }}
    </p>
    <radio
      :options="[
        {
          id: '1',
          label: 'Elke maand',
          description: 'januari, februari, ..., december',
        },
        {
          id: '2',
          label: 'Elke twee maanden',
          description: 'januari, maart, ..., november',
        },
        {
          id: '3',
          label: 'Elk kwartaal',
          description: 'januari, april, juli, oktober',
        },
        {
          id: '4',
          label: 'Elk trimester',
          description: 'januari, mei, september',
        },
        { id: '6', label: 'Elk semester', description: 'januari, juli' },
        { id: '12', label: 'Jaarlijks', description: 'alleen in januari' },
      ]"
      @select="(v) => (selectedId = v)"
    />
    <div class="buttons">
      <app-button
        v-if="selectedId != null"
        thinner
        @click="submit"
        :loading="loading"
        >{{ $t("common.next") }}</app-button
      >
    </div>
  </container>
</template>

<script lang="ts">
import { inProperties, PropertiesActions } from "@/store/properties";
import { useActiveProperty } from "@/util/properties";
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const customValue = ref("12");
    const selectedId = ref(null as null | string);
    const loading = ref(false);
    const store = useStore();
    const router = useRouter();
    const activeProperty = useActiveProperty();

    return {
      selectedId,
      customValue,
      loading,
      async submit() {
        const activePropertyId = activeProperty.value?.id;
        if (activePropertyId == null) return;
        if (selectedId.value == null) return;
        loading.value = true;
        const termStr = selectedId.value;
        const term = parseInt(termStr);
        await store.dispatch(inProperties(PropertiesActions.PATCH), {
          id: activePropertyId,
          updates: {
            payment_term: term,
          },
        });
        loading.value = false;
        router.push({
          name: "accounting.overview.transactions",
        });
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.description {
  margin-top: 10px;
  line-height: 130%;
  /* text-align: center; */
}

.options {
  width: 100%;
  margin-top: 20px;
}

.custom-month {
  width: 30px;
  padding: 3px 5px;
  border-radius: 3px;
  box-shadow: none;
  outline: none;
  border: 1px solid $border-general;
}

.invalid {
  border: 1px solid $bg-error;
  background-color: rgba($bg-error, 0.3);
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.plural.disabled {
  opacity: 0.5;
}
</style>
