import { accountingGetters, inAccounting } from "@/store/accounting/accounting";
import { Transaction } from "@/types";
import { DateTime } from "luxon";
import { computed, Ref } from "vue";
import { useStore } from "vuex";
import { useActiveProperty } from "./properties";

export function useTransactionsActiveProperty(): Ref<Transaction[]> {
  const store = useStore();
  const activeProperty = useActiveProperty();

  const transactions = computed(() => {
    if (activeProperty.value == null) return [];
    const all = store.getters[inAccounting(accountingGetters.ALL_TRANSACTIONS)];
    if (all == null) return [];
    const forProperty = all[activeProperty.value.id + ""];
    if (forProperty == null) return [];
    return forProperty;
  });
  return transactions;
}

// Vind alle mogelijke kwartalen
// const startQ1 = DateTime.fromISO("")
const quarterMonths = [1, 4, 7, 10];

export function findPreferredQuarterMonth() {
  const now = DateTime.now();
  const currentMonth = now.month;
  //
  const nearest = quarterMonths.reduce((lowestMonth, month) => {
    const diffLowest = Math.abs(lowestMonth - currentMonth);
    const diffCurrent = Math.abs(month - currentMonth);
    if (diffLowest < diffCurrent) lowestMonth;
    return month;
  });

  return nearest;
}
