<template>
  <popup-wrapper maxWidth="400px">
    <div class="info-popup">
      <header class="header">
        <h1 class="title">
          {{ params.title }}
        </h1>
      </header>
      <div class="body">
        {{ params.text }}
      </div>
    </div>
  </popup-wrapper>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import PopupWrapper from "@/components/popups/PopupWrapper.vue";
import { showPopup } from "@/components/popups/popups";

const component = defineComponent({
  components: { PopupWrapper },
  props: {
    params: {
      type: Object as PropType<{
        title: string;
        text: string;
      }>,
    },
  },
});

export const showInfoPopup = (title: string, text: string) => {
  showPopup(component, {
    title,
    text,
  });
};

export default component;
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 35px;
  box-sizing: border-box;
  padding: 0 15px;
  display: flex;
  align-items: center;
  background-color: $bg-accent;
  color: $text-dark;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.title {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.01em;
}

.body {
  color: $text-alt;
  opacity: 0.8;
  box-sizing: border-box;
  padding: 15px;
  min-height: 150px;
  line-height: 1.3;
}
</style>
