<template>
  <div class="image-chat-item" @click="showLightbox">
    <div v-if="currentUrl == null" class="loader-wrapper">
      <loader width="50px" height="50px" thickness="4px" opacity="0.1" />
    </div>
    <div
      v-if="currentUrl != null"
      class="image"
      :style="{
        'background-image': `url('${currentUrl}')`,
      }"
    ></div>
  </div>
</template>

<script lang="ts">
import { FileItem } from "@/repositories/fileRepository";
import { filesActions, inFiles } from "@/store/files";
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
import Loader from "@/components/icons/Loader.vue";
import { showLightboxPopup } from "../util/LightBox.vue";

export default defineComponent({
  components: { Loader },
  props: {
    imageId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const imageId = computed(() => Number(props.imageId));
    const currentImage = ref(null as null | FileItem);

    const updateImage = async () => {
      let image = await store.dispatch(inFiles(filesActions.GET_ONE), {
        id: imageId.value,
      });
      console.log("Image:", image, imageId.value);
      currentImage.value = image;
    };

    watch(imageId, updateImage);
    updateImage();

    const currentUrl = computed(() => currentImage.value?.url);

    return {
      currentUrl,
      showLightbox() {
        if (currentImage.value == null) return;
        showLightboxPopup([currentImage.value.url]);
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.image-chat-item {
  position: relative;
  width: 150px;
  padding-top: 100%;
  border-radius: 13px;
  background-color: darken($color: $bg-general, $amount: 5%);
  overflow: hidden;
  cursor: pointer;

  @include screen-from("tablet-wide") {
    width: 200px;
  }
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
</style>
