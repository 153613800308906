
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    size: {
      type: String,
      default: "22px",
    },
  },
});
