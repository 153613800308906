import { createApp } from "vue";
import store from "./store";
import App from "./App.vue";
import router from "./router";
import "@/style/app.scss";
import installCommon from "@/plugins/install_common";
import installLogrocket from "./plugins/install_logrocket";
import installPush from "./plugins/install_push";
import installPopper from "./plugins/install_popper";
// import installSentry from "./plugins/install_sentry";

const app = createApp(App).use(store).use(router);

// TODO: maybe remove
import "@codetrix-studio/capacitor-google-auth";
import { Device } from "@capacitor/device";
import { startStoreUpdater } from "./store/util/storeUpdater";
import { installI18n } from "./plugins/install_i18n";
import installVueQuery from "./plugins/install_vuequery";

startStoreUpdater(store);

// TODO: try again with sentry
// installSentry(app, router);
installI18n(app);
installCommon(app);
installLogrocket(app);
installPush(app);
installPopper(app);
installVueQuery(app);

Device.getInfo().then((data) => {
  console.log("Device Version:", data.osVersion);
  console.log("Device info:", data);
});

app.mount("#app");
