
import { defineComponent, PropType, Ref } from "vue";
import Icon from "@/components/icons/Icon.vue";

export interface GenericPageOption {
  id: string;
  iconName: string;
  text: string;
  onClick: () => void;
  danger?: boolean;
  success?: boolean;
  showWhen?: Ref<boolean>;
}

export default defineComponent({
  components: { Icon },
  props: {
    options: {
      type: Array as PropType<GenericPageOption[]>,
      required: true,
    },
  },
});
