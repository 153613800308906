<template>
  <generic-page-popup title="Nieuw pand" use-margin-top>
    <form class="form" @submit.prevent="submit">
      <div class="form-field">
        <dropdown
          v-model="type"
          label="Wat voor soort pand is dit?"
          placeholder="Types"
          :options="typeOptions"
        />
      </div>
      <div class="form-field">
        <textbox
          v-model="identifier"
          label="Pand naam"
          placeholder="Naam, bv: 2c"
          thinner
        />
      </div>
      <div class="form-field">
        <dropdown
          v-model="responsible"
          label="Hoofdverantwoordelijke"
          placeholder="Klik om te selecteren"
          :options="residentOptions"
        />
        <div class="create-user-wrapper">
          <app-link @click="createUser">Nieuwe gebruiker</app-link>
        </div>
      </div>
      <div class="buttons">
        <app-button :loading="submitting" type="submit" thinner2
          >Aanmaken</app-button
        >
      </div>
    </form>
  </generic-page-popup>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue";
import { showPopup } from "@/components/popups/popups";
import { useResidents } from "@/store/properties/hooks";
import { useStore } from "vuex";
import { inProperties, PropertiesActions } from "@/store/properties";
import { CreatePropertyLotParams } from "@/types";
import { useActiveProperty } from "@/util/properties";
import { showAddResidentPopup } from "@/features/residents/popups/AddResident.vue";
import { useLotTypeOptions } from "../hooks/types";

const component = defineComponent({
  props: {
    params: {
      type: Object as PropType<{ customTypes: string[] }>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const activeProperty = useActiveProperty();
    const { residents, loading, refetch: refetchResidents } = useResidents();
    const submitting = ref(false);

    const identifier = ref("");
    const responsible = ref(null as string | null);
    const type = ref(null as string | null);

    const error = ref("");
    const typeOptions = useLotTypeOptions(props.params.customTypes);

    return {
      type,
      typeOptions,
      identifier,
      responsible,
      residents,
      loading,
      submitting,
      error,
      residentOptions: computed(() => {
        if (loading.value) return {};
        return residents.value.reduce<Record<string, string>>(
          (options, resident) => {
            options[resident.id] =
              resident.first_name + " " + resident.last_name;
            return options;
          },
          {}
        );
      }),
      async createUser() {
        await showAddResidentPopup();
        refetchResidents();
      },
      async submit() {
        submitting.value = true;

        if (
          type.value == null ||
          responsible.value == null ||
          identifier.value.length <= 0
        ) {
          error.value = "Gelieve alle velden in te vullen.";
          return;
        }

        try {
          await store.dispatch(inProperties(PropertiesActions.CREATE_LOT), {
            identifier: identifier.value,
            property_id: activeProperty.value?.id,
            type: type.value,
            main_responsible_id: Number.parseInt(responsible.value as string),
          } as CreatePropertyLotParams);
          emit("finish");
        } catch (e) {
          error.value =
            "Er ging iets mis, onze excuses hiervoor. U kan ons altijd bereiken op jasper@mysyndic.com";
        } finally {
          submitting.value = false;
        }
      },
    };
  },
});

export const showAddLotPopup = (customTypes: string[]) => {
  return showPopup(component, { customTypes });
};

export default component;
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 20px;
  margin-bottom: 20px;
}

.form-field {
  margin-bottom: 10px;
}

.create-user-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
}
</style>
