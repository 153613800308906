<template>
  <template v-if="fieldSetting.type === 'string'">
    <textbox
      :style="{ 'max-width': fieldSetting.meta?.maxWidth }"
      :label="fieldSetting.label"
      :placeholder="fieldSetting.meta?.placeholder"
      :name="fieldSetting.name"
      :id="fieldSetting.id"
      :type="fieldSetting.meta?.type || 'text'"
      @update:modelValue="(val) => $emit('update:value', val)"
      :modelValue="modelValue"
    />
  </template>
  <template v-else-if="fieldSetting.type === 'date'">
    <date-picker
      :label="fieldSetting.label"
      :placeholder="fieldSetting.meta?.placeholder"
      :id="fieldSetting.name"
      @update:modelValue="(val) => $emit('update:value', val)"
      :modelValue="modelValue"
    />
  </template>
  <template v-else-if="fieldSetting.type === 'time'">
    <time-picker
      :label="fieldSetting.label"
      :placeholder="fieldSetting.meta?.placeholder"
      :id="fieldSetting.name"
      @update:modelValue="(val) => $emit('update:value', val)"
      :modelValue="modelValue"
    />
  </template>
  <!-- <template v-else-if="fieldSetting.type === 'datetime'">
    <date-time-picker
      :label="fieldSetting.label"
      :placeholder="fieldSetting.meta?.placeholder"
      :id="fieldSetting.name"
      @update:modelValue="(val) => $emit('update:value', val)"
      :modelValue="modelValue"
    />
  </template> -->
  <template v-else-if="fieldSetting.type === 'hr'">
    <form-ruler :label="fieldSetting.label || null" />
  </template>
  <template v-else-if="fieldSetting.type === 'boolean'">
    <app-switch
      :label="fieldSetting.label"
      @update:modelValue="(val) => $emit('update:value', val)"
      :modelValue="modelValue"
    />
  </template>
  <template v-else-if="fieldSetting.type === 'profile-image'">
    <profile-picture-upload
      :label="fieldSetting.label"
      :modelValue="modelValue"
      @update:modelValue="(val) => $emit('update:value', val)"
      :uploadFunction="fieldSetting.meta.uploadFunction"
    />
  </template>
  <template v-else-if="fieldSetting.type === 'dropdown'">
    <dropdown
      :label="fieldSetting.label"
      :placeholder="fieldSetting.meta?.placeholder"
      :options="fieldSetting.meta?.options"
      @update:modelValue="(val) => $emit('update:value', val)"
      :modelValue="modelValue ?? fieldSetting.meta?.default"
    />
  </template>
  <template v-else-if="fieldSetting.type === 'textarea'">
    <app-textarea
      :placeholder="fieldSetting.meta?.placeholder"
      :label="fieldSetting.label"
      @update:modelValue="(val) => $emit('update:value', val)"
      :modelValue="modelValue ?? fieldSetting.meta?.default"
    />
  </template>
  <template v-else-if="fieldSetting.type === 'images'">
    <images-upload
      :images="modelValue"
      :uploadFunction="fieldSetting.meta.upload"
      :label="fieldSetting.label"
      @newImages="(val) => $emit('update:value', val)"
    />
  </template>
  <template v-else-if="fieldSetting.type === 'files'">
    <images-upload
      :files="modelValue"
      :uploadFunction="fieldSetting.meta.upload"
      :label="fieldSetting.label"
      @newFiles="(val) => $emit('update:value', val)"
    />
  </template>
  <template v-if="fieldSetting.subCheckbox != null">
    <div class="sub-checkbox-wrapper">
      <input
        type="checkbox"
        :name="fieldSetting.subCheckbox.name"
        @change="(val) => $emit('update:subCheckbox', val.target.checked)"
        :checked="subCheckboxValue"
        :id="fieldSetting.subCheckbox.name"
      />
      <label :for="fieldSetting.subCheckbox.name">
        {{ fieldSetting.subCheckbox.label }}
      </label>
    </div>
  </template>
</template>

<script lang="ts">
import { ValidationRule } from "@vuelidate/core";
import { defineComponent, Prop } from "vue";
import FormRuler from "./FormRuler.vue";
import ProfilePictureUpload from "@/components/form/ProfilePictureUpload.vue";
import { ImagesUpload } from "@/components/form";

export interface FieldSetting {
  name: string;
  type:
    | "string"
    | "hr"
    | "boolean"
    | "profile-image"
    | "dropdown"
    | "textarea"
    | "images"
    | "files"
    | "date"
    | "time";
  label: string;
  meta?: Record<string, any>;
  rules?: ValidationRule | { [K: string]: ValidationRule };
  smaller?: boolean;
  subCheckbox?: {
    name: string;
    label: string;
  };
}

export default defineComponent({
  name: "GeneratedFormItem",
  components: { FormRuler, ProfilePictureUpload, ImagesUpload },
  props: {
    fieldSetting: {
      required: true,
    } as Prop<FieldSetting>,
    modelValue: {
      default: null,
    },
    subCheckboxValue: {
      default: null,
    },
  },
});
</script>

<style lang="scss" scoped>
.sub-checkbox-wrapper {
  margin-top: 4px;
  padding-top: 1px;
  font-size: 14px;
  color: $text-alt;

  input {
    opacity: 0.7;
  }
  label {
    opacity: 0.7;
  }

  input:checked {
    opacity: 1;
  }
  input:checked + label {
    opacity: 1;
  }
}
</style>
