<template>
  <generic-page
    :title="issue.title"
    hide-header-desktop
    :loading="issue == null"
  >
    <div class="property-issue" v-if="issue != null">
      <header class="header">
        <div class="street">{{ issue.property.name }}</div>
        <div class="time">
          {{ timeago(issue.inserted_at, " geleden") }}
          <div
            class="settings settings--desktop"
            @click="goToEdit"
            v-if="isAdmin"
          >
            <settings-icon />
          </div>
        </div>
      </header>
      <div class="tags tags--mobile">
        <div class="tag" v-for="tag in issueTags(issue)" :key="tag">
          {{ tag }}
        </div>
      </div>
      <h2 class="title">{{ issue.title }}</h2>
      <div class="author">
        {{ issue.author.first_name }} {{ issue.author.last_name }}
      </div>
      <div class="tags tags--web">
        <div class="tag tag--red" v-for="tag in issueTags(issue)" :key="tag">
          {{ tag }}
        </div>
      </div>
      <p class="text">
        {{ issue.message }}
      </p>
      <div class="images" @click="showLightbox()">
        <div
          class="image"
          v-for="p in issue.pictures"
          :key="p.id"
          :style="{ 'background-image': `url('${p.url}')` }"
        ></div>
      </div>
      <div class="button-wrapper">
        <app-button
          class="resolved-button"
          thinner
          success
          v-if="isAdmin && (issue.open || issue.in_progress)"
          @click="markResolved"
          :loading="state.loadingButtons"
        >
          <template v-slot:icon>
            <check-icon />
          </template>
          {{ $t("issues.mark_solved") }}
        </app-button>
        <app-button
          class="resolved-button"
          thinner
          v-if="isAdmin && issue.resolved"
          @click="markOpen"
          :loading="state.loadingButtons"
        >
          <template v-slot:icon>
            <check-icon />
          </template>
          {{ $t("issues.mark_open") }}
        </app-button>
        <app-button
          class="resolved-button"
          thinner
          dark
          v-if="isAdmin && issue.open"
          @click="markInProgress"
          :loading="state.loadingButtons"
        >
          <template v-slot:icon>
            <check-icon />
          </template>
          {{ $t("issues.mark_in_progress") }}
        </app-button>
        <app-button
          class="resolved-button"
          thinner
          v-if="isAdmin && issue.in_progress"
          @click="markOpen"
          :loading="state.loadingButtons"
        >
          <template v-slot:icon>
            <check-icon />
          </template>
          {{ $t("issues.mark_not_in_progres") }}
        </app-button>
      </div>
    </div>
  </generic-page>
</template>

<script lang="ts">
import { timeago } from "@/util/timeago";
import { computed, defineComponent, reactive, ref } from "vue";
import { Issue } from "@/repositories/issueRepository";
import { useStore } from "vuex";
import { inIssues, issueActions } from "@/store/issues";
import { useRoute, useRouter } from "vue-router";
import { showLightboxPopup } from "@/components/util/LightBox.vue";
import { authGetters, inAuth } from "@/store/auth";
import { issueTags } from "@/util/issues";
import CheckIcon from "@/components/icons/Check.vue";
import SettingsIcon from "@/components/icons/Settings.vue";
import { showEditIssuePopup } from "@/features/issues/popups/EditIssue.vue";

export default defineComponent({
  components: { CheckIcon, SettingsIcon },
  setup() {
    const issue = ref(null as null | Issue);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      lightboxEnabled: false,
      loadingButtons: false,
    });

    const fetchIssue = async () => {
      issue.value = await store.dispatch(inIssues(issueActions.BY_ID), {
        id: route.params.issueId,
      });
    };
    fetchIssue();

    const isAdmin = computed(() => {
      if (issue.value == null) return false;
      const user = store.getters[inAuth(authGetters.USER)];
      if (user == null) return false;
      const adminId = issue.value.property.admin_id;
      return adminId === user.id;
    });

    return {
      isAdmin,
      issue,
      state,
      timeago,
      issueTags,
      showLightbox() {
        let pictures = issue.value?.pictures;
        if (pictures == null) return;
        showLightboxPopup(pictures.map((p) => p.url));
      },
      goToEdit() {
        if (issue.value == null) return;
        // router.push({
        //   name: "properties.issues.edit",
        //   params: {
        //     id: issue.value.property_id,
        //     issueId: issue.value.id,
        //   },
        // });
        showEditIssuePopup(issue.value.id);
      },
      async markInProgress() {
        if (issue.value == null) return;
        state.loadingButtons = true;
        await store.dispatch(inIssues(issueActions.UPDATE), {
          id: issue.value.id,
          updates: { status: "in_progress" },
        });
        await fetchIssue();
        state.loadingButtons = false;
      },
      async markResolved() {
        if (issue.value == null) return;
        state.loadingButtons = true;
        const newIssue = await store.dispatch(inIssues(issueActions.UPDATE), {
          id: issue.value.id,
          updates: { status: "resolved" },
        });
        router.push({
          name: "properties.issues.list",
          params: {
            id: newIssue.property_id,
          },
        });
        state.loadingButtons = false;
      },
      async markOpen() {
        if (issue.value == null) return;
        state.loadingButtons = true;
        await store.dispatch(inIssues(issueActions.UPDATE), {
          id: issue.value.id,
          updates: { status: "open" },
        });
        await fetchIssue();
        state.loadingButtons = false;
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.property-issue {
  width: 100%;
  margin-top: 40px;
  padding: 0 20px;
  color: $text-alt;
  box-sizing: border-box;

  @include screen-from("tablet-wide") {
    padding: 20px 30px 60px;
    background-color: white;
    border: 1px solid $border-general;
    border-radius: 5px;
  }
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9375rem;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
  font-weight: 600;
  font-family: $font-text;

  @include screen-from("tablet") {
    margin-bottom: 25px;
  }
}

.tags {
  display: flex;
  flex-flow: row wrap;
  margin-top: 12px;

  &--web {
    display: none;
    margin-top: 5px;
  }

  @include screen-from("tablet") {
    &--web {
      display: flex;
    }

    &--mobile {
      display: none;
    }
  }
}

.tag {
  color: white;
  font-size: 13px;
  text-transform: uppercase;
  font-family: $font-text;
  padding: 9px 11px;
  border-radius: 3px;
  box-shadow: $shadow-general;
  margin-right: 8px;
  margin-top: 8px;
  background-color: $text-alt;
  font-weight: 600;
  letter-spacing: 0.02rem;

  &--red {
    background-color: $bg-error;
  }
}

.title {
  margin-top: 14px;
  font-family: $font-title;
  color: $text-dark;
  letter-spacing: -0.01rem;
  font-size: 1.5rem;
  line-height: 130%;
  font-weight: 600;

  @include screen-from("tablet") {
    font-size: 2rem;
    max-width: 750px;
  }
}

.time {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.text {
  margin-top: 17px;
  font-size: 0.875rem;
  line-height: 140%;
  font-family: $font-text;
  letter-spacing: 0.02rem;

  @include screen-from("tablet") {
    font-size: 1rem;
    margin-top: 20px;
    margin-bottom: 25px;
    max-width: 750px;
  }
}

.images {
  display: flex;
  flex-flow: row wrap;
  margin-top: 12px;
  cursor: pointer;

  @include screen-from("tablet") {
    flex-flow: column;
  }
}

.image {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  background-color: white;
  margin-top: 8px;
  margin-right: 8px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @include screen-from("tablet") {
    width: 320px;
    height: 320px;
    margin-bottom: 15px;
  }
}

.button-wrapper {
  margin-top: 50px;
}

.resolved-button {
  width: 100%;

  @include screen-from("tablet") {
    width: auto;
  }

  &:last-child {
    margin-top: 10px;
  }
}

.author {
  font-family: $font-text;
  font-size: 0.875rem;
  color: $text-alt;
  opacity: 0.6;
  margin-top: -2px;
}

.settings {
  cursor: pointer;

  &--desktop {
    margin-left: 20px;
  }
}
</style>
