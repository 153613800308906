
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import PopupWrapper from "./PopupWrapper.vue";
import FinanceItem from "@/components/finance/FinanceItem.vue";
import { Exception } from "@/error_handling/Exception";
import { finActions, inFin } from "@/store/fin";

export default defineComponent({
  components: { PopupWrapper, FinanceItem },
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const documents = ref([] as Document[]);

    const store = useStore();

    // eslint-disable-next-line vue/no-setup-props-destructure
    const propertyIds: null | number[] = props.params.property_ids;
    if (propertyIds == null) {
      throw new Exception(
        "SelectDocumentPopup requires the property_ids parameter."
      );
    }
    propertyIds.forEach((pid) => {
      store
        .dispatch(inFin(finActions.LIST), { propertyId: pid })
        .then((values) => {
          documents.value.push(...values);
        });
    });

    return {
      documents,
      selectDocument(doc: Document) {
        emit("finish", doc);
      },
    };
  },
});
