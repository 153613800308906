import { getPlatform } from "@/util/platform";
import { ApiResponse, post } from "./repository";

export default {
  register(token: string): ApiResponse<{ success: true }> {
    return post(`/notifications/register`, {
      token,
      platform: getPlatform(), // TODO: set platform depending on current platform
    });
  },
};
