<template>
  <page-loader v-if="property == null && residents.length === 0" />
  <container thinner v-else>
    <div class="property-details">
      <generic-header>{{ $t("property.residence_details") }}</generic-header>
      <div class="sections">
        <section class="section">
          <h2 class="section-title">{{ $t("common.address") }}</h2>
          <p class="section-text section-text__bold">
            {{ property.street }} {{ property.number }}
          </p>
          <p class="section-text" v-if="property.app_nr">
            {{ $t("common.apartment") }}: {{ property.app_nr }}
          </p>
          <p class="section-text">{{ property.zipcode }} {{ property.city }}</p>
        </section>
        <section class="section">
          <h2 class="section-title">{{ $t("property.resident_count") }}</h2>
          <p class="section-text section-text__bold">
            {{ residents.length }}
          </p>
        </section>
        <section class="section">
          <h2 class="section-title">{{ $t("base_deed") }}</h2>
          <p v-if="property.basisakte == null && !isAdmin" class="section-text">
            {{ $t("property.base_deed_missing") }}
          </p>
          <file-item-view
            v-if="property.basisakte != null"
            :file-item="property.basisakte"
            :name="$t('property.base_deed')"
            @click="routeToFile(property.basisakte)"
            no-download-text
          />
          <app-link v-if="isAdmin" @click="newBasisakte">{{
            $t("property.upload_new_base_deed")
          }}</app-link>
        </section>
        <section class="section">
          <h2 class="section-title">
            {{ $t("property.rules_internal_order") }}
          </h2>
          <p v-if="property.rio == null && !isAdmin" class="section-text">
            {{ $t("property.no_rio") }}
          </p>
          <file-item-view
            v-if="property.rio != null"
            :file-item="property.rio"
            :name="$t('property.rules_internal_order')"
            @click="routeToFile(property.rio)"
            no-download-text
          />
          <app-link v-if="isAdmin" @click="newRIO">{{
            $t("property.upload_new_rio")
          }}</app-link>
        </section>
      </div>
    </div>
  </container>
</template>

<script lang="ts">
import { useRoute } from "vue-router";
import { defineComponent, Ref, ref, watchEffect } from "vue";
import { User } from "@/types";
import { inProperties, PropertiesActions } from "@/store/properties";
import { useStore } from "vuex";
import { useIsAdminById } from "@/util/auth";
import UploadPopup from "@/components/popups/UploadPopup.vue";
import { showPopup } from "@/components/popups/popups";
import { FileItem } from "@/repositories/fileRepository";
import FileItemView from "@/components/util/FileItemView.vue";
import { Property } from "@/repositories/propertyRepository";

function fetchProperty(id: number | string): Ref<null | Property> {
  const store = useStore();
  const property = ref(null as null | Property);

  store
    .dispatch(inProperties(PropertiesActions.BY_ID), { id })
    .then((value) => (property.value = value));

  return property;
}

export default defineComponent({
  components: { FileItemView },
  setup() {
    const route = useRoute();
    const store = useStore();
    const property = fetchProperty(route.params.id as string);
    const isAdmin = useIsAdminById(route.params.id as string);
    const residents = ref([] as User[]);

    watchEffect(() => console.log(property));

    store
      .dispatch(inProperties(PropertiesActions.LIST_RESIDENTS), {
        id: route.params.id,
      })
      .then((value) => {
        residents.value = value;
      });

    return {
      property,
      residents,
      isAdmin,
      async newRIO() {
        if (property.value == null) return;
        const file = (await showPopup(UploadPopup)) as FileItem;
        if (file == null) return;

        const newProperty = await store.dispatch(
          inProperties(PropertiesActions.PATCH),
          {
            id: property.value.id,
            updates: {
              rio_id: file.id,
            },
          }
        );
        property.value = newProperty;
      },
      async newBasisakte() {
        if (property.value == null) return;
        const file = (await showPopup(UploadPopup)) as FileItem;
        if (file == null) return;

        const newProperty = await store.dispatch(
          inProperties(PropertiesActions.PATCH),
          {
            id: property.value.id,
            updates: {
              basisakte_id: file.id,
            },
          }
        );
        property.value = newProperty;
      },
      routeToFile(file: FileItem) {
        window.open(file.url, "_blank");
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.sections {
  @include screen-from("tablet") {
    margin-top: 50px;
  }
}

.section {
  padding-bottom: 20px;
  margin: 20px 20px 0;
  box-sizing: border-box;
  border-bottom: 1px solid $border-general;

  &:last-child {
    border-bottom: none;
  }

  @include screen-from("tablet") {
    margin: 20px 0;
  }
}

.section-title {
  font-size: 0.875rem;
  letter-spacing: 0.04rem;
  font-weight: 400;
  color: $text-gray;
  margin-bottom: 10px;
}

.section-text {
  font-size: 1rem;
  color: $text-dark;
  font-weight: 500;
  margin-bottom: 15px;

  &__bold {
    font-size: 1.5rem;
    font-weight: 600;
  }
}
</style>
