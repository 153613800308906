
import { defineComponent, ref } from "vue";
import AppForm from "@/components/form/AppForm.vue";
import FormField from "@/components/form/FormField.vue";
import { useRouter } from "vue-router";
import FormError from "@/components/form/FormError.vue";
import { useStore } from "vuex";
import { authActions, inAuth } from "@/store/auth";
import FlashMessage from "@/components/common/FlashMessage.vue";

export default defineComponent({
  components: { AppForm, FormField, FormError, FlashMessage },
  setup() {
    const router = useRouter();
    const store = useStore();
    const email = ref("");
    const fieldMandatoryError = ref(false);
    const sent = ref(false);
    const notFound = ref(false);

    return {
      fieldMandatoryError,
      email,
      sent,
      notFound,
      async handleSubmit() {
        sent.value = false;
        notFound.value = false;
        fieldMandatoryError.value = false;

        const emailValue = email.value.trim().toLowerCase();

        if (!/^\S+@\S+$/.test(emailValue)) {
          fieldMandatoryError.value = true;
          return;
        }

        const result = await store.dispatch(
          inAuth(authActions.CREATE_PASS_RESET),
          {
            email: emailValue,
          }
        );

        if (result) {
          sent.value = true;
        } else {
          notFound.value = true;
        }
      },
      toLogin() {
        router.push("/auth/login");
      },
    };
  },
});
