import { Channel, Socket } from "phoenix";
import { AppChannel } from "./AppChannel";
import store from "@/store/index";
import { authGetters, inAuth } from "@/store/auth";
import { Chat, Invite, RemoveEventListenerFunction, User } from "@/types";

export class UserChannel extends AppChannel {
  private channel: Channel | null = null;

  setup(socket: Socket): void {
    const user: User = store.getters[inAuth(authGetters.USER)];
    this.createChannel(socket, user.id);
  }

  onBankLinked(cb: () => void): RemoveEventListenerFunction {
    const handler = () => {
      cb();
    };
    this.emitter.on("bank-linked", handler);
    return () => {
      this.emitter.off("bank-linked", handler);
    };
  }

  onInvite(cb: (invite: Invite) => void): RemoveEventListenerFunction {
    const handler = (invite?: Invite) => {
      if (invite == null) return;
      cb(invite);
    };
    this.emitter.on<Invite>("invite", handler);
    return () => {
      this.emitter.off<Invite>("invite", handler);
    };
  }

  onInviteRemoved(cb: (invite: Invite) => void): RemoveEventListenerFunction {
    const handler = (invite?: Invite) => {
      if (invite == null) return;
      cb(invite);
    };
    this.emitter.on<Invite>("invite-removed", handler);
    return () => {
      this.emitter.off<Invite>("invite-removed", handler);
    };
  }

  private createChannel(socket: Socket, userId: number) {
    this.channel = socket.channel(`user:${userId}`);
    this.channel.join();
    this.channel.on("invite", (data: { invite: Invite }) => {
      this.emitter.emit("invite", data.invite);
    });
    this.channel.on("invite-removed", (data: { invite: Invite }) => {
      this.emitter.emit("invite-removed", data.invite);
    });
    this.channel.on("new-chat", (data: { chat: Chat }) => {
      console.log("New chat");
      this.globalEmitter.emit("new-chat", data);
    });
    this.channel.on("bank-linked", () => {
      console.log("Bank linked!");
      this.globalEmitter.emit("bank-linked");
    });
  }
}
