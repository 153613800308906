<template>
  <container>
    <generic-header>{{ $t("issues.new") }}</generic-header>
    <div class="create-property-issues">
      <app-form
        :submitHandler="submit"
        submit-full
        :submit-text="$t('common.create')"
        thinner
        danger-button
      >
        <form-field>
          <textbox
            id="titel"
            name="titel"
            :placeholder="$t('issues.title_example')"
            :label="$t('common.title')"
            v-model="formData.title"
          />
          <field-error :fieldErrors="fieldErrors" name="title" />
        </form-field>
        <form-field>
          <dropdown
            :label="$t('issues.location')"
            :placeholder="$t('issues.select_location')"
            :options="locationOptions"
            v-model="formData.location"
          />
          <field-error :fieldErrors="fieldErrors" name="location" />
        </form-field>
        <form-field>
          <dropdown
            label="Type"
            :placeholder="$t('issues.select_type')"
            :options="{
              damage: $t('issues.types.damage'),
              elec: $t('issues.types.elec'),
              humid: $t('issues.types.humid'),
              dirt: $t('issues.types.dirt'),
              other: $t('issues.types.other'),
            }"
            v-model="formData.type"
          />
          <field-error :fieldErrors="fieldErrors" name="type" />
        </form-field>
        <form-field>
          <app-textarea
            id="description"
            name="description"
            :placeholder="$t('issues.description_example')"
            :label="$t('common.description')"
            v-model="formData.description"
          />
          <field-error :fieldErrors="fieldErrors" name="message" />
        </form-field>
        <form-field>
          <images-upload
            :images="formData.images"
            :uploadFunction="uploadImage"
            :label="$t('common.pictures')"
          />
        </form-field>
      </app-form>
    </div>
  </container>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref } from "vue";
import {
  FormField,
  AppForm,
  ImagesUpload,
  FieldError,
} from "@/components/form";
import { useStore } from "vuex";
import { filesActions, inFiles } from "@/store/files";
import { FileItem } from "@/repositories/fileRepository";
import { optimizeImage } from "@/util/image";
import { inIssues, issueActions } from "@/store/issues";
import { useRoute, useRouter } from "vue-router";
import { ValidationException } from "@/error_handling/ValidationException";
import { Property } from "@/repositories/propertyRepository";
import { inProperties, PropertiesActions } from "@/store/properties";

export default defineComponent({
  components: { FormField, AppForm, ImagesUpload, FieldError },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const formData = reactive({
      title: "",
      description: "",
      images: [] as FileItem[],
      type: null,
      location: null,
    });
    const fieldErrors = ref({} as { [fieldName: string]: string[] });

    const property = ref(null as null | Property);
    store
      .dispatch(inProperties(PropertiesActions.BY_ID), {
        id: route.params.id,
      })
      .then((value) => {
        property.value = value;
      });

    const locationOptions = computed(() => {
      if (property.value == null) return { "-2": "Laden.." };
      let options: Record<string, string> = {};
      if (property.value.has_basement) {
        options["kelder"] = "Kelder";
      }
      if (property.value.has_parking) {
        options["parking"] = "Parking";
      }
      options["gelijksvloers"] = "Gelijksvloers";
      for (let i = 1; i < property.value.floors; i++) {
        options[`${i}e verdieping`] = `${i}e verdieping`;
      }
      return options;
    });

    return {
      formData,
      fieldErrors,
      locationOptions,
      async submit() {
        try {
          const result = await store.dispatch(inIssues(issueActions.CREATE), {
            propertyId: route.params.id,
            issueParams: {
              title: formData.title,
              message: formData.description,
              pictures: formData.images.map((i) => i.id),
              type: formData.type,
              location: formData.location,
            },
          });
          router.push({
            name: "properties.issues.show",
            params: {
              propertyId: route.params.id,
              issueId: result.id,
            },
          });
        } catch (e) {
          if (e instanceof ValidationException) {
            fieldErrors.value = e.getErrors();
          } else {
            throw e;
          }
        }
      },
      async uploadImage(file: File) {
        const blob = await optimizeImage(file, 1000);
        const uploadedFile = await store.dispatch(
          inFiles(filesActions.UPLOAD),
          {
            file: blob,
          }
        );
        formData.images.push(uploadedFile);
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.create-property-issues {
  margin-top: 30px;
  padding: 0 30px;
  box-sizing: border-box;

  @include screen-from("tablet") {
    padding: 0;
  }
}
</style>
