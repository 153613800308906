
import { defineComponent, reactive, ref } from "vue";
import AppForm from "@/components/form/AppForm.vue";
import FormField from "@/components/form/FormField.vue";
import FormError from "@/components/form/FormError.vue";
import useVuelidate from "@vuelidate/core";
import FieldError from "@/components/form/FieldError.vue";
import { required, email, minLength } from "@vuelidate/validators";
import { useStore } from "vuex";
import { authActions, inAuth } from "@/store/auth";
import { useRouter } from "vue-router";
import { ValidationException } from "@/error_handling/ValidationException";
import { logBreakingException } from "@/util/logger";
import { sleep } from "@/util/sleep";

export default defineComponent({
  components: {
    AppForm,
    FormField,
    FormError,
    FieldError,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const state = reactive({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    });
    const fieldErrors = ref({} as { [fieldName: string]: string[] });

    const rules = {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      password: { required, minLength: minLength(6) },
    };

    const v = useVuelidate(rules, state);

    return {
      state,
      v,
      fieldErrors,
      async handleSubmit() {
        v.value.$touch();
        if (v.value.$invalid) return;
        try {
          await sleep(500);
          await store.dispatch(inAuth(authActions.REGISTER), {
            firstName: state.firstName,
            lastName: state.lastName,
            email: state.email.toLowerCase(),
            password: state.password,
          });
          router.push({
            name: "login",
            query: {
              email: state.email,
              registered: "true",
            },
          });
        } catch (e) {
          if (e instanceof ValidationException) {
            fieldErrors.value = e.getErrors();
          } else {
            logBreakingException(e);
          }
        }
      },
    };
  },
});
