<template>
  <popup-wrapper max-width="400px" @finish="$emit('finish')">
    <div class="content">
      <!--  -->
      <h1 class="title">Kies een factuur</h1>
      <div class="invoices">
        <div class="invoice">
          <!--  -->
        </div>
      </div>
    </div>
  </popup-wrapper>
</template>

<script lang="ts">
import {
  accountingActions,
  accountingGetters,
  inAccounting,
} from "@/store/accounting/accounting";
import { computed, defineComponent, PropType } from "vue";
import { useStore } from "vuex";
import PopupWrapper from "./PopupWrapper.vue";

export default defineComponent({
  emits: ["finish"],
  components: { PopupWrapper },
  props: {
    params: {
      type: Object as PropType<{ propertyId: string | number }>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    store.dispatch(
      inAccounting(accountingActions.FETCH_TRANSACTIONS),
      props.params.propertyId
    );

    // const openInvoices =

    return {};
  },
});
</script>

<style lang="scss" scoped>
.content {
  padding: 30px 30px 10px;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  color: $text-alt;
  margin-bottom: 20px;
}
</style>
