<template>
  <div class="info-icon" :class="{ ml }" @click="show">
    <question-icon />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import QuestionIcon from "@/components/icons/Question.vue";
import { showInfoPopup } from "./InfoPopup.vue";

export default defineComponent({
  components: { QuestionIcon },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    ml: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return {
      show() {
        showInfoPopup(props.title, props.text);
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.info-icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 50%;
  color: $text-accent;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &.ml {
    margin-left: 10px;
  }
}
</style>
