
import { User } from "@/types";
import { computed, defineComponent, PropType } from "vue";
import { fullName } from "@/store/users/util";
import { sharesForResident } from "@/util/properties";
import { Property } from "@/repositories/propertyRepository";
import { showPopup } from "@/components/popups/popups";
import PaymentsPopup from "../popups/payments_popup/PaymentsPopup.vue";
import AngleDownIcon from "@/components/icons/AngleDown.vue";

export default defineComponent({
  components: { AngleDownIcon },
  props: {
    resident: {
      type: Object as PropType<User>,
      required: true,
    },
    property: {
      type: Object as PropType<Property>,
      required: true,
    },
    debt: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    return {
      imageUrl: computed(
        () => props.resident?.avatar_url ?? "/img/empty_profile.svg"
      ),
      fullName: computed(() => fullName(props.resident ?? "")),
      shares: computed(() =>
        sharesForResident(props.property, props.resident.id)
      ),
      handleClick() {
        showPopup(PaymentsPopup, {
          user: props.resident,
          property: props.property,
        });
      },
    };
  },
});
