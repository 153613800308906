
import { useCurrentUser } from "@/util/auth";
import { useActiveProperty } from "@/util/properties";
import { capitalize, computed, defineComponent } from "vue";
import EmptyProfileIcon from "@/components/icons/EmptyProfile.vue";

export default defineComponent({
  components: { EmptyProfileIcon },
  setup() {
    const currentUser = useCurrentUser();
    const activeProperty = useActiveProperty();

    const isAdmin = computed(
      () =>
        currentUser.value != null &&
        currentUser.value.id === activeProperty.value?.admin_id
    );

    return {
      currentUser,
      capitalize,
      isAdmin,
    };
  },
});
