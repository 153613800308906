
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["hide"],
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: String,
      default: "auto",
    },
  },
});
