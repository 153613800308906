<template>
  <page-loader />
</template>

<script lang="ts">
import { Property } from "@/repositories/propertyRepository";
import { inProperties, PropertiesActions } from "@/store/properties";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const router = useRouter();
    const store = useStore();

    console.log("Initiating...");

    store
      .dispatch(inProperties(PropertiesActions.LIST))
      .then((properties: Property[]) => {
        console.log("Properties:", properties.length, properties);
        if (properties.length === 0) {
          // TODO: If user has invites, show them the invites
          router.push({
            name: "properties",
          });
          return;
        }
        if (properties.length === 1) {
          const property = properties[0];
          router.push({
            name: "properties.show",
            params: {
              id: property.id,
            },
          });
          return;
        }
        router.push({
          name: "properties",
        });
      });

    return {};
  },
});
</script>
