
import { GenericPageOption } from "@/components/common/generic-page/GenericPageOptions.vue";
import { showToast } from "@/components/notifications/toast";
import { showPopup } from "@/components/popups/popups";
import { inMeetings, meetingsActions } from "@/store/meetings/meetings";
import { Meeting } from "@/types";
import { isCurrentPropertyAdmin } from "@/util/auth";
import { DateTime } from "luxon";
import { capitalize, computed, defineComponent, PropType, Ref, ref } from "vue";
import { useStore } from "vuex";
import { showEditMeetingPopup } from "../EditMeeting/EditMeeting.vue";
import TalkingPointsFile from "./TalkingPointsFile.vue";

const component = defineComponent({
  components: { TalkingPointsFile },
  props: {
    params: {
      type: Object as PropType<{ meeting: Meeting }>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const meeting = ref(props.params.meeting);
    const uploadingFile = ref(false);

    const files = computed(() => meeting.value?.files ?? []);

    const formattedDate = computed(() => {
      if (meeting.value == null) return;
      const str = DateTime.fromJSDate(
        new Date(meeting.value.scheduled_for)
      ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
      return capitalize(str);
    });

    const formattedTime = computed(() => {
      if (meeting.value == null) return;
      let dt;
      if (typeof meeting.value.scheduled_for === "string") {
        dt = DateTime.fromISO(meeting.value.scheduled_for, {
          zone: "utc",
        });
      } else {
        dt = DateTime.fromJSDate(meeting.value.scheduled_for);
      }
      const str = dt.toLocal().toLocaleString(DateTime.TIME_24_SIMPLE);
      return capitalize(str);
    });

    const isAdmin = isCurrentPropertyAdmin();
    const options: Ref<GenericPageOption[]> = computed(() => {
      console.log("ADmin:", isAdmin.value);
      if (!isAdmin.value) return [];
      return [
        {
          id: "edit",
          iconName: "edit",
          text: "Aanpassen",
          onClick() {
            showEditMeetingPopup(props.params.meeting);
            emit("finish");
          },
        } as GenericPageOption,
      ];
    });

    return {
      options,
      files,
      uploadingFile,
      formattedDate,
      formattedTime,
      meeting,
      async addFile(file: File, type: string) {
        uploadingFile.value = true;
        try {
          const updatedMeeting = await store.dispatch(
            inMeetings(meetingsActions.ADD_FILE),
            {
              meetingId: props.params.meeting.id,
              file,
              type,
            }
          );
          console.log("Setting new meeting");
          meeting.value = updatedMeeting;
          showToast({
            type: "success",
            text: "Bestand geüpload!",
          });
        } catch (e) {
          showToast({
            type: "error",
            text: "Er ging iets mis :/. Gelieve jasper@mysyndic.com te contacteren. Onze oprechte excuses voor dit ongemak.",
          });
        } finally {
          uploadingFile.value = false;
        }
      },
    };
  },
});

export const showMeetingPopup = (meeting: Meeting) => {
  return showPopup(component, { meeting });
};

export default component;
