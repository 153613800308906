<template>
  <generic-page
    :title="property?.name"
    :loading="property == null"
    :options="options"
  >
    <!-- <page-loader v-if="property == null" />
  <container thinner v-else> -->
    <!-- <div class="edit-property"> -->
    <!-- <generic-header>{{ property.name }}</generic-header> -->
    <generated-form
      submitText="Opslaan"
      :fieldSettings="fieldSettings"
      :submitHandler="saveChanges"
      :fieldValues="property"
    />
    <!-- </div> -->
    <!-- </container> -->
  </generic-page>
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect } from "vue";
import GeneratedForm, {
  FieldsSettings,
} from "@/components/form/GeneratedForm.vue";
import { required } from "@vuelidate/validators";
import { useStore } from "vuex";
import { Property } from "@/repositories/propertyRepository";
import { inProperties, PropertiesActions } from "@/store/properties";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { GenericPageOption } from "@/components/common/generic-page/GenericPageOptions.vue";
import { showConfirmPopup } from "@/components/popups/Confirm.vue";
import { useActiveProperty } from "@/util/properties";

export default defineComponent({
  components: { GeneratedForm },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const activeProperty = useActiveProperty();
    const router = useRouter();
    const route = useRoute();
    const property = ref(null as null | Property);

    const options: GenericPageOption[] = [
      {
        id: "delete",
        text: "Remove property",
        iconName: "trash",
        danger: true,
        async onClick() {
          if (activeProperty.value == null) return;
          const result = await showConfirmPopup({
            title: "Bent u zeker dat u deze residentie wilt verwijderen?",
            confirmText: "Verwijder residentie",
            declineText: "Behoud residentie",
          });
          if (result !== true) return;
          await store.dispatch(inProperties(PropertiesActions.DELETE), {
            id: activeProperty.value.id,
          });
          window.location.href = "/";
        },
      },
    ];

    const fieldSettings: FieldsSettings = [
      {
        name: "name_header",
        type: "hr",
        label: t("property.one"),
      },
      {
        name: "name",
        type: "string",
        label: t("property.name_residence"),
        meta: { placeholder: t("property.name_residence") },
        rules: { required },
      },
      {
        name: "is_vme",
        type: "boolean",
        label: t("property.is_vme?"),
      },
      { name: "location_header", type: "hr", label: "Locatie" },
      [
        {
          name: "street",
          type: "string",
          label: t("common.street"),
          meta: { placeholder: t("common.street_nr") },
          rules: { required },
        },
        {
          name: "number",
          type: "string",
          label: t("common.street_nr"),
          meta: { placeholder: "31" },
          rules: { required },
          smaller: true,
        },
      ],
      [
        {
          name: "zipcode",
          type: "string",
          label: t("common.zipcode"),
          meta: { placeholder: "1000" },
          smaller: true,
        },
        {
          name: "city",
          type: "string",
          label: t("common.city"),
          meta: { placeholder: t("common.city_example") },
        },
      ],
      {
        name: "floors",
        type: "string",
        label: t("property.floor_count"),
        meta: { placeholder: "16", maxWidth: "480px" },
      },

      { name: "details_header", type: "hr", label: t("common.details") },
      { name: "has_basement", type: "boolean", label: t("common.basement") },
      { name: "has_parking", type: "boolean", label: t("common.parking") },
      // {
      //   name: "has_financial_data",
      //   type: "boolean",
      //   label: t("property.allow_financial"),
      // },
    ];

    watchEffect(() => console.log(property.value));

    store
      .dispatch(inProperties(PropertiesActions.BY_ID), {
        id: route.params.id,
      })
      .then((value) => {
        property.value = value;
      });

    return {
      options,
      fieldSettings,
      property,
      async saveChanges(changes: Record<string, any>) {
        if (property.value == null) return;
        await store.dispatch(inProperties(PropertiesActions.PATCH), {
          id: property.value.id,
          updates: changes,
        });
        router.push({
          name: "properties.show",
          params: { id: property.value.id },
        });
      },
    };
  },
});
</script>
