<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 23 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 18L0 10L3 7L8 12L20 0L23 3L8 18Z" fill="currentColor" />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    width: {
      type: String,
      default: "23px",
    },
    height: {
      type: String,
      default: "18px",
    },
  },
});
</script>
