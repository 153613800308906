<template>
  <page-loader v-if="loading" />
  <div class="generic-page" v-else>
    <div class="container header" :class="{ tabs: tabs != null, wide }">
      <generic-page-header
        :hideOnDesktop="hideHeaderDesktop"
        :title="title"
        :longTitle="longTitle"
        :options="options"
        :actions="actions"
        :titleInfo="titleInfo"
        @open-menu="openSidebar"
      />
      <template v-if="tabs != null">
        <generic-page-tabs :tabs="tabs" />
      </template>
    </div>
    <div class="container content" :class="{ wide }">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { inOther, OtherActions } from "@/store/other";
import { defineComponent, PropType } from "vue";
import { useStore } from "vuex";
import { GenericPageActions } from "../../../components/common/generic-page/GenericPageActions.vue";
import GenericPageHeader from "../../../components/common/generic-page/GenericPageHeader.vue";
import { GenericPageOption } from "../../../components/common/generic-page/GenericPageOptions.vue";
import GenericPageTabs, {
  GenericPageTab,
} from "../../../components/common/generic-page/GenericPageTabs.vue";

export default defineComponent({
  components: { GenericPageHeader, GenericPageTabs },
  props: {
    // On desktop, show wider
    wide: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    // Only shown from tablet & up
    longTitle: {
      type: String,
      default: null,
    },
    hideHeaderDesktop: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as PropType<GenericPageOption[]>,
      default: null,
    },
    actions: {
      type: Object as PropType<GenericPageActions>,
      default: null,
    },
    tabs: {
      type: Array as PropType<GenericPageTab[]>,
      default: null,
    },
    titleInfo: {
      type: Object as PropType<{ title: string; text: string }>,
      default: null,
    },
  },
  setup() {
    const store = useStore();

    return {
      openSidebar() {
        store.dispatch(inOther(OtherActions.SHOW_FINANCE_SIDEBAR));
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  @include screen-from("tablet-small") {
    max-width: 600px;
    padding: 0;
  }

  @include screen-from("tablet") {
    max-width: 700px;
    padding-left: 100px;
  }

  @include screen-from("desktop") {
    max-width: 700px;
    padding: 0;
    padding-left: 50px;
  }

  &.wide {
    @include screen-from("desktop-wide") {
      max-width: 900px;
    }
  }
}

.header {
  /* background-color: white;
  border-bottom: 1px solid $border-general; */
  &.tabs {
    background-color: white;
    border-bottom: 1px solid $border-general;

    @include screen-from("tablet-small") {
      background-color: transparent;
    }

    @include screen-from("tablet") {
      /* border: none; */
      background-color: transparent;
    }
  }
}

.content {
  margin-top: 20px;
  @include screen-from("desktop") {
    margin-top: 20px;
  }
}
</style>
