
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    // color: {
    //   type: String,
    //   default: "#111111",
    // },
    width: {
      type: String,
      default: "22",
    },
    height: {
      type: String,
      default: "22",
    },
    size: {
      type: String,
      default: null,
    },
    cross: {
      type: Boolean,
      default: false,
    },
    strokeWidth: {
      type: String,
      default: "1",
    },
  },
});
