
import { computed, defineComponent, PropType } from "vue";
import FolderIcon from "@/components/icons/Folder.vue";
import { Item } from "@/types";
import { isImageFile } from "@/store/documents/util";

export default defineComponent({
  components: { FolderIcon },
  props: {
    item: {
      type: Object as PropType<Item>,
      required: true,
    },
  },
  setup(props) {
    const extension = computed(() => {
      const itemName = props.item.name;
      const extension = itemName.match(/\.[^.]+/i);
      if (extension == null) return null;
      return extension[0].split(".").join("").toUpperCase();
    });

    return {
      extension,
      isImage: computed(() => props.item?.file && isImageFile(props.item.file)),
    };
  },
});
