<template>
  <div class="chat-view">
    <header class="chat-view-header">
      <generic-header override-backbutton @back="$emit('hide')">
        {{ title }}
        <template v-slot:right>
          <div class="close-button" @click="$emit('hide')">
            <CloseIcon />
          </div>
        </template>
      </generic-header>
    </header>
    <div class="messages" ref="messagesEl">
      <chat-message
        v-for="(message, index) in messages"
        :key="message"
        :message="message"
        :author="authorById(message.author_id)"
        :isActive="index === 0"
      />
    </div>
    <write-message-bar
      class="message-bar"
      @send="sendMessage"
      @add-document="addDocument"
      @add-issue="addIssue"
      @add-image="addImage"
    />
  </div>
</template>

<script lang="ts">
import appSocket from "@/channels/appSocket";
import { Issue } from "@/repositories/issueRepository";
import { Property } from "@/repositories/propertyRepository";
import { chatsGetters, inChats } from "@/store/chats";
import { inProperties, PropertiesActions } from "@/store/properties";
import { Chat, Message, User } from "@/types";
import { computed, defineComponent, PropType, ref, watch } from "vue";
import { useStore } from "vuex";
import { showPopup } from "../popups/popups";
import SelectIssuePopupVue from "../popups/SelectIssuePopup.vue";
import ChatMessage from "@/components/chat/ChatMessage.vue";
import WriteMessageBar from "@/components/chat/WriteMessageBar.vue";
import CloseIcon from "@/components/icons/Times.vue";
import { showUploadFilePopup } from "@/features/documents/popups/UploadFile.vue";
import { filesActions, inFiles } from "@/store/files";
import { FileItem } from "@/repositories/fileRepository";
import { isMobilePlatform } from "@/util/platform";
import { showChooseDocumentPopup } from "@/features/documents/popups/ChooseDocument.vue";

export default defineComponent({
  components: { ChatMessage, WriteMessageBar, CloseIcon },
  props: {
    chat: {
      type: Object as PropType<Chat>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const messages = computed(() => {
      if (props.chat == null) return;
      const messages: Message[] = store.getters[inChats(chatsGetters.MESSAGES)](
        props.chat.id
      );
      return messages.sort((a, b) => {
        return b.inserted_at.localeCompare(a.inserted_at);
      });
    });

    const messagesEl = ref(null as null | HTMLDivElement);
    watch(messages, () => {
      if (messagesEl.value == null) return;
      messagesEl.value.scrollTop = messagesEl.value.scrollHeight;

      // TODO: Unsure if this is necessary
      if (isMobilePlatform()) {
        document.body.scrollTop = document.body.scrollHeight;
      }
    });

    function markAllSeen() {
      if (messages.value == null) return;
      if (messages.value.length === 0) return;
      const oldest = messages.value[0];
      appSocket.getChatChannels().markSeen(props.chat.id, oldest.id);
    }

    const chatId = computed(() => props.chat?.id);
    markAllSeen();
    watch([messages, chatId], () => {
      markAllSeen();
    });

    const authorById = computed(() => (userId: number) => {
      if (props.chat == null) return null;
      return props.chat.users.find((u: User) => u.id === userId);
    });

    // async function overlappingProperties(): Promise<Property[]> {
    //   const c: Chat | null = props.chat;
    //   if (c == null) return [];

    //   return await store.dispatch(
    //     inProperties(PropertiesActions.LIST_OVERLAPPING),
    //     {
    //       userIds: c.users.map((u) => u.id),
    //     }
    //   );
    // }

    return {
      messagesEl,
      messages,
      authorById,
      sendMessage(content: string) {
        appSocket.getChatChannels().sendMessage(props.chat.id, content);
      },
      async addDocument() {
        const c: Chat | null = props.chat;
        if (c == null) return;

        let item = await showChooseDocumentPopup();
        if (item == null) return null;
        if (item.file_id == null) return null;
        appSocket
          .getChatChannels()
          .sendMessage(props.chat.id, item.file_id.toString(), "image");
      },
      async addImage() {
        const c: Chat | null = props.chat;
        if (c == null) return;
        //
        let image = await showUploadFilePopup({ accept: "image/*" });
        if (image == null) return;

        let uploadedFile = (await store.dispatch(inFiles(filesActions.UPLOAD), {
          file: image,
        })) as FileItem;
        appSocket
          .getChatChannels()
          .sendMessage(props.chat.id, uploadedFile.id.toString(), "image");
      },
      async addIssue() {
        const c: Chat | null = props.chat;
        if (c == null) return;

        let params: Record<string, any> = {};
        if (c.property_id != null) {
          params["property_id"] = c.property_id;
        } else {
          const overlappingProperties = await store.dispatch(
            inProperties(PropertiesActions.LIST_OVERLAPPING),
            {
              userIds: c.users.map((u) => u.id),
            }
          );
          params["property_ids"] = overlappingProperties.map(
            (p: Property) => p.id
          );
        }

        const issue: Issue | null = await showPopup(
          SelectIssuePopupVue,
          params
        );
        if (issue == null) return;
        appSocket
          .getChatChannels()
          .sendMessage(props.chat.id, issue.id.toString(), "issue");
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.chat-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: $bg-general;
  z-index: 200;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto 1fr auto;

  @include screen-from("tablet-wide") {
    grid-template-rows: 1fr auto;
    background-color: transparent;
    position: relative;
  }
}

.chat-view-header {
  @include screen-from("tablet-wide") {
    display: none;
  }
}

.messages {
  /* max-height: calc(100% - 70px - 120px); */
  overflow-y: auto;
  padding: 0 30px;
  display: flex;
  flex-flow: column-reverse nowrap;
  box-sizing: border-box;
}

.message-bar {
  @include screen-from("tablet-wide") {
    padding-bottom: 20px;
  }
}

.close-button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  cursor: pointer;

  svg {
    width: 28px;
    height: 28px;
  }
}
</style>
