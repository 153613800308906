
import { defineComponent } from "vue";
import QuestionIcon from "@/components/icons/Question.vue";
import { showInfoPopup } from "./InfoPopup.vue";

export default defineComponent({
  components: { QuestionIcon },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    ml: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return {
      show() {
        showInfoPopup(props.title, props.text);
      },
    };
  },
});
