
import { defineComponent, PropType, ref } from "vue";
import PopupWrapper from "@/components/popups/PopupWrapper.vue";
import { showPopup } from "@/components/popups/popups";

const component = defineComponent({
  components: { PopupWrapper },
  props: {
    params: {
      type: Object as PropType<{ name: string }>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const name = ref(props.params.name);

    return {
      name,
      submit() {
        if (name.value === props.params.name) {
          emit("finish");
          return;
        }
        emit("finish", name.value);
      },
    };
  },
});

export const promptNewFolderName = (currentName: string) => {
  return showPopup(component, { name: currentName });
};

export default component;
