
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    strokeWidth: {
      type: Number,
      default: 1,
    },
    width: {
      type: String,
      default: "24px",
    },
    height: {
      type: String,
      default: "23px",
    },
  },
});
