
import { useStore } from "vuex";
import { computed, defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { authGetters, inAuth } from "@/store/auth";
import { useActiveProperty } from "@/util/properties";
import UserPropertyItems from "./UserPropertyItems.vue";
import UserPropertyAdminItems from "./UserPropertyAdminItems.vue";
import { useIssues } from "@/store/issues/hooks";

export default defineComponent({
  components: {
    UserPropertyItems,
    UserPropertyAdminItems,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const property = useActiveProperty();

    if (route.params.id == null) {
      router.push({ name: "properties" });
      return;
    }

    const currentUser = store.getters[inAuth(authGetters.USER)];
    const { data: issues } = useIssues();
    const propertyIssues = computed(() =>
      issues.value?.filter((issue) => issue.property_id === property.value?.id)
    );

    return {
      issues: propertyIssues,
      isAdmin: computed(() => property.value?.admin_id === currentUser.id),
      property,
    };
  },
});
