import { RouteRecordRaw } from "vue-router";
import Documents from "./pages/Documents.vue";

export const routes: RouteRecordRaw[] = [
  {
    path: "/documents/:directoryId?",
    name: "documents.list",
    component: Documents,
  },
];
