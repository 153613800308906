export default {
  "ordinal": {
    "count": [
      1,
      2,
      3
    ],
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1th"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2nd"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3rd"])},
    "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "th"])}
  },
  "months": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Januari"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Februari"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])}
  },
  "auth": {
    "roles": {
      "resident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co-owner"])},
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])}
    },
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "login_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "re_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "no_account?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account?"])},
    "forgot_password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
    "bad_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong email and/or password"])},
    "register_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration succesful."])},
    "already_registered?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])},
    "invalid_password_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid password reset link"])},
    "reset_email_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If an account with this email exists, you will receive an email with a reset link."])},
    "unknown_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown email address"])},
    "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "didnt_forget_password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember your password?"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "new_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New account"])},
    "login_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in with"])}
  },
  "chat": {
    "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
    "my_chats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Chats"])},
    "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
    "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persons"])},
    "empty_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This chat doesnt have any messages yet"])},
    "no_messages_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No messages yet"])},
    "input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a message.."])},
    "find_convo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find chat"])}
  },
  "property": {
    "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residence"])},
    "select_a_residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a residence"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residence details"])},
    "you_were_invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been invited!"])},
    "you_were_invited...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been invited to join"])},
    "my_residences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My residences"])},
    "many": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residences"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select residence"])},
    "create_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Add residence"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New residence"])},
    "name_residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of residence"])},
    "association": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association of co-owners"])},
    "floor_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of floors (without basement and including ground floor)"])},
    "allow_financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow insights into financial data"])},
    "residence_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residence details"])},
    "resident_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of residences"])},
    "base_deed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["founding act"])},
    "base_deed_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No founding act (ask an admin to upload it)"])},
    "upload_new_base_deed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload founding act"])},
    "rules_internal_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal rules and procedures"])},
    "no_rio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No internal rules (ask an admin to upload it)"])},
    "upload_new_rio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload new interal rules and procedures"])},
    "issues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issues"])},
    "recent_issues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent issues"])},
    "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounting"])},
    "residents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co-owners"])},
    "resident_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residence administration"])},
    "is_vme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is a VME?"])}
  },
  "lots": {
    "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lot"])},
    "many": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lots"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add lot"])}
  },
  "residents": {
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add co-owner"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit co-owner"])},
    "send_email_invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send email invitation"])},
    "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co-owner"])},
    "many": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co-owners"])}
  },
  "fin": {
    "doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
    "new_doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New document"])},
    "add_doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new document"])},
    "doc_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of documents"])},
    "total_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total expenses"])},
    "recent_docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent documents"])}
  },
  "issues": {
    "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New issue"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit issue"])},
    "solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solved"])},
    "title_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamp broken on the second floor"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "select_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select location"])},
    "select_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select type"])},
    "types": {
      "damage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damage"])},
      "elec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electrical"])},
      "humid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moisture"])},
      "dirt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirt"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
    },
    "description_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This morning, I noticed..."])},
    "mark_not_in_progres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as not in progress"])},
    "mark_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as unsolved"])},
    "mark_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as solved"])},
    "mark_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as in progress"])},
    "see_solved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View solved issues"])},
    "recent_issues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent issues"])}
  },
  "accounting": {
    "contribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijdrage"])},
    "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
    "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
    "live_transaction_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import transactions"])},
    "live_transaction_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We automatically import your transactions from your bank account"])},
    "add_invoices_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload invoices"])},
    "add_invoices_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload your invoices and link them to your bank transactions"])},
    "resident_payment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co-owner payments"])},
    "resident_payment_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co-owners automatically get notified on how much they have to pay."])},
    "add_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankrekening toevoegen"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are responsibly for the correctness of your accounting."])},
    "select_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select bank"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank transactions"])},
    "types": {
      "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming"])},
      "out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing"])},
      "both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both"])}
    },
    "incomplete_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incomplete transaction"])},
    "incomplete_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incomplete transactions"])},
    "transactions_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog geen transactions, toekomstige bankoverschrijvingen zullen hier zichtbaar worden binnen 24 uur"])},
    "invoice_delete": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this invoice?"])},
      "confirmText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, delete it"])},
      "declineText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action can not be undone"])}
    },
    "upload_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload your invoice"])},
    "INCOMPLETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INCOMPLETE"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "add_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add information"])},
    "when_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When do co-owners receive their bill for the communal costs?"])},
    "when_payment_options": {
      "each_quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each quarter"])},
      "each_semester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each semester"])},
      "each": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each"])},
      "each_singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
      "each_plural_suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s"])}
    },
    "extra_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra info"])},
    "show_extra_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show extra info"])},
    "add_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add invoice"])},
    "invoice_describe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is this invoice about?"])},
    "invoice_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice description"])},
    "invoice_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice total (euro incl. BTW)"])},
    "invoice_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice file (pdf or picture)"])},
    "invoice_use_existing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use existing invoice"])}
  },
  "common": {
    "comingsoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming soon"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "take_a_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take a picture"])},
    "choose_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a file"])},
    "searchdots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deny"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
    "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "amount_cur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "add_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add file"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "short_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short description"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "phone_nr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartment"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
    "street_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My street"])},
    "street_nr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zipcode"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place"])},
    "city_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My city"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "inspect_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View details"])},
    "basement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basement"])},
    "parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking"])},
    "ground_floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ground floor"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "my_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My profile"])},
    "choose_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose image"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
    "invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitations"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty"])}
  },
  "upload": {
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload file"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops, something went wrong."])},
    "release": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can release :)"])},
    "click_or_drag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click or drag a file"])},
    "click_to_choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to pick a file"])}
  },
  "error": {
    "field_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field is invalid"])}
  },
  "legal": {
    "by_registering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By registering you accept our"])},
    "by_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By logging in you accept our"])},
    "terms_of_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of use"])}
  },
  "user": {
    "show_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show email to other residents"])}
  }
}