<template>
  <generic-page title="Finance">
    <div>
      This page contains the finance agreement. aka: "you are responsible for
      all your financial shizzle, we don't take any responsibility. Make sure to
      double check your stuff"
    </div>
    <app-button :to="{ name: 'finance.bank.link' }">Link bank</app-button>
  </generic-page>
</template>
