<template>
  <div class="generated-form">
    <app-form
      :submitFull="true"
      :submitText="submitText"
      :submitHandler="handleSubmit"
      thinner
    >
      <form-field v-for="(fieldSetting, index) in fieldSettings" :key="index">
        <template v-if="Array.isArray(fieldSetting)">
          <div class="shared-line">
            <div
              class="shared-line-item"
              :class="{ 'shared-line-item--smaller': item.smaller }"
              v-for="(item, index) in fieldSetting"
              :key="index"
            >
              <generated-form-item
                @update:value="(val) => (formData[item.name] = val)"
                :fieldSetting="item"
                :modelValue="valueFor(item.name)"
                @update:subCheckbox="
                  (val) => (formData[item?.subCheckbox?.name] = val)
                "
                :subCheckboxValue="valueFor(item?.subCheckbox?.name)"
              />
              <field-error :fieldErrors="fieldErrors" :name="item.name" />
            </div>
          </div>
        </template>
        <template v-else>
          <generated-form-item
            @update:value="(val) => (formData[fieldSetting.name] = val)"
            :fieldSetting="fieldSetting"
            :modelValue="valueFor(fieldSetting.name)"
            @update:subCheckbox="
              (val) => (formData[fieldSetting?.subCheckbox?.name] = val)
            "
            :subCheckboxValue="valueFor(fieldSetting?.subCheckbox?.name)"
          />
          <field-error :fieldErrors="fieldErrors" :name="fieldSetting.name" />
        </template>
      </form-field>
    </app-form>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, Prop, reactive, ref } from "vue";
import { AppForm, FormField } from "./index";
import GeneratedFormItem, { FieldSetting } from "./GeneratedFormItem.vue";
import FieldError from "./FieldError.vue";
import { ValidationException } from "@/error_handling/ValidationException";

export type FieldsSettingsItem = FieldSetting | FieldSetting[];
export type FieldsSettings = FieldsSettingsItem[];

export default defineComponent({
  components: { AppForm, FormField, GeneratedFormItem, FieldError },
  props: {
    fieldSettings: {
      type: Array,
      required: true,
    } as Prop<FieldsSettings>,
    fieldValues: {
      type: Object,
      default: () => ({}),
    },
    submitText: {
      type: String,
      default: "Opslaan",
    },
    submitHandler: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const formData = reactive({} as { [name: string]: any });

    const fieldErrors = ref({} as { [fieldName: string]: string[] });

    return {
      formData,
      fieldErrors,
      setFormData(key: string, value: any) {
        formData[key] = value;
      },
      async handleSubmit() {
        try {
          await props.submitHandler(formData);
        } catch (e) {
          if (e instanceof ValidationException) {
            fieldErrors.value = (e.getErrors()?.data as any)?.errors;
          } else {
            throw e;
          }
        }
      },
      valueFor: computed(() => {
        return (name: string) => {
          if (name == null) return null;
          if (formData != null && formData[name] != null) {
            return formData[name];
          }
          if (props.fieldValues != null && props.fieldValues[name] != null) {
            return props.fieldValues[name];
          }
          return null;
        };
      }),
    };
  },
});
</script>

<style lang="scss" scoped>
// .generated-form {
/* width: 100%; */
/* padding: 10px 30px 0;

  @include screen-from("tablet") {
    padding: 30px 0 0;
  } */
//}

.shared-line {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: stretch;
  width: 100%;
}

.shared-line-item {
  // width: 100%;

  &:first-child {
    margin-right: 10px;
  }

  &:last-child {
    margin-left: 10px;
  }

  &--smaller {
    max-width: 100px;
  }
}
</style>
