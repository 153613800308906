<template>
  <div class="wrapper">
    <drop-button label-icon="add" label-text="Nieuw" :items="actions.actions" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { GenericPageActions } from "../GenericPageActions.vue";

export default defineComponent({
  props: {
    actions: {
      type: Object as PropType<GenericPageActions>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.wrapper {
  display: none;

  @include screen-from("tablet-wide") {
    display: block;
  }
}
</style>
