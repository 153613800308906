
import { defineComponent, PropType } from "vue";
import GenericPageHeader from "./GenericPageHeader.vue";
import CloseIcon from "@/components/icons/Times.vue";
import { GenericPageOption } from "./GenericPageOptions.vue";
import { isShittyPlatform } from "@/util/platform";

export default defineComponent({
  components: { GenericPageHeader, CloseIcon },
  props: {
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as PropType<GenericPageOption[]>,
      default: null,
    },
    active: {
      type: Boolean,
      default: true,
    },
    useMarginTop: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      ios: isShittyPlatform(),
    };
  },
});
