
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    width: {
      type: String,
      default: "20px",
    },
    height: {
      type: String,
      default: "20px",
    },
    thickness: {
      type: String,
      default: "2px",
    },
    color: {
      type: String,
      default: undefined,
    },
    opacity: {
      type: String,
      default: "1",
    },
  },
});
