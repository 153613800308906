<template>
  <div class="invoice">
    <card :tags="tags" tagsPosition="right">
      <div class="content">
        <div class="title">{{ invoice.description }}</div>
        <div class="second-row">
          <div class="date">{{ formattedDate }}</div>
          <div class="amount">{{ amount }}</div>
        </div>
      </div>
      <template v-slot:slide>
        <div class="slide-content">
          <div class="cells">
            <div class="cell">
              <app-link target="_blank" :href="invoice.file.url"
                >Factuur openen</app-link
              >
            </div>
            <div class="cell">
              <div class="cell-label">Openstaand</div>
              <div class="cell-value">{{ outstandingAmount }}</div>
            </div>
          </div>
          <div class="slide-title">Transacties</div>
          <div class="transactions">
            <transaction-invoice-mini
              v-for="transaction in invoice.transactions"
              :key="transaction.id"
              :transaction="transaction"
              :transactionInvoice="transactionInvoiceById(transaction.id)"
            />
          </div>
        </div>
      </template>
    </card>
  </div>
</template>

<script lang="ts">
import { errorTag } from "@/components/common/Card.vue";
import {
  getInvoiceOutstandingAmount,
  isInvoiceFinished,
} from "@/store/accounting/util";
import { Invoice } from "@/types";
import { formatAsEuro } from "@/util/price";
import { formatDate } from "@/util/time";
import { computed, defineComponent, PropType, watchEffect } from "vue";
import TransactionInvoiceMini from "./TransactionInvoiceMini.vue";

export default defineComponent({
  components: { TransactionInvoiceMini },
  props: {
    invoice: {
      type: Object as PropType<Invoice>,
      required: true,
    },
  },
  setup(props) {
    const isFinished = computed(() => isInvoiceFinished(props.invoice));

    const transactionInvoiceById = (transactionId: number) => {
      return props.invoice.transactions_invoices?.find(
        (ti) => ti.transaction_id === transactionId
      );
    };

    watchEffect(() => console.log(props.invoice));

    return {
      transactionInvoiceById,
      outstandingAmount: computed(() =>
        formatAsEuro(getInvoiceOutstandingAmount(props.invoice))
      ),
      formattedDate: computed(() => formatDate(props.invoice.inserted_at)),
      amount: computed(() => formatAsEuro(props.invoice.amount)),
      tags: isFinished.value ? [] : [errorTag("Onvolledig")],
    };
  },
});
</script>

<style lang="scss" scoped>
.invoice {
  margin-bottom: 10px;
}

.content {
  padding: 20px;
  box-sizing: border-box;
}

.title {
  font-size: 1rem;
  font-weight: bold;
  line-height: 120%;
}

.second-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 5px;
}

.amount {
  /* font-size: 1.125rem; */
  color: $text-gray;
  /* font-weight: bold; */
}

.date {
  color: $text-gray;
}

.slide-content {
  padding: 20px;
}

.slide-title {
  font-size: 1.125rem;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
}

.cell-label {
  text-align: right;
  font-size: 0.65rem;
  text-transform: uppercase;
  color: $text-gray;
  font-weight: bold;
  margin-bottom: 3px;
  letter-spacing: 0.03em;
}

.cell-value {
  text-align: right;
  font-size: 1rem;
  font-weight: 600;
}

.transactions {
  max-width: 300px;
}

.cells {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
</style>
