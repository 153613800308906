<template>
  <header class="header" :class="{ hideOnDesktop }">
    <a class="hamburger-menu" @click="() => $emit('back')" v-if="backButton">
      <back-icon size="15" />
    </a>
    <a class="hamburger-menu" @click="$emit('open-menu')" v-else>
      <hamburger />
    </a>
    <h1 class="title">
      <span class="short-title">
        {{ title }}
      </span>
      <span class="long-title">
        {{ longTitle ?? title }}
      </span>
      <info
        ml
        v-if="titleInfo"
        :title="titleInfo.title"
        :text="titleInfo.text"
      />
    </h1>
    <div class="options">
      <template v-if="options">
        <generic-page-options :options="options" />
      </template>
      <template v-if="actions">
        <generic-page-actions :actions="actions" />
      </template>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { useStore } from "vuex";
import { inOther, OtherActions } from "@/store/other";
import Hamburger from "../../icons/Hamburger.vue";
import GenericPageOptions, {
  GenericPageOption,
} from "./GenericPageOptions.vue";
import BackIcon from "@/components/icons/AngleLeft.vue";
import { GenericPageActions as GenericPageActionsType } from "./GenericPageActions.vue";
import GenericPageActions from "./GenericPageActions.vue";

export default defineComponent({
  components: { Hamburger, GenericPageOptions, BackIcon, GenericPageActions },
  props: {
    title: {
      type: String,
      required: true,
    },
    // Only shown from tablet & up
    longTitle: {
      type: String,
      default: null,
    },
    hideOnDesktop: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as PropType<GenericPageOption[]>,
      default: null,
    },
    backButton: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Object as PropType<GenericPageActionsType>,
      default: null,
    },
    titleInfo: {
      type: Object as PropType<{ title: string; text: string }>,
      default: null,
    },
  },
  setup() {
    const store = useStore();

    return {
      openMobileSidebar() {
        store.dispatch(inOther(OtherActions.SHOW_MOBILE_SIDEBAR));
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 70px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  &.hideOnDesktop {
    @include screen-from("tablet-wide") {
      display: none;
    }
  }
}

.title {
  color: $text-dark;
  font-size: 1.3rem;
  font-weight: 600;
  font-family: $font-title;
  text-overflow: ellipsis;
  height: 16 * 1.5px;
  white-space: nowrap;
  padding: 0 10px;
  padding-bottom: 2px;
  box-sizing: border-box;
  line-height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  @include screen-from("tablet-wide") {
    font-size: 1.8rem;
  }

  @include screen-from("tablet-small") {
    padding: 0;
  }
}

.hamburger-menu {
  cursor: pointer;
  width: 40px;
  -webkit-tap-highlight-color: transparent;

  @include screen-from("tablet-wide") {
    display: none;
  }
}

.options {
  width: 40px;

  @include screen-from("tablet-wide") {
    width: auto;
    display: flex;
    flex-flow: row;
    align-items: center;
  }
}

.short-title {
  @include screen-from("tablet") {
    display: none;
  }
}
.long-title {
  display: none;
  @include screen-from("tablet") {
    display: block;
  }
}
</style>
