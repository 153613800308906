<template>
  <div class="options">
    <div
      @click="select(option)"
      class="option-wrapper"
      v-for="option in options"
      :key="option.id"
    >
      <card :selected="selectedId === option.id">
        <div class="option">
          <input
            class="option-input"
            type="radio"
            name="duration"
            value="3"
            :id="option.id"
            :checked="selectedId === option.id"
          />
          <div class="option-content">
            <label :for="option.id" class="option-label">
              {{ option.label }}
            </label>
            <p class="option-description">{{ option.description }}</p>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watchEffect } from "vue";

interface RadioOption {
  id: string;
  label: string;
  description: string;
}

export default defineComponent({
  emits: ["select"],
  props: {
    options: {
      type: Array as PropType<RadioOption[]>,
      required: true,
    },
    selected: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const selectedId = ref(null as null | string);

    watchEffect(() => {
      if (selectedId.value == null) {
        selectedId.value = props.selected;
      }
    });

    return {
      selectedId,
      select(option: RadioOption) {
        selectedId.value = option.id;
        emit("select", option.id);
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.option-wrapper {
  margin-bottom: 10px;
  cursor: pointer;
}

.option {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  padding: 15px 20px;
  box-sizing: border-box;
  cursor: pointer;
}

.option-input {
  margin: 0;
  margin-top: 1px;
  margin-right: 15px;
}

.option-content {
  display: flex;
  flex-flow: column nowrap;
}

.option-label {
  font-weight: 600;
  font-size: 1.125rem;
  letter-spacing: 0.01em;
}

.option-description {
  font-size: 0.875rem;
  color: $text-gray;
  margin-top: 5px;
  letter-spacing: 0.02em;
}
</style>
