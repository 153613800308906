
import { getPlatform } from "@/util/platform";
import { defineComponent, ref } from "vue";

export default defineComponent({
  emits: ["submit"],
  props: {
    submitHandler: {
      type: Function,
      required: true,
    },
    submitText: {
      type: String,
      default: "Submit",
    },
    submitFull: {
      type: Boolean,
      default: false,
    },
    submitAlwaysFull: {
      type: Boolean,
      default: false,
    },
    thinner: {
      type: Boolean,
      default: false,
    },
    dangerButton: {
      type: Boolean,
      default: false,
    },
    noPaddingBottom: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const loading = ref(false);

    return {
      loading,
      async handleSubmit() {
        loading.value = true;
        await Promise.resolve(props.submitHandler());
        loading.value = false;
      },
      shouldUseDarkButton() {
        return getPlatform() === "web";
      },
    };
  },
});
