// import { PushNotifications } from "@capacitor/push-notifications";
import { App, computed, watch } from "vue";
import PushNotificationsPlugin from "@/capacitor/plugins/PushNotificationsPlugins";
import store from "@/store/index";
import { authGetters, inAuth } from "@/store/auth";
import notificationsRepository from "@/repositories/notificationsRepository";
import { getPlatform } from "@/util/platform";

export default async (app: App): Promise<void> => {
  attemptRegister();
  const user = computed(() => store.getters[inAuth(authGetters.USER)]);
  watch(user, () => {
    attemptRegister();
  });
};

export async function attemptRegister() {
  const user = computed(() => store.getters[inAuth(authGetters.USER)]);
  console.log("Attempting push register", user.value);
  if (user.value == null) return;
  // Don't install on web because websocket connection doesn't get broken
  if (getPlatform() === "web") return;
  const result = await PushNotificationsPlugin.register();
  if (result == null) return;
  notificationsRepository.register(result.token);
  console.log(
    "----------------------------Registered with:",
    result?.token,
    user.value.id
  );
}
