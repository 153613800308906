
import { computed, defineComponent, PropType } from "vue";
import PopupWrapper from "@/components/popups/PopupWrapper.vue";

export default defineComponent({
  components: { PopupWrapper },
  props: {
    params: {
      type: Object as PropType<{ info: string }>,
      required: true,
    },
  },
  setup(props) {
    //
    return {
      items: computed(() =>
        props.params.info
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll("'", "")
          .split(",")
      ),
    };
  },
});
