<template>
  <div class="mobile-sidebar-wrapper" :class="{ visible, hiding }">
    <div class="mobile-sidebar-bg" @click="close"></div>
    <div class="mobile-sidebar" :class="{ mobile: isMobile }">
      <div class="close-button" @click="close">
        <close-icon class="close-icon" />
      </div>
      <div class="item item--clickable" @click="openProfile">
        <sidebar-profile-info />
      </div>
      <div class="item">
        <div class="property-selector-wrapper">
          <property-selector />
        </div>
      </div>
      <div class="item item--no-border" v-if="activeProperty != null">
        <sidebar-menu @close="close" />
      </div>
      <div class="spacer"></div>
      <!-- <div class="item item--no-border">
        <div class="new-button-wrapper">
          <app-button @click="toCreateResidence" thinner dark
            >+ {{ $t("property.new") }}</app-button
          >
        </div>
      </div> -->
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import SidebarProfileInfo from "./SidebarProfileInfo.vue";
import PropertySelector from "@/components/layout/util/PropertySelector.vue";
import SidebarMenu from "./SidebarMenu.vue";
import CloseIcon from "@/components/icons/AngleLeft.vue";
import { useStore } from "vuex";
import { inOther, OtherActions, OtherGetters } from "@/store/other";
import { useRouter } from "vue-router";
import { useActiveProperty } from "@/util/properties";
import { isMobilePlatform } from "@/util/platform";
import { useCurrentUser } from "@/util/auth";

export default defineComponent({
  components: { SidebarProfileInfo, PropertySelector, SidebarMenu, CloseIcon },
  setup() {
    const store = useStore();
    const router = useRouter();
    const currentUser = useCurrentUser();

    const hiding = ref(false);
    const visible = computed(
      () => store.getters[inOther(OtherGetters.IS_MOBILE_SIDEBAR_OPEN)]
    );

    const activeProperty = useActiveProperty();

    const close = () => {
      hiding.value = true;
      setTimeout(() => {
        store.dispatch(inOther(OtherActions.HIDE_MOBILE_SIDEBAR));
        hiding.value = false;
      }, 200);
    };

    return {
      currentUser,
      visible,
      close,
      hiding,
      activeProperty,
      toCreateResidence() {
        router.push({
          name: "properties.create",
        });
        close();
      },
      openProfile() {
        router.push({
          name: "profile",
        });
        close();
      },
      isMobile: isMobilePlatform(),
    };
  },
});
</script>

<style lang="scss" scoped>
$alwaysShowAt: "tablet-wide";

.mobile-sidebar-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 201;
  display: none;

  &.visible {
    display: block;
  }

  &.hiding {
    display: block;
  }

  @include screen-from($alwaysShowAt) {
    display: block;
    width: 300px;
  }
}

.mobile-sidebar-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($bg-mobile-menu, 0);
  animation: mobileSidebarBgEnter 0.2s ease-in-out forwards;

  @include screen-from($alwaysShowAt) {
    display: none;
  }
}
.mobile-sidebar-wrapper.hiding .mobile-sidebar-bg {
  animation: mobileSidebarBgLeave 0.2s ease-in-out forwards;
}
@keyframes mobileSidebarBgEnter {
  from {
    background-color: rgba($bg-mobile-menu, 0);
  }
  to {
    background-color: rgba($bg-mobile-menu, 0.4);
  }
}
@keyframes mobileSidebarBgLeave {
  from {
    background-color: rgba($bg-mobile-menu, 0.4);
  }
  to {
    background-color: rgba($bg-mobile-menu, 0);
  }
}

.mobile-sidebar {
  position: absolute;
  top: 0;
  box-sizing: border-box;
  height: 100%;
  max-width: 300px;
  width: 90%;
  background-color: $bg-general;
  box-shadow: 4px 3px 21px 10px rgba($bg-mobile-menu, 0.3);
  display: flex;
  flex-flow: column nowrap;
  left: -100%;
  animation: mobileSidebarEnter 0.2s ease-out forwards;

  padding-top: 1vh;
  &.mobile {
    @media screen and (min-height: 750px) {
      /* padding-top: 30px; */
      padding-top: 2vh;
    }
  }

  @include screen-from($alwaysShowAt) {
    box-shadow: 2px 1px 5px 5px rgba($bg-mobile-menu, 0.1);
    /* border-right: 2px solid $border-general; */
    background-color: white;
    animation: none;
    left: 0;
  }
}
.mobile-sidebar-wrapper.hiding .mobile-sidebar {
  animation: mobileSidebarLeave 0.2s ease-in forwards;

  @include screen-from($alwaysShowAt) {
    animation: none;
  }
}
@keyframes mobileSidebarEnter {
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
}
@keyframes mobileSidebarLeave {
  from {
    left: 0;
  }
  to {
    left: -100%;
  }
}

.item {
  width: 100%;
  border-bottom: 1px solid $border-general;

  &--no-border {
    border-bottom: none;
  }

  &--clickable {
    cursor: pointer;
  }
}

.property-selector-wrapper {
  padding: 15px;
}

.spacer {
  flex-grow: 1;
  height: 100%;
}

.new-button-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-self: center;
  justify-content: center;
  padding-bottom: 25px;
}

.close-button {
  cursor: pointer;
  top: 50px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: $bg-mobile-menu;
  color: white;
  position: absolute;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  transform: translateX(50%);

  @include screen-from($alwaysShowAt) {
    display: none;
  }
}

.close-icon {
  width: 16px;
  height: 16px;
}
</style>
