
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    noMarginX: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
      default: 0,
    },
  },
});
