
import { inOther, OtherActions } from "@/store/other";
import { defineComponent, PropType } from "vue";
import { useStore } from "vuex";
import { GenericPageActions } from "./GenericPageActions.vue";
import GenericPageHeader from "./GenericPageHeader.vue";
import { GenericPageOption } from "./GenericPageOptions.vue";
import GenericPageTabs, { GenericPageTab } from "./GenericPageTabs.vue";

export default defineComponent({
  components: { GenericPageHeader, GenericPageTabs },
  props: {
    // On desktop, show wider
    wide: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    // Only shown from tablet & up
    longTitle: {
      type: String,
      default: null,
    },
    hideHeaderDesktop: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as PropType<GenericPageOption[]>,
      default: null,
    },
    actions: {
      type: Object as PropType<GenericPageActions>,
      default: null,
    },
    tabs: {
      type: Array as PropType<GenericPageTab[]>,
      default: null,
    },
    titleInfo: {
      type: Object as PropType<{ title: string; text: string }>,
      default: null,
    },
  },
  setup() {
    const store = useStore();

    return {
      openSidebar() {
        store.dispatch(inOther(OtherActions.SHOW_MOBILE_SIDEBAR));
      },
    };
  },
});
