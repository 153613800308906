
import appSocket from "@/channels/appSocket";
import { inProperties, PropertiesActions } from "@/store/properties";
import { Invite } from "@/types";
import { useRemoveListener } from "@/util/component";
import { defineComponent } from "vue";
import { ref } from "vue-demi";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore();
    const newInvitation = ref(null as null | Invite);

    // TODO: make impossible to interact for a few seconds so accidental click is impossible

    useRemoveListener(() =>
      appSocket.getUserChannel().onInvite((invite: Invite) => {
        newInvitation.value = invite;
      })
    );

    useRemoveListener(() =>
      appSocket.getUserChannel().onInviteRemoved((invite: Invite) => {
        if (newInvitation.value?.id === invite.id) {
          newInvitation.value = null;
        }
      })
    );

    const hide = () => {
      newInvitation.value = null;
    };

    const accept = () => {
      if (newInvitation.value == null) return;
      store
        .dispatch(inProperties(PropertiesActions.ACCEPT_INVITE), {
          id: newInvitation.value.id,
        })
        .then(() => {
          hide();
        });
    };

    const decline = () => {
      if (newInvitation.value == null) return;
      store
        .dispatch(inProperties(PropertiesActions.DECLINE_INVITE), {
          id: newInvitation.value.id,
        })
        .then(() => {
          hide();
        });
    };

    return {
      accept,
      decline,
      hide,
      newInvitation,
    };
  },
});
