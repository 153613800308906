
import { OverviewItem } from "@/components/common/overview/Overview.vue";
import {
  useBankAccountDetails,
  usePaymentRequests,
  useUserPayments,
} from "@/store/accounting/hooks";
import { dateNextPayment, getDebtForUser } from "@/store/accounting/util";
import { useCurrentUserShares } from "@/store/properties/hooks";
import { useLoading } from "@/store/util/hooks";
import { useCurrentUser } from "@/util/auth";
import { formatAsEuro } from "@/util/price";
import { useActiveProperty } from "@/util/properties";
import { DateTime } from "luxon";
import { computed, defineComponent } from "vue";
import PaymentItem from "../../components/payments/PaymentItem.vue";

export default defineComponent({
  components: { PaymentItem },
  setup() {
    const activeProperty = useActiveProperty();
    const activePropertyId = computed(() => activeProperty.value?.id);
    const user = useCurrentUser();
    const userId = computed(() => user.value?.id ?? null);
    const { loading: loadingPayments, payments } = useUserPayments(userId);
    const { loading: loadingRequests, paymentRequests } =
      usePaymentRequests(userId);
    const { loading: ibanLoading, details: bankDetails } =
      useBankAccountDetails(activePropertyId);

    const debt = computed(() => {
      if (userId.value == null) return;
      return getDebtForUser(
        userId.value,
        payments.value,
        paymentRequests.value
      );
    });

    const overviewItems: OverviewItem[] = [
      {
        label: "Te betalen",
        value: "€750,00",
      },
    ];

    const paymentIdentifier = computed(() =>
      user.value?.payment_identifier?.toUpperCase()
    );

    const userShares = useCurrentUserShares();
    const totalShares = computed(() => activeProperty.value?.total_shares);
    const userSharePercentage = computed(() => {
      if (userShares.value == null || userShares.value === 0) return "(0%)";
      if (totalShares.value == null || totalShares.value === 0) return "";
      const value = (userShares.value / totalShares.value) * 100;
      return `(~${value.toFixed(0)}%)`;
    });

    const nextPayment = computed(() => {
      if (activeProperty.value == null) return;
      const date = dateNextPayment(activeProperty.value);
      return DateTime.fromJSDate(date).toFormat(`dd LLLL yyyy`);
    });

    return {
      payments,
      debt,
      overviewItems,
      paymentIdentifier,
      bankDetails,
      userShares,
      userSharePercentage,
      nextPayment,
      formattedDebt: computed(() => formatAsEuro(debt.value ?? 0)),
      loading: useLoading(loadingPayments, loadingRequests, ibanLoading),
    };
  },
});
