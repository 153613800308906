
import { accountingActions, inAccounting } from "@/store/accounting/accounting";
import { Transaction } from "@/types";
import { useActiveProperty } from "@/util/properties";
import { computed, defineComponent, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import TransactionItem from "./Transaction.vue";
import { useTransactionsActiveProperty } from "@/util/accounting";
import SearchIcon from "@/components/icons/Search.vue";
import CloseIcon from "@/components/icons/Times.vue";
import { isTransactionFinished } from "@/store/accounting/util";
import { useRouter } from "vue-router";

function useToggleSearch() {
  const showSearch = ref(false);
  const toggleSearch = () => (showSearch.value = !showSearch.value);

  return {
    showSearch,
    toggleSearch,
  };
}

export default defineComponent({
  components: {
    TransactionItem,
    SearchIcon,
    CloseIcon,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const activeProperty = useActiveProperty();
    const transactions = useTransactionsActiveProperty();
    const searchOptions = ref({
      type: null as null | "both" | "in" | "out",
      text: "" as string,
    });
    const isLoading = ref(true);
    const hideFinished = ref(false);

    watchEffect(() => {
      if (activeProperty.value == null) return;
      isLoading.value = true;
      store
        .dispatch(
          inAccounting(accountingActions.FETCH_TRANSACTIONS),
          activeProperty.value.id
        )
        .catch((e) => {
          if (e?.response?.status === 404) {
            router.push({
              name: "accounting.setup",
            });
          }
        })
        .finally(() => {
          isLoading.value = false;
        });
    });

    const fileInput = ref(null as null | HTMLInputElement);
    const selectingTransaction = ref(null as null | Transaction);

    const anyContainText = (needle: string, haystacks: string[]) =>
      haystacks.some(
        (h) => h != null && h.toLowerCase().includes(needle.toLowerCase())
      );

    const filteredTransactions = computed(() => {
      return transactions.value.filter((t: Transaction) => {
        if (hideFinished.value && isTransactionFinished(t)) return false;
        if (searchOptions.value.type === "in" && t.type === "out") return false;
        if (searchOptions.value.type === "out" && t.type === "in") return false;
        if (searchOptions.value.text == null || searchOptions.value.text === "")
          return true;
        return anyContainText(searchOptions.value.text, [
          t.from_address,
          t.from_name,
          t.to_address,
          t.to_name,
          t.description,
        ]);
      });
    });
    watchEffect(() => console.log("Raw:", transactions.value.length));
    watchEffect(() =>
      console.log("Filtered:", filteredTransactions.value.length)
    );

    const unfinishedCount = computed(() => {
      if (transactions.value === null) return 0;
      return transactions.value.filter((t) => !isTransactionFinished(t)).length;
    });

    return {
      unfinishedCount,
      hideFinished,
      filteredTransactions,
      searchOptions,
      transactions,
      fileInput,
      isLoading,
      addInvoice(transaction: Transaction) {
        const el = fileInput.value;
        if (el == null) return;
        selectingTransaction.value = transaction;
        el.click();
      },
      ...useToggleSearch(),
    };
  },
});
