<template>
  <div class="add-popup">
    <h2 class="title">Toevoegen</h2>
    <div class="options">
      <a href="#" @click.prevent="addDocument" class="option">
        <book-icon class="icon" />
        <!-- {{ $t("fin.doc") }} -->
        Document
      </a>
      <a href="#" @click.prevent="addIssue" class="option">
        <issue-icon class="icon" stroke-width="1" />
        {{ $t("issues.issue") }}
      </a>
      <a href="#" @click.prevent="addImage" class="option">
        <images-icon class="icon" stroke-width="1" />
        {{ $t("common.picture") }}
      </a>
    </div>
    <div class="arrow-wrapper">
      <div class="arrow-down"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import IssueIcon from "@/components/icons/Issue.vue";
import ImagesIcon from "@/components/icons/Images.vue";
import BookIcon from "@/components/icons/Book.vue";

export default defineComponent({
  components: { BookIcon, IssueIcon, ImagesIcon },
  setup(props, { emit }) {
    return {
      addDocument() {
        emit("hide");
        emit("document");
      },
      addIssue() {
        emit("hide");
        emit("issue");
      },
      addImage() {
        emit("hide");
        emit("image");
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.add-popup {
  position: absolute;
  bottom: calc(100% - 3px);
  right: -20px;
  background-color: white;
  box-shadow: $shadow-general, 0 4px 20px 5px rgba($text-dark, 0.07);
  padding: 15px 0 0;
  border-radius: 5px;
  animation: fadeIn 0.1s ease-out;
}

@keyframes fadeIn {
  from {
    transform: translate(10px, 20px);
    opacity: 0;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.title {
  color: $text-gray;
  font-size: 0.8rem;
  padding: 5px 20px 7px;
  text-transform: uppercase;
  font-weight: 600;
}

.options {
  display: flex;
  flex-flow: column nowrap;
}

.option {
  width: 100%;
  color: $text-alt;
  text-decoration: none;
  padding: 10px 25px;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 1rem;
}

.icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  color: black;
}

.arrow-wrapper {
  display: flex;
  justify-content: flex-end;
}

.arrow-down {
  $arrowSize: 15px;
  position: relative;
  bottom: -13px;
  right: 15px;
  width: 0;
  height: 0;
  border-left: $arrowSize solid transparent;
  border-right: $arrowSize solid transparent;
  border-top: $arrowSize solid white;
  border-radius: $arrowSize;
}
</style>
