
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  ref,
  watch,
  watchEffect,
} from "vue";
import PopupWrapper from "./PopupWrapper.vue";
import AppForm from "@/components/form/AppForm.vue";
import FormField from "@/components/form/FormField.vue";
import FieldError from "@/components/form/FieldError.vue";
import { CreatePaymentParams, Transaction, User } from "@/types";
import { formatAsEuro, parseToRawPrice } from "@/util/price";
import { useStore } from "vuex";
import { accountingActions, inAccounting } from "@/store/accounting/accounting";
import { inProperties, PropertiesActions } from "@/store/properties";
import { fullName } from "@/helpers/user";
import { getTransactionOutstandingAmount } from "@/store/accounting/util";

export default defineComponent({
  components: { PopupWrapper, AppForm, FormField, FieldError },
  props: {
    params: {
      type: Object as PropType<{ transaction: Transaction }>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const residents = ref([] as User[]);
    const state = reactive({
      selectedResident: null as null | string,
      amount: getTransactionOutstandingAmount(props.params.transaction) as
        | number
        | null,
      amountDisplayed: formatAsEuro(
        getTransactionOutstandingAmount(props.params.transaction),
        {
          noEuroSign: true,
        }
      ),
    });

    watchEffect(() => {
      store
        .dispatch(inProperties(PropertiesActions.LIST_RESIDENTS), {
          id: props.params.transaction.property_id,
        })
        .then((values) => {
          residents.value = values;
        });
    });

    const residentOptions = computed(() =>
      residents.value.reduce<{ [id: string]: string }>(
        (result, resident) =>
          Object.assign(result, { [resident.id]: fullName(resident) }),
        {}
      )
    );

    const formatAmount = () => {
      state.amountDisplayed = formatAsEuro(state.amount ?? 0, {
        noEuroSign: true,
      });
    };
    watch(
      () => state.amountDisplayed,
      (amountDisplayed: string) => {
        state.amount = parseToRawPrice(amountDisplayed);
      }
    );

    const fieldErrors = ref({} as Record<string, string[]>);

    return {
      state,
      residents,
      residentOptions,
      fieldErrors,
      formatAmount,
      async handleSubmit() {
        formatAmount();

        fieldErrors.value = {};
        if (state.selectedResident == null) {
          fieldErrors.value["resident"] = [
            "Gelieve een resident te selecteren.",
          ];
        }
        if (state.amount == null) {
          fieldErrors.value["amount"] = ["Ongeldige waarde"];
        }
        if (
          state.amount != null &&
          state.amount > props.params.transaction.amount
        ) {
          const max = (props.params.transaction.amount / 100).toFixed(2);
          fieldErrors.value["amount"] = [`Maximum waarde van €${max}`];
          state.amount = props.params.transaction.amount;
          formatAmount();
        }
        if (Object.keys(fieldErrors.value).length > 0) return;

        if (state.selectedResident == null) return;
        await store.dispatch(inAccounting(accountingActions.CREATE_PAYMENT), {
          propertyId: props.params.transaction.property_id,
          createParams: {
            amount: state.amount,
            user_id: parseInt(state.selectedResident),
            transaction_id: props.params.transaction.id,
          } as CreatePaymentParams,
        });
        emit("finish");
      },
    };
  },
});
