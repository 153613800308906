import issueRepository, { Issue } from "@/repositories/issueRepository";
import { ActionContext, MutationTree } from "vuex";
import { RootState } from ".";

export interface IssuesState {
  cached_issues: Issue[];
}

type IssuesActionContext = ActionContext<IssuesState, RootState>;

export function inIssues(action: string): string {
  return "issues/" + action;
}

export const issueMutations = {
  ADD_ISSUE: "ADD_ISSUE",
  ADD_ISSUES: "ADD_ISSUES",
  INVALIDATE: "INVALIDATE",
};

export const issueActions = {
  CREATE: "CREATE",
  BY_ID: "BY_ID",
  LIST: "LIST",
  LIST_ALL: "LIST_ALL",
  UPDATE: "UPDATE",
};

const state = (): IssuesState => ({
  cached_issues: [],
});

const mutations: MutationTree<IssuesState> = {
  [issueMutations.ADD_ISSUE](state, issue: Issue) {
    const existingIssue = state.cached_issues.find((i) => i.id === issue.id);
    if (existingIssue != null) return;
    state.cached_issues.push(issue);
  },
  [issueMutations.ADD_ISSUES](state, issues: Issue[]) {
    const issuesById: Record<number, Issue> = {};
    state.cached_issues.forEach((issue) => {
      issuesById[issue.id] = issue;
    });
    issues.forEach((issue) => {
      issuesById[issue.id] = issue;
    });
    state.cached_issues = Object.values(issuesById);
  },
  [issueMutations.INVALIDATE](state, issueId: number) {
    state.cached_issues = state.cached_issues.filter((i) => i.id !== issueId);
  },
};

const actions = {
  async [issueActions.CREATE](
    context: IssuesActionContext,
    params: { issueParams: any; propertyId: any }
  ): Promise<Issue> {
    const response = await issueRepository.create(
      params.propertyId,
      params.issueParams
    );
    return response.data.data.issue;
  },
  async [issueActions.BY_ID](
    context: IssuesActionContext,
    { id, allowFromCache = false }: { id: number; allowFromCache?: boolean }
  ): Promise<Issue> {
    if (allowFromCache) {
      if (typeof id === "string") {
        id = parseInt(id);
      }
      const cachedIssue = context.state.cached_issues.find((i) => i.id === id);
      if (cachedIssue != null) {
        return cachedIssue;
      }
    }

    const response = await issueRepository.byId(id);
    const issue = response.data.data.issue;
    context.commit(issueMutations.ADD_ISSUE, issue);
    return issue;
  },
  async [issueActions.LIST](context: IssuesActionContext): Promise<Issue[]> {
    const response = await issueRepository.list();
    const issues = response.data.data.issues;
    context.commit(issueMutations.ADD_ISSUES, issues);
    return issues;
  },
  async [issueActions.LIST_ALL](): Promise<Issue[]> {
    const response = await issueRepository.listAll();
    return response.data.data.issues;
  },
  async [issueActions.UPDATE](
    context: IssuesActionContext,
    params: {
      id: number;
      updates: any;
    }
  ): Promise<Issue> {
    const response = await issueRepository.update(params.id, params.updates);
    context.commit(issueMutations.INVALIDATE, params.id);
    return response.data.data.issue;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
