
import { computed, defineComponent, ref, watchEffect } from "vue";
import EmptyProfileIcon from "@/components/icons/EmptyProfile.vue";
import { useStore } from "vuex";
import { authGetters, inAuth } from "@/store/auth";
import { Message } from "@/types";
import { DateTime } from "luxon";
import IssueChatItem from "./IssueChatItem.vue";
import DocumentChatItem from "./DocumentChatItem.vue";
import ImageChatItem from "./ImageChatItem.vue";

export default defineComponent({
  components: {
    EmptyProfileIcon,
    IssueChatItem,
    DocumentChatItem,
    ImageChatItem,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    message: {
      type: Object,
      required: true,
    },
    author: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const messageEl = ref(null as null | HTMLDivElement);
    const currentUser = computed(() => store.getters[inAuth(authGetters.USER)]);

    const timeAgo = (message: Message) => {
      const insertedAt = DateTime.fromISO(message.inserted_at, {
        zone: "utc",
      }).setZone(DateTime.local().zoneName);

      const now = DateTime.now();
      if (insertedAt.year !== now.year) {
        return insertedAt.toFormat("dd/LL/yyyy");
      }
      if (insertedAt.day !== now.day) {
        return insertedAt.toFormat("dd/LL");
      }

      return insertedAt.toFormat("hh:mm");
    };

    const isActive = computed(() => props.isActive);

    watchEffect(() => {
      if (isActive.value && messageEl.value != null) {
        messageEl.value.scrollIntoView();
      }
    });

    return {
      messageEl,
      currentUser,
      timeAgo,
    };
  },
});
