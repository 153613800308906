<template>
  <div class="lightbox">
    <div class="bg" @click="$emit('finish')"></div>
    <div class="content">
      <swiper :slides-per-view="1" :space-between="50">
        <light-box-nav @exit="$emit('finish')" :images="params.images" />
        <template v-for="image in params.images" :key="image">
          <swiper-slide>
            <light-box-slide :image="image" />
          </swiper-slide>
        </template>
      </swiper>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import LightBoxSlide from "./LightBoxSlide.vue";
import LightBoxNav from "./LightBoxNav.vue";

import "swiper/swiper.scss";
import { showPopup } from "../popups/popups";

const component = defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    LightBoxSlide,
    LightBoxNav,
  },
  props: {
    params: {
      type: Object as PropType<{ images: string[] }>,
      required: true,
    },
  },
});

export const showLightboxPopup = (images: string[]) => {
  return showPopup(component, { images });
};

export default component;
</script>

<style lang="scss" scoped>
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($text-dark, 0.9);
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
</style>
