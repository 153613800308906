
import { useActiveProperty } from "@/util/properties";
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import PropertyOption from "@/components/property/PropertyOption.vue";
import IssueIcon from "@/components/icons/Issue.vue";
import ChatIcon from "@/components/icons/Chat.vue";
import { useActivePropertyIssues } from "@/store/issues/hooks";
import PaymentIcon from "@/components/icons/Payment.vue";
import { useCurrentUserPaymentRequests } from "@/store/accounting/hooks";
import BookIcon from "@/components/icons/Book.vue";

export default defineComponent({
  components: {
    PropertyOption,
    IssueIcon,
    ChatIcon,
    PaymentIcon,
    BookIcon,
  },
  props: {
    noMarginX: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter();
    const property = useActiveProperty();
    const { issues } = useActivePropertyIssues();
    const { paymentRequests } = useCurrentUserPaymentRequests();

    return {
      issues,
      hasPayments: computed(() => paymentRequests.value.length > 0),
      toPayments() {
        router.push({
          name: "accounting.user.payments",
        });
      },
      toDocuments() {
        router.push({
          name: "documents.list",
        });
      },
      toIssues() {
        if (property.value == null) return;
        router.push({
          name: "properties.issues.list",
          params: { id: property.value.id },
        });
      },
      toChat() {
        if (property.value == null) return;
        router.push({
          name: "chats.list",
          query: {
            propertyId: property.value.id,
          },
        });
      },
    };
  },
});
