
import { computed, defineComponent, ref } from "vue";
import PropertyListing from "@/components/property/PropertyListing.vue";
import BottomMiddleLink from "@/components/BottomMiddleLink.vue";
import { useStore } from "vuex";
import { inProperties, PropertiesActions } from "@/store/properties";
import { useRoute, useRouter } from "vue-router";
import { Property } from "@/repositories/propertyRepository";

export default defineComponent({
  components: { PropertyListing, BottomMiddleLink },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const properties = ref(null as any[] | null);

    const toProperty = (propertyId: number) => {
      if (route.query.back != null) {
        router.push(route.query.back as string);
        return;
      }

      router.push({
        name: "properties.show",
        params: {
          id: propertyId,
        },
      });
    };

    const updateProperties = () => {
      store
        .dispatch(inProperties(PropertiesActions.LIST))
        .then((props: Property[]) => {
          if (props.length === 1) {
            const property = props[0];
            toProperty(property.id);
          }
          properties.value = props;
        });
    };
    updateProperties();

    return {
      hasBack: computed(() => route.query?.back != null),
      properties,
      toProperty,
      updateProperties,
    };
  },
});
