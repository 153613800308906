<template>
  <page-loader v-if="loading" />
  <div class="select-bank" v-else>
    <generic-header center-title>
      {{ $t("accounting.select_bank") }}
    </generic-header>
    <container maxWidth="350px" centerh>
      <textbox
        class="search-box"
        type="search"
        :placeholder="$t('common.searchdots')"
        thinner
        v-model="filterText"
      />
      <div class="bank-options" v-if="banks?.length > 0">
        <div
          @click="selectBank(bank)"
          class="bank-option"
          v-for="bank in filteredBanks"
          :key="bank.id"
        >
          <div
            class="bank-logo"
            :style="{ 'background-image': `url('${bank.logo}')` }"
          ></div>
          <div class="bank-text">{{ bank.name }}</div>
        </div>
      </div>
      <div class="bank-options" v-else>
        <page-loader smallHeight />
      </div>
    </container>
  </div>
</template>

<script lang="ts">
import appSocket from "@/channels/appSocket";
import { getLocale } from "@/plugins/install_i18n";
import {
  accountingActions,
  accountingGetters,
  inAccounting,
} from "@/store/accounting/accounting";
import { authActions, inAuth } from "@/store/auth";
import { Bank } from "@/types";
import { isMobilePlatform } from "@/util/platform";
import { useActiveProperty } from "@/util/properties";
import { Browser } from "@capacitor/browser";
import { computed, defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { App } from "@capacitor/app";

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const activeProperty = useActiveProperty();
    const loading = ref(false);
    // const baseUrl = process.env.VUE_APP_BASE_URL;
    const webAppBaseUrl =
      process.env.VUE_APP_BASE_URL ?? "https://app.mysyndic.com";

    store.dispatch(inAccounting(accountingActions.FETCH_BANKS));
    const banks = computed(
      () => store.getters[inAccounting(accountingGetters.BANKS)]
    );

    const filterText = ref("");
    const filteredBanks = computed(() => {
      return banks.value.filter((b: Bank) =>
        b.name.toLowerCase().includes(filterText.value.toLowerCase())
      );
    });

    return {
      banks,
      filterText,
      filteredBanks,
      loading,
      async selectBank(bank: Bank) {
        if (activeProperty.value == null) return;

        if (isMobilePlatform()) {
          const token = await store.dispatch(
            inAuth(authActions.CREATE_SINGLE_USE_TOKEN)
          );
          try {
            const url =
              webAppBaseUrl +
              `/accounting/select_bank/${bank.id}/${
                activeProperty.value.id
              }/${token}?language=${getLocale()}`;

            // `http://localhost:8080/accounting/select_bank/HELLO_BE_GEBABEBB/2/f8c9db21-6890-4435-8f83-2a072f53c2db?language=nl`
            console.log("Url:", url);
            appSocket.getUserChannel().onBankLinked(() => {
              Browser.close();
              loading.value = true;
              setTimeout(async () => {
                await router.push({
                  name: "accounting.select_payment_duration",
                });
                loading.value = false;
              }, 500);
            });
            Browser.addListener("browserPageLoaded", () => {
              console.log("Browser finished loading");
            });
            Browser.addListener("browserFinished", () => {
              console.log("Browser finished");
              router.push({
                name: "accounting.select_payment_duration",
              });
            });
            App.addListener("appUrlOpen", (data) => {
              if (data.url.includes("mysyndicapp://")) {
                console.log("Appurl");
                Browser.close();
                router.push({
                  name: "accounting.select_payment_duration",
                });
              }
            });
            setInterval(() => {
              if (activeProperty.value?.id == null) {
                console.log("No active property");
                return;
              }
              store
                .dispatch(
                  inAccounting(accountingActions.FETCH_TRANSACTIONS),
                  activeProperty.value.id
                )
                .then(() => {
                  router.push({
                    name: "accounting.transactions.list",
                  });
                })
                .catch(() => {
                  console.log("No transactions yet");
                });
            }, 5000);
            await Browser.open({
              url,
            });
          } catch (e) {
            console.log("e:", e);
            console.log((e as any).message);
            console.log(JSON.stringify(e));
          }
          return;
        }

        const token = await store.dispatch(
          inAuth(authActions.CREATE_SINGLE_USE_TOKEN)
        );
        const callbackUrl =
          webAppBaseUrl +
          `/accounting/select_bank/finish/${activeProperty.value.id}/${token}`;
        const link = await store.dispatch(
          inAccounting(accountingActions.BUILD_LINK),
          {
            redirectTo: callbackUrl,
            bankAspspId: bank.id,
            propertyId: activeProperty.value.id,
          }
        );
        console.log(callbackUrl, link);
        window.location.href = link;
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.search-box {
  margin-top: 20px;
}

.bank-options {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 15px;
  width: 100%;
}

.bank-option {
  margin-bottom: 10px;
  background-color: white;
  width: 100%;
  border: 1px solid $border-general;
  border-radius: 6px;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:active {
    background-color: $bg-accent-hover;
  }
}

.bank-logo {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.bank-text {
  line-height: 1.3;
}
</style>
