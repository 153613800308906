<template>
  <div class="hamburger">
    <div class="slice"></div>
    <div class="slice"></div>
    <div class="slice"></div>
  </div>
</template>

<style lang="scss" scoped>
.hamburger {
  width: 25px;
  height: 19px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding: 5px 0;
}

.slice {
  width: 100%;
  height: 2px;
  background-color: $text-dark;
  border-radius: 1.5px;
}
</style>
