<template>
  <div class="chat-listing" @click="$emit('click')" :class="{ active }">
    <div
      v-if="iconUrl != null"
      class="icon"
      :style="{ 'background-image': `url('${iconUrl}')` }"
    ></div>
    <div class="icon" v-else>
      <empty-profile />
    </div>
    <div class="content">
      <h3 class="title">{{ title }}</h3>
      <div class="last-message">{{ lastMessage }}</div>
    </div>
    <div class="right">
      <div class="unread-count" v-if="unreadCount > 0">
        <template v-if="unreadCount < 10">
          {{ unreadCount }}
        </template>
        <template v-else>9+</template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import EmptyProfile from "@/components/util/EmptyProfile.vue";

export default defineComponent({
  emits: ["click"],
  components: { EmptyProfile },
  props: {
    title: {
      type: String,
      required: true,
    },
    lastMessage: {
      type: String,
      required: true,
    },
    iconUrl: {
      type: String,
      default: null,
    },
    unreadCount: {
      type: Number,
      default: 0,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.chat-listing {
  display: grid;
  grid-template-columns: 50px 1fr 70px;
  align-items: center;
  column-gap: 13px;
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;

  &:active {
    background-color: rgba($bg-accent, 0.2);
  }

  &:hover {
    background-color: $bg-general;
  }

  @include screen-from("tablet-wide") {
    border-bottom: 1px solid $border-general;
    padding: 20px;
    margin-bottom: 0;

    &.active {
      background-color: $bg-general;

      &::after {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        width: 5px;
        left: 0;
        background-color: $bg-accent;
      }
    }
  }
}

.icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.content {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}

.title {
  font-size: 1.125rem;
  color: $text-alt;
  font-weight: 600;
  font-family: $font-title;
  margin-bottom: 7px;

  @include screen-from("tablet-wide") {
    margin-bottom: 3px;
  }
}

.last-message {
  font-size: 0.875rem;
  letter-spacing: 0.02rem;
  color: $text-alt;
  line-height: 1.4;
  font-family: $font-text;
  white-space: nowrap;
  overflow-x: hidden;
  // 100% - icon - column gap - padding left - padding right - some space right
  max-width: calc(100vw - 50px - 7px - 20px - 20px - 60px);
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  max-width: 130px;
}

.right {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.unread-count {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $bg-accent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9375rem;
  color: $text-alt;
  font-weight: 600;
}
</style>
