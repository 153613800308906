<template>
  <div class="wrapper">
    <div class="icon">
      <template v-if="social.toLowerCase() === 'email'">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" idth="">
          <path
            fill="currentColor"
            d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z"
          />
        </svg>
      </template>
      <template v-if="social.toLowerCase() === 'apple'">
        <svg
          width="32"
          height="32"
          aria-hidden="true"
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
        >
          <path
            fill="currentColor"
            d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
          ></path>
        </svg>
      </template>
      <template v-if="social.toLowerCase() === 'facebook'">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M32 15.9975C31.9995 12.9399 31.123 9.9465 29.4741 7.37162C27.8252 4.79675 25.4731 2.74824 22.6962 1.46862C19.9192 0.18899 16.8338 -0.268166 13.8051 0.15127C10.7765 0.570705 7.93139 1.84917 5.60674 3.8353C3.28208 5.82144 1.57519 8.43207 0.688139 11.3581C-0.198914 14.2842 -0.228981 17.4032 0.601496 20.3458C1.43197 23.2884 3.08822 25.9315 5.37415 27.9621C7.66009 29.9927 10.48 31.3257 13.5 31.8035V20.6225H9.438V15.9975H13.5V12.4725C13.5 8.46249 15.889 6.24749 19.543 6.24749C20.743 6.26413 21.9402 6.36841 23.125 6.55949V10.4975H21.107C20.7631 10.4518 20.4134 10.4841 20.0837 10.592C19.754 10.6999 19.4528 10.8805 19.2024 11.1206C18.952 11.3608 18.7589 11.6541 18.6373 11.979C18.5157 12.3039 18.4688 12.652 18.5 12.9975V15.9975H22.938L22.228 20.6225H18.5V31.8035C22.2638 31.2081 25.6915 29.2889 28.1663 26.3914C30.6412 23.4938 32.0006 19.8081 32 15.9975Z"
            fill="#3B5998"
          />
        </svg>
      </template>
      <template v-if="social.toLowerCase() === 'google'">
        <svg
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.2457 6.98328C18.5013 6.98189 20.6845 7.77897 22.4086 9.23328L27.238 4.63432C25.3841 2.92491 23.1574 1.67112 20.7344 0.972468C18.3114 0.273811 15.759 0.149514 13.2796 0.609441C10.8002 1.06937 8.46211 2.10086 6.45093 3.62205C4.43974 5.14324 2.81079 7.11226 1.69336 9.37285L7.13783 13.5694C7.77279 11.6547 8.99407 9.98828 10.6287 8.80624C12.2633 7.6242 14.2284 6.98644 16.2457 6.98328Z"
            fill="#D94F3D"
          />
          <path
            d="M6.6459 16.5829C6.64726 15.5585 6.81331 14.541 7.13769 13.5694L1.69322 9.37283C0.579556 11.6132 0 14.081 0 16.5829C0 19.0848 0.579556 21.5526 1.69322 23.793L7.13769 19.5964C6.81331 18.6248 6.64726 17.6073 6.6459 16.5829Z"
            fill="#F2C042"
          />
          <path
            d="M31.8265 13.6292H16.3194V20.2751H25.1068C24.584 22.1542 23.3864 23.7744 21.7432 24.8253L27.1456 28.9901C30.5978 25.8916 32.6262 20.8533 31.8265 13.6292Z"
            fill="#5085ED"
          />
          <path
            d="M21.7411 24.8255C20.0715 25.7811 18.1682 26.2512 16.2457 26.1827C14.2284 26.1795 12.2633 25.5418 10.6287 24.3597C8.99407 23.1777 7.77279 21.5113 7.13783 19.5966L1.69336 23.7931C3.03815 26.504 5.11264 28.7859 7.68349 30.3821C10.2543 31.9783 13.2196 32.8256 16.2457 32.8286C20.2276 32.9368 24.1089 31.5692 27.1435 28.9888L21.7411 24.8255Z"
            fill="#57A75C"
          />
        </svg>
      </template>
      <slot />
    </div>
    <div class="text">{{ $t("auth.login_with") }} {{ social }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    // google || facebook
    social: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: white;
  border: 1px solid $border-general;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  color: $text-dark;
  padding: 15px 20px;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: $bg-general;
  }

  &:active {
    background-color: $bg-accent;
  }
}

.icon {
  width: 32px;
  height: 32px;
  margin-right: 25px;
}
</style>
