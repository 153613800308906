import { computed } from "vue";

const defaultTypes: Record<string, string> = {
  apartment: "Woningen",
  parking: "Parking",
  other: "Andere",
};

export const useLotTypeOptions = (types: string[]) => {
  return computed(() => {
    const allTypes: Record<string, string> = {};
    Object.values(types).forEach((type) => (allTypes[type] = type));
    Object.keys(defaultTypes).forEach(
      (key) => (allTypes[key] = defaultTypes[key])
    );
    return allTypes;
  });
};

export const useTranslateType = () => {
  return (type: string) => {
    const translation = defaultTypes[type];
    if (translation == null) return type;
    return translation;
  };
};
