import { CreateItemParams, Item, PatchItemParams } from "@/types";
import { ApiResponse, del, get, patch, post } from "./repository";

export default {
  listItems(propertyId: number | string): ApiResponse<{ items: Item[] }> {
    return get(`/properties/${propertyId}/items`);
  },
  uploadItem(
    params: CreateItemParams,
    file: File
  ): ApiResponse<{ item: Item }> {
    const formData = new FormData();
    Object.entries(params).forEach(
      ([key, value]) => value !== undefined && formData.append(key, value)
    );
    formData.append("file", file);
    return post("/items", formData);
  },
  createItem(params: CreateItemParams): ApiResponse<{ item: Item }> {
    return post("/items/dir", params);
  },
  patchItem(
    itemId: number | string,
    changes: PatchItemParams
  ): ApiResponse<{ item: Item }> {
    return patch(`/items/${itemId}`, {
      changes,
    });
  },
  deleteItem(itemId: number | string): ApiResponse<{ item: Item }> {
    return del(`/items/${itemId}`);
  },
  getUnvisitedItemIds(
    propertyId: number | string
  ): ApiResponse<{ unvisited_ids: number[] }> {
    return get(`/properties/${propertyId}/unvisited_items`);
  },
  markItemsVisited(itemIds: number[] | string[]): ApiResponse<void> {
    return post(`/item_visits`, { item_ids: itemIds });
  },
};
