<template>
  <!-- <page-loader v-if="properties == null" />
  <container thinner v-else> -->
  <!-- <div class="properties"> -->
  <!-- <generic-header hide-back-button>
        <template v-if="!hasBack">
          {{ $t("property.my_residences") }}
        </template>
        <template v-else>
          {{ $t("property.select_a_residence") }}
        </template>
        <template v-slot:right>
          <invitation-dropdown @properties-changed="updateProperties" />
        </template>
      </generic-header> -->
  <generic-page :title="$t('property.select_a_residence')">
    <div class="listings">
      <property-listing
        v-for="property in properties"
        :key="property.id"
        :title="property.name"
        :sub-title="`${property.street} ${property.number}`"
        :bottom-text="'Residentie'"
        @select="toProperty(property.id)"
      />
      <!-- <property-listing /> -->
    </div>
    <bottom-middle-link
      class="add-residence"
      :to="{ name: 'properties.create' }"
    >
      {{ $t("property.create_button") }}
    </bottom-middle-link>
  </generic-page>
  <!-- </div>
  </container> -->
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import PropertyListing from "@/components/property/PropertyListing.vue";
import BottomMiddleLink from "@/components/BottomMiddleLink.vue";
import { useStore } from "vuex";
import { inProperties, PropertiesActions } from "@/store/properties";
import { useRoute, useRouter } from "vue-router";
import { Property } from "@/repositories/propertyRepository";

export default defineComponent({
  components: { PropertyListing, BottomMiddleLink },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const properties = ref(null as any[] | null);

    const toProperty = (propertyId: number) => {
      if (route.query.back != null) {
        router.push(route.query.back as string);
        return;
      }

      router.push({
        name: "properties.show",
        params: {
          id: propertyId,
        },
      });
    };

    const updateProperties = () => {
      store
        .dispatch(inProperties(PropertiesActions.LIST))
        .then((props: Property[]) => {
          if (props.length === 1) {
            const property = props[0];
            toProperty(property.id);
          }
          properties.value = props;
        });
    };
    updateProperties();

    return {
      hasBack: computed(() => route.query?.back != null),
      properties,
      toProperty,
      updateProperties,
    };
  },
});
</script>

<style lang="scss" scoped>
.add-residence {
  margin-top: 70px;
}
</style>
