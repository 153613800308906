<template>
  <div class="list-payments">
    <page-loader v-if="loading" />
    <!-- <container thinner width="90%" v-else> -->
    <div v-else>
      <h2 class="title title--first">Overzicht</h2>
      <div class="overview">
        <!-- <div class="cell">
          <div class="cell-label">Datum volgende betaling</div>
          <div class="cell-value">22 Mar 2022</div>
        </div> -->
        <!-- Misschien totaal aantal duizendsten? -->
        <!-- <div class="cell">
          <div class="cell-label">Schuld volgende betaling</div>
          <div class="cell-value">€2022,00</div>
        </div> -->

        <!-- TODO: Need more info hre -->
        <overview
          :items="[
            {
              label: 'Datum volgende betaling',
              value: formattedNextPaymentDate,
            },
            {
              label: 'Openstaande schuld',
              value: totalDebt,
            },
          ]"
        />
        <!-- <div class="cell">
          <div class="cell-label">Openstaande schuld</div>
          <div class="cell-value">€2022,00</div>
        </div> -->
        <!-- 
          - Volgende betaling
          - Totaal openstaande schuld
          - Totale schuld bij volgende betaling
          - 
         -->
        <!-- <div class="cell">
          <div class="cell-label">Volgende betaling</div>
        </div> -->
      </div>
      <!-- 
        TODO: Show when next payment will be
       -->
      <h2 class="title">Mede-eigenaars</h2>
      <div class="residents">
        <resident-item
          v-for="resident in residents"
          :key="resident.id"
          :resident="resident"
          :property="property"
          :debt="debtForUser(resident.id)"
        />
      </div>
    </div>
    <!-- </container> -->
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useAllPaymentRequests, usePayments } from "@/store/accounting/hooks";
import { useResidents } from "@/store/properties/hooks";
import ResidentItem from "../../components/payments/ResidentItem.vue";
import { useActiveProperty } from "@/util/properties";
import {
  dateNextPayment,
  getDebtForProperty,
  getDebtForUser,
} from "@/store/accounting/util";
import { DateTime } from "luxon";
import { formatAsEuro } from "@/util/price";

export default defineComponent({
  components: { ResidentItem },
  setup() {
    const { payments, loading: paymentsLoading } = usePayments();
    const { residents, loading: residentsLoading } = useResidents();
    const activeProperty = useActiveProperty();
    const { paymentRequests, loading: paymentRequestsLoading } =
      useAllPaymentRequests();

    const debtForUser = computed(() => (userId: number) => {
      return getDebtForUser(userId, payments.value, paymentRequests.value);
    });

    const totalDebt = computed(() =>
      formatAsEuro(getDebtForProperty(payments.value, paymentRequests.value))
    );

    const formattedNextPaymentDate = computed(() => {
      if (activeProperty.value == null) return "";
      const date = dateNextPayment(activeProperty.value);
      return DateTime.fromJSDate(date).toFormat("dd LLLL yyyy");
      // activeProperty.value && dateNextPayment(activeProperty.value)
    });

    return {
      property: activeProperty,
      payments,
      residents,
      debtForUser,
      totalDebt,
      loading: computed(
        () =>
          paymentsLoading.value ||
          residentsLoading.value ||
          paymentRequestsLoading.value
      ),
      formattedNextPaymentDate,
    };
  },
});
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 10px;

  &--first {
    margin-top: 20px;
  }
}

.overview {
  margin-bottom: 30px;
}

.cell {
  margin-bottom: 10px;
}

.cell-label {
  font-size: 0.75rem;
  text-transform: uppercase;
  color: $text-gray;
  font-weight: bold;
  margin-bottom: 3px;
  letter-spacing: 0.03em;
}

.cell-value {
  font-size: 1rem;
  /* font-weight: 600; */
  letter-spacing: 0.02em;
}
</style>
