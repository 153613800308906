
import { defineComponent, PropType } from "vue";
import { GenericPageActions } from "../GenericPageActions.vue";

export default defineComponent({
  props: {
    actions: {
      type: Object as PropType<GenericPageActions>,
      required: true,
    },
  },
});
