
import { defineComponent } from "vue";
import IssueIcon from "@/components/icons/Issue.vue";
import ImagesIcon from "@/components/icons/Images.vue";
import BookIcon from "@/components/icons/Book.vue";

export default defineComponent({
  components: { BookIcon, IssueIcon, ImagesIcon },
  setup(props, { emit }) {
    return {
      addDocument() {
        emit("hide");
        emit("document");
      },
      addIssue() {
        emit("hide");
        emit("issue");
      },
      addImage() {
        emit("hide");
        emit("image");
      },
    };
  },
});
