import { useItems } from "@/store/documents/hooks";
import { computed, ref } from "vue";
import { itemsSort } from "../util/sortdocs";

export const useItemTree = () => {
  const { value: items, loading } = useItems();
  const currentDirectoryId = ref<null | number>(null);

  const currentItems = computed(() => {
    return [...items.value]
      .filter((item) => {
        if (item.parent_id == null && currentDirectoryId.value == null)
          return true;
        return item.parent_id == currentDirectoryId.value;
      })
      .sort(itemsSort);
  });

  return {
    items,
    currentItems,
    loading,
    currentDirectoryId,
    openDir(id: number) {
      currentDirectoryId.value = id;
    },
    getItem(id: number) {
      return items.value.find((item) => id === item.id);
    },
  };
};
