<template>
  <generic-page-popup :loading="issues == null" :title="$t('issues.solved')">
    <div class="issues">
      <issue-listing
        v-for="issue in issuesForCurrentProperty"
        :key="issue.id"
        :issue="issue"
        @click="toIssue(issue)"
      />
    </div>
  </generic-page-popup>
</template>

<script lang="ts">
import { Issue } from "@/repositories/issueRepository";
import { inIssues, issueActions } from "@/store/issues";
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import IssueListing from "@/components/property/IssueListing.vue";
import { useActiveProperty } from "@/util/properties";
import { showPopup } from "@/components/popups/popups";
import { showViewIssuePopup } from "./ViewIssue.vue";

const component = defineComponent({
  components: { IssueListing },
  setup() {
    const store = useStore();
    const issues = ref(null as null | Issue[]);
    const activeProperty = useActiveProperty();

    store.dispatch(inIssues(issueActions.LIST_ALL)).then((values) => {
      issues.value = values;
    });

    const issuesForCurrentProperty = computed(() => {
      if (issues.value == null) return [];
      const p = activeProperty.value;
      if (p == null) return issues.value;
      return issues.value.filter(
        (issue) => issue.status == "resolved" && issue.property_id === p.id
      );
    });

    return {
      issues,
      issuesForCurrentProperty,
      toIssue(issue: Issue) {
        showViewIssuePopup({
          issueId: issue.id,
        });
      },
    };
  },
});

export const showResolvedIssuesPopup = () => {
  showPopup(component, {});
};

export default component;
</script>

<style lang="scss" scoped>
.issues {
  padding: 0 10px;

  @include screen-from("tablet") {
    padding: 0;
    margin-top: 40px;
  }
}
</style>
