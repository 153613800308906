
import { defineComponent } from "vue";
import GeneratedForm, {
  FieldsSettings,
} from "@/components/form/GeneratedForm.vue";
import { required } from "@vuelidate/validators";
import { useStore } from "vuex";
import { inProperties, PropertiesActions } from "@/store/properties";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { chatsActions, inChats } from "@/store/chats";

export default defineComponent({
  components: { GeneratedForm },
  setup() {
    const { t } = useI18n();

    const fieldSettings: FieldsSettings = [
      {
        name: "name",
        type: "string",
        label: t("property.name_residence"),
        meta: { placeholder: "Kerkstraat 1" },
        rules: { required },
      },
      {
        name: "is_vme",
        type: "boolean",
        label: t("property.association"),
      },
      { name: "location_header", type: "hr", label: "Location" },
      [
        {
          name: "street",
          type: "string",
          label: t("common.street"),
          meta: { placeholder: t("common.street_example") },
          rules: { required },
        },
        {
          name: "number",
          type: "string",
          label: t("common.street_nr"),
          meta: { placeholder: "31" },
          rules: { required },
          smaller: true,
        },
      ],
      [
        {
          name: "zipcode",
          type: "string",
          label: t("common.zipcode"),
          meta: { placeholder: "1000" },
          smaller: true,
        },
        {
          name: "city",
          type: "string",
          label: t("common.city"),
          meta: { placeholder: t("common.city_example") },
        },
      ],
      {
        name: "floors",
        type: "string",
        label: t("property.floor_count"),
        meta: { placeholder: "6" },
      },

      { name: "details_header", type: "hr", label: t("common.details") },
      { name: "has_basement", type: "boolean", label: t("common.basement") },
      { name: "has_parking", type: "boolean", label: t("common.parking") },
      // {
      //   name: "has_financial_data",
      //   type: "boolean",
      //   label: t("property.allow_financial"),
      // },
    ];

    const store = useStore();
    const router = useRouter();

    console.log("on");
    return {
      fieldSettings,
      async createProperty(formData: Record<string, any>) {
        const property = await store.dispatch(
          inProperties(PropertiesActions.CREATE),
          formData
        );
        await store.dispatch(inChats(chatsActions.RE_FETCH));
        router.push({
          name: "properties.show",
          params: {
            id: property.id,
          },
        });
      },
    };
  },
});
