<template>
  <page-loader v-if="!verified" />
  <div class="reset-password" v-else>
    <flash-message error v-if="error != null">
      {{ error }}
    </flash-message>
    <app-form
      :submitText="$t('auth.change_password')"
      submitAlwaysFull
      :submitHandler="handleSubmit"
    >
      <form-field>
        <textbox
          type="password"
          :label="$t('auth.new_password')"
          :placeholder="$t('auth.new_password')"
          v-model="newPassword"
        />
        <FieldError name="password" :fieldErrors="fieldErrors" />
      </form-field>
      <form-field>
        <textbox
          v-model="repeatNewPassword"
          type="password"
          :label="$t('auth.re_password')"
          :placeholder="$t('auth.re_password')"
        />
      </form-field>
    </app-form>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watchEffect } from "vue";
import AppForm from "@/components/form/AppForm.vue";
import FormField from "@/components/form/FormField.vue";
import { useStore } from "vuex";
import { authActions, inAuth } from "@/store/auth";
import { useRoute, useRouter } from "vue-router";
import FieldError from "@/components/form/FieldError.vue";

export default defineComponent({
  components: { AppForm, FormField, FieldError },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const verified = ref(false as boolean);
    const newPassword = ref("");
    const repeatNewPassword = ref("");
    const error = ref(null as null | string);
    const fieldErrors = ref({} as Record<string, string[]>);

    const token = computed(() => route.params.token);
    watchEffect(() => {
      store
        .dispatch(inAuth(authActions.GET_PASS_RESET), {
          token: token.value,
        })
        .then((isValid) => {
          if (isValid) {
            verified.value = true;
          } else {
            console.log(
              "ResetPassword: token invalid, routing to login with message 'bad-token'"
            );
            router.push({
              name: "login",
              query: {
                message: "bad-token",
              },
            });
          }
        });
    });

    return {
      verified,
      newPassword,
      repeatNewPassword,
      error,
      fieldErrors,
      async handleSubmit() {
        if (newPassword.value !== repeatNewPassword.value) {
          error.value = "Wachtwoorden komen niet overeen";
          return;
        }
        try {
          await store.dispatch(inAuth(authActions.DELETE_PASS_RESET), {
            token: token.value,
            newPassword: newPassword.value,
          });
          router.push({
            name: "login",
          });
        } catch (e) {
          const err = e as any;
          if (err.errors != null) {
            fieldErrors.value = err.errors;
          } else {
            throw e;
          }
        }
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.reset-password {
  max-width: 315px;
  margin-left: auto;
  margin-right: auto;
}
</style>
