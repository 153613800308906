<template>
  <div class="item-preview">
    <folder-icon v-if="item.is_folder" width="70%" height="70%" />
    <div
      class="image"
      v-if="isImage"
      :style="{ backgroundImage: `url('${item.file.url}')` }"
    ></div>
    <div class="file-type" v-if="!item.is_folder && !isImage">
      {{ extension }}
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import FolderIcon from "@/components/icons/Folder.vue";
import { Item } from "@/types";
import { isImageFile } from "@/store/documents/util";

export default defineComponent({
  components: { FolderIcon },
  props: {
    item: {
      type: Object as PropType<Item>,
      required: true,
    },
  },
  setup(props) {
    const extension = computed(() => {
      const itemName = props.item.name;
      const extension = itemName.match(/\.[^.]+/i);
      if (extension == null) return null;
      return extension[0].split(".").join("").toUpperCase();
    });

    return {
      extension,
      isImage: computed(() => props.item?.file && isImageFile(props.item.file)),
    };
  },
});
</script>

<style lang="scss" scoped>
.item-preview {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  border-radius: 5px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.file-type {
  font-size: 13px;
  font-weight: 500;
}
</style>
