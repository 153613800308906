
import { useOnClickOutside } from "@/util/click";
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isVisible = ref(false);

    return {
      wrapperEl: useOnClickOutside(() => {
        isVisible.value = false;
      }),
      isVisible,
      select(key: string) {
        emit("select", key);
        emit("select:" + key);
        isVisible.value = false;
      },
    };
  },
});
