<template>
  <div class="forgot-password">
    <flash-message v-if="sent || notFound">
      {{ $t("auth.reset_email_sent") }}
    </flash-message>
    <!-- <flash-message error v-if="notFound">
      {{ $t("auth.unknown_email") }}
    </flash-message> -->
    <app-form
      :submitText="$t('auth.reset_password')"
      submitAlwaysFull
      :submitHandler="handleSubmit"
    >
      <form-field>
        <textbox
          type="email"
          placeholder="my@email.com"
          :label="$t('common.email_address')"
          v-model="email"
        />
        <form-error v-if="fieldMandatoryError">{{
          $t("error.field_invalid")
        }}</form-error>
      </form-field>
    </app-form>
    <div class="login" @click="toLogin">
      {{ $t("auth.didnt_forget_password?") }}
      <app-link to="/auth/login">{{ $t("auth.login") }}</app-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import AppForm from "@/components/form/AppForm.vue";
import FormField from "@/components/form/FormField.vue";
import { useRouter } from "vue-router";
import FormError from "@/components/form/FormError.vue";
import { useStore } from "vuex";
import { authActions, inAuth } from "@/store/auth";
import FlashMessage from "@/components/common/FlashMessage.vue";

export default defineComponent({
  components: { AppForm, FormField, FormError, FlashMessage },
  setup() {
    const router = useRouter();
    const store = useStore();
    const email = ref("");
    const fieldMandatoryError = ref(false);
    const sent = ref(false);
    const notFound = ref(false);

    return {
      fieldMandatoryError,
      email,
      sent,
      notFound,
      async handleSubmit() {
        sent.value = false;
        notFound.value = false;
        fieldMandatoryError.value = false;

        const emailValue = email.value.trim().toLowerCase();

        if (!/^\S+@\S+$/.test(emailValue)) {
          fieldMandatoryError.value = true;
          return;
        }

        const result = await store.dispatch(
          inAuth(authActions.CREATE_PASS_RESET),
          {
            email: emailValue,
          }
        );

        if (result) {
          sent.value = true;
        } else {
          notFound.value = true;
        }
      },
      toLogin() {
        router.push("/auth/login");
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.forgot-password {
  @include screen-from("tablet-small") {
    max-width: 315px;
    margin-left: auto;
    margin-right: auto;
  }
}

.login {
  color: $text-dark;
  font-weight: 500;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 40px;
  text-align: center;
}
</style>
