<template>
  <page-loader v-if="issues == null" />
  <container class="resolved-issues" v-else>
    <header class="header">
      <generic-header>{{ $t("issues.solved") }}</generic-header>
    </header>

    <div class="issues">
      <issue-listing
        v-for="issue in issuesForCurrentProperty"
        :key="issue.id"
        :issue="issue"
        @click="toIssue(issue)"
      />
    </div>
  </container>
</template>

<script lang="ts">
import { Issue } from "@/repositories/issueRepository";
import { inIssues, issueActions } from "@/store/issues";
import { computed, defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import IssueListing from "@/components/property/IssueListing.vue";
import { useActiveProperty } from "@/util/properties";

export default defineComponent({
  components: { IssueListing },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const issues = ref(null as null | Issue[]);
    const activeProperty = useActiveProperty();

    store.dispatch(inIssues(issueActions.LIST_ALL)).then((values) => {
      issues.value = values;
    });

    const issuesForCurrentProperty = computed(() => {
      if (issues.value == null) return [];
      const p = activeProperty.value;
      if (p == null) return issues.value;
      return issues.value.filter(
        (issue) => issue.status == "resolved" && issue.property_id === p.id
      );
    });

    return {
      issues,
      issuesForCurrentProperty,
      toIssue(issue: Issue) {
        router.push({
          name: "properties.issues.show",
          params: {
            propertyId: route.params.id,
            issueId: issue.id,
          },
        });
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.issues {
  padding: 0 10px;

  @include screen-from("tablet") {
    padding: 0;
    margin-top: 40px;
  }
}
</style>
