<template>
  <div class="profile-picture-upload" @click="handleClick">
    <div class="picture-wrapper">
      <div
        class="picture"
        :style="`background-image: url('${currentPicture || modelValue}')`"
        v-if="modelValue != null || currentPicture != null"
      ></div>
      <div class="default" v-else>
        <empty-profile-icon />
      </div>
    </div>
    <input
      @change="imageSelected"
      ref="fileEl"
      type="file"
      class="file"
      hidden
    />
    <button type="button" class="upload-button">{{ label }}</button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import EmptyProfileIcon from "@/components/icons/EmptyProfile.vue";

export default defineComponent({
  components: { EmptyProfileIcon },
  props: {
    label: {
      type: String,
      default: "",
    },
    uploadFunction: {
      type: Function,
      default: null,
    },
    modelValue: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const fileEl = ref(null as null | HTMLInputElement);
    const currentPicture = ref(null as null | string);

    return {
      fileEl,
      currentPicture,
      handleClick() {
        if (fileEl.value) {
          fileEl.value.click();
        }
      },
      async imageSelected() {
        const files = fileEl.value?.files;
        if (files == null || files.length === 0) return;
        if (props.uploadFunction != null) {
          const result = await props.uploadFunction(files[0]);
          currentPicture.value = result;
          emit("update:modelValue", result);
          console.log(currentPicture.value, props.modelValue);
        }
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.profile-picture-upload {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  max-width: 400px;
}

.picture-wrapper {
  position: relative;
  // height: 100px;
  // max-height: 100px;
  // padding-top: 100%;
  height: 90px;
  width: 90px;
  min-width: 90px;
  margin-right: 30px;
  // background-color: $bg-mobile-menu;
  background-color: $bg-accent;
  border-radius: 50%;
}

.picture {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.upload-button {
  width: 100%;
  // height:
  background-color: white;
  border: 1px solid #e1d8b2;
  color: $text-alt;
  font-size: 1.125rem;
  font-weight: 600;
  font-family: $font-text;
  border-radius: 5px;
  padding: 18px 0px;
  box-sizing: border-box;
}

.default {
  width: 50%;
  height: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  // color: $bg-accent;
  color: $bg-mobile-menu;
}
</style>
