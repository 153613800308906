<template>
  <div class="popup" v-if="show">
    <div class="bg" @click="$emit('hide')"></div>
    <div class="content" :style="{ 'max-width': maxWidth }">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["hide"],
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: String,
      default: "auto",
    },
  },
});
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #1e1803;
  opacity: 0.9;
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
  top: 13vh;
  max-height: 78vh;
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  background-color: white;
  box-sizing: border-box;
  border-radius: 10px;
}
</style>
