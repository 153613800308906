<template>
  <generic-page-popup
    :loading="loading"
    :title="issue?.title ?? ''"
    :options="options"
  >
    <div class="property-issue">
      <!-- <header class="header">
        <div class="street">{{ issue.property.name }}</div>
        <div class="time">
          {{ timeago(issue.inserted_at, " geleden") }}
        </div>
      </header> -->
      <div class="tags tags--mobile">
        <div class="tag" v-for="tag in issueTags(issue)" :key="tag">
          {{ tag }}
        </div>
      </div>
      <!-- <h2 class="title">{{ issue.title }}</h2> -->
      <!-- <div class="author">
        {{ issue.author.first_name }} {{ issue.author.last_name }}
      </div> -->
      <div class="tags tags--web">
        <div class="tag" v-for="tag in issueTags(issue)" :key="tag">
          {{ tag }}
        </div>
      </div>
      <p class="text">
        {{ issue.message }}
        <span
          class="no-message"
          v-if="issue.message == null || issue.message === ''"
        >
          Deze melding heeft geen extra informatie
        </span>
      </p>
      <div class="images">
        <div
          class="image"
          v-for="p in issue.pictures"
          :key="p.id"
          :style="{ 'background-image': `url('${p.url}')` }"
          @click="showLightbox"
        ></div>
      </div>
      <div class="button-wrapper">
        <app-button
          class="resolved-button"
          thinner
          success
          v-if="isAdmin && (issue.open || issue.in_progress)"
          @click="() => mark('resolved') && close(true)"
          :loading="marking"
        >
          <template v-slot:icon>
            <check-icon />
          </template>
          {{ $t("issues.mark_solved") }}
        </app-button>
        <app-button
          class="resolved-button"
          thinner
          v-if="isAdmin && issue.resolved"
          @click="() => mark('open')"
          :loading="marking"
        >
          <template v-slot:icon>
            <check-icon />
          </template>
          {{ $t("issues.mark_open") }}
        </app-button>
        <app-button
          class="resolved-button"
          thinner
          dark
          v-if="isAdmin && issue.open"
          @click="() => mark('in_progress')"
          :loading="marking"
        >
          <template v-slot:icon>
            <check-icon />
          </template>
          {{ $t("issues.mark_in_progress") }}
        </app-button>
        <app-button
          class="resolved-button"
          thinner
          v-if="isAdmin && issue.in_progress"
          @click="() => mark('open')"
          :loading="marking"
        >
          <template v-slot:icon>
            <check-icon />
          </template>
          {{ $t("issues.mark_not_in_progres") }}
        </app-button>
      </div>
      <!-- <lightbox
        :images="issue.pictures"
        v-if="state.lightboxEnabled"
        @hide="hideLightbox()"
      /> -->
    </div>
  </generic-page-popup>
</template>

<script lang="ts">
import { showPopup } from "@/components/popups/popups";
import store from "@/store";
import { inIssues, issueActions } from "@/store/issues";
import { useIssue } from "@/store/issues/hooks";
import { isCurrentPropertyAdmin } from "@/util/auth";
import { issueTags } from "@/util/issues";
import { timeago } from "@/util/timeago";
import { computed, defineComponent, PropType, ref } from "vue";
import CheckIcon from "@/components/icons/Check.vue";
import { GenericPageOption } from "@/components/common/generic-page/GenericPageOptions.vue";
import { showEditIssuePopup } from "./EditIssue.vue";
import { showLightboxPopup } from "@/components/util/LightBox.vue";
import { useQueryClient } from "vue-query";

interface ViewIssueParams {
  issueId: number;
}

const component = defineComponent({
  components: { CheckIcon },
  props: {
    params: {
      type: Object as PropType<ViewIssueParams>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const issueId = computed(() => props.params.issueId);
    const queryClient = useQueryClient();
    const { data: issue, isLoading: loadingIssue } = useIssue(issueId);
    const isAdmin = isCurrentPropertyAdmin();
    const marking = ref(false as boolean);

    const options: GenericPageOption[] = [
      {
        id: "edit",
        iconName: "edit",
        text: "Aanpassen",
        async onClick() {
          if (!issue.value) return;
          await showEditIssuePopup(issue.value.id);
          queryClient.invalidateQueries(["properties", "issues"]);
        },
      },
    ];

    return {
      options,
      issue,
      loading: loadingIssue,
      isAdmin,
      issueTags,
      timeago,
      marking,
      close(hasChanges: boolean) {
        emit("finish", hasChanges);
      },
      async mark(status: "open" | "resolved" | "in_progress") {
        if (issue.value == null) return;
        if (!["open", "resolved", "in_progress"].includes(status)) {
          throw new Error(`Invalid status ${status}`);
        }
        marking.value = true;
        await store.dispatch(inIssues(issueActions.UPDATE), {
          id: issue.value.id,
          updates: { status: status },
        });
        queryClient.invalidateQueries(["properties", "issues"]);
        marking.value = false;
      },
      showLightbox() {
        const pictures = issue.value?.pictures;
        if (pictures == null) return;
        showLightboxPopup(pictures.map((p) => p.url));
      },
    };
  },
});

export default component;

export const showViewIssuePopup = (
  params: ViewIssueParams
): Promise<boolean | null> => {
  return showPopup(component, params);
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-size: 0.9375rem;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
  font-weight: 600;
  font-family: $font-text;

  @include screen-from("tablet") {
    margin-bottom: 25px;
  }
}

.tags {
  display: flex;
  flex-flow: row wrap;
  margin-top: 12px;

  &--web {
    display: none;
    margin-top: 5px;
  }

  @include screen-from("tablet") {
    &--web {
      display: flex;
    }

    &--mobile {
      display: none;
    }
  }
}

.tag {
  color: white;
  font-size: 13px;
  text-transform: uppercase;
  font-family: $font-text;
  padding: 9px 11px;
  border-radius: 3px;
  box-shadow: $shadow-general;
  margin-right: 8px;
  margin-top: 8px;
  background-color: $text-alt;
  font-weight: 600;
  letter-spacing: 0.02rem;

  &--red {
    background-color: $bg-error;
  }
}

.title {
  margin-top: 14px;
  font-family: $font-title;
  color: $text-dark;
  letter-spacing: -0.01rem;
  font-size: 1.5rem;
  line-height: 130%;
  font-weight: 600;

  @include screen-from("tablet") {
    font-size: 2rem;
    max-width: 750px;
  }
}

.time {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.text {
  margin-top: 17px;
  font-size: 0.875rem;
  line-height: 140%;
  font-family: $font-text;
  letter-spacing: 0.02rem;

  @include screen-from("tablet") {
    font-size: 1rem;
    margin-top: 20px;
    margin-bottom: 25px;
    max-width: 750px;
  }
}

.images {
  display: flex;
  flex-flow: row wrap;
  margin-top: 12px;
}

.image {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  background-color: white;
  margin-top: 8px;
  margin-right: 8px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;

  @include screen-from("tablet") {
    width: 160px;
    height: 160px;
    margin-bottom: 15px;
  }
}

.button-wrapper {
  margin-top: 50px;
}

.resolved-button {
  width: 100%;

  @include screen-from("tablet") {
    width: auto;
  }

  &:last-child {
    margin-top: 10px;
  }
}

.author {
  font-family: $font-text;
  font-size: 0.875rem;
  color: $text-alt;
  opacity: 0.6;
  margin-top: -2px;
}

.settings {
  cursor: pointer;

  &--desktop {
    margin-left: 20px;
  }
}

.no-message {
  opacity: 0.8;
  text-align: center;
  line-height: 1.3;
  display: block;
}
</style>
