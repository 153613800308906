
import { showConfirmPopup } from "@/components/popups/Confirm.vue";
import { showPopup } from "@/components/popups/popups";
import { inProperties, PropertiesActions } from "@/store/properties";
import { useLots } from "@/store/properties/hooks";
import { PropertyLot, User } from "@/types";
import { isCurrentPropertyAdmin, useCurrentUser } from "@/util/auth";
import { useActiveProperty } from "@/util/properties";
import { computed, defineComponent, PropType, ref } from "vue";
import { useStore } from "vuex";
import LotItem from "@/features/lots/components/LotItem.vue";
import { showEditLotPopup } from "@/features/lots/popups/EditLotPopup.vue";

const component = defineComponent({
  components: { LotItem },
  props: {
    params: {
      type: Object as PropType<{
        resident: User;
      }>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const deleting = ref(false);
    const activeProperty = useActiveProperty();
    const isAdmin = isCurrentPropertyAdmin();
    const currentUser = useCurrentUser();
    const isCurrentUser = computed(
      () => props.params.resident.id === currentUser.value?.id
    );
    const { loading: loadingLots, lots } = useLots();
    const userLots = computed(() =>
      lots.value.filter(
        (lot) => lot.main_responsible_id === props.params.resident.id
      )
    );

    return {
      isCurrentUser,
      deleting,
      isAdmin,
      loadingLots,
      lots: userLots,
      showLot(lot: PropertyLot) {
        let types = computed(() =>
          (lots.value ?? []).map((lot) => lot.type).filter((v) => v != null)
        );
        showEditLotPopup(lot, types.value);
      },
      async makeAdmin() {
        if (activeProperty.value == null || props.params == null) return;
        deleting.value = true;
        try {
          const result = await showConfirmPopup({
            title: "Bent u zeker?",
            subTitle:
              "Er kan maar 1 beheerder zijn per residentie, u zal deze rol dus verliezen.",
            confirmText: "Rol overdragen",
            declineText: "Annuleren",
          });
          if (result !== true) return;
          await store.dispatch(inProperties(PropertiesActions.PATCH), {
            id: activeProperty.value.id,
            updates: { admin_id: props.params.resident.id },
          });
        } finally {
          deleting.value = false;
        }
      },
      async deleteUser() {
        if (activeProperty.value == null || props.params == null) return;
        deleting.value = true;
        try {
          const result = await showConfirmPopup({
            title: "Bent u zeker?",
            confirmText: "Verwijder gebruiker",
            declineText: "Behoud gebruiker",
            subTitle:
              "De gebruiker zal niet langer toegang hebben tot deze residentie",
          });
          if (result !== true) return;
          await store.dispatch(
            inProperties(PropertiesActions.REMOVE_FROM_PROPERTY),
            {
              userId: props.params?.resident.id,
              propertyId: activeProperty.value?.id,
            }
          );
          emit("finish", "deleted");
        } finally {
          deleting.value = false;
        }
      },
    };
  },
});

export const showViewResidentPopup = (
  resident: User
): Promise<undefined | "deleted"> => {
  return showPopup(component, { resident }) as any;
};

export default component;
