<template>
  <div class="popup-view">
    <template v-for="popupId in popupIds" :key="popupId">
      <component
        :is="metaById[popupId].component"
        :params="metaById[popupId].params"
        @finish="finishPopup(metaById[popupId], $event)"
        :active="activeId === popupId"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watchEffect } from "vue";
import { onNewPopup, PopupMeta } from "./popups";

export default defineComponent({
  setup() {
    const metaById: Record<number, PopupMeta> = {};
    const popupIds = ref([] as number[]);
    onNewPopup((popupMeta: PopupMeta) => {
      // TODO: this does not get cleaned up and could turn into a memory leak
      metaById[popupMeta.id] = popupMeta;

      // Hide transient popups
      popupIds.value = popupIds.value.filter((id) => {
        const meta = metaById[id];
        if (meta != null && meta.transient) {
          console.log("Filtered out", meta);
          return false;
        }
        return true;
      });

      popupIds.value.push(popupMeta.id);
    });

    // Disable body scroll when popups are open
    watchEffect(() => {
      if (popupIds.value.length > 0) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "visible";
      }
    });

    return {
      popupIds,
      activeId: computed(() => popupIds.value[popupIds.value.length - 1]),
      metaById,
      finishPopup(popup: PopupMeta, data: any) {
        popupIds.value = popupIds.value.filter((id) => id !== popup.id);
        if (popup.finishedCallback != null) {
          popup.finishedCallback(data);
        }
      },
    };
  },
});
</script>
