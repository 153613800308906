
import { defineComponent, PropType, ref } from "vue";
import { useOnClickOutside } from "@/util/click";

export enum CardTagType {
  ERROR = "error",
  INFO = "info",
  SUCCESS = "success",
}

export interface CardTag {
  text: string;
  type: CardTagType;
}

export const errorTag = (text: string) => ({ text, type: CardTagType.ERROR });
export const infoTag = (text: string) => ({ text, type: CardTagType.INFO });
export const successTag = (text: string) => ({
  text,
  type: CardTagType.SUCCESS,
});

export default defineComponent({
  props: {
    tags: {
      type: Array as PropType<CardTag[]>,
      default: () => [],
    },
    tagsPosition: {
      type: String as PropType<"left" | "right">,
      default: "left",
    },
    tooltip: {
      type: String,
      default: null,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const showSlideOut = ref(false);
    const clickOutsideRef = useOnClickOutside(
      () => (showSlideOut.value = false)
    );

    return {
      clickOutsideRef,
      showSlideOut,
      handleClick() {
        showSlideOut.value = !showSlideOut.value;
      },
    };
  },
});
