<template>
  <label :for="forId"><slot /></label>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    forId: {
      type: String,
      default: null,
    },
  },
});
</script>

<style lang="scss" scoped>
label {
  color: $text-dark;
  font-size: 0.875rem;
  line-height: 140%;
  padding-bottom: 4px;
  letter-spacing: 0.02rem;
  display: block;
}
</style>
