<template>
  <div class="wrapper" @click="handleClick">
    <card>
      <div class="resident-item">
        <div class="label error" v-if="debt > 0">SCHULD</div>
        <div
          class="avatar"
          :style="{
            backgroundImage: `url('${imageUrl}')`,
            backgroundSize: resident.avatar_url ? 'cover' : '60%',
          }"
        ></div>
        <div class="info">
          <h3 class="name">
            {{ fullName }}
          </h3>
          <p class="shares">{{ shares }}/{{ property.total_shares }}</p>
        </div>
        <div class="angle-wrapper">
          <angle-down-icon width="22px" height="22px" />
        </div>
      </div>
    </card>
  </div>
</template>

<script lang="ts">
import { User } from "@/types";
import { computed, defineComponent, PropType } from "vue";
import { fullName } from "@/store/users/util";
import { sharesForResident } from "@/util/properties";
import { Property } from "@/repositories/propertyRepository";
import { showPopup } from "@/components/popups/popups";
import PaymentsPopup from "../popups/payments_popup/PaymentsPopup.vue";
import AngleDownIcon from "@/components/icons/AngleDown.vue";

export default defineComponent({
  components: { AngleDownIcon },
  props: {
    resident: {
      type: Object as PropType<User>,
      required: true,
    },
    property: {
      type: Object as PropType<Property>,
      required: true,
    },
    debt: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    return {
      imageUrl: computed(
        () => props.resident?.avatar_url ?? "/img/empty_profile.svg"
      ),
      fullName: computed(() => fullName(props.resident ?? "")),
      shares: computed(() =>
        sharesForResident(props.property, props.resident.id)
      ),
      handleClick() {
        showPopup(PaymentsPopup, {
          user: props.resident,
          property: props.property,
        });
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.items {
  padding: 20px;
  box-sizing: border-box;
}

.item {
  box-sizing: border-box;
  padding: 10px;
}

.wrapper {
  margin-bottom: 10px;
}

.resident-item {
  display: grid;
  grid-template-columns: 40px auto 30px;
  column-gap: 15px;
  padding: 15px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

.label {
  position: absolute;
  top: -11.5px;
  right: 20px;
  height: 20px;
  padding: 0 10px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: white;
  border: 1px solid $border-brown;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
  text-transform: 0.03em;
  font-size: 0.875rem;
  line-height: 100%;
  padding-bottom: 1px;

  &.error {
    background-color: $text-error;
    color: white;
    border: 1px solid $bg-error;
  }
}

.avatar {
  background-position: center;
  background-repeat: no-repeat;
  background-color: $bg-accent;
  border-radius: 50%;
  width: 100%;
  padding-top: 100%;
}

.info {
  display: flex;
  flex-flow: column nowrap;
}

.name {
  font-weight: 500;
  font-size: 1.25rem;
}

.shares {
  color: $text-gray;
  font-size: 0.875rem;
  margin-top: 5px;
  letter-spacing: 0.02em;
}

.angle-wrapper {
  display: flex;
  align-items: center;
}
</style>
