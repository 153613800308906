
import { computed, defineComponent, ref, watchEffect } from "vue";
import ChatListing from "@/components/chat/ChatListing.vue";
import { useStore } from "vuex";
import { chatsActions, chatsGetters, inChats } from "@/store/chats";
import { Chat, Message, User } from "@/types";
import { authGetters, inAuth } from "@/store/auth";
import ChatSearch from "@/components/chat/ChatSearch.vue";
import EmptyProfile from "@/components/util/EmptyProfile.vue";
import ChatView from "@/components/chat/ChatView.vue";
import { isMobilePlatform } from "@/util/platform";
import { useRoute } from "vue-router";

export default defineComponent({
  components: { ChatListing, ChatSearch, EmptyProfile, ChatView },
  setup() {
    const store = useStore();
    const chats = ref(null as null | Chat[]);
    const selectedChat = ref(null as null | Chat);
    const route = useRoute();

    watchEffect(async () => {
      const propertyId = route?.query?.propertyId;
      if (propertyId == null) return;
      const chat = await store.dispatch(
        inChats(chatsActions.GET_OR_CREATE_PROPERTY_CHAT),
        { propertyId }
      );
      selectedChat.value = chat;
    });

    store.dispatch(inChats(chatsActions.LIST)).then((values) => {
      chats.value = values;
    });

    const newMessageCount = computed(() => (chat: Chat) => {
      const user: User | null = store.getters[inAuth(authGetters.USER)];
      return store.getters[inChats(chatsGetters.NEW_MESSAGE_COUNT)](
        chat.id,
        user?.id
      );
    });

    const lastUsefulMessage = computed(() => (chat: Chat) => {
      const messages: Message[] = store.getters[inChats(chatsGetters.MESSAGES)](
        chat.id
      );
      if (messages.length === 0) return null;
      return messages.reduce<Message | null>(
        (latest: Message | null, curr: Message) => {
          if (curr.type !== "text") return latest;
          if (latest == null) return curr;
          return latest.inserted_at > curr.inserted_at ? latest : curr;
        },
        null
      );
    });

    // Make issue and document type messages available in excerpt
    // const messageExcerpt = computed(() => (message: Message) => {
    //   if (message.type === "text") return message.text;
    //   if (message.type === "issue") {
    //     // const issue =
    //   }
    // });

    const otherUsers = computed(() => (chat: Chat) => {
      const user: User | null = store.getters[inAuth(authGetters.USER)];
      if (user == null) return chat.users;
      return chat.users.filter((u) => u.id !== user?.id);
    });

    const titleForChat = computed(() => (chat: Chat) => {
      if (chat.property_id != null)
        return chat.property?.name || "Er ging iets mis";

      const user: User | null = store.getters[inAuth(authGetters.USER)];
      const otherUsers = chat.users.filter((u: User) => u.id !== user?.id);

      if (otherUsers.length === 0) return "Alleen ik";

      const firstUserName =
        otherUsers[0].first_name + " " + otherUsers[0].last_name;
      if (otherUsers.length === 1) {
        return firstUserName;
      }
      return firstUserName + ` en ${otherUsers.length} anderen`;
    });

    const iconUrlForChat = computed(() => (chat: Chat) => {
      if (chat.property_id != null) return "/img/chat_property.svg";

      const user: User | null = store.getters[inAuth(authGetters.USER)];
      if (chat.users.length === 2) {
        const otherUser = chat.users.find((u) => u.id !== user?.id);
        return otherUser?.avatar_url;
      }
      const messages: Message[] = store.getters[inChats(chatsGetters.MESSAGES)](
        chat.id
      );
      const newest = messages.reduce<Message | null>((newest, curr) => {
        if (newest == null) return curr;
        if (curr.inserted_at > newest.inserted_at) {
          return curr;
        }
        return newest;
      }, null);
      const authorId = newest?.author_id;
      if (authorId == null) return null;
      const lastUser = chat.users.find((u) => u.id === authorId);
      return lastUser?.avatar_url;
    });

    let orderedChats = computed(() => {
      if (chats.value == null) return null;
      return [...chats.value].sort((chatA: Chat, chatB: Chat) => {
        let lastMessageA = lastUsefulMessage.value(chatA);
        let lastMessageB = lastUsefulMessage.value(chatB);
        if (lastMessageA == null) return -1;
        if (lastMessageB == null) return 1;
        if (lastMessageA.inserted_at > lastMessageB.inserted_at) return -1;
        if (lastMessageA.inserted_at < lastMessageB.inserted_at) return 1;
        return 0;
      });
    });

    return {
      chats: orderedChats,
      lastUsefulMessage,
      otherUsers,
      titleForChat,
      iconUrlForChat,
      newMessageCount,
      selectedChat,
      toChat(chat: Chat) {
        console.log("Chat:", chat);
        selectedChat.value = chat;
        // router.push({
        //   name: "chats.show",
        //   params: {
        //     id: chat.id,
        //   },
        // });
      },
      hideChat() {
        console.log("hiding chat");
        selectedChat.value = null;

        if (isMobilePlatform()) {
          window.scrollTo(0, 0);
        }
      },
      test() {
        console.log("Test");
      },
    };
  },
});
