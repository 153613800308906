<template>
  <ul class="features">
    <li class="feature" v-for="feature in features" :key="feature.text">
      <div class="feature-icon-wrapper">
        <check-icon class="feature-icon" />
      </div>
      <div class="feature-content">
        <h2 class="feature-title">{{ feature.title }}</h2>
        <p class="feature-text">
          {{ feature.text }}
        </p>
      </div>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import CheckIcon from "@/components/icons/Check.vue";

export default defineComponent({
  components: { CheckIcon },
  props: {
    features: {
      type: Array as PropType<{ title: string; text: string }[]>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.feature {
  display: flex;
  flex-flow: row nowrap;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.feature-icon-wrapper {
  width: 22px;
  min-width: 22px;
  height: 22px;
  background-color: $bg-accent;
  color: $text-alt;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.feature-icon {
  width: 12px;
  height: 10px;
}

.feature-title {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 120%;
  padding-bottom: 7px;
}

.feature-text {
  font-size: 1rem;
  line-height: 130%;
  color: $text-alt;
}
</style>
