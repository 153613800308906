import appSocket from "@/channels/appSocket";
import { Property } from "@/repositories/propertyRepository";
import { authGetters, inAuth } from "@/store/auth";
import { inProperties, PropertiesActions } from "@/store/properties";
import { clearStore } from "@/store/util/clear";
import { User } from "@/types";
import { computed, ComputedRef, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useActiveProperty } from "./properties";

export function useHasAuth(): Ref<boolean> {
  const store = useStore();
  const hasAuth = computed(() => store.getters[inAuth(authGetters.HAS_AUTH)]);
  return hasAuth;
}

export function useCurrentUser(): Ref<User | null> {
  const store = useStore();
  const user = computed(() => store.getters[inAuth(authGetters.USER)]);
  return user;
}

export function isCurrentPropertyAdmin() {
  const activeProperty = useActiveProperty();
  const user = useCurrentUser();
  return computed(() => {
    if (activeProperty.value == null) return false;
    if (user.value == null) return false;
    return activeProperty.value.admin_id == user.value.id;
  });
}

export function useIsAdmin(property: Property): Ref<boolean> {
  const user = useCurrentUser();

  return computed(() => {
    if (user.value == null) return false;
    return property.admin_id === user.value.id;
  });
}

export function useIsAdminById(propertyId: number | string): {
  property: Ref<null | Property>;
  isAdmin: ComputedRef<boolean>;
} {
  const user = useCurrentUser();
  const property: Ref<null | Property> = ref(null);

  const store = useStore();
  store
    .dispatch(inProperties(PropertiesActions.BY_ID), {
      id: propertyId,
    })
    .then((value) => {
      property.value = value;
    });

  const isAdmin = computed(() => {
    if (user.value == null) return false;
    if (property.value == null) return false;
    return property.value.admin_id === user.value.id;
  });

  return {
    isAdmin,
    property,
  };
}

export function useLogout(): () => Promise<void> {
  const router = useRouter();

  return async () => {
    appSocket.logout();
    await clearStore();
    console.log("auth.ts: logging out, routing to login");
    router.push({
      name: "login",
    });
  };
}
