<template>
  <container thinner>
    <div class="add-resident">
      <generic-header>{{ $t("residents.add") }}</generic-header>
      <form class="container" @submit.prevent="submit">
        <label for="email" class="label">
          {{ $t("residents.send_email_invite") }}
        </label>
        <textbox
          v-model="email"
          id="email"
          name="email"
          placeholder="Email van mede-eigenaar"
          type="email"
        />
        <form-error v-if="error != null">{{ error }}</form-error>
        <!-- TODO: Re-add this when paid plans come back -->
        <!-- TODO: Make this much more clear with a specification of how much. -->
        <!-- <div class="warning">
          <h2 class="title">De prijs van uw abonnement zal veranderen.</h2>
          <p class="description">
            De prijs van uw abonnement is berekent per resident. Wanneer u een
            resident toevoegt zal de totaalprijs dus stijgen.
          </p>
        </div> -->
        <!-- <app-button :loading="loading" type="submit" class="button" width="100%"
          >Ik begrijp het, versturen</app-button
        > -->
        <app-button
          :loading="loading"
          type="submit"
          class="button"
          width="100%"
          >{{ $t("common.send") }}</app-button
        >
      </form>
    </div>
  </container>
</template>

<script lang="ts">
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { defineComponent, ref } from "vue";
import { inProperties, PropertiesActions } from "@/store/properties";
import { DuplicateException } from "@/error_handling/DuplicateException";
import FormError from "@/components/form/FormError.vue";

export default defineComponent({
  components: { FormError },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const email = ref("");
    const loading = ref(false);
    const error = ref(null as null | string);

    return {
      email,
      loading,
      error,
      async submit() {
        try {
          loading.value = true;
          await store.dispatch(
            inProperties(PropertiesActions.INVITE_RESIDENT),
            {
              id: route.params.id,
              email: email.value,
            }
          );
          router.push({
            name: "properties.residents.list",
            params: {
              id: route.params.id,
            },
            query: {
              invited: email.value,
            },
          });
        } catch (e) {
          if (e instanceof DuplicateException) {
            error.value =
              "Een gebruiker met dit email adres is al lid van deze residentie";
          } else {
            throw e;
          }
        } finally {
          loading.value = false;
        }
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.container {
  padding: 0 20px;
  box-sizing: border-box;
  margin-top: 40px;

  @include screen-from("tablet") {
    padding: 0;
  }
}

.label {
  font-size: 1.125rem;
  font-weight: 600;
  color: $text-alt;
  font-family: $font-title;
  margin-bottom: 20px;
  display: block;
}

.title {
  color: $text-alt;
  font-size: 1.125rem;
  font-weight: 600;
  font-family: $font-title;
  margin-top: 30px;
  line-height: 130%;
}

.description {
  margin-top: 10px;
  font-size: 0.875rem;
  letter-spacing: 0.02rem;
  line-height: 140%;
  color: $text-alt;
  font-family: $font-text;
  max-width: 600px;
}

.button {
  margin-top: 30px;
}
</style>
