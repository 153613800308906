
import { defineComponent, onMounted, ref, watch } from "vue";
export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const active = ref(props.modelValue as boolean);
    onMounted(() => {
      emit("update:modelValue", props.modelValue === true);
    });

    watch(
      () => props.modelValue,
      () => {
        active.value = props.modelValue;
      }
    );

    return {
      active,
      handleClick() {
        active.value = !active.value;
        emit("update:modelValue", active.value);
      },
    };
  },
});
