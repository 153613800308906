
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import AngleDownIcon from "@/components/icons/AngleDown.vue";
import { useOnClickOutside } from "@/util/click";
import { debounce } from "debounce";

export default defineComponent({
  components: { AngleDownIcon },
  props: {
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: String,
      default: null,
    },
    thinner: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const open = ref(false as boolean);

    function hide() {
      open.value = false;
    }

    const clickOutsideRef = useOnClickOutside(() => (open.value = false));

    const ITEM_HEIGHT_PX = 45;
    const MAX_HEIGHT_PX = 300;
    const selectedItemRef = ref(null as null | HTMLDivElement);
    const optionCount = computed(() => Object.keys(props.options).length);

    const dropdownPosition = ref("bottom" as "bottom" | "top");
    const dropperHeightLimit = ref(300);
    const calcDropdownPosition = debounce(() => {
      let ref = selectedItemRef.value;
      if (ref == null) return;

      const height = Math.min(
        optionCount.value * ITEM_HEIGHT_PX,
        MAX_HEIGHT_PX
      );
      const pageHeight =
        window.innerHeight ||
        document.body.clientHeight ||
        selectedItemRef.value?.clientHeight;

      if (pageHeight == null) return;

      const boundingBox = ref.getBoundingClientRect();
      const distanceToTopOfScreen = boundingBox.top;
      const distanceToBottomOfScreen = pageHeight - boundingBox.bottom;

      if (distanceToTopOfScreen < distanceToBottomOfScreen) {
        dropperHeightLimit.value = Math.min(300, distanceToBottomOfScreen);
        dropdownPosition.value = "bottom";
        return;
      }

      if (distanceToBottomOfScreen < height) {
        dropperHeightLimit.value = Math.min(300, distanceToTopOfScreen - 50);
        dropdownPosition.value = "top";
      } else {
        dropperHeightLimit.value = Math.min(300, distanceToBottomOfScreen - 50);
        dropdownPosition.value = "bottom";
      }
    }, 100);

    watch([selectedItemRef, optionCount], calcDropdownPosition);
    onMounted(() => {
      window.addEventListener("scroll", calcDropdownPosition, true);
      window.addEventListener("resize", calcDropdownPosition);
      calcDropdownPosition();
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", calcDropdownPosition);
      window.removeEventListener("resize", calcDropdownPosition);
    });

    return {
      dropdownPosition,
      dropperHeightLimit,
      selectedItemRef,
      clickOutsideRef,
      dropdownEl: useOnClickOutside(() => {
        open.value = false;
      }),
      open,
      show() {
        open.value = true;
      },
      hide,
      toggle() {
        open.value = !open.value;
      },
      select(optionKey: string) {
        emit("update:modelValue", optionKey);
        hide();
      },
    };
  },
});
