<template>
  <div class="invitation-dropdown" v-if="invites.length !== 0">
    <div class="icon" @click="show">
      <bell-icon />
      <div class="count">{{ invites.length }}</div>
    </div>
    <div class="popup" v-if="showing">
      <div class="bg" @click="hide"></div>
      <div class="close" @click="hide">
        <times-icon />
      </div>
      <div class="content">
        <h1 class="title">{{ $t("common.invites") }}</h1>
        <div class="invitations">
          <div class="invitation" v-for="invite in invites" :key="invite.id">
            <div class="invitation__text">{{ invite.property.name }}</div>
            <div class="invitation__choice">
              <div class="invitation__accept" @click="accept(invite)">
                <check-icon />
              </div>
              <div class="invitation__decline" @click="decline(invite)">
                <times-icon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import BellIcon from "@/components/icons/Bell.vue";
import CheckIcon from "@/components/icons/Check.vue";
import TimesIcon from "@/components/icons/Times.vue";
import { Invite } from "@/types";
import { useStore } from "vuex";
import { inProperties, PropertiesActions } from "@/store/properties";
import appSocket from "@/channels/appSocket";
import { useRemoveListener } from "@/util/component";

export default defineComponent({
  components: { BellIcon, CheckIcon, TimesIcon },
  setup(props, { emit }) {
    const store = useStore();
    const showing = ref(false as boolean);
    const invites = ref([] as Invite[]);

    const hide = () => (showing.value = false);
    const show = () => (showing.value = true);

    const updateInvites = () => {
      if (showing.value === true) return;
      store
        .dispatch(inProperties(PropertiesActions.LIST_INVITES))
        .then((values) => {
          invites.value = values;
        });
    };
    updateInvites();

    useRemoveListener(() =>
      appSocket.getUserChannel().onInvite(() => {
        updateInvites();
      })
    );

    useRemoveListener(() =>
      appSocket.getUserChannel().onInviteRemoved(() => {
        console.log("Invite removed");
        updateInvites();
      })
    );

    const accept = (invite: Invite) => {
      store
        .dispatch(inProperties(PropertiesActions.ACCEPT_INVITE), {
          id: invite.id,
        })
        .then(() => {
          invites.value = invites.value.filter((i) => i.id !== invite.id);
          emit("properties-changed");
        });
    };

    const decline = (invite: Invite) => {
      store
        .dispatch(inProperties(PropertiesActions.DECLINE_INVITE), {
          id: invite.id,
        })
        .then(() => {
          invites.value = invites.value.filter((i) => i.id !== invite.id);
        });
    };

    return {
      accept,
      decline,
      hide,
      show,
      showing,
      invites,
    };
  },
});
</script>

<style lang="scss" scoped>
.invitation-dropdown {
  display: flex;
  justify-content: flex-end;
}

.icon {
  position: relative;
  width: 25px;
  height: 25px;
  color: $text-alt;
  cursor: pointer;
}

.count {
  position: absolute;
  bottom: -2px;
  right: -4px;
  border-radius: 50%;
  background-color: $bg-success;
  color: $text-light;
  width: 17px;
  height: 17px;
  font-weight: 600;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
}

.bg {
  background-color: #1e1803;
  opacity: 0.9;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.close {
  position: absolute;
  top: 22px;
  right: 32px;
  width: 20px;
  height: 20px;
  color: $text-light;
  z-index: 3;
  cursor: pointer;
}

.content {
  position: relative;
  z-index: 2;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  top: 13vh;
  padding: 25px 30px 30px;
  background-color: white;
  box-sizing: border-box;
  border-radius: 10px;
  max-width: 400px;
}

.title {
  color: $text-alt;
  font-family: $font-title;
  font-size: 1.5rem;
  line-height: 175%;
  font-weight: 600;
}

.invitations {
  display: flex;
  flex-flow: column nowrap;
}

.invitation {
  display: grid;
  grid-template-columns: auto 60px;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid #eef0f1;
  }
}

.invitation__text {
  font-weight: 500;
  font-size: 1.125rem;
  color: $text-alt;
  font-family: $font-text;
  line-height: 1.375;
  padding: 20px 15px 20px 0;
}

.invitation__choice {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  column-gap: 15px;
}

.invitation__accept {
  width: 100%;
  color: $bg-success;
  cursor: pointer;

  svg {
    width: 85%;
  }
}

.invitation__decline {
  width: 100%;
  color: $bg-error;
  cursor: pointer;

  svg {
    width: 80%;
  }
}
</style>
