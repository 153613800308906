import { includesAll } from "./str";

const currFormatter = Intl.NumberFormat("nl-BE", {
  style: "currency",
  currency: "EUR",
});

/**
 * @param amount a flat amount such as 1000 -> €10,00
 */
export function formatAsEuro(
  amount: number,
  options?: { noEuroSign: boolean }
): string {
  const result = currFormatter.format(amount / 100);
  if (options?.noEuroSign) return result.replace("€", "").trim();
  return result;
}

/**
 * parses user input to a value that can be sent to the backend
 * @param rawInput Input from the user (through a textbox for example)
 * @returns null if invalid, the parsed number if valid
 */
export function parseToRawPrice(rawInput: string): number | null {
  if (rawInput === "") return 0;
  rawInput = rawInput.replace("€", "").trim();
  if (includesAll(rawInput, [".", ","])) {
    rawInput = rawInput.replace(".", "");
  }
  rawInput = rawInput.replace(",", ".");
  const parsed = Number.parseFloat(rawInput);
  if (Number.isNaN(parsed)) return null;
  return Math.floor(parsed * 100);
}
