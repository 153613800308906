import { Channel, Socket } from "phoenix";
import { AppChannel } from "./AppChannel";
import store from "@/store/index";
import { inProperties, PropertiesActions } from "@/store/properties";
import { Property } from "@/repositories/propertyRepository";
import { Chat, Invite, RemoveEventListenerFunction, User } from "@/types";
import { showToast } from "@/components/notifications/toast";
import { chatsActions, inChats } from "@/store/chats";

type PropertyAndInvite = { invite: Invite; property: Property };

// TODO: when accepting invitation to new property, also join that property

export class PropertyChannels extends AppChannel {
  private _channels: Record<number, Channel> = {};

  setup(socket: Socket): void {
    store
      .dispatch(inProperties(PropertiesActions.LIST))
      .then((properties: Property[]) => {
        properties.forEach((property) => this.setupChannel(socket, property));
      });
  }

  onInviteAccepted(
    cb: (data: PropertyAndInvite) => void
  ): RemoveEventListenerFunction {
    return this.onEmitterEvent<PropertyAndInvite>("invite-accepted", cb);
  }

  onInviteDeclined(
    cb: (data: PropertyAndInvite) => void
  ): RemoveEventListenerFunction {
    return this.onEmitterEvent<PropertyAndInvite>("invite-declined", cb);
  }

  private setupChannel(socket: Socket, property: Property) {
    const channel = socket.channel(`property:${property.id}`);
    this._channels[property.id] = channel;
    channel.join();
    channel.on(
      "invite-accepted",
      ({ invite, user }: { invite: Invite; user: User }) => {
        showToast({
          type: "success",
          text: `${user.first_name} ${user.last_name} is nu lid van de residentie: ${property.name}`,
        });
        store.dispatch(inChats(chatsActions.RE_FETCH));
        this.emitter.emit("invite-accepted", { invite, property });
      }
    );
    channel.on("invite-declined", ({ invite }: { invite: Invite }) => {
      this.emitter.emit("invite-declined", { invite, property });
    });
    channel.on("new-chat", (data: { chat: Chat }) => {
      this.globalEmitter.emit("new-chat", data);
    });
  }
}
