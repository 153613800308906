<template>
  <div class="card" :class="{ selected }" ref="clickOutsideRef">
    <div class="question-wrapper" v-if="tooltip">
      <popper hover arrow :content="tooltip" class="info">
        <div class="questionmark">?</div>
      </popper>
    </div>
    <div class="tags" v-if="tags.length > 0" :class="`tags--${tagsPosition}`">
      <div
        class="tag"
        v-for="tag in tags"
        :key="tag.text"
        :class="`tag--${tag.type}`"
      >
        {{ tag.text }}
      </div>
    </div>
    <div
      class="inside-content"
      :class="{ open: showSlideOut && $slots.slide }"
      @click="handleClick"
    >
      <slot />
    </div>
    <div class="slide-out" v-if="$slots.slide && showSlideOut">
      <slot name="slide" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import { useOnClickOutside } from "@/util/click";

export enum CardTagType {
  ERROR = "error",
  INFO = "info",
  SUCCESS = "success",
}

export interface CardTag {
  text: string;
  type: CardTagType;
}

export const errorTag = (text: string) => ({ text, type: CardTagType.ERROR });
export const infoTag = (text: string) => ({ text, type: CardTagType.INFO });
export const successTag = (text: string) => ({
  text,
  type: CardTagType.SUCCESS,
});

export default defineComponent({
  props: {
    tags: {
      type: Array as PropType<CardTag[]>,
      default: () => [],
    },
    tagsPosition: {
      type: String as PropType<"left" | "right">,
      default: "left",
    },
    tooltip: {
      type: String,
      default: null,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const showSlideOut = ref(false);
    const clickOutsideRef = useOnClickOutside(
      () => (showSlideOut.value = false)
    );

    return {
      clickOutsideRef,
      showSlideOut,
      handleClick() {
        showSlideOut.value = !showSlideOut.value;
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  background-color: white;
}

.tags {
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  z-index: 2;

  &--left {
    left: 20px;
  }

  &--right {
    right: 20px;
  }
}

.tag {
  padding: 5px 10px;
  line-height: 100%;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;
  color: white;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  text-align: center;
  margin-left: 5px;
  border-radius: 5px;

  &--error {
    background-color: $bg-error;
  }

  &--info {
    background-color: $bg-accent;
    color: $text-dark;
  }

  &--success {
    background-color: $bg-success;
  }
}

.inside-content {
  width: 100%;
  border: 1px solid $border-general;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: white;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.selected .inside-content {
  border-color: transparent;
  outline: 2px solid $bg-accent;
}

.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.slide-out {
  position: absolute;
  width: 100%;
  top: calc(100% - 10px);
  left: 0;
  border: 1px solid $border-general;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-sizing: border-box;
  padding-top: 10px;
}
.selected .slide-out {
  border-color: transparent;
  outline: 2px solid $bg-accent;
}

.test {
  position: absolute;
  top: 0;
  right: -5px;
  transform: translateY(-50%);
  z-index: 10;
}

.questionmark {
  width: 20px;
  height: 20px;
  border: 1px solid $border-general;
  border-radius: 50%;
  color: $text-dark;
  background-color: white;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
