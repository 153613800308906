<template>
  <div class="finance-layout">
    <router-view />
  </div>
  <finance-sidebar />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FinanceSidebar from "./FinanceSidebar.vue";

export default defineComponent({
  components: { FinanceSidebar },
  setup() {
    console.log("Finance layout");
  },
});
</script>
