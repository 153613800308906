import * as Sentry from "@sentry/vue";

export const logException = (err: Error): void => {
  // console.error(err);
  Sentry.captureException(err);
};

export const logBreakingException = (err: Error): void => {
  console.error(err);
  // TODO: show toaster to client
  Sentry.captureException(err);
};
