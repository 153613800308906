
import appSocket from "@/channels/appSocket";
import { Issue } from "@/repositories/issueRepository";
import { Property } from "@/repositories/propertyRepository";
import { chatsGetters, inChats } from "@/store/chats";
import { inProperties, PropertiesActions } from "@/store/properties";
import { Chat, Message, User } from "@/types";
import { computed, defineComponent, PropType, ref, watch } from "vue";
import { useStore } from "vuex";
import { showPopup } from "../popups/popups";
import SelectIssuePopupVue from "../popups/SelectIssuePopup.vue";
import ChatMessage from "@/components/chat/ChatMessage.vue";
import WriteMessageBar from "@/components/chat/WriteMessageBar.vue";
import CloseIcon from "@/components/icons/Times.vue";
import { showUploadFilePopup } from "@/features/documents/popups/UploadFile.vue";
import { filesActions, inFiles } from "@/store/files";
import { FileItem } from "@/repositories/fileRepository";
import { isMobilePlatform } from "@/util/platform";
import { showChooseDocumentPopup } from "@/features/documents/popups/ChooseDocument.vue";

export default defineComponent({
  components: { ChatMessage, WriteMessageBar, CloseIcon },
  props: {
    chat: {
      type: Object as PropType<Chat>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const messages = computed(() => {
      if (props.chat == null) return;
      const messages: Message[] = store.getters[inChats(chatsGetters.MESSAGES)](
        props.chat.id
      );
      return messages.sort((a, b) => {
        return b.inserted_at.localeCompare(a.inserted_at);
      });
    });

    const messagesEl = ref(null as null | HTMLDivElement);
    watch(messages, () => {
      if (messagesEl.value == null) return;
      messagesEl.value.scrollTop = messagesEl.value.scrollHeight;

      // TODO: Unsure if this is necessary
      if (isMobilePlatform()) {
        document.body.scrollTop = document.body.scrollHeight;
      }
    });

    function markAllSeen() {
      if (messages.value == null) return;
      if (messages.value.length === 0) return;
      const oldest = messages.value[0];
      appSocket.getChatChannels().markSeen(props.chat.id, oldest.id);
    }

    const chatId = computed(() => props.chat?.id);
    markAllSeen();
    watch([messages, chatId], () => {
      markAllSeen();
    });

    const authorById = computed(() => (userId: number) => {
      if (props.chat == null) return null;
      return props.chat.users.find((u: User) => u.id === userId);
    });

    // async function overlappingProperties(): Promise<Property[]> {
    //   const c: Chat | null = props.chat;
    //   if (c == null) return [];

    //   return await store.dispatch(
    //     inProperties(PropertiesActions.LIST_OVERLAPPING),
    //     {
    //       userIds: c.users.map((u) => u.id),
    //     }
    //   );
    // }

    return {
      messagesEl,
      messages,
      authorById,
      sendMessage(content: string) {
        appSocket.getChatChannels().sendMessage(props.chat.id, content);
      },
      async addDocument() {
        const c: Chat | null = props.chat;
        if (c == null) return;

        let item = await showChooseDocumentPopup();
        if (item == null) return null;
        if (item.file_id == null) return null;
        appSocket
          .getChatChannels()
          .sendMessage(props.chat.id, item.file_id.toString(), "image");
      },
      async addImage() {
        const c: Chat | null = props.chat;
        if (c == null) return;
        //
        let image = await showUploadFilePopup({ accept: "image/*" });
        if (image == null) return;

        let uploadedFile = (await store.dispatch(inFiles(filesActions.UPLOAD), {
          file: image,
        })) as FileItem;
        appSocket
          .getChatChannels()
          .sendMessage(props.chat.id, uploadedFile.id.toString(), "image");
      },
      async addIssue() {
        const c: Chat | null = props.chat;
        if (c == null) return;

        let params: Record<string, any> = {};
        if (c.property_id != null) {
          params["property_id"] = c.property_id;
        } else {
          const overlappingProperties = await store.dispatch(
            inProperties(PropertiesActions.LIST_OVERLAPPING),
            {
              userIds: c.users.map((u) => u.id),
            }
          );
          params["property_ids"] = overlappingProperties.map(
            (p: Property) => p.id
          );
        }

        const issue: Issue | null = await showPopup(
          SelectIssuePopupVue,
          params
        );
        if (issue == null) return;
        appSocket
          .getChatChannels()
          .sendMessage(props.chat.id, issue.id.toString(), "issue");
      },
    };
  },
});
