
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "100%",
    },
    maxWidth: {
      type: String,
      default: "auto",
    },
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    return {
      handleInput(event: InputEvent) {
        emit("update:modelValue", (event.target as any).value);
      },
      handleBlur() {
        emit("blur");
      },
    };
  },
});
