<template>
  <form-error v-if="fieldErrors[name]">
    {{ message }}
  </form-error>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormError from "./FormError.vue";

export default defineComponent({
  components: { FormError },
  props: {
    fieldErrors: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    message(): string {
      if (
        this.fieldErrors == null ||
        this.fieldErrors[this.name] == null ||
        this.fieldErrors[this.name].length <= 0
      ) {
        return "";
      }
      return this.fieldErrors[this.name][0];
    },
  },
});
</script>
