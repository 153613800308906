
import { ValidationRule } from "@vuelidate/core";
import { defineComponent, Prop } from "vue";
import FormRuler from "./FormRuler.vue";
import ProfilePictureUpload from "@/components/form/ProfilePictureUpload.vue";
import { ImagesUpload } from "@/components/form";

export interface FieldSetting {
  name: string;
  type:
    | "string"
    | "hr"
    | "boolean"
    | "profile-image"
    | "dropdown"
    | "textarea"
    | "images"
    | "files"
    | "date"
    | "time";
  label: string;
  meta?: Record<string, any>;
  rules?: ValidationRule | { [K: string]: ValidationRule };
  smaller?: boolean;
  subCheckbox?: {
    name: string;
    label: string;
  };
}

export default defineComponent({
  name: "GeneratedFormItem",
  components: { FormRuler, ProfilePictureUpload, ImagesUpload },
  props: {
    fieldSetting: {
      required: true,
    } as Prop<FieldSetting>,
    modelValue: {
      default: null,
    },
    subCheckboxValue: {
      default: null,
    },
  },
});
