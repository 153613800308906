<template>
  <router-link
    :target="target"
    v-if="to != null && href == null"
    class="link"
    :to="to"
    ><slot
  /></router-link>
  <a
    :download="download"
    :target="target"
    :href="href"
    v-else
    class="link"
    @click="$emit('click')"
    ><slot
  /></a>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],
  props: {
    to: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    // Specify the name of the download
    download: {
      type: String,
      default: null,
    },
  },
});
</script>

<style lang="scss" scoped>
.link {
  color: $text-accent;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01rem;
  line-height: 130%;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;

  &:hover {
    color: darken($text-accent, 10%);
  }

  &:active {
    color: $text-dark;
  }
}
</style>
