import { computed, Ref, ref, watchEffect } from "vue";
import { Store, useStore } from "vuex";
import { useInvalidatable } from "./invalidate";

export const useStoreAction = <T>(
  defaultValue: T,
  actionHandler: (store: Store<any>) => Promise<T> | T,
  keys?: (string | number)[]
): {
  value: Ref<T>;
  loading: Ref<boolean>;
  refetch: () => Promise<void>;
} => {
  const store = useStore();
  const loading = ref(false);
  const result = ref(defaultValue as any);

  const fetch = async () => {
    result.value = await Promise.resolve(actionHandler(store));
  };

  if (keys) {
    useInvalidatable(keys, async () => fetch());
  }

  watchEffect(async () => {
    loading.value = true;
    await fetch();
    loading.value = false;
  });

  return {
    loading,
    value: result,
    refetch: fetch,
  };
};

export const useLoading = (...loadingItems: Ref<boolean>[]): Ref<boolean> => {
  return computed(() => {
    return !loadingItems.some((item) => !item.value);
  });
};
