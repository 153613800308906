<template>
  <menu-dropper
    @select:profile="$router.push({ name: 'profile' })"
    @select:logout="logout"
    :options="{ profile: $t('common.my_profile'), logout: $t('auth.logout') }"
  >
    <!-- <router-link to="/profile" class="profile"> -->
    <div class="profile">
      <div class="name">{{ name }}</div>
      <div class="icon-wrapper">
        <mini-profile-icon />
      </div>
    </div>
    <!-- </router-link> -->
  </menu-dropper>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import MiniProfileIcon from "@/components/icons/MiniEmptyAvatar.vue";
import { useLogout, useCurrentUser } from "@/util/auth";
import MenuDropper from "./MenuDropper.vue";

export default defineComponent({
  components: { MiniProfileIcon, MenuDropper },
  setup() {
    const currentUser = useCurrentUser();

    const name = computed(() => {
      if (currentUser.value == null) return "";
      return currentUser.value.first_name;
    });

    const doLogout = useLogout();

    return {
      currentUser,
      name,
      logout() {
        doLogout();
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.profile {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: $text-dark;
  text-decoration: none;
}

.name {
  font-family: $font-text;
  margin-right: 10px;
  font-weight: 500;
  text-decoration: none;
}
</style>
