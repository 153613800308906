export class BrowserStorage<T> {
  constructor(private key: string) {}

  set(v: T) {
    localStorage.setItem(this.key, JSON.stringify(v));
  }

  get(): T | null {
    const json = localStorage.getItem(this.key);
    if (json == null) return null;
    return JSON.parse(json);
  }

  clear() {
    localStorage.removeItem(this.key);
  }
}
