<template>
  <header
    class="generic-header"
    :class="{
      web,
      ios,
      mobile,
      'hide-on-desktop': hideDesktop,
      'no-padding': noPadding,
    }"
  >
    <a class="hamburger-menu" @click="openMobileSidebar">
      <hamburger />
    </a>
    <!-- <a v-if="!hideBackButton" @click="goBack" class="back-button">
      <BackIcon />
    </a>
    <div class="back-button" v-else></div> -->
    <h1
      class="title"
      :class="{ 'title--center': centerTitle, 'title--no-padding': noPadding }"
    >
      <slot />
    </h1>
    <div class="right">
      <slot name="right" />
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getPlatform, isMobilePlatform, isWebPlatform } from "@/util/platform";
import { useRouter } from "vue-router";
import Hamburger from "../../icons/Hamburger.vue";
import { useStore } from "vuex";
import { inOther, OtherActions } from "@/store/other";

export default defineComponent({
  components: { Hamburger },
  props: {
    hideDesktop: {
      type: Boolean,
      default: false,
    },
    overrideBackbutton: {
      type: Boolean,
      default: false,
    },
    hideBackButton: {
      type: Boolean,
      default: false,
    },
    centerTitle: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();

    return {
      web: isWebPlatform(),
      ios: getPlatform() === "ios",
      mobile: isMobilePlatform(),
      goBack() {
        if (props.overrideBackbutton) {
          console.log("Backbutton");
          emit("back");
        } else {
          router.go(-1);
        }
      },
      openMobileSidebar() {
        store.dispatch(inOther(OtherActions.SHOW_MOBILE_SIDEBAR));
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.generic-header {
  width: 100%;
  padding: 0 30px;
  height: 70px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  &.no-padding {
    padding: 0;
    /* padding: 0; */
  }

  /* @media screen and (min-height: 800px) {
    margin-top: 3vh;
  } */

  @include screen-from("tablet") {
    margin-top: 0;
  }

  /* &.web {
    @include screen-from("tablet-wide") {
      height: auto;
      padding: 0;
    }
  } */

  &.hide-on-desktop {
    @include screen-from("tablet-wide") {
      display: none;
    }
  }

  margin-top: 1vh;
  &.mobile {
    margin-top: 1.5vh;

    @media screen and (min-height: 800px) {
      margin-top: 2.5vh;
    }
  }
  /* &.web {
    margin-top: 1.5vh;
  } */
  /* &.ios {
    padding-top: 10px;
  } */
}

.back-button {
  -webkit-tap-highlight-color: transparent;
  width: 40px;
  cursor: pointer;
}
.web .back-button {
  @include screen-from("tablet-wide") {
    display: none;
  }
}

.title {
  color: $text-dark;
  font-size: 1.3rem;
  font-weight: 600;
  font-family: $font-title;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 16 * 1.5px;
  white-space: nowrap;
  padding: 0 10px;
  // padding: 0 0px;
  box-sizing: border-box;

  &--center {
    text-align: center;
    width: 100%;
  }

  &--no-padding {
    padding: 0;
  }
}
.web .title {
  @include screen-from("tablet-wide") {
    font-size: 2rem;
    height: auto;
    overflow: visible;
  }
}

.right {
  width: 40px;
  color: $text-dark;

  @include screen-from("tablet-wide") {
    width: auto;
  }
}

.hamburger-menu {
  cursor: pointer;
  width: 40px;
  -webkit-tap-highlight-color: transparent;
}
.web .hamburger-menu {
  @include screen-from("tablet-wide") {
    display: none;
  }
}
</style>
