
import { computed, defineComponent, Prop, reactive, ref } from "vue";
import { AppForm, FormField } from "./index";
import GeneratedFormItem, { FieldSetting } from "./GeneratedFormItem.vue";
import FieldError from "./FieldError.vue";
import { ValidationException } from "@/error_handling/ValidationException";

export type FieldsSettingsItem = FieldSetting | FieldSetting[];
export type FieldsSettings = FieldsSettingsItem[];

export default defineComponent({
  components: { AppForm, FormField, GeneratedFormItem, FieldError },
  props: {
    fieldSettings: {
      type: Array,
      required: true,
    } as Prop<FieldsSettings>,
    fieldValues: {
      type: Object,
      default: () => ({}),
    },
    submitText: {
      type: String,
      default: "Opslaan",
    },
    submitHandler: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const formData = reactive({} as { [name: string]: any });

    const fieldErrors = ref({} as { [fieldName: string]: string[] });

    return {
      formData,
      fieldErrors,
      setFormData(key: string, value: any) {
        formData[key] = value;
      },
      async handleSubmit() {
        try {
          await props.submitHandler(formData);
        } catch (e) {
          if (e instanceof ValidationException) {
            fieldErrors.value = (e.getErrors()?.data as any)?.errors;
          } else {
            throw e;
          }
        }
      },
      valueFor: computed(() => {
        return (name: string) => {
          if (name == null) return null;
          if (formData != null && formData[name] != null) {
            return formData[name];
          }
          if (props.fieldValues != null && props.fieldValues[name] != null) {
            return props.fieldValues[name];
          }
          return null;
        };
      }),
    };
  },
});
