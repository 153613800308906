
import { Payment } from "@/types";
import { formatAsEuro } from "@/util/price";
import { DateTime } from "luxon";
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    payment: {
      type: Object as PropType<Payment>,
      required: true,
    },
  },
  setup(props) {
    console.log("Payment:", props.payment);
    return {
      amount: computed(() => formatAsEuro(props.payment.amount)),
      formattedDate: computed(() => {
        const dt = DateTime.fromISO(props.payment.transaction.value_date);
        return dt.toFormat("dd LLL yyyy");
      }),
    };
  },
});
