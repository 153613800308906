
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { defineComponent, ref } from "vue";
import { inProperties, PropertiesActions } from "@/store/properties";
import { DuplicateException } from "@/error_handling/DuplicateException";
import FormError from "@/components/form/FormError.vue";

export default defineComponent({
  components: { FormError },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const email = ref("");
    const loading = ref(false);
    const error = ref(null as null | string);

    return {
      email,
      loading,
      error,
      async submit() {
        try {
          loading.value = true;
          await store.dispatch(
            inProperties(PropertiesActions.INVITE_RESIDENT),
            {
              id: route.params.id,
              email: email.value,
            }
          );
          router.push({
            name: "properties.residents.list",
            params: {
              id: route.params.id,
            },
            query: {
              invited: email.value,
            },
          });
        } catch (e) {
          if (e instanceof DuplicateException) {
            error.value =
              "Een gebruiker met dit email adres is al lid van deze residentie";
          } else {
            throw e;
          }
        } finally {
          loading.value = false;
        }
      },
    };
  },
});
