import { getPlatform } from "@/util/platform";
import { registerPlugin } from "@capacitor/core";
import { PushNotifications, Token } from "@capacitor/push-notifications";

export interface PushNotificationsPlugin {
  register(): Promise<{ token: string }>;
}

let Plugin: null | PushNotificationsPlugin = null;
if (getPlatform() === "android") {
  Plugin = registerPlugin<PushNotificationsPlugin>("Push");
}

// export default Plugin;
export default {
  register(): Promise<{ token: string } | null> {
    console.log("Attempting to register");
    if (getPlatform() === "android") {
      console.log("Registering 'PUSH' for android");
      if (Plugin == null) throw Error("Push plugin is null on android");
      return Plugin.register();
    }
    if (getPlatform() === "ios") {
      console.log("Register 'PushNotifications' for ios or web.");
      return new Promise((resolve) => {
        PushNotifications.addListener("registration", (token: Token) => {
          resolve({ token: token.value });
        });
        PushNotifications.register();
      });
    }
    // if (getPlatform() === "web") {
    //   return
    // }
    return new Promise((resolve) => resolve(null));
    // throw Error("Notifications not supported on current platform");
  },
};
