
import { showPopup } from "@/components/popups/popups";
import { Item } from "@/types";
import { computed, defineComponent, PropType, ref, watchEffect } from "vue";
import ItemComponent from "../components/Item.vue";

interface Params {
  items: Item[];
}

const component = defineComponent({
  components: { Item: ItemComponent },
  props: {
    params: {
      type: Object as PropType<Params>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const searchElRef = ref<HTMLInputElement | null>(null);
    const searchQuery = ref("");
    const filter = computed(() => {
      const result = props.params.items.filter((item) => {
        return item.name
          .toLowerCase()
          .includes(searchQuery.value.toLowerCase());
      });
      return { items: result.slice(0, 9), hasMore: result.length > 9 };
    });

    watchEffect(() => {
      console.log("SeachElRef", searchElRef.value);
      if (searchElRef.value) {
        searchElRef.value.focus();
      }
    });

    return {
      searchElRef,
      searchQuery,
      filter,
      onOpen(item: Item) {
        emit("finish", item);
      },
    };
  },
});

export const showSearchDocumentsPopup = (
  params: Params
): Promise<Item | null> => {
  return showPopup(component, params);
};

export default component;
