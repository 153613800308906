
import { defineComponent, PropType } from "vue";
import MobileActionButton from "./actions/MobileActionButton.vue";
import DesktopActionButton from "./actions/DesktopActionButton.vue";

export interface GenericPageAction {
  label: string;
  icon: string;
  onClick: () => void;
}

export interface GenericPageActions {
  label: string; // Only shown on desktop
  icon: string;
  actions: GenericPageAction[];
}

export default defineComponent({
  components: { MobileActionButton, DesktopActionButton },
  props: {
    actions: {
      type: Object as PropType<GenericPageActions>,
      required: true,
    },
  },
});
