<template>
  <div class="issue-listing" @click="$emit('click')">
    <div class="tags tags--mobile" v-if="!noTags">
      <div class="tag" v-for="tag in issueTags(issue)" :key="tag">
        {{ tag }}
      </div>
    </div>
    <div class="content-wrapper">
      <div class="content">
        <h2 class="title">{{ issue.title }}</h2>
        <div class="time">
          {{ timeago(issue.inserted_at, " geleden", true).toLowerCase() }}
        </div>
        <div class="tags tags--web" v-if="!noTags">
          <div class="tag" v-for="tag in issueTags(issue)" :key="tag">
            {{ tag }}
          </div>
        </div>
      </div>
      <div class="images" v-if="!noImages">
        <div
          class="image"
          v-for="p in issue.pictures.slice(0, 2)"
          :key="p.id"
          :style="{ 'background-image': `url('${p.url}')` }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { timeago } from "@/util/timeago";
import { issueTags } from "@/util/issues";

export default defineComponent({
  emits: ["click"],
  props: {
    issue: {
      type: Object,
      required: true,
    },
    noText: {
      type: Boolean,
      default: false,
    },
    noImages: {
      type: Boolean,
      default: false,
    },
    noTags: {
      type: Boolean,
      default: false,
    },
    noAuthor: {
      type: Boolean,
      default: false,
    },
    altTime: {
      type: Boolean,
      deafult: false,
    },
  },
  setup() {
    return {
      issueTags,
      timeago,
    };
  },
});
</script>

<style lang="scss" scoped>
.issue-listing {
  width: 100%;
  padding: 20px;
  color: $text-alt;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid $border-dark;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  transition: border-color 100ms ease;

  &:hover {
    border-color: $bg-accent;
  }
}

.tags {
  display: flex;
  flex-flow: row wrap;
  margin-top: 12px;

  &--web {
    display: none;
    margin-top: 6px;
  }

  &--mobile {
    // justify-content: flex-end;
    margin-top: -8px;
  }

  @include screen-from("tablet-wide") {
    &--web {
      display: flex;
    }

    &--mobile {
      display: none;
    }
  }
}

.tag {
  color: white;
  font-size: 13px;
  text-transform: uppercase;
  font-family: $font-text;
  padding: 9px 11px;
  border-radius: 3px;
  box-shadow: $shadow-general;
  margin-right: 8px;
  margin-top: 8px;
  background-color: $text-alt;
  font-weight: 600;
  letter-spacing: 0.02rem;

  box-shadow: none;
}

.title {
  margin-top: 8px;
  font-family: $font-title;
  color: $text-dark;
  letter-spacing: -0.01rem;
  font-size: 1.5rem;
  line-height: 130%;
  font-weight: 600;

  max-width: 585px;

  @include screen-from("tablet") {
    margin-top: 0;
  }
}

.text {
  margin-top: 17px;
  font-size: 0.875rem;
  line-height: 140%;
  font-family: $font-text;
  height: 40px;
  overflow-y: hidden;
  letter-spacing: 0.02rem;

  max-width: 550px;
}

.content-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.images {
  display: flex;
  flex-flow: row wrap;
  // margin-top: 12px;
}

.image {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: $bg-general;
  margin-top: 8px;
  margin-right: 8px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid rgba($bg-mobile-menu, 0.1);

  @include screen-from("tablet") {
    width: 80px;
    height: 80px;
  }
}

.time {
  font-family: $font-text;
  font-size: 0.875rem;
  color: $text-alt;
  opacity: 0.6;
  margin-top: -2px;
}
</style>
