import { inUsers, usersActions, usersGetters } from "@/store/users";
import { Settings } from "luxon";
import { App } from "vue-demi";
import { createI18n, LocaleMessages, VueMessageType } from "vue-i18n";
import store from "../store/index";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): LocaleMessages<VueMessageType> {
  const locales = require.context(
    "../locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: LocaleMessages<VueMessageType> = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

export const i18n = createI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || "nl",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
  globalInjection: true,
});

export const setLocale = (locale: string) => {
  Settings.defaultLocale = locale;
  store.dispatch(inUsers(usersActions.SET_LANGUAGE), { language: locale });
};

export const getLocale = () => {
  return store.getters[inUsers(usersGetters.LANGUAGE)];
};

export const installI18n = (app: App) => {
  return app.use(i18n);
};
