<template>
  <div class="residents">
    <div
      class="resident-wrapper"
      v-for="resident in residents"
      :key="resident.id"
    >
      <card
        :tags="tagsForUser(resident)"
        tagsPosition="right"
        @click="showUser(resident)"
      >
        <div class="resident resident2">
          <div
            v-if="resident.avatar_url != null"
            class="profile-picture"
            :style="{ 'background-image': `url('${resident.avatar_url}')` }"
          ></div>
          <div class="profile-picture" v-else>
            <empty-profile-icon class="empty-icon" />
          </div>
          <div class="details">
            <div class="name">
              {{ resident.first_name }} {{ resident.last_name }}
              <!-- <span class="admin" v-if="property?.admin_id === resident.id"
              >({{ $t("common.admin") }})</span
            > -->
            </div>
            <div class="bottom-details">
              <div
                v-if="resident.email_is_public || isAdmin"
                class="bottom-detail"
              >
                {{ resident.email }}
              </div>
              <div
                v-if="resident.tel != null && resident.tel !== ''"
                class="bottom-detail"
              >
                {{ resident.tel }}
              </div>
              <!-- <div class="join-date">
                {{ timeago(resident.inserted_at, " geleden").toLowerCase() }}
              </div> -->
              <!-- <div class="shares"> -->
              <!-- TODO: Translate duizendsten -->
              <!-- {{ sharesForResident(resident.id) }} duizendsten -->
              <!-- // TODO: only show when admin -->
              <!-- <div class="shares-link">
                <app-link @click="editShares(resident)">Aanpassen</app-link>
              </div> -->
              <!-- </div> -->
            </div>
          </div>
        </div>
      </card>
    </div>

    <!-- <div class="resident" v-for="resident in residents" :key="resident.id">
      <div
        v-if="resident.avatar_url != null"
        class="profile-picture"
        :style="{ 'background-image': `url('${resident.avatar_url}')` }"
      ></div>
      <div class="profile-picture" v-else>
        <empty-profile-icon class="empty-icon" />
      </div>
      <div class="details">
        <div class="name">
          {{ resident.first_name }} {{ resident.last_name }}
          <span class="admin" v-if="property?.admin_id === resident.id"
            >({{ $t("common.admin") }})</span
          >
        </div>
        <div class="bottom-details">
          <div class="shares">
            {{ sharesForResident(resident.id) }} duizendsten
            <div class="shares-link">
              <app-link @click="editShares(resident)">Aanpassen</app-link>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script lang="ts">
import { User } from "@/types";
import { defineComponent, PropType } from "vue";
import EmptyProfileIcon from "@/components/icons/EmptyProfile.vue";
import { sharesForResident } from "@/util/properties";
import { Property } from "@/repositories/propertyRepository";
import { CardTag, CardTagType } from "../common/Card.vue";
import { showViewResidentPopup } from "@/features/residents/popups/ViewResident.vue";
import { isCurrentPropertyAdmin } from "@/util/auth";

export default defineComponent({
  components: { EmptyProfileIcon },
  props: {
    residents: {
      type: Array as PropType<User[]>,
      required: true,
    },
    property: {
      type: Object as PropType<Property | null>,
    },
  },
  setup(props) {
    const isAdmin = isCurrentPropertyAdmin();

    return {
      isAdmin,
      tagsForUser(user: User): CardTag[] {
        if (props.property?.admin_id === user.id) {
          return [{ type: CardTagType.INFO, text: "Beheerder" }];
        }
        return [];
      },
      sharesForResident(residentId: number) {
        if (props.property == null) return 0;
        return sharesForResident(props.property, residentId) ?? 0;
      },
      async showUser(resident: User) {
        if (isAdmin.value) {
          showViewResidentPopup(resident);
        }
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.residents {
  width: 100%;
  padding-bottom: 20px;
}

.resident2 {
  padding: 15px 20px;
}

.resident {
  margin-top: 20px;
  width: 100%;
  /* padding: 0 20px; */
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &:first-child {
    margin-top: 0;
  }
}

.profile-picture {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: $bg-accent;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 13px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.details {
  display: flex;
  flex-flow: column nowrap;
}

.name {
  font-size: 1.125rem;
  font-weight: 600;
  color: $text-alt;
  font-family: $font-title;
}

.join-date {
  font-size: 0.875rem;
  color: $text-alt;
  margin-top: 7px;
  letter-spacing: 0.01rem;
}

.add-icon-wrapper {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.shares {
  font-size: 0.875rem;
  color: $text-alt;
  margin-top: 7px;
  letter-spacing: 0.04rem;
  /* padding-left: 20px; */
}

.shares-link {
  display: inline-block;
  padding-left: 10px;
}

.admin {
  font-weight: 400;
  font-size: 1rem;
}

.add-icon-wrapper {
  @include screen-from("tablet-wide") {
    display: none;
  }
}

.add-button {
  display: none;

  @include screen-from("tablet-wide") {
    display: block;
  }
}

.empty-icon {
  width: 60%;
  height: 60%;
}

.bottom-details {
  display: flex;
  flex-flow: column nowrap;
  opacity: 0.7;
  font-size: 14px;
  margin-top: 2px;
  letter-spacing: 0.02em;
}

.bottom-detail {
  margin-top: 4px;
}

.resident-wrapper {
  margin-bottom: 20px;
}
</style>
