<template>
  <div>
    <page-loader />
  </div>
</template>

<script lang="ts">
import { accountingActions, inAccounting } from "@/store/accounting/accounting";
import { authActions, inAuth } from "@/store/auth";
import { inProperties, PropertiesActions } from "@/store/properties";
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const bankId = route.params.bankId;
    const propertyId = route.params.propertyId;
    const token = route.params.token;

    if (bankId == null || propertyId == null || token == null) {
      router.push({ name: "login" });
      return;
    }

    const run = async () => {
      try {
        await store.dispatch(inAuth(authActions.SESSION_FROM_SU_TOKEN), token);
        await store.dispatch(inProperties(PropertiesActions.SELECT_PROPERTY), {
          propertyId,
        });

        const singleUseToken = await store.dispatch(
          inAuth(authActions.CREATE_SINGLE_USE_TOKEN)
        );

        const baseUrl = process.env.VUE_APP_BASE_URL;
        // const callbackUrl =
        //   baseUrl +
        //   `/accounting/select_bank/finish/${propertyId}/${singleUseToken}`;
        const callbackUrl =
          baseUrl + `/accounting/select_bank/finish_mobile/${singleUseToken}`;

        console.log("CallbackUrl:", callbackUrl);
        const link = await store.dispatch(
          inAccounting(accountingActions.BUILD_LINK),
          {
            redirectTo: callbackUrl,
            bankAspspId: bankId,
            propertyId: propertyId,
          }
        );
        console.log("Built link:", link);
        window.location.href = link;
      } catch (e) {
        console.log("Failed:", e);
        router.push({ name: "login" });
      }
    };
    run();
  },
});
</script>
