
import { Item } from "@/types";
import { defineComponent, PropType } from "vue";
import ItemPreview from "./ItemPreview.vue";

export default defineComponent({
  components: { ItemPreview },
  props: {
    item: {
      type: Object as PropType<Item>,
      required: true,
    },
  },
});
