<template>
  <svg
    :width="width"
    :height="width"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23 12C23 10.895 22.105 10 21 10H19.738C19.549 9.268 19.261 8.578 18.886 7.942L19.778 7.05C20.559 6.269 20.559 5.003 19.778 4.222C18.997 3.441 17.731 3.441 16.95 4.222L16.058 5.114C15.422 4.739 14.732 4.451 14 4.262V3C14 1.896 13.105 1 12 1C10.895 1 10 1.895 10 3V4.262C9.268 4.451 8.578 4.739 7.942 5.114L7.05 4.222C6.269 3.441 5.003 3.441 4.222 4.222C3.441 5.003 3.441 6.269 4.222 7.05L5.114 7.942C4.739 8.578 4.451 9.268 4.262 10H3C1.896 10 1 10.895 1 12C1 13.105 1.895 14 3 14H4.262C4.451 14.732 4.739 15.422 5.114 16.058L4.222 16.95C3.441 17.731 3.441 18.997 4.222 19.778C5.003 20.559 6.269 20.559 7.05 19.778L7.942 18.886C8.577 19.261 9.268 19.549 10 19.738V21C10 22.104 10.895 23 12 23C13.105 23 14 22.105 14 21V19.738C14.732 19.549 15.422 19.261 16.058 18.886L16.95 19.778C17.731 20.559 18.997 20.559 19.778 19.778C20.559 18.997 20.559 17.731 19.778 16.95L18.886 16.058C19.261 15.423 19.549 14.732 19.738 14H21C22.104 14 23 13.105 23 12Z"
      stroke="currentColor"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
      stroke="currentColor"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    width: {
      type: String,
      default: "24",
    },
    color: {
      type: String,
      default: "#111",
    },
  },
});
</script>
