<template>
  <!-- <generic-page title="Financiën">
    <div>test</div>
    Testing
  </generic-page> -->
  <generic-finance-page>
    <button @click="openMenu">Open</button>
    test
    <!--  -->
  </generic-finance-page>
</template>

<script lang="ts">
import { inOther, OtherActions } from "@/store/other";
import { defineComponent } from "vue";
import { useStore } from "vuex";
import GenericFinancePage from "../components/GenericFinancePage.vue";

export default defineComponent({
  components: { GenericFinancePage },
  setup() {
    const store = useStore();
    console.log("Overview");

    return {
      openMenu() {
        store.dispatch(inOther(OtherActions.SHOW_FINANCE_SIDEBAR));
      },
    };
  },
});
</script>
