
import { computed, defineComponent, PropType, ref } from "vue";
import { formatAsEuro } from "@/util/price";
import { Transaction } from "@/types";
import { DateTime } from "luxon";
import AddInvoicePopup from "@/components/popups/AddInvoice.vue";
import { showPopup } from "@/components/popups/popups";
import Invoice from "./Invoice.vue";
import AngleDownIcon from "@/components/icons/AngleDown.vue";
import AddResidentPayment from "@/components/popups/AddResidentPayment.vue";
import ResidentPayment from "./ResidentPayment.vue";
import AdditionalInfoPopupVue from "./AdditionalInfoPopup.vue";
import { isTransactionFinished } from "@/store/accounting/util";

export default defineComponent({
  components: { Invoice, AngleDownIcon, ResidentPayment },
  props: {
    transaction: {
      type: Object as PropType<Transaction>,
      required: true,
    },
  },
  setup(props) {
    // const { t } = useI18n();
    const showDetails = ref(false);

    const addInvoice = async () => {
      await showPopup(
        AddInvoicePopup,
        {
          transaction: props.transaction,
        },
        true
      );
    };

    const addPayment = async () => {
      await showPopup(
        AddResidentPayment,
        {
          transaction: props.transaction,
        },
        true
      );
    };

    const hasInvoices = computed(() => {
      return (
        props.transaction.invoices && props.transaction.invoices.length > 0
      );
      // const invoices = props.transaction.invoices;
      // return invoices != null && invoices.length > 0;
    });
    const hasPayments = computed(() => {
      const payments = props.transaction.resident_payments;
      return payments != null && payments.length > 0;
    });

    const isComplete = computed(() => {
      return isTransactionFinished(props.transaction);
    });

    return {
      formatAsEuro,
      hasInvoices,
      hasPayments,
      isComplete,
      showDetails,
      showAddInfo(info: string) {
        showPopup(AdditionalInfoPopupVue, {
          info,
        });
      },
      isOutgoing: computed(() => props.transaction.type === "out"),
      // parseAdditionalInfo(additionalInfo: string) {
      //   if (additionalInfo == null) return "";
      //   console.log(additionalInfo);
      //   return additionalInfo
      //     .split(",")
      //     .map((item) => item.replaceAll("'", ""))
      //     .join("\n");
      // },
      formattedDate: computed(() => {
        if (props.transaction == null) return "";
        const dt = DateTime.fromISO(props.transaction.value_date);
        return dt.toFormat("dd LLLL yyyy");
      }),
      formattedDateShort: computed(() => {
        if (props.transaction == null) return "";
        const dt = DateTime.fromISO(props.transaction.value_date);
        return `${dt.toFormat("dd LLL")} '${dt.toFormat("yy")}`;
      }),
      addInvoice,
      addPayment,
    };
  },
});
