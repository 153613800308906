
import { defineComponent } from "vue";
import GeneratedForm, {
  FieldsSettings,
} from "@/components/form/GeneratedForm.vue";

export default defineComponent({
  components: { GeneratedForm },
  setup() {
    const fieldSettings: FieldsSettings = [
      {
        name: "notifications",
        type: "boolean",
        label: "Ontvang notificaties bij nieuwe transacties",
        smaller: true,
      },
    ];

    return {
      fieldSettings,
    };
  },
});
