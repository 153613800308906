<template>
  <div class="wrapper">
    <popper hover :content="msg">
      <div class="questionmark">?</div>
    </popper>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    msg: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.questionmark {
  width: 20px;
  height: 20px;
  border: 1px solid $border-general;
  border-radius: 50%;
  color: $text-dark;
  background-color: white;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  position: relative;
  z-index: 10;
}
</style>
