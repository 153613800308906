import { Meeting } from "@/types";
import { Ref } from "vue";
import { useStoreAction } from "../util/hooks";
import { inMeetings, meetingsActions } from "./meetings";

export const useMeeting = (issueId: Ref<string | number>) => {
  const result = useStoreAction<null | Meeting>(
    null,
    (store) =>
      store.dispatch(inMeetings(meetingsActions.BY_ID), { id: issueId.value }),
    ["meetings"]
  );
  return {
    loading: result.loading,
    meeting: result.value,
    refetch: result.refetch,
  };
};

export const useMeetings = (propertyId: Ref<string | number | null>) => {
  const result = useStoreAction<Meeting[]>(
    [],
    (store) => {
      if (propertyId.value == null) return [];
      return store.dispatch(inMeetings(meetingsActions.LIST), {
        propertyId: propertyId.value,
      });
    },
    ["meetings"]
  );

  return {
    loading: result.loading,
    meetings: result.value,
    refetch: result.refetch,
  };
};
