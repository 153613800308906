<template>
  <div
    class="property-listing"
    @click="$emit('select')"
    :class="{ 'no-hover': noHover }"
  >
    <h2 class="title">{{ title }}</h2>
    <h3 class="sub-title">{{ subTitle }}</h3>
    <div class="bottom-text" v-if="bottomLink == null">{{ bottomText }}</div>
    <!-- Used in user property view -->
    <div v-if="bottomLink != null" class="bottom-link">
      <app-link :to="bottomLink">{{ bottomText }}</app-link>
    </div>

    <div class="bg"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["select"],
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    bottomText: {
      type: String,
      required: true,
    },
    bottomLink: {
      type: String,
      default: null,
    },
    noHover: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.property-listing {
  width: 100%;
  background-color: white;
  border: 1px solid $border-general;
  box-shadow: 0px 5px 21px rgba(22, 20, 38, 0.04);
  padding: 20px;
  border-radius: 5px;
  box-sizing: border-box;
  height: 168px;
  position: relative;
  margin-bottom: 20px;

  &:hover {
    border: 1px solid darken($border-general, 20%);
    cursor: pointer;
  }

  &.no-hover:hover {
    cursor: auto;
    border: 1px solid $border-general;
  }
}

.title {
  line-height: 120%;
  margin-top: -3px;
  color: $text-dark;
  font-family: $font-title;
  font-size: 1.5rem;
  font-weight: 600;
}

.sub-title {
  font-size: 1rem;
  font-weight: 500;
  color: $text-dark;
  font-family: $font-text;
  line-height: 130%;
  margin-top: 10px;
}

.bottom-text {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #938d76;
  font-size: 0.875rem;
  letter-spacing: 0.02rem;
  line-height: 1.125rem;
}

.bottom-link {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.bg {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 140px;
  height: 100px;
  background-image: url("/img/property_listing_bg.svg");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
