<template>
  <generic-page-popup title="Melding aanpassen" :loading="issue == null">
    <div class="create-issue">
      <generated-form
        submitText="Opslaan"
        :fieldSettings="settings"
        :submitHandler="submit"
        :fieldValues="issue"
      />
    </div>
  </generic-page-popup>
</template>

<script lang="ts">
import { showPopup } from "@/components/popups/popups";
import { computed, defineComponent, PropType, Ref } from "vue";
import GeneratedForm, {
  FieldsSettings,
} from "@/components/form/GeneratedForm.vue";
import { useLocationOptions, useIssue } from "@/store/issues/hooks";
import { useActiveProperty } from "@/util/properties";
import { useStore } from "vuex";
import { inIssues, issueActions } from "@/store/issues";
import { optimizeImage } from "@/util/image";
import { filesActions, inFiles } from "@/store/files";
import { Issue } from "@/repositories/issueRepository";

const component = defineComponent({
  components: { GeneratedForm },
  props: {
    params: {
      type: Object as PropType<{ issueId: number }>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const property = useActiveProperty();
    const locationOptions = useLocationOptions(property);
    const issueId = computed(() => props.params.issueId);
    const { data: issue } = useIssue(issueId);

    const settings: Ref<FieldsSettings> = computed(
      () =>
        [
          {
            type: "string",
            name: "title",
            label: "Titel",
            meta: { placeholder: "Lamp tweede verdieping stuk" },
          },
          {
            type: "dropdown",
            name: "location",
            label: "Locatie",
            meta: {
              placeholder: "Selecteer locatie",
              options: locationOptions.value,
            },
          },
          {
            type: "textarea",
            name: "message",
            label: "Beschrijving",
            meta: {
              placeholder: "Deze morgen zag ik...",
            },
          },
          {
            type: "images",
            name: "pictures",
            label: "Foto's",
            meta: {
              async upload(file: File) {
                const blob = await optimizeImage(file, 1000);
                return await store.dispatch(inFiles(filesActions.UPLOAD), {
                  file: blob,
                });
              },
            },
          },
        ] as FieldsSettings
    );

    return {
      settings,
      issue,
      async submit(formData: any) {
        if (property.value == null) return;
        if (issueId.value == null) return;
        await store.dispatch(inIssues(issueActions.UPDATE), {
          id: issueId.value,
          updates: formData,
        });
        emit("finish");
      },
    };
  },
});

export const showEditIssuePopup = (issueId: number): Promise<null | Issue> => {
  return showPopup(component, { issueId });
};

export default component;
</script>
