<template>
  <!-- 
    This is a full page popup on mobile and a normal popup on desktop.
   -->
  <div
    class="generic-page-popup"
    :class="{
      mt: useMarginTop,
      ios,
    }"
  >
    <div class="bg" @click="$emit('finish')" v-if="active"></div>
    <div class="box">
      <div class="close-button" @click="$emit('finish')">
        <close-icon size="14" />
      </div>
      <page-loader v-if="loading" />
      <div class="box-wrapper" v-else>
        <generic-page-header
          :title="title"
          back-button
          @back="$emit('finish')"
          :options="options"
        />
        <div class="content">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import GenericPageHeader from "./GenericPageHeader.vue";
import CloseIcon from "@/components/icons/Times.vue";
import { GenericPageOption } from "./GenericPageOptions.vue";
import { isShittyPlatform } from "@/util/platform";

export default defineComponent({
  components: { GenericPageHeader, CloseIcon },
  props: {
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as PropType<GenericPageOption[]>,
      default: null,
    },
    active: {
      type: Boolean,
      default: true,
    },
    useMarginTop: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      ios: isShittyPlatform(),
    };
  },
});
</script>

<style lang="scss" scoped>
.generic-page-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $bg-general;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 202;
  box-sizing: border-box;

  @include screen-from("tablet-wide") {
    background-color: transparent;

    &.mt {
      align-items: flex-start;
      padding-top: 15vh;
    }
  }

  &.ios {
    margin-top: 25px;

    @include screen-from("tablet-wide") {
      margin-top: 0;
    }
  }
}

.bg {
  @include screen-from("tablet-wide") {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.7);
    z-index: 1;
  }
}

.box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 2;
  overflow: visible;

  @include screen-from("tablet-wide") {
    position: relative;
    background-color: $bg-general;
    max-width: 650px;
    height: auto;
    border-radius: 7px;
    box-shadow: 0 20px 20px 30px rgba(#000, 0.1);
    /* max-height: 90vh; */
    /* overflow-y: auto; */
  }
}

.box-wrapper {
  padding: 0 30px;
  overflow-y: auto;
  height: 100%;
  // TODO: Check if this has any consequences anywhere else
  // overflow-y: auto;
  // max-height: 100%;

  @include screen-from("tablet-wide") {
    position: relative;
    box-sizing: border-box;
    padding: 20px 40px;
    max-height: 90vh;
  }
}

.content {
  margin-top: 20px;
}

.close-button {
  display: none;

  @include screen-from("tablet") {
    cursor: pointer;
    display: block;
    position: absolute;
    top: -16px;
    right: 10px;
    width: 32px;
    height: 32px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    background-color: $bg-mobile-menu;
    color: white;
    border-radius: 50%;
    z-index: 3;
  }
}
</style>
