
import { useRoute, useRouter } from "vue-router";
import {
  Component,
  defineComponent,
  onBeforeUnmount,
  shallowRef,
  watchEffect,
} from "vue";
import Auth from "@/layouts/Auth.vue";
import Default from "@/layouts/Default.vue";
import Empty from "@/layouts/Empty.vue";
import ToastViewer from "@/components/notifications/ToastViewer.vue";
import PopupView from "@/components/popups/PopupView.vue";
import { App } from "@capacitor/app";
import {
  getPlatform,
  isMobilePlatform,
  isShittyPlatform,
  isWebPlatform,
  useIsActive,
} from "./util/platform";
import { useCurrentUser } from "./util/auth";
import appSocket from "./channels/appSocket";
import { LocalNotifications } from "@capacitor/local-notifications";
import { Haptics } from "@capacitor/haptics";

const layouts: { [name: string]: Component } = {
  Default,
  Auth,
  Empty,
};

export default defineComponent({
  components: { ToastViewer, PopupView },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const layout = shallowRef(layouts["Default"]);

    LocalNotifications.checkPermissions().then((permissionStatus) => {
      if (
        ["prompt", "prompt-with-rationale"].includes(permissionStatus.display)
      ) {
        LocalNotifications.requestPermissions();
      }
    });

    watchEffect(() => {
      if (route.meta?.layout != null) {
        layout.value = layouts[route.meta.layout as string] || Default;
      } else {
        layout.value = Default;
      }
    });

    if (getPlatform() === "android") {
      const router = useRouter();

      App.addListener("backButton", ({ canGoBack }) => {
        if (!canGoBack) {
          console.log("Can't go back");
          App.exitApp();
        }
        router.go(-1);
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    App.addListener("appUrlOpen", (event) => {
      // TODO: route to the correct location
      // const slug = event.url.split(".app").pop();
      router.push("/");
    });

    const currentUser = useCurrentUser();
    const isActive = useIsActive();

    const audio = new Audio("/notification.mp3");

    const unbindNotification = appSocket
      .getChatChannels()
      .onAnyMessage(async (data) => {
        if (data.message.author_id === currentUser.value?.id) return;

        if (isActive.value) {
          if (isMobilePlatform()) {
            Haptics.vibrate();
          }

          return;
        }

        if (isWebPlatform()) {
          audio.play();
        } else {
          LocalNotifications.schedule({
            notifications: [
              {
                smallIcon: "ic_launcher",
                title: "MySyndic: Nieuw bericht",
                body: data.message.text,
                id: data.message.id,
                sound: "/notification.mp3",
              },
            ],
          });
        }
      });
    onBeforeUnmount(() => {
      unbindNotification();
    });

    // CapacitorApp.addListener("backButton", () => {});
    // BackButtonListener.

    return {
      layout,
      route,
      isIos: isShittyPlatform(),
    };
  },
});
