<template>
  <div class="sidebar-profile-info" v-if="currentUser != null">
    <div
      v-if="currentUser.avatar_url != null"
      class="image"
      :style="{
        'background-image': `url('${currentUser.avatar_url}')`,
      }"
    ></div>
    <div v-else class="image">
      <empty-profile-icon class="empty-icon" />
    </div>
    <div class="user-specifics">
      <div class="name">
        {{ capitalize(currentUser.first_name) }}
        {{ capitalize(currentUser.last_name) }}
      </div>
      <div class="role">
        <template v-if="isAdmin">{{ $t("auth.roles.admin") }}</template>
        <template v-else>{{ $t("auth.roles.resident") }}</template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useCurrentUser } from "@/util/auth";
import { useActiveProperty } from "@/util/properties";
import { capitalize, computed, defineComponent } from "vue";
import EmptyProfileIcon from "@/components/icons/EmptyProfile.vue";

export default defineComponent({
  components: { EmptyProfileIcon },
  setup() {
    const currentUser = useCurrentUser();
    const activeProperty = useActiveProperty();

    const isAdmin = computed(
      () =>
        currentUser.value != null &&
        currentUser.value.id === activeProperty.value?.admin_id
    );

    return {
      currentUser,
      capitalize,
      isAdmin,
    };
  },
});
</script>

<style lang="scss" scoped>
.sidebar-profile-info {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
}

.image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 15px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  background-color: $bg-accent;
}

.empty-icon {
  width: 60%;
  height: 60%;
}

.name {
  font-weight: bold;
  color: $text-alt;
}

.role {
  font-weight: 400;
  color: $text-dark;
  opacity: 0.6;
  font-size: 0.8rem;
  padding-top: 3px;
}

.user-specifics {
  display: flex;
  flex-flow: column nowrap;
}
</style>
