
import { defineComponent } from "vue";
import FinanceSidebar from "./FinanceSidebar.vue";

export default defineComponent({
  components: { FinanceSidebar },
  setup() {
    console.log("Finance layout");
  },
});
