<template>
  <div
    class="spinner"
    :style="{
      width,
      height,
      'border-top-width': thickness,
      'border-right-width': thickness,
      'border-top-color': color,
      opacity,
    }"
  ></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    width: {
      type: String,
      default: "20px",
    },
    height: {
      type: String,
      default: "20px",
    },
    thickness: {
      type: String,
      default: "2px",
    },
    color: {
      type: String,
      default: undefined,
    },
    opacity: {
      type: String,
      default: "1",
    },
  },
});
</script>

<style lang="scss" scoped>
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  box-sizing: border-box;
  border-radius: 50%;
  border-top: 2px solid $text-dark;
  border-right: 2px solid transparent;
  animation: spinner 0.6s linear infinite;
  margin-left: auto;
  margin-right: auto;
}
</style>
