import { User, VerifyLoginCodeResponse } from "@/types";
import { ApiResponse, del, get, post, redirectTo } from "./repository";

export interface RegisterData {
  user: User;
}

export interface LoginData {
  user: User;
  access_token: string;
  refresh_token: string;
}

export default {
  register(params: {
    email: string;
    password: string;
    first_name: string;
    last_name: string;
  }): ApiResponse<RegisterData> {
    return post(
      "/auth/register",
      {
        user: params,
      },
      { noAuth: true }
    );
  },
  login(params: { email: string; password: string }): ApiResponse<LoginData> {
    return post("/auth/sessions", params, { noAuth: true });
  },
  refresh(refresh: string): ApiResponse<LoginData> {
    return post("/auth/sessions/refresh", { refresh }, { noAuth: true });
  },
  showMe(): ApiResponse<{ user: User }> {
    return get("/auth/sessions/me");
  },
  // oauth(provider: "facebook" | "google"): void {
  //   // return get(`/auth/${provider}`);
  //   redirectTo(`/auth/${provider}`);
  // },
  async googleLogin(idToken: string): ApiResponse<LoginData> {
    return post("/auth/google/login", { id_token: idToken }, { noAuth: true });
  },
  async appleLogin(
    idToken: string,
    firstName: string,
    lastName: string
  ): ApiResponse<
    LoginData | { state: "more_info"; token: string; email: string }
  > {
    return post(
      "/auth/apple/login",
      {
        id_token: idToken,
        params: {
          first_name: firstName,
          last_name: lastName,
        },
      },
      { noAuth: true }
    );
  },
  facebookRedirect(): void {
    redirectTo("/auth/facebook");
  },
  googleRedirect(): void {
    redirectTo("/auth/google");
  },
  async facebookLogin(fbAccessToken: string): ApiResponse<LoginData> {
    try {
      return await post(
        "/auth/facebook/login",
        {
          token: fbAccessToken,
        },
        { noAuth: true }
      );
    } catch (error) {
      const err: any = error;
      // TODO: Clean up
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(JSON.stringify(err.response.data));
        console.log(JSON.stringify(err.response.status));
        console.log(JSON.stringify(err.response.headers));
      } else if (err.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(JSON.stringify(err.request));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", JSON.stringify(err.message));
      }
      console.log(JSON.stringify(err.config));
      throw error;
    }
  },
  async createPasswordReset(email: string): ApiResponse<{ success: boolean }> {
    return post(`/auth/password_resets`, { email }, { noAuth: true });
  },
  async getPasswordReset(token: string): ApiResponse<{ valid: boolean }> {
    return get(`/auth/password_resets/${token}`, { noAuth: true });
  },
  async deletePasswordReset(
    token: string,
    newPassword: string
  ): ApiResponse<{ success: true }> {
    return del(
      `/auth/password_resets/${token}`,
      {
        new_password: newPassword,
      },
      { noAuth: true }
    );
  },
  async registerWithToken(
    token: string,
    userParams: {
      email: string;
      // password: string;
      first_name: string;
      last_name: string;
    }
  ): ApiResponse<LoginData> {
    return post(
      `/auth/register/${token}`,
      {
        user: userParams,
      },
      { noAuth: true }
    );
  },
  createSingleUseToken(): ApiResponse<{ token: string }> {
    return post(`/auth/single_use_tokens`);
  },
  createSessionFromSingleUseToken(token: string): ApiResponse<LoginData> {
    console.log(`/auth/single_use_tokens/${token}`);
    return post(`/auth/single_use_tokens/use`, { token }, { noAuth: true });
  },
  sendLoginCode(email: string): ApiResponse<{ success: true }> {
    return post(`/auth/login_codes`, { email }, { noAuth: true });
  },
  verifyLoginCode(
    code: string,
    email: string
  ): ApiResponse<VerifyLoginCodeResponse> {
    return post(
      `/auth/login_codes/${code}/verify`,
      { email },
      { noAuth: true }
    );
  },
  registerWithLoginCode(
    code: string,
    firstName: string,
    lastName: string,
    email: string
  ): ApiResponse<LoginData> {
    return post(
      `/auth/login_codes/${code}/register`,
      {
        first_name: firstName,
        last_name: lastName,
        email,
      },
      { noAuth: true }
    );
  },
  loginWithLoginCode(code: string, email: string): ApiResponse<LoginData> {
    return post(`/auth/login_codes/${code}/login`, { email }, { noAuth: true });
  },
};
