import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState } from ".";

interface OtherState {
  mobileSidebarOpen: boolean;
  financeSidebarOpen: boolean;
}

export const inOther = (value: string) => `other/${value}`;

export const OtherMutations = {
  HIDE_MOBILE_SIDEBAR: "HIDE_MOBILE_SIDEBAR",
  SHOW_MOBILE_SIDEBAR: "SHOW_MOBILE_SIDEBAR",
  TOGGLE_MOBILE_SIDEBAR: "TOGGLE_MOBILE_SIDEBAR",

  HIDE_FINANCE_SIDEBAR: "HIDE_FINANCE_SIDEBAR",
  SHOW_FINANCE_SIDEBAR: "SHOW_FINANCE_SIDEBAR",
  TOGGLE_FINANCE_SIDEBAR: "TOGGLE_FINANCE_SIDEBAR",
};

export const OtherActions = {
  HIDE_MOBILE_SIDEBAR: "HIDE_MOBILE_SIDEBAR",
  SHOW_MOBILE_SIDEBAR: "SHOW_MOBILE_SIDEBAR",
  TOGGLE_MOBILE_SIDEBAR: "TOGGLE_MOBILE_SIDEBAR",

  HIDE_FINANCE_SIDEBAR: "HIDE_FINANCE_SIDEBAR",
  SHOW_FINANCE_SIDEBAR: "SHOW_FINANCE_SIDEBAR",
  TOGGLE_FINANCE_SIDEBAR: "TOGGLE_FINANCE_SIDEBAR",
};

export const OtherGetters = {
  IS_MOBILE_SIDEBAR_OPEN: "IS_MOBILE_SIDEBAR_OPEN",
  IS_FINANCE_SIDEBAR_OPEN: "IS_FINANCE_SIDEBAR_OPEN",
};

const state: OtherState = {
  mobileSidebarOpen: false,
  financeSidebarOpen: false,
};

const getters: GetterTree<OtherState, RootState> = {
  [OtherGetters.IS_MOBILE_SIDEBAR_OPEN](state) {
    return state.mobileSidebarOpen;
  },
  [OtherGetters.IS_FINANCE_SIDEBAR_OPEN](state) {
    return state.financeSidebarOpen;
  },
};

const mutations: MutationTree<OtherState> = {
  [OtherMutations.HIDE_MOBILE_SIDEBAR](state) {
    state.mobileSidebarOpen = false;
  },
  [OtherMutations.SHOW_MOBILE_SIDEBAR](state) {
    state.mobileSidebarOpen = true;
  },
  [OtherMutations.TOGGLE_MOBILE_SIDEBAR](state) {
    state.mobileSidebarOpen = !state.mobileSidebarOpen;
  },

  [OtherMutations.HIDE_FINANCE_SIDEBAR](state) {
    state.financeSidebarOpen = false;
  },
  [OtherMutations.SHOW_FINANCE_SIDEBAR](state) {
    state.financeSidebarOpen = true;
  },
  [OtherMutations.TOGGLE_FINANCE_SIDEBAR](state) {
    state.financeSidebarOpen = !state.financeSidebarOpen;
  },
};

const actions: ActionTree<OtherState, RootState> = {
  [OtherActions.SHOW_MOBILE_SIDEBAR](context) {
    context.commit(OtherMutations.SHOW_MOBILE_SIDEBAR);
  },
  [OtherActions.HIDE_MOBILE_SIDEBAR](context) {
    context.commit(OtherMutations.HIDE_MOBILE_SIDEBAR);
  },
  [OtherActions.TOGGLE_MOBILE_SIDEBAR](context) {
    context.commit(OtherMutations.TOGGLE_MOBILE_SIDEBAR);
  },

  [OtherActions.SHOW_FINANCE_SIDEBAR](context) {
    context.commit(OtherMutations.SHOW_FINANCE_SIDEBAR);
  },
  [OtherActions.HIDE_FINANCE_SIDEBAR](context) {
    context.commit(OtherMutations.HIDE_FINANCE_SIDEBAR);
  },
  [OtherActions.TOGGLE_FINANCE_SIDEBAR](context) {
    context.commit(OtherMutations.TOGGLE_FINANCE_SIDEBAR);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
