<template>
  <div class="popup" v-if="show">
    <div class="bg" @click="$emit('finish')"></div>
    <div class="content" :style="{ 'max-width': maxWidth }">
      <div class="close-button" @click="$emit('finish')" v-if="!hideClose">
        <times-icon width="15px" height="15px" />
      </div>
      <div class="content-inner">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TimesIcon from "@/components/icons/Times.vue";

export default defineComponent({
  components: { TimesIcon },
  emits: ["finish"],
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: String,
      default: "600px",
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  display: flex;
  align-items: center;

  animation: showAnimation 0.1s ease-out;
}

@keyframes showAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // background-color: #1e1803;
  background-color: $bg-mobile-menu;
  opacity: 0.9;
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  background-color: white;
  box-sizing: border-box;
  border-radius: 10px;
}

.content-inner {
  overflow-y: auto;
  max-height: 80vh;
}

.close-button {
  position: absolute;
  top: -12.5px;
  right: 15px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: $bg-mobile-menu;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}
</style>
